import React, { useEffect } from "react";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import { Provider, useDispatch } from "react-redux";
import { App as AntdApp, ConfigProvider } from "antd";
import store from "./redux/store";
import { initializeAuth } from "./redux/authentication/authSlice";
import ProtectedRoute from "./route/ProtectedRoute";
import ThemeToggle from './theme/ThemeToggle';
import { useTheme } from './theme/ThemeContext';

// Componenti
import Login from "./pages/Login";
import DashboardLayout from "./components/DashboardLayout";
import Home from "./pages/Home";
import AR3dViewer from "./pages/AR3dViewer";
import FrameValidation from "./pages/FrameValidation";
import Analytics from "./pages/Analytics";
import DataConsumption from "./pages/DataConsumption";
import Profile from "./pages/Profile";
import OrderPage from "./pages/OrderPage";
import ARShades3dsViewer from "./pages/ARShades3dsViewer";
import ARShades3dViewer from "./pages/ARShades3dViewer";
import Gateways from "./pages/Gateways";
import ARShades3dViewerCustom from "./pages/ARShades3dViewerCustom";
import ARShades3dViewerCustomTwo from "./pages/ARShades3dViewerCustomTwo";
import ARShades3dViewerCustomThree from "./pages/ARShades3dViewerCustomThree";
import ARShades3dsTemplate from "./pages/ARShades3dsTemplate";
import AdminView from "./components/Admin/AdminView";
import Products from "./frameValidation/pages/Products";

const getLastVisitedPage = () => {
  return localStorage.getItem("lastVisitedPage") || "/home";
};

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        { path: "home", element: <Home /> },
        { path: "ar3dviewer", element: <AR3dViewer /> },
        { path: "framevalidation", element: <FrameValidation /> },
        { path: "framevalidation/:brandId", element: <Products /> },
        { path: "analytics", element: <Analytics /> },
        { path: "analytics-vtos", element: <Analytics /> },
        { path: "analytics-gateways", element: <Gateways /> },
        { path: "dataconsumption", element: <DataConsumption /> },
        { path: "profile", element: <Profile /> },
        { path: "admin", element: <AdminView /> },
        { path: "orderpage", element: <OrderPage /> },
        { path: "analytics/admin", element: <AdminView /> },
      ],
    },
    { path: "/login", element: <Login /> },
    {
      path: `${process.env.PUBLIC_URL}/Arshades3ds/:TokenBrand/glasses/:TokenGlasses`,
      element: <ARShades3dsViewer />,
    },
    {
      path: `${process.env.PUBLIC_URL}/Arshades3d/:TokenBrand/glasses/:TokenGlasses/variant/:TokenVariant`,
      element: <ARShades3dViewer />,
    },
    {
      path: `${process.env.PUBLIC_URL}/animation`,
      element: <ARShades3dViewerCustom />,
    },
    {
      path: `${process.env.PUBLIC_URL}/animationTwo`,
      element: <ARShades3dViewerCustomTwo />,
    },
    {
      path: `${process.env.PUBLIC_URL}/animationThree`,
      element: <ARShades3dViewerCustomThree />,
    },
    {
      path: `${process.env.PUBLIC_URL}/3d-animation/:templateId/variant/:variantId`,
      element: <ARShades3dsTemplate />,
    },
    {
      path: "*",
      element: <Navigate to={getLastVisitedPage()} />,
    },
  ],
  {
    future: {
      v7_startTransition: true,
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true,
    },
  }
);

// Componente che include il tema e la navigazione
const ThemedApp = () => {
  const dispatch = useDispatch();
  const { isDarkMode, toggleTheme } = useTheme();

  useEffect(() => {
    dispatch(initializeAuth());
  }, [dispatch]);

  return (
    <div className="App">
      <div style={{ position: 'fixed', top: 20, right: 20, zIndex: 1000 }}>
        <ThemeToggle isDarkMode={isDarkMode} toggleTheme={toggleTheme} />
      </div>
      <RouterProvider router={router} />
    </div>
  );
};

const App = () => (
  <Provider store={store}>
    <ConfigProvider>
      <AntdApp>
        <ThemedApp />
      </AntdApp>
    </ConfigProvider>
  </Provider>
);

export default App;