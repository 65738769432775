import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import CenteredLoader from "../components/CenteredLoader";

const ProtectedRoute = ({ children }) => {
    const { isAuthenticated, loading } = useSelector((state) => state.auth);
    const location = useLocation(); // Ottieni il percorso corrente

    if (loading) return <CenteredLoader />;

    if (!isAuthenticated) {
        localStorage.setItem("lastVisitedPage", location.pathname);
        return <Navigate to="/login" />;
    }

    return children;
};

export default ProtectedRoute;