import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listaBrand: [], // Inizia con una lista vuota
  isIconView: true, // Stato iniziale per la vista icona
};

const config = createSlice({
  name: "config",
  initialState,
  reducers: {
    setListaBrand(state, action) {
      state.listaBrand = action.payload; // Aggiorna la lista dei brand
    },
    setMenuIcon(state, action) {
      state.isIconView = action.payload; // Aggiorna la vista icona
    },
  },
});

export const configActions = config.actions;

export default config.reducer;