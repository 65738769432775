import React, { useEffect, useRef, useState } from "react";
import { Card, Spin, theme } from "antd";
import { EyeOutlined, CodeOutlined } from "@ant-design/icons";
import { statusMapping } from "../../services/utils/ar3dViewer";
import CustomTooltip from "../common/CustomTooltip";

function GlassCard({ model, navigate, onClickCard, onClickExportCode, onClickView }) {
    const { token } = theme.useToken();
    const canvasRef = useRef(null);
    const [processedImage, setProcessedImage] = useState(null);

    const removeWhiteBackground = (image) => {
        const canvas = canvasRef.current;

        if (!canvas) {
            console.error("Canvas non trovato");
            return;
        }

        const ctx = canvas.getContext("2d", { willReadFrequently: true });

        if (!ctx) {
            console.error("Impossibile ottenere il contesto 2D del canvas");
            return;
        }

        canvas.width = image.width;
        canvas.height = image.height;

        ctx.drawImage(image, 0, 0);

        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;

        for (let i = 0; i < data.length; i += 4) {
            const red = data[i];
            const green = data[i + 1];
            const blue = data[i + 2];
            if (red > 240 && green > 240 && blue > 240) {
                data[i + 3] = 0;
            }
        }

        ctx.putImageData(imageData, 0, 0);

        setProcessedImage(canvas.toDataURL("image/png"));
    };

    useEffect(() => {
        let isMounted = true;

        const img = new Image();
        img.src = model.poster3 ? model.poster3 : model.poster;
        img.crossOrigin = "anonymous";
        img.onload = () => {
            if (isMounted && canvasRef.current) {
                removeWhiteBackground(img);
            }
        };

        return () => {
            isMounted = false;
        };
    }, [model.poster3, model.poster]);

    const displayStatus = statusMapping[model.stato] || model.stato;

    const statusLabel = (model) => {
        const statusColors = {
            "Pubblicato": "#59B858",
            "In Pubblicazione": "#59B858",
            "Rev. Cliente": "#E3BA36",
            "Rev. Spaarkly": "#E3BA36",
            "Rev. Modellista": "#E3BA36",
            "Incompleto": "grey",
        };
        return statusColors[model.stato] || "grey";
    };

    return (
        <Card
            onClick={onClickCard}
            key={model.id}
            hoverable
            style={{
                borderRadius: "8px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
                position: "relative",
                backgroundColor: "white",
                width: "100%",
                maxWidth: "280px",
            }}
            cover={
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: 150,
                        overflow: "hidden",
                    }}
                >
                    {processedImage ? (
                        <img
                            src={processedImage}
                            alt={model.nomeOcchiale}
                            style={{
                                maxWidth: "85%",
                                maxHeight: "85%",
                                objectFit: "contain",
                                marginTop: "45px",
                            }}
                        />
                    ) : (
                        <Spin style={{ position: "absolute", top: "40%", left: "50%", transform: "translate(-50%, -50%)" }} />
                    )}
                </div>
            }
        >
            <div
                style={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    backgroundColor: statusLabel(model),
                    color: token.colorTextWhite,
                    padding: "4px 8px",
                    borderRadius: "16px",
                    fontSize: "12px",
                    fontWeight: "bold",
                }}
            >
                {displayStatus}
            </div>
            <div
                style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    backgroundColor: "#f5f5f5",
                    color: "black",
                    padding: "4px 8px",
                    borderBottomLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    fontSize: "12px",
                    fontWeight: "bold",
                }}
            >
                <strong style={{ fontSize: "16px" }}>{model.nomeOcchiale}</strong>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "16px",
                    marginBottom: 20,
                }}
            >
                <CustomTooltip title="Iframe codes">
                    <CodeOutlined
                        style={{
                            fontSize: "20px",
                            cursor: "pointer",
                            color: token.colorPrimary,
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            onClickExportCode();
                        }}
                    />
                </CustomTooltip>

                <CustomTooltip title="3D AR viewer">
                    <EyeOutlined
                        style={{
                            fontSize: "20px",
                            cursor: "pointer",
                            color: token.colorPrimary,
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            onClickView();
                        }}
                    />
                </CustomTooltip>
            </div>
            <canvas ref={canvasRef} style={{ display: "none" }} />
        </Card>
    );
}

export default GlassCard;