import dayjs from "dayjs";
import {
    collection, doc, getDoc, getDocs, query, where, orderBy, addDoc
} from "firebase/firestore";
import { db } from "../../data/base";
import * as XLSX from "xlsx";

// Imposta le date finali/iniziali usando dayjs
export const endDate = dayjs().endOf("day").toDate();
export const startDate = new Date(endDate);

// Funzioni di formattazione delle date con dayjs
const formatDate = (dateString) => dayjs(dateString).format("YYYY-MM-DD");
const formatDateTwo = (dateString) => dayjs(dateString).format("YYYY/MM/DD");

// Recupera i nomi delle linee da Firestore
const getLineNames = async (lineRefs) => {
    const lineNames = {};
    const promises = lineRefs.map(async (lineRef) => {
        const docRef = doc(db, "Linea", lineRef);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            lineNames[lineRef] = docSnap.data().nome_linea;
        } else {
            lineNames[lineRef] = "Unknown Linea";
        }
    });
    await Promise.all(promises);
    return lineNames;
};

// Funzioni di supporto per il calcolo delle medie
const calculateAverageSessionTime = (totalTime, numberOfSessions) =>
    numberOfSessions === 0 ? 0 : totalTime / numberOfSessions;

const calculateAverageSessionsPerUser = (numberOfSessions, uniqueUsersCount) =>
    uniqueUsersCount === 0 ? 0 : numberOfSessions / uniqueUsersCount;

const calculateAverageEngagementTime = (totalTime, uniqueUsersCount) =>
    uniqueUsersCount === 0 ? 0 : totalTime / uniqueUsersCount;

// FUNZIONE PRINCIPALE: Recupero e aggregazione dati
export const fetchData = async (date, brandId, props) => {
    try {
        const startDateObj = dayjs(date).startOf("day").toDate();
        const endDateObj = dayjs(date).endOf("day").toDate();

        const startDateTwo = dayjs(startDateObj).format("YYYY/MM/DD") + " 00:00:00";
        const endDateTwo = dayjs(endDateObj).format("YYYY/MM/DD") + " 23:59:59";
        const formattedDate = dayjs(date).format("YYYY/MM/DD");

        // Recupera il brand e il suo code_path
        const brandRef = doc(db, "Brand", brandId);
        const brandDoc = await getDoc(brandRef);
        const brandData = brandDoc.data();
        const brandCodePath = brandData.code_path;

        const modelsMap = await fetchModelDocuments(brandCodePath);
        const allData = await fetchAllData(brandRef, startDateTwo, endDateTwo);

        if (
            allData["Sessione"].length === 0 &&
            allData["Sessione_WebVto"].length === 0 &&
            allData["Sessione_Visualizzatori3d"].length === 0
        ) {
            return false;
        }

        for (const [collectionName, sessionsData] of Object.entries(allData)) {
            const uniqueUsers = new Set(
                sessionsData.map(
                    (session) =>
                        `${formatDate(session.data_inizio_sessione)}|${session.device_id}|${session.device}`
                )
            );

            const service =
                collectionName === "Sessione"
                    ? "APP"
                    : collectionName === "Sessione_WebVto"
                        ? "WEB VTO"
                        : "3D VIEWER";

            const aggregatedDataExists = await checkIfAggregatedDataExists(
                formattedDate,
                brandRef,
                service
            );
            if (aggregatedDataExists) {
                return false;
            }

            const metricsData = await calculateMetrics(
                sessionsData,
                uniqueUsers,
                modelsMap,
                service,
                brandRef
            );
            await uploadAnalyticsToFirebase(metricsData, service, formattedDate, brandRef);
        }
        return true;
    } catch (error) {
        console.error("Errore nel recupero dei dati delle sessioni:", error);
        return false;
    }
};

const fetchDataFromCollection = async (collectionName, brandRef, startDateTwo, endDateTwo) => {
    const colRef = collection(db, collectionName);
    const q = query(
        colRef,
        where("ref_catalogo", "==", brandRef),
        where("data_inizio_sessione", ">=", startDateTwo),
        where("data_inizio_sessione", "<=", endDateTwo),
        orderBy("data_inizio_sessione")
    );
    const querySnapshot = await getDocs(q);
    const sessionsData = [];
    querySnapshot.forEach((docSnap) => {
        sessionsData.push({ id: docSnap.id, ...docSnap.data() });
    });
    return sessionsData;
};

const fetchAllData = async (brandRef, startDateTwo, endDateTwo) => {
    const collections = ["Sessione", "Sessione_WebVto", "Sessione_Visualizzatori3d"];
    const allData = {};
    for (const coll of collections) {
        const sessionsData = await fetchDataFromCollection(coll, brandRef, startDateTwo, endDateTwo);
        allData[coll] = sessionsData;
    }
    return allData;
};

// Funzione per estrapolare il browser dai dati del dispositivo
const extractBrowserFromDevice = (device) => {
    const browserMatch = device.match(
        /(Firefox|Chrome|Safari|Opera|Edge|Trident|FBAV|Instagram|SamsungBrowser)\/?\s*(\d+)/i
    );
    if (browserMatch) {
        let browser = browserMatch[1];
        let deviceType = /Mobile|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile Safari/i.test(device)
            ? "Mobile"
            : "Desktop";
        return `${browser} (${deviceType})`;
    }
    return "Unknown";
};

// Recupera i documenti dei modelli in base a brandCodePath
const fetchModelDocuments = async (brandCodePath) => {
    const modelsMap = new Map();
    const colRef = collection(db, "Modello");
    const q = query(
        colRef,
        where("loadingId", ">=", `${brandCodePath}0000000000000`),
        where("loadingId", "<", `${parseInt(brandCodePath) + 1}0000000000000`)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((docSnap) => {
        modelsMap.set(docSnap.id, docSnap.data());
    });
    return modelsMap;
};

// Filtra gli occhiali in base a lineaRef
const filterOcchialiByLineaRef = (occhiali, validLineaRefs) =>
    occhiali.filter((occhiale) => {
        const lineaRefId = occhiale.lineaRef && occhiale.lineaRef.id;
        return validLineaRefs.includes(lineaRefId);
    });

// Valori validi di lineaRef per ciascun brand
const validLineaRefsForBrand = {
    rBf3geYISXtkue0WGgFT: [
        "EVW9rjBgsniZsnAjPWH2", "dWi02Aq4RFgYAGZbARuo", "Hdpt0OVId4SBueQObzDJ",
        "Rh3tjgtWCIXz0QX6bCZF", "e1nr6YatnWeXN9QdVvUd", "SUTMvKlcQ9WAl45vQeHg",
        "4QNS5YCaJfqBqFVra1ot", "SODMSCfYr9x0da8saeB3", "nYZivzRPTJ3qAbEFDUpZ"
    ],
    qmDTd8ciaSs31akT3Eqq: [
        "n66oxhJgPyXJcT1UbS72", "BfC4Zie62Ai8HMPxpHwk", "wXvdZoM0PvtatPWSZsVK",
        "mUPKV87BSyMotmn3UHC2", "k5BB0LXiVy8TxWNGmjKi", "hpjqsiNYFMUIjz4PMF7L",
        "5XN78gw7rupJArC66kty", "oP6IeFnBHZYpwMCrdBJ9", "aNY4n03jKOeZsUE2CpWk",
        "Nft8HyvXPr3VRfOqK9Ba", "beMSQNwdNekTzGl0A4R7", "pD2j4dJJd8SkfyKLdlkA",
        "5NpnHkVuqowJqWvJZRVz", "Au17IuezBz9bSyvsodBU", "LHXAMh5fnc7cXenqVy6B"
    ],
};

// Calcola le metriche per le sessioni
export const calculateMetrics = async (
    sessions,
    totalDevice,
    modelsMap,
    service,
    brandRef
) => {
    const MIN_SESSION_DURATION = 4;
    const MAX_SESSION_DURATION = 450;
    const filteredSessions = sessions.filter(
        (session) => session.total_time >= MIN_SESSION_DURATION
    );

    if (filteredSessions.length === 0) {
        return {
            totalSessions: 0,
            totalUniqueUsers: 0,
            newUsersCount: 0,
            averageSessionTime: { minutes: 0, seconds: 0 },
            averageEngagementTime: { minutes: 0, seconds: 0 },
            averageSessionsPerUser: 0,
            lineDataArray: [],
            glassesDataArray: [],
            variantsDataArray: [],
            deviceData: [],
        };
    }

    let deviceDataMap = {};
    let totalSeconds = 0;
    let uniqueUsers = new Set();
    let newUsers = new Set();
    let lineDataMap = {};
    let glassesDataMap = {};
    let variantsDataMap = {};
    let lineRefs = new Set();

    const updatedSessions = filteredSessions.map((session) => {
        if (
            (brandRef.id === "rBf3geYISXtkue0WGgFT" || brandRef.id === "qmDTd8ciaSs31akT3Eqq") &&
            service === "APP"
        ) {
            const validRefs = validLineaRefsForBrand[brandRef.id];
            return {
                ...session,
                lista_occhiali_visualizzati: filterOcchialiByLineaRef(
                    session.lista_occhiali_visualizzati,
                    validRefs
                ),
            };
        }
        return session;
    });

    updatedSessions.forEach((session) => {
        const sessionUniqueUsers = new Set();
        const sessionTotalSeconds = session.lista_occhiali_visualizzati.reduce(
            (total, occhiale) => {
                const occhialeSeconds = occhiale.listaModelliProvati.reduce(
                    (acc, modello) => acc + modello.totalSeconds,
                    0
                );
                if (
                    occhialeSeconds <= MAX_SESSION_DURATION &&
                    occhialeSeconds > MIN_SESSION_DURATION
                ) {
                    total += occhialeSeconds;

                    let lineaRef;
                    if (service === "APP") {
                        lineaRef = occhiale.lineaRef.id;
                    } else if (service === "WEB VTO") {
                        lineaRef = occhiale.lineaRefs.id;
                    } else if (service === "3D VIEWER") {
                        lineaRef = occhiale.lineaRef.id;
                    }

                    lineRefs.add(lineaRef);
                    if (!lineDataMap[lineaRef]) {
                        lineDataMap[lineaRef] = {
                            lineRef: lineaRef,
                            time: 0,
                            visualization: 0,
                            sessions: new Set(),
                            uniqueUsers: new Set(),
                            newUsers: new Set(),
                        };
                    }
                    lineDataMap[lineaRef].time += occhialeSeconds;
                    lineDataMap[lineaRef].visualization += occhiale.listaModelliProvati.length;
                    lineDataMap[lineaRef].sessions.add(session.id);
                    lineDataMap[lineaRef].uniqueUsers.add(session.device_id);
                    if (session.is_first_session) {
                        lineDataMap[lineaRef].newUsers.add(session.device_id);
                    }

                    let glassesRef;
                    if (service === "APP") {
                        glassesRef = occhiale.glassesRef.id;
                    } else if (service === "WEB VTO") {
                        glassesRef = occhiale.glassesRefs.id;
                    } else if (service === "3D VIEWER") {
                        glassesRef = occhiale.glassesRef;
                    }

                    if (!glassesDataMap[glassesRef]) {
                        glassesDataMap[glassesRef] = {
                            lineRef: lineaRef,
                            glassesRef: glassesRef,
                            nome_occhiale: occhiale.nome_occhiale,
                            time: 0,
                            visualization: 0,
                            sessions: new Set(),
                            uniqueUsers: new Set(),
                            newUsers: new Set(),
                        };
                    }
                    glassesDataMap[glassesRef].time += occhialeSeconds;
                    glassesDataMap[glassesRef].visualization += occhiale.listaModelliProvati.length;
                    glassesDataMap[glassesRef].sessions.add(session.id);
                    glassesDataMap[glassesRef].uniqueUsers.add(session.device_id);
                    if (session.is_first_session) {
                        glassesDataMap[glassesRef].newUsers.add(session.device_id);
                    }

                    occhiale.listaModelliProvati.forEach((modello) => {
                        const variantKey = `${modello.modelRef.id}-${modello.tagliaRef.id}`;
                        const modelData = modelsMap.get(modello.modelRef.id);
                        if (!variantsDataMap[variantKey]) {
                            variantsDataMap[variantKey] = {
                                modelRef: modello.modelRef.id,
                                tagliaRef: modello.tagliaRef.id,
                                occhialeRef: occhiale.id,
                                esaColorFramePrimary: modelData ? modelData.esaColorFramePrimary : null,
                                esaColorFrameSecondary: modelData ? modelData.esaColorFrameSecondary : null,
                                esaColorLensesPrimary: modelData ? modelData.esaColorLensesPrimary : null,
                                esaColorLensesSecondary: modelData ? modelData.esaColorLensesSecondary : null,
                                time: 0,
                                visualization: 0,
                                sessions: new Set(),
                                uniqueUsers: new Set(),
                                newUsers: new Set(),
                                glassesRef: glassesRef,
                                lineRef: lineaRef,
                            };
                        }
                        variantsDataMap[variantKey].time += modello.totalSeconds;
                        variantsDataMap[variantKey].visualization += 1;
                        variantsDataMap[variantKey].sessions.add(session.id);
                        variantsDataMap[variantKey].uniqueUsers.add(session.device_id);
                        if (session.is_first_session) {
                            variantsDataMap[variantKey].newUsers.add(session.device_id);
                        }
                    });
                }
                sessionUniqueUsers.add(session.device_id);
                return total;
            },
            0
        );

        totalSeconds += sessionTotalSeconds;
        uniqueUsers.add(
            `${formatDate(session.data_inizio_sessione)}|${session.device_id}|${session.device}`
        );
        if (session.is_first_session) {
            newUsers.add(
                `${formatDate(session.data_inizio_sessione)}|${session.device_id}|${session.device}`
            );
        }

        if (service === "APP") {
            const deviceType = session.device_os;
            if (!deviceDataMap[deviceType]) {
                deviceDataMap[deviceType] = { type: deviceType, count: 0, uniqueDevices: new Set() };
            }
            deviceDataMap[deviceType].count += 1;
            deviceDataMap[deviceType].uniqueDevices.add(session.device_id);
        } else if (service === "WEB VTO" || service === "3D VIEWER") {
            const browserType = extractBrowserFromDevice(session.device);
            if (!deviceDataMap[browserType]) {
                deviceDataMap[browserType] = { type: browserType, count: 0, uniqueDevices: new Set() };
            }
            deviceDataMap[browserType].count += 1;
            deviceDataMap[browserType].uniqueDevices.add(session.device_id);
        }
    });

    const avgSecondsSession = totalSeconds / filteredSessions.length;
    const avgSecondsEngagement = totalSeconds / totalDevice.size;
    const avgSessionsPerUser = parseFloat((filteredSessions.length / uniqueUsers.size).toFixed(2));

    const minutesSession = Math.floor(avgSecondsSession / 60);
    const secondsSession = Math.round(avgSecondsSession % 60);
    const minutesEngagement = Math.floor(avgSecondsEngagement / 60);
    const secondsEngagement = Math.round(avgSecondsEngagement % 60);

    const lineNames = await getLineNames([...lineRefs]);

    Object.keys(lineDataMap).forEach((lineRef) => {
        lineDataMap[lineRef].nome_linea = lineNames[lineRef] || "Unknown Linea";
        const lineData = lineDataMap[lineRef];
        lineData.numberOfSessions = lineData.sessions.size;
        lineData.numberOfUniqueUsers = lineData.uniqueUsers.size;
        lineData.newUsersCount = lineData.newUsers.size;
        lineData.averageSessionTime = calculateAverageSessionTime(lineData.time, lineData.numberOfSessions);
        lineData.averageSessionsPerUser = calculateAverageSessionsPerUser(lineData.numberOfSessions, lineData.numberOfUniqueUsers);
        lineData.averageEngagementTime = calculateAverageEngagementTime(lineData.time, lineData.numberOfUniqueUsers);
    });

    const lineDataArray = Object.values(lineDataMap).map((lineData) => ({
        lineRef: lineData.lineRef,
        nome_linea: lineData.nome_linea,
        time: lineData.time,
        visualization: lineData.visualization,
        numberOfSessions: lineData.numberOfSessions,
        numberOfUniqueUsers: lineData.numberOfUniqueUsers,
        newUsersCount: lineData.newUsersCount,
        averageSessionTime: lineData.averageSessionTime,
        averageSessionsPerUser: lineData.averageSessionsPerUser,
        averageEngagementTime: lineData.averageEngagementTime,
    }));

    Object.keys(glassesDataMap).forEach((glassesRef) => {
        const glassesData = glassesDataMap[glassesRef];
        glassesData.numberOfSessions = glassesData.sessions.size;
        glassesData.numberOfUniqueUsers = glassesData.uniqueUsers.size;
        glassesData.newUsersCount = glassesData.newUsers.size;
        glassesData.averageSessionTime = calculateAverageSessionTime(glassesData.time, glassesData.numberOfSessions);
        glassesData.averageSessionsPerUser = calculateAverageSessionsPerUser(glassesData.numberOfSessions, glassesData.numberOfUniqueUsers);
        glassesData.averageEngagementTime = calculateAverageEngagementTime(glassesData.time, glassesData.uniqueUsers.size);
    });

    const glassesDataArray = Object.values(glassesDataMap).map((glassesData) => ({
        glassesRef: glassesData.glassesRef,
        nome_occhiale: glassesData.nome_occhiale,
        time: glassesData.time,
        visualization: glassesData.visualization,
        numberOfSessions: glassesData.sessions.size,
        numberOfUniqueUsers: glassesData.uniqueUsers.size,
        newUsersCount: glassesData.newUsersCount,
        averageSessionTime: glassesData.averageSessionTime,
        averageSessionsPerUser: glassesData.averageSessionsPerUser,
        averageEngagementTime: glassesData.averageEngagementTime,
        lineRef: glassesData.lineRef,
    }));

    Object.keys(variantsDataMap).forEach((variantKey) => {
        const variantData = variantsDataMap[variantKey];
        variantData.numberOfSessions = variantData.sessions.size;
        variantData.numberOfUniqueUsers = variantData.uniqueUsers.size;
        variantData.newUsersCount = variantData.newUsers.size;
        variantData.averageSessionTime = calculateAverageSessionTime(variantData.time, variantData.sessions.size);
        variantData.averageSessionsPerUser = calculateAverageSessionsPerUser(variantData.sessions.size, variantData.uniqueUsers.size);
        variantData.averageEngagementTime = calculateAverageEngagementTime(variantData.time, variantData.uniqueUsers.size);
    });

    const variantsDataArray = Object.values(variantsDataMap)
        .filter((variantData) => variantData.time >= MIN_SESSION_DURATION)
        .map((variantData) => ({
            modelRef: variantData.modelRef,
            glassesRef: variantData.glassesRef,
            lineRef: variantData.lineRef,
            tagliaRef: variantData.tagliaRef,
            esaColorFramePrimary: variantData.esaColorFramePrimary,
            esaColorFrameSecondary: variantData.esaColorFrameSecondary,
            esaColorLensesPrimary: variantData.esaColorLensesPrimary,
            esaColorLensesSecondary: variantData.esaColorLensesSecondary,
            time: variantData.time,
            visualization: variantData.visualization,
            numberOfSessions: variantData.sessions.size,
            numberOfUniqueUsers: variantData.uniqueUsers.size,
            newUsersCount: variantData.newUsers.size,
            averageSessionTime: variantData.averageSessionTime,
            averageSessionsPerUser: variantData.averageSessionsPerUser,
            averageEngagementTime: variantData.averageEngagementTime,
        }));

    const deviceDataArray = Object.values(deviceDataMap).map((deviceData) => ({
        type: deviceData.type,
        count: deviceData.count,
        uniqueDevices: deviceData.uniqueDevices.size,
    }));

    return {
        totalSessions: filteredSessions.length,
        totalUniqueUsers: uniqueUsers.size,
        newUsersCount: newUsers.size,
        averageSessionTime: { minutes: minutesSession, seconds: secondsSession },
        averageEngagementTime: { minutes: minutesEngagement, seconds: secondsEngagement },
        averageSessionsPerUser: avgSessionsPerUser,
        lineDataArray,
        glassesDataArray,
        variantsDataArray,
        deviceData: deviceDataArray,
    };
};

export const uploadAnalyticsToFirebase = async (metricsData, service, date, brandRef) => {
    const {
        totalSessions,
        totalUniqueUsers,
        newUsersCount,
        averageSessionTime,
        averageSessionsPerUser,
        averageEngagementTime,
        lineDataArray,
        glassesDataArray,
        variantsDataArray,
        deviceData,
    } = metricsData;

    const allMetricsZero =
        totalSessions === 0 &&
        totalUniqueUsers === 0 &&
        newUsersCount === 0 &&
        averageSessionTime.minutes === 0 &&
        averageSessionTime.seconds === 0 &&
        averageSessionsPerUser === 0 &&
        averageEngagementTime.minutes === 0 &&
        averageEngagementTime.seconds === 0;

    if (allMetricsZero) return;

    try {
        // Crea il documento principale nella collezione Aggregate_Session
        const aggregateSessionDocRef = await addDoc(collection(db, "Aggregate_Session"), {
            totalSessions,
            totalUniqueUsers,
            newUsersCount,
            avgSessionTime: averageSessionTime,
            averageSessionsPerUser,
            avgEngagementTime: averageEngagementTime,
            lineArray: lineDataArray,
            service,
            date,
            brand: brandRef.id,
            deviceData,
        });
        const aggregateSessionDocId = aggregateSessionDocRef.id;

        // Crea il documento nella sottocollezione glassesAnalytics
        const subColRef = collection(doc(db, "Aggregate_Session", aggregateSessionDocId), "glassesAnalytics");
        await addDoc(subColRef, {
            glassesArray: glassesDataArray,
            variantsArray: variantsDataArray,
        });
    } catch (error) {
        console.error("Errore nel caricamento dei dati su Firebase:", error);
    }
};

const checkIfAggregatedDataExists = async (formattedDate, brandRef, service) => {
    const q = query(
        collection(db, "Aggregate_Session"),
        where("date", "==", formattedDate),
        where("brand", "==", brandRef.id),
        where("service", "==", service)
    );
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
};

export const getAggregatedData = async (brandIds, startDate, endDate) => {
    try {
        const q = query(
            collection(db, "Aggregate_Session"),
            where("date", ">=", startDate),
            where("date", "<=", endDate)
        );
        const snapshot = await getDocs(q);
        const filteredResult = snapshot.docs.filter((docSnap) =>
            brandIds.includes(docSnap.data().brand)
        );
        const aggregatedData = await Promise.all(
            filteredResult.map(async (docSnap) => ({ ...docSnap.data(), id: docSnap.id }))
        );
        return aggregatedData;
    } catch (error) {
        console.error("Error fetching aggregated data:", error);
        return [];
    }
};

const fetchSubcollectionData = async (docId, subcollectionName) => {
    try {
        const subColRef = collection(doc(db, "Aggregate_Session", docId), subcollectionName);
        const subcollectionSnapshot = await getDocs(subColRef);
        return subcollectionSnapshot.docs.map((docSnap) => ({
            ...docSnap.data(),
            id: docSnap.id,
        }));
    } catch (error) {
        console.error(`Error fetching subcollection data (${subcollectionName}):`, error);
        return [];
    }
};

export const enrichDataWithSubcollections = async (aggregateData, setLoading) => {
    try {
        const enrichedDataPromises = aggregateData.map(async (doc) => {
            const glassesAnalytics = await fetchSubcollectionData(doc.id, "glassesAnalytics");
            const glassesArray = glassesAnalytics.length > 0 ? glassesAnalytics[0].glassesArray || [] : [];
            const variantsArray = glassesAnalytics.length > 0 ? glassesAnalytics[0].variantsArray || [] : [];
            return { ...doc, glassesArray, variantsArray };
        });
        const enrichedData = await Promise.all(enrichedDataPromises);
        setLoading(false);
        return enrichedData;
    } catch (error) {
        console.error("Error enriching data with subcollections:", error);
        return aggregateData;
    }
};

export const aggregateDataForAllBrands = async (
    startDate,
    endDate,
    setLoadingMessage,
    setSkippedDays,
    incrementCompletedOperations,
    props,
    setTotalOperations
) => {
    try {
        const brandsSnapshot = await getDocs(collection(db, "Brand"));
        const brandIds = brandsSnapshot.docs.map((docSnap) => docSnap.id);
        const numberOfDays = dayjs(endDate).diff(dayjs(startDate), "day") + 1;
        const totalOperations = numberOfDays * brandIds.length;
        setTotalOperations(totalOperations);

        for (const brandId of brandIds) {
            await aggregateDataForBrand(
                startDate,
                endDate,
                brandId,
                setLoadingMessage,
                setSkippedDays,
                incrementCompletedOperations,
                props
            );
        }
    } catch (error) {
        console.error("Errore nel recupero dei dati dei brand:", error);
    }
};

export const aggregateDataForBrand = async (
    startDate,
    endDate,
    brandId,
    setLoadingMessage,
    setSkippedDays,
    incrementCompletedOperations,
    props
) => {
    const numberOfDays = dayjs(endDate).diff(dayjs(startDate), "day") + 1;
    const skippedDays = [];
    for (let i = 0; i < numberOfDays; i++) {
        const currentDate = dayjs(startDate).add(i, "day").toDate();
        const formattedDate = dayjs(currentDate).format("YYYY/MM/DD");
        setLoadingMessage(`Processing date: ${formattedDate} for brand: ${brandId}`);
        const success = await fetchData(formattedDate, brandId, props);
        if (!success) {
            skippedDays.push(formattedDate);
        }
        incrementCompletedOperations();
    }
    setSkippedDays((prevSkippedDays) => [
        ...prevSkippedDays,
        { brandId, skippedDays },
    ]);
    setLoadingMessage(`Processing complete for brand: ${brandId}`);
};

/// DOWNLOAD REPORT ///

export async function generateReport(startDate, endDate, selectedBrands, selectedOptions) {
    const formattedStartDate = dayjs(startDate).format("YYYY/MM/DD");
    const formattedEndDate = dayjs(endDate).format("YYYY/MM/DD");
    try {
        const brandsArray = Array.isArray(selectedBrands) ? selectedBrands.map(String) : [];
        const q = query(
            collection(db, "Aggregate_Session"),
            where("date", ">=", formattedStartDate),
            where("date", "<=", formattedEndDate)
        );
        const querySnapshot = await getDocs(q);
        const aggregateList = querySnapshot.docs.map((docSnap) => docSnap.data());

        const promises = brandsArray.map(async (id) => {
            try {
                const brandDataDoc = await getDoc(doc(db, "Brand", id));
                return { id, ...brandDataDoc.data() };
            } catch (error) {
                console.error(`Errore nel recupero dei dati del brand per l'ID ${id}:`, error);
                return null;
            }
        });
        const validResults = (await Promise.all(promises)).filter(Boolean);
        let resultsArray = [];

        validResults.forEach((brand) => {
            const filteredList = aggregateList.filter((data) => data.brand === brand.id);
            const result = getMetricsForReport(filteredList);
            resultsArray.push({
                avgEngTimeTotal: result.avgEngTimeTotal,
                avgSessionPerUserTotal: result.avgSessionPerUserTotal,
                avgSessionTimeTotal: result.avgSessionTimeTotal,
                totalNewUsers: result.totalNewUsers,
                totalSession: result.totalSession,
                totalUsers: result.totalUsers,
                nomeBrand: brand.nome_brand,
            });
        });

        const brandIds = new Set(validResults.map((brand) => brand.id));
        const filteredAggregateList = aggregateList.filter((item) => brandIds.has(item.brand));
        const totals = getMetricsForReport(filteredAggregateList);
        resultsArray.push({
            avgEngTimeTotal: totals.avgEngTimeTotal,
            avgSessionPerUserTotal: totals.avgSessionPerUserTotal,
            avgSessionTimeTotal: totals.avgSessionTimeTotal,
            totalNewUsers: totals.totalNewUsers,
            totalSession: totals.totalSession,
            totalUsers: totals.totalUsers,
            nomeBrand: "TOTALS",
        });

        const allData = {};
        const sheetData = {};
        const selectedCategories = [];

        const addCategoryData = (category, data) => {
            const categoryResults = validResults.map((brand) => {
                const filteredCategoryList = data.filter((item) => item.brand === brand.id);
                const result = getMetricsForReport(filteredCategoryList);
                return {
                    avgEngTimeTotal: result.avgEngTimeTotal,
                    avgSessionPerUserTotal: result.avgSessionPerUserTotal,
                    avgSessionTimeTotal: result.avgSessionTimeTotal,
                    totalNewUsers: result.totalNewUsers,
                    totalSession: result.totalSession,
                    totalUsers: result.totalUsers,
                    nomeBrand: brand.nome_brand,
                };
            });

            const categoryTotals = getMetricsForReport(data);
            categoryResults.push({
                avgEngTimeTotal: categoryTotals.avgEngTimeTotal,
                avgSessionPerUserTotal: categoryTotals.avgSessionPerUserTotal,
                avgSessionTimeTotal: categoryTotals.avgSessionTimeTotal,
                totalNewUsers: categoryTotals.totalNewUsers,
                totalSession: categoryTotals.totalSession,
                totalUsers: categoryTotals.totalUsers,
                nomeBrand: "TOTALS",
            });

            allData[category] = categoryResults;
            sheetData[category] = data;
            selectedCategories.push(category);
        };

        if (selectedOptions.includes("all")) {
            allData["ALL SERVICES"] = resultsArray;
            ["WEB VTO", "3D VIEWER", "APP"].forEach((category) => {
                addCategoryData(category, aggregateList.filter((item) => item.service === category));
            });
        } else {
            selectedOptions.forEach((option) => {
                addCategoryData(option, aggregateList.filter((item) => item.service === option));
            });
            if (selectedCategories.length > 1 && !selectedOptions.includes("all")) {
                const option1 = selectedCategories[0];
                const option2 = selectedCategories[1];
                addCategoryData("ALL SERVICES", aggregateList.filter((item) => item.service === option1 || item.service === option2));
            }
        }

        generateExcelFile(allData, selectedOptions);
    } catch (error) {
        console.error("Errore nella generazione del report:", error);
        throw new Error("Impossibile generare il report");
    }
}

function generateExcelFile(data, selectedOptions) {
    const wb = XLSX.utils.book_new();

    Object.keys(data).forEach((category) => {
        const formattedData = data[category].map((item) => ({
            nomeBrand: item.nomeBrand,
            avgEngTimeTotal: item.avgEngTimeTotal,
            avgSessionPerUserTotal: item.avgSessionPerUserTotal,
            avgSessionTimeTotal: item.avgSessionTimeTotal,
            totalNewUsers: item.totalNewUsers,
            totalSession: item.totalSession,
            totalUsers: item.totalUsers,
        }));

        const ws = XLSX.utils.json_to_sheet(formattedData, {
            header: [
                "nomeBrand",
                "avgEngTimeTotal",
                "avgSessionPerUserTotal",
                "avgSessionTimeTotal",
                "totalNewUsers",
                "totalSession",
                "totalUsers",
            ],
        });

        const sheetNames = {
            "ALL SERVICES": "ALL SERVICES",
            "WEB VTO": "WEB VTO",
            "3D VIEWER": "3D-AR VIEWER",
            APP: "VTO APPS",
            Combined: "COMBINED DATA",
        };

        const sheetName = sheetNames[category] || "Report";
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
    });

    const now = dayjs();
    const dateString = now.format("YYYY-MM-DD");
    let fileName = `ARShades_Report_${dateString}`;

    const allOptions = ["ALL SERVICES", "WEB VTO", "3D VIEWER", "VTO APPS"];
    const allOptionsSelected =
        allOptions.every((option) => selectedOptions.map((o) => o.toUpperCase()).includes(option.toUpperCase())) ||
        selectedOptions.map((o) => o.toUpperCase()).includes("ALL");

    if (allOptionsSelected) {
        fileName += "-(ALL)";
    } else if (selectedOptions.length) {
        let optionsString = selectedOptions.map((option) => option.toUpperCase()).join(",");
        optionsString = optionsString.replace(/\ball\b/g, "ALL").replace(/\bAPP\b/g, "VTO APPS");
        fileName += `-(${optionsString})`;
    }
    fileName += ".xlsx";

    XLSX.writeFile(wb, fileName);
}

// METRICS CON SOLO I TOTALI
function getMetricsForReport(aggregateList) {
    let totalUsers = 0;
    let totalNewUsers = 0;
    let totalSession = 0;
    let totalAvgSessionTime = 0;
    let totalAvgEngTime = 0;
    let totalSessionPerUser = 0;

    aggregateList.forEach((item) => {
        totalUsers += item.totalUniqueUsers;
        totalNewUsers += item.newUsersCount;
        totalSession += item.totalSessions;
        totalAvgSessionTime += getTimeInSecs(item.avgSessionTime) * item.totalSessions;
        totalSessionPerUser += item.totalSessions / (item.totalUniqueUsers || 1);
        totalAvgEngTime += getTimeInSecs(item.avgSessionTime) * item.totalSessions;
    });

    const avgSessionTimeTotal = totalSession ? convertiSecondiAMinuti(totalAvgSessionTime / totalSession) : 0;
    const avgEngTimeTotal = totalUsers ? convertiSecondiAMinuti(totalAvgEngTime / totalUsers) : 0;
    const avgSessionPerUserTotal = totalUsers ? (totalSession / totalUsers).toFixed(2) : 0;

    return {
        totalUsers,
        totalNewUsers,
        totalSession,
        avgSessionTimeTotal,
        avgEngTimeTotal,
        avgSessionPerUserTotal,
    };
}

export const getTimeInSecs = (data) => {
    let total = data.seconds ?? 0;
    if (data.minutes) {
        total += data.minutes * 60;
    }
    return total;
};

export function convertiSecondiAMinuti(secondi) {
    const minuti = Math.floor(secondi / 60);
    const secondiRimanenti = secondi % 60;
    const risultato = minuti + secondiRimanenti / 100;
    return parseFloat(risultato.toFixed(2));
}

export function getMinSecObject(secondi) {
    const minuti = Math.floor(secondi / 60);
    const secondiRimanenti = secondi % 60;
    return { minutes: minuti, seconds: secondiRimanenti };
}

const cleanData = ({ data, labels }) => {
    const uniqueData = sumDuplicates({ data, labels });
    const sortedData = sortArrByDate({
        data: uniqueData.data,
        labels: uniqueData.labels,
    });
    return sortedData;
};

const sortArrByDate = ({ data, labels }) => {
    const dataArray = labels.map((label, index) => ({
        label,
        value: data[index],
    }));
    dataArray.sort((a, b) => Date.parse(a.label) - Date.parse(b.label));
    return {
        data: dataArray.map((item) => item.value),
        labels: dataArray.map((item) => item.label),
    };
};

const sumDuplicates = ({ data, labels }) => {
    const labelSumMap = {};
    for (let i = 0; i < labels.length; i++) {
        const label = labels[i];
        const value = data[i];
        labelSumMap[label] = (labelSumMap[label] || 0) + value;
    }
    const uniqueLabels = Object.keys(labelSumMap);
    return {
        data: uniqueLabels.map((label) => labelSumMap[label]),
        labels: uniqueLabels,
    };
};