import { db } from "../data/base";
import { format } from "date-fns";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc
} from "firebase/firestore";
import { useSpring, animated } from "react-spring";
import { secureGetItem } from "../data/utils";

export const pieData = {
  labels: ["Number of users"],
  datasets: [
    {
      label: "# of user",
      data: [],
      backgroundColor: ["#241F45"],
      borderWidth: 1,
    },
  ],
};

export const pieOptions = {
  circumference: Math.PI,
  rotation: Math.PI,
  plugins: {
    legend: {
      labels: {
        generateLabels: false,
      },
    },
    doughnutCenterText: {
      font: {
        size: "40",
      },
      text: "Total",
    },
  },
};

export const lineData = {
  labels: [],
  datasets: [
    {
      label: "Number of sessions",
      data: [],
      borderColor: "#2D5BFF",
      backgroundColor: "rgba(229, 234, 252, 0.4)",
      pointStyle: "circle",
      pointRadius: 5,
      pointBackgroundColor: "#2D5BFF",
      pointBorderColor: "#2D5BFF",
      pointBorderWidth: 2,
      tension: 0,
    },
  ],
};

export const lineOptions = {
  responsive: true,
  plugins: {
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      display: true,
    },
    y: {
      display: true,
      beginAtZero: true,
      suggestedMin: 0,
    },
  },
  elements: {
    line: {
      fill: true,
      borderWidth: 2,
    },
  },
};

export const dashboardData = [
  { leftTitle: "0", leftSubtitle: "Total 3D Assets" },
  { leftTitle: "0", leftSubtitle: "Main 3D Assets" },
  { leftTitle: "0", leftSubtitle: "Published 3D Assets" },
  { leftTitle: "0", leftSubtitle: "To Be Validated 3D Assets" },
  { leftTitle: "0", leftSubtitle: "In The Making 3D Assets" },
];

export const ListAllBrands = async (rxBrands, brands, setBrandList) => {
  try {
    const brandPromises = rxBrands.map(async (brandId) => {
      const brandDocRef = doc(db, "Brand", brandId);
      const brandDoc = await getDoc(brandDocRef);
      if (brandDoc.exists()) {
        return { id: brandDoc.id, ...brandDoc.data() };
      }
      return null;
    });
    const fetchedBrands = (await Promise.all(brandPromises)).filter(Boolean);
    setBrandList([...brands, ...fetchedBrands]);
  } catch (error) {
    console.error("Error fetching brand data:", error);
  }
};

export const getBrandsByRef = async (rxBrands) => {
  const brands = [];
  const userBrands = secureGetItem("brands") || [];

  // Verifica che userBrands sia un array o lo converte in array
  const userBrandsArray = Array.isArray(userBrands) ? userBrands :
    (typeof userBrands === 'string' ? userBrands.split(',') : []);

  for (const brandId of rxBrands) {
    if (userBrandsArray.includes(brandId)) {
      try {
        const brandDocRef = doc(db, "Brand", brandId);
        const brandSnapshot = await getDoc(brandDocRef);
        if (brandSnapshot.exists()) {
          brands.push({ id: brandId, ...brandSnapshot.data() });
        }
      } catch (error) {
        console.error(`Errore durante il recupero del brand con ID ${brandId}:`, error);
      }
    }
  }
  return brands;
};

export const getModelsFromFirebase = async (selectedBrand, rxBrands) => {
  try {
    let brandCodes = [];
    if (selectedBrand.id === "all") {
      const brandsSnapshot = await getDocs(collection(db, "Brand"));
      brandsSnapshot.forEach((brandDoc) => {
        if (rxBrands.includes(brandDoc.id)) {
          const data = brandDoc.data();
          if (data.code_path) {
            brandCodes.push(data.code_path);
          }
        }
      });
    } else {
      const brandDoc = await getDoc(doc(db, "Brand", selectedBrand.id));
      if (brandDoc.exists()) {
        const data = brandDoc.data();
        if (data.code_path) {
          brandCodes.push(data.code_path);
        }
      }
    }
    const modelsSnapshot = await getDocs(collection(db, "Modello"));
    const userModels = modelsSnapshot.docs
      .filter((modelDoc) => {
        const modelData = modelDoc.data();
        if (!modelData.codiceVariante) return false;
        const mBrandCode = modelData.codiceVariante.substring(0, 2);
        return selectedBrand.id === "all"
          ? brandCodes.includes(mBrandCode)
          : brandCodes[0] === mBrandCode;
      })
      .map((modelDoc) => ({
        id: modelDoc.id,
        ...modelDoc.data(),
      }));
    return userModels;
  } catch (error) {
    console.error("Error fetching models from Firebase:", error);
    return [];
  }
};

export const getGlassesInfoFromFirebase = async (selectedBrand, rxBrands) => {
  const listGlasses = [];
  try {
    if (selectedBrand.id) {
      const glassesSnapshot = await getDocs(collection(db, "Occhiale"));
      const glassesPromises = glassesSnapshot.docs.map(async (docSnap) => {
        const data = docSnap.data();
        if (
          (selectedBrand.id === "all" &&
            data.id &&
            rxBrands.includes(data.brand)) ||
          (data.brand === selectedBrand.id && data.id)
        ) {
          const glassesRefSnapshot = await getDocs(
            collection(db, "Visualizzatori3D", data.id, "Glasses")
          );
          glassesRefSnapshot.forEach((g) => {
            listGlasses.push(g.data());
          });
        }
      });
      await Promise.all(glassesPromises);
    }
    return listGlasses;
  } catch (error) {
    console.error("Error fetching glasses info from Firebase:", error);
    return [];
  }
};

export const NumberOfUsers = async (setUsersCount) => {
  try {
    const collRef = collection(db, "Profile");
    const querySnapshot = await getDocs(collRef);
    setUsersCount(querySnapshot.size);
  } catch (error) {
    console.error("Error fetching number of users:", error);
  }
};

export const returnDashboardData = async (models, data) => {
  return [
    {
      leftTitle: `${models.length}`,
      leftSubtitle: data[0].leftSubtitle,
    },
    {
      leftTitle: `${models.filter((model) => model.main_model === true).length}`,
      leftSubtitle: data[1].leftSubtitle,
    },
    {
      leftTitle: `${models.filter(
        (model) =>
          model.stato === "Pubblicato" ||
          model.stato === "In Pubblicazione"
      ).length}`,
      leftSubtitle: data[2].leftSubtitle,
    },
    {
      leftTitle: `${models.filter((model) => model.stato === "Rev. Cliente").length}`,
      leftSubtitle: data[3].leftSubtitle,
    },
    {
      leftTitle: `${models.filter(
        (model) =>
          model.stato === "Rev. Spaarkly" ||
          model.stato === "Rev. Modellista"
      ).length}`,
      leftSubtitle: data[4].leftSubtitle,
    },
  ];
};

export const formatDate = (date) => {
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);
  const seconds = ("0" + date.getSeconds()).slice(-2);
  return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
};

export const incrementTotalSession = (total) => total + 1;

export const sessionWeekWiseCountFunction = (sessionWeekWiseCount, data) => {
  const timestamp = new Date(data.data_inizio_sessione.replace(/\//g, "-")).getTime();
  const now = Date.now();
  const diff = now - timestamp;
  const dayIndex = 6 - Math.floor(diff / (24 * 60 * 60 * 1000));
  if (dayIndex >= 0 && dayIndex <= 6) {
    sessionWeekWiseCount[dayIndex] += 1;
  }
};

export const sessionWeekWiseAggregateFunc = (sessionWeekWiseCount, data, endDate) => {
  // Normalizza endDate a fine giornata
  const parsedEndDate = new Date(endDate.replace(/\//g, "-") + "T23:59:59");
  // Normalizza la data del record a inizio giornata
  const parsedDataDate = new Date(data.date.replace(/\//g, "-") + "T00:00:00");

  const timestamp = parsedDataDate.getTime();
  const now = parsedEndDate.getTime();
  const diff = now - timestamp;
  const dayIndex = 6 - Math.floor(diff / (24 * 60 * 60 * 1000));

  if (dayIndex >= 0 && dayIndex <= 6) {
    sessionWeekWiseCount[dayIndex] += data.totalSessions;
  }
};

export const fetchData = async (
  selectedBrand,
  setNumberOfUsers,
  setNumberOfSession,
  setIsLoading,
  setSessionWeekWiseCount,
  rxBrands
) => {
  const customStartDate = new Date();
  const customEndDate = new Date();
  customEndDate.setHours(23, 59, 59);
  customEndDate.setDate(customEndDate.getDate() - 1);
  customStartDate.setDate(customEndDate.getDate() - 7);

  const sessionWeekWiseCount = Array(7).fill(0);
  const startDate = customStartDate.toISOString();
  const endDate = customEndDate.toISOString();
  const deviceIds = new Set();
  let totalSession = 0;

  setIsLoading(true);

  const fetchSessions = async (brandId, collectionName) => {
    const sessionQuery = query(
      collection(db, collectionName),
      where("data_inizio_sessione", ">=", startDate),
      where("data_inizio_sessione", "<=", endDate),
      where("ref_catalogo.id", "==", brandId)
    );
    const sessionDocs = await getDocs(sessionQuery);
    sessionDocs.forEach((docSnap) => {
      const data = docSnap.data();
      if (data.device_id) deviceIds.add(data.device_id);
      totalSession++;
      sessionWeekWiseCountFunction(sessionWeekWiseCount, data);
    });
  };

  try {
    if (selectedBrand.id === "all") {
      const brandsQuery = query(collection(db, "Brand"));
      const brandSnaps = await getDocs(brandsQuery);
      const brandList = brandSnaps.docs
        .filter((docSnap) => rxBrands.includes(docSnap.id))
        .map((docSnap) => ({ id: docSnap.id, ...docSnap.data() }));
      for (const brand of brandList) {
        await Promise.all([
          fetchSessions(brand.id, "Sessione"),
          fetchSessions(brand.id, "Sessione_Visualizzatori3d"),
          fetchSessions(brand.id, "Sessione_WebVto"),
        ]);
      }
    } else {
      await Promise.all([
        fetchSessions(selectedBrand.id, "Sessione"),
        fetchSessions(selectedBrand.id, "Sessione_Visualizzatori3d"),
        fetchSessions(selectedBrand.id, "Sessione_WebVto"),
      ]);
    }
    setNumberOfUsers(deviceIds.size);
    setNumberOfSession(totalSession);
    setSessionWeekWiseCount(sessionWeekWiseCount);
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setIsLoading(false);
  }
};

export const fetchAggregateData = async (
  selectedBrand,
  setNumberOfUsers,
  setNumberOfSession,
  setIsLoading,
  setSessionWeekWiseCount,
  rxBrands
) => {
  const customEndDate = new Date();
  customEndDate.setHours(23, 59, 59);
  // Imposta customEndDate a ieri
  customEndDate.setDate(customEndDate.getDate() - 1);
  // Imposta customStartDate a 6 giorni prima di customEndDate (7 giorni totali)
  const customStartDate = new Date(customEndDate.getTime() - 6 * 24 * 60 * 60 * 1000);

  const sessionWeekWiseCount = Array(7).fill(0);
  const startDate = format(customStartDate, "yyyy/MM/dd");
  const endDate = format(customEndDate, "yyyy/MM/dd");

  let totalSession = 0;
  let totalUsers = 0;

  setIsLoading(true);

  const fetchAggregateSessions = async (brandId) => {
    const sessionQuery = query(
      collection(db, "Aggregate_Session"),
      where("date", ">=", startDate),
      // Modifica qui: usa "<=" per includere anche la data di oggi
      where("date", "<=", endDate),
      where("brand", "==", brandId)
    );
    const sessionSnaps = await getDocs(sessionQuery);
    sessionSnaps.forEach((docSnap) => {
      const data = docSnap.data();
      totalSession += data.totalSessions ?? 0;
      totalUsers += data.totalUniqueUsers ?? 0;
      sessionWeekWiseAggregateFunc(sessionWeekWiseCount, data, endDate);
    });
  };

  try {
    if (selectedBrand.id === "all") {
      const brandsQuery = query(collection(db, "Brand"));
      const brandSnaps = await getDocs(brandsQuery);
      const brandList = brandSnaps.docs
        .filter((docSnap) => rxBrands.includes(docSnap.id))
        .map((docSnap) => ({ id: docSnap.id, ...docSnap.data() }));
      for (const brand of brandList) {
        await fetchAggregateSessions(brand.id);
      }
    } else {
      await fetchAggregateSessions(selectedBrand.id);
    }
    setNumberOfUsers(totalUsers);
    setNumberOfSession(totalSession);
    setSessionWeekWiseCount(sessionWeekWiseCount);
  } catch (error) {
    console.error("Error fetching aggregate data:", error);
  } finally {
    setIsLoading(false);
  }
};

export const NumberAnimated = ({ n }) => {
  const { number } = useSpring({
    from: { number: 0 },
    number: n,
    delay: 200,
    config: { mass: 1, tension: 20, friction: 10 },
  });
  return <animated.div>{number.to((num) => num.toFixed(0))}</animated.div>;
};