import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Usa localStorage come storage
import rootReducer from "./rootReducer";

// Configurazione di redux-persist
const persistConfig = {
    key: "root",
    storage,
    whitelist: ["config", "generalConfig"],
    blacklist: ["analytics"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configura lo store con Redux Toolkit
const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignora le azioni di redux-persist
                ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
            },
        }),
});

// Configura redux-persist
export const persistor = persistStore(store);
export default store;