import React from "react";
import { Select, Typography, Input, Button, Tag, Row, Col } from "antd";

const { Option } = Select;
const { Text } = Typography;

const Filters = ({
    searchQuery,
    setSearchQuery,
    selectedCatalogs,
    setSelectedCatalogs,
    catalogsList,
    loadingCatalogs,
    selectedStati,
    setSelectedStati,
    statesList,
    loadingStates,
    genders,
    selectedGender,
    setSelectedGender,
    modelTypes,
    selectedModelType,
    setSelectedModelType,
    frameTypes,
    selectedFrameType,
    setSelectedFrameType,
    lensTreatments,
    selectedLensTreatment,
    setSelectedLensTreatment,
    loadingTags,
}) => {
    // Funzione per resettare tutti i filtri
    const clearFilters = () => {
        setSearchQuery("");
        setSelectedCatalogs([]);
        setSelectedStati([]);
        setSelectedGender([]);
        setSelectedModelType([]);
        setSelectedFrameType([]);
        setSelectedLensTreatment([]);
    };

    const handleCatalogChange = (values) => {
        if (values.includes("all")) {
            setSelectedCatalogs([]);
        } else {
            setSelectedCatalogs(values);
        }
    };

    const handleStatiChange = (values) => {
        if (values.includes("all")) {
            setSelectedStati([]);
        } else {
            setSelectedStati(values);
        }
    };

    // Helpers per ottenere i nomi
    const getCatalogName = (id) => {
        const catalog = catalogsList.find((c) => c.id === id);
        return catalog ? catalog.nome_brand : id;
    };

    const getStateName = (id) => {
        const state = statesList.find((s) => s.id === id);
        return state ? state.name : id;
    };

    // Per i tag, usiamo questo helper
    const getTagName = (category, id) => {
        let list = [];
        if (category === "gender") list = genders;
        else if (category === "modelType") list = modelTypes;
        else if (category === "frameType") list = frameTypes;
        else if (category === "lensTreatment") list = lensTreatments;
        const tag = list.find((item) => item.id === id);
        return tag ? tag.name : id;
    };

    // Funzioni per rimuovere un filtro
    const removeCatalog = (id) => {
        setSelectedCatalogs(selectedCatalogs.filter((item) => item !== id));
    };

    const removeState = (id) => {
        setSelectedStati(selectedStati.filter((item) => item !== id));
    };

    const removeTag = (category, id) => {
        if (category === "gender") {
            setSelectedGender(selectedGender.filter((item) => item !== id));
        } else if (category === "modelType") {
            setSelectedModelType(selectedModelType.filter((item) => item !== id));
        } else if (category === "frameType") {
            setSelectedFrameType(selectedFrameType.filter((item) => item !== id));
        } else if (category === "lensTreatment") {
            setSelectedLensTreatment(selectedLensTreatment.filter((item) => item !== id));
        }
    };

    // Funzione helper per il maxTagPlaceholder: se più di 0 opzioni sono selezionate,
    // mostra "X option(s) selected"
    const maxTagPlaceholder = (omittedValues) =>
        omittedValues.length === 1
            ? "1 option selected"
            : `${omittedValues.length} options selected`;

    return (
        <div>
            {/* Riga 1: Input e Clear Filter */}
            <Row gutter={[16, 16]} align="middle" justify="space-between">
                <Col flex="60%">
                    <Text>Search</Text>
                    <Input
                        placeholder="Name, SKU, EAN or UPC Code"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        style={{ width: "100%" }}
                    />
                </Col>
                <Col flex="40%" style={{ textAlign: "right" }}>
                    <Button onClick={clearFilters}>Clear Filters</Button>
                </Col>
            </Row>

            {/* Riga 2: Selezione dei filtri e visualizzazione dei tag */}
            <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
                {/* Colonna dei filtri (60% width) */}
                <Col flex="60%">
                    {/* Primo blocco di filtri: Catalog e Status e Gender */}
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={8}>
                            <Text>Select Catalog</Text>
                            <Select
                                mode="multiple"
                                placeholder="Select catalogues"
                                value={selectedCatalogs}
                                onChange={handleCatalogChange}
                                loading={loadingCatalogs}
                                style={{ width: "100%" }}
                                maxTagCount={0}
                                maxTagPlaceholder={maxTagPlaceholder}
                            >
                                {catalogsList.map((catalog) => (
                                    <Option key={catalog.id} value={catalog.id}>
                                        {catalog.nome_brand}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col xs={24} sm={8}>
                            <Text>Select Status</Text>
                            <Select
                                mode="multiple"
                                placeholder="Select statuses"
                                value={selectedStati}
                                onChange={handleStatiChange}
                                loading={loadingStates}
                                style={{ width: "100%" }}
                                maxTagCount={0}
                                maxTagPlaceholder={maxTagPlaceholder}
                            >
                                {statesList.map((state) => (
                                    <Option key={state.id} value={state.id}>
                                        {state.name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col xs={24} sm={8}>
                            <Text>Select Gender</Text>
                            <Select
                                mode="multiple"
                                placeholder="Select gender"
                                value={selectedGender}
                                onChange={setSelectedGender}
                                loading={loadingTags}
                                style={{ width: "100%" }}
                                maxTagCount={0}
                                maxTagPlaceholder={maxTagPlaceholder}
                            >
                                {genders.map((tag) => (
                                    <Option key={tag.id} value={tag.id}>
                                        {tag.name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>

                    {/* Secondo blocco di filtri: Model Type, Frame Type e Lens Treatment */}
                    <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
                        <Col xs={24} sm={8}>
                            <Text>Select Model Type</Text>
                            <Select
                                mode="multiple"
                                placeholder="Select model type"
                                value={selectedModelType}
                                onChange={setSelectedModelType}
                                loading={loadingTags}
                                style={{ width: "100%" }}
                                maxTagCount={0}
                                maxTagPlaceholder={maxTagPlaceholder}
                            >
                                {modelTypes.map((tag) => (
                                    <Option key={tag.id} value={tag.id}>
                                        {tag.name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col xs={24} sm={8}>
                            <Text>Select Frame Type</Text>
                            <Select
                                mode="multiple"
                                placeholder="Select frame type"
                                value={selectedFrameType}
                                onChange={setSelectedFrameType}
                                loading={loadingTags}
                                style={{ width: "100%" }}
                                maxTagCount={0}
                                maxTagPlaceholder={maxTagPlaceholder}
                            >
                                {frameTypes.map((tag) => (
                                    <Option key={tag.id} value={tag.id}>
                                        {tag.name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col xs={24} sm={8}>
                            <Text>Select Lens Treatment</Text>
                            <Select
                                mode="multiple"
                                placeholder="Select lens treatment"
                                value={selectedLensTreatment}
                                onChange={setSelectedLensTreatment}
                                loading={loadingTags}
                                style={{ width: "100%" }}
                                maxTagCount={0}
                                maxTagPlaceholder={maxTagPlaceholder}
                            >
                                {lensTreatments.map((tag) => (
                                    <Option key={tag.id} value={tag.id}>
                                        {tag.name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>
                </Col>

                {/* Colonna dei Selected Filters (40% width) */}
                <Col flex="40%">
                    <div style={{ padding: "0 8px" }}>
                        {selectedCatalogs.length > 0 && (
                            <div style={{ marginBottom: 8 }}>
                                <Text strong>Catalog: </Text>
                                {selectedCatalogs.map((id) => (
                                    <Tag
                                        key={id}
                                        closable
                                        onClose={() => removeCatalog(id)}
                                        style={{ marginBottom: 4 }}
                                    >
                                        {getCatalogName(id)}
                                    </Tag>
                                ))}
                            </div>
                        )}
                        {selectedStati.length > 0 && (
                            <div style={{ marginBottom: 8 }}>
                                <Text strong>Status: </Text>
                                {selectedStati.map((id) => (
                                    <Tag
                                        key={id}
                                        closable
                                        onClose={() => removeState(id)}
                                        style={{ marginBottom: 4 }}
                                    >
                                        {getStateName(id)}
                                    </Tag>
                                ))}
                            </div>
                        )}
                        {selectedGender.length > 0 && (
                            <div style={{ marginBottom: 8 }}>
                                <Text strong>Gender: </Text>
                                {selectedGender.map((id) => (
                                    <Tag
                                        key={id}
                                        closable
                                        onClose={() => removeTag("gender", id)}
                                        style={{ marginBottom: 4 }}
                                    >
                                        {getTagName("gender", id)}
                                    </Tag>
                                ))}
                            </div>
                        )}
                        {selectedModelType.length > 0 && (
                            <div style={{ marginBottom: 8 }}>
                                <Text strong>Model Type: </Text>
                                {selectedModelType.map((id) => (
                                    <Tag
                                        key={id}
                                        closable
                                        onClose={() => removeTag("modelType", id)}
                                        style={{ marginBottom: 4 }}
                                    >
                                        {getTagName("modelType", id)}
                                    </Tag>
                                ))}
                            </div>
                        )}
                        {selectedFrameType.length > 0 && (
                            <div style={{ marginBottom: 8 }}>
                                <Text strong>Frame Type: </Text>
                                {selectedFrameType.map((id) => (
                                    <Tag
                                        key={id}
                                        closable
                                        onClose={() => removeTag("frameType", id)}
                                        style={{ marginBottom: 4 }}
                                    >
                                        {getTagName("frameType", id)}
                                    </Tag>
                                ))}
                            </div>
                        )}
                        {selectedLensTreatment.length > 0 && (
                            <div style={{ marginBottom: 8 }}>
                                <Text strong>Lens Treatment: </Text>
                                {selectedLensTreatment.map((id) => (
                                    <Tag
                                        key={id}
                                        closable
                                        onClose={() => removeTag("lensTreatment", id)}
                                        style={{ marginBottom: 4 }}
                                    >
                                        {getTagName("lensTreatment", id)}
                                    </Tag>
                                ))}
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Filters;