import React, { useCallback, useState, useEffect } from 'react';
import Papa from 'papaparse';
import { useDropzone } from 'react-dropzone';
import { db } from '../data/base';
import { message, Button, Input, Spin, Table, Space, Row, Col, theme } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { collection, query, where, getDocs } from 'firebase/firestore';
import HomeCard from '../components/OrderPage/HomeCard';

export default function OrderPage() {
    const { token } = theme.useToken();
    // Stati per la gestione dei dati CSV, filtri e ricerche
    const [csvData, setCsvData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [statusFilter, setStatusFilter] = useState('All');
    const [isCsvLoaded, setIsCsvLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [statusCounts, setStatusCounts] = useState({
        'Disponibile': "0",
        'In Progress': "0",
        'Non Disponibile': "0",
    });
    const [searchSku, setSearchSku] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [showSearchResults, setShowSearchResults] = useState(false);
    const [activeMode, setActiveMode] = useState('');
    const columnsOfInterest = ['skuCode', 'Name', 'Sku Present?', 'Stato'];

    // Funzione per la ricerca per SKU
    const searchBySku = async () => {
        if (searchSku.trim() === '') {
            message.error('Please enter a SKU.');
            return;
        }
        if (searchSku.trim().length !== 13) {
            message.error('SKU must be exactly 13 characters.');
            return;
        }
        setLoading(true);
        setActiveMode('search');
        try {
            const skuCode = searchSku.trim().substring(0, 1500);
            const q = query(
                collection(db, 'Modello'),
                where('sku_model', '==', skuCode)
            );
            const querySnapshot = await getDocs(q);
            let searchResult = [];
            if (!querySnapshot.empty) {
                querySnapshot.forEach(doc => {
                    const row = {
                        skuCode: skuCode,
                        'Sku Present?': 'SI',
                        'Stato': doc.data().stato || 'Non Disponibile',
                        'Name': doc.data().nomeOcchiale || 'Empty',
                    };
                    searchResult.push(row);
                });
            } else {
                searchResult = [{
                    skuCode: skuCode,
                    'Sku Present?': 'NO',
                    'Stato': 'Non Disponibile',
                    'Name': 'Empty',
                }];
            }
            setSearchResults(searchResult);
            setShowSearchResults(true);
        } catch (error) {
            console.error('Error in Firestore query:', error);
        }
        setLoading(false);
    };

    const resetState = () => {
        setCsvData([]);
        setFilteredData([]);
        setStatusFilter('All');
        setIsCsvLoaded(false);
        setLoading(false);
        setStatusCounts({
            'Disponibile': "0",
            'In Progress': "0",
            'Non Disponibile': "0",
        });
        setSearchSku('');
        setSearchResults([]);
        setShowSearchResults(false);
        setActiveMode('');
    };

    // Per ogni riga del CSV, interroga Firestore per aggiornare i dati
    const fetchAndUpdateData = async (data) => {
        const updatedData = await Promise.all(data.map(async (row) => {
            if (row.skuCode) {
                const skuCode = row.skuCode.substring(0, 1500);
                try {
                    const q = query(
                        collection(db, 'Modello'),
                        where('sku_model', '==', skuCode)
                    );
                    const querySnapshot = await getDocs(q);
                    if (!querySnapshot.empty) {
                        row['Sku Present?'] = 'SI';
                        row['Stato'] = 'Non Disponibile';
                        querySnapshot.forEach(doc => {
                            let stato = doc.data().stato || 'Non Disponibile';
                            if (stato === 'Pubblicato' || stato === 'In Pubblicazione') {
                                stato = 'Disponibile';
                            } else {
                                stato = 'In Progress';
                            }
                            row['Stato'] = stato;
                            row['Name'] = doc.data().nomeOcchiale || 'Empty';
                        });
                    } else {
                        row['Sku Present?'] = 'NO';
                        row['Stato'] = 'Non Disponibile';
                        row['Name'] = 'Empty';
                    }
                } catch (error) {
                    console.error('Error in Firestore query:', error);
                    row['Sku Present?'] = 'NO';
                    row['Stato'] = 'Non Disponibile';
                    row['Name'] = 'Empty';
                }
            } else {
                row['Sku Present?'] = 'NO';
                row['Stato'] = 'Non Disponibile';
                row['Name'] = 'Empty';
            }
            return row;
        }));
        return updatedData;
    };

    // Gestione del drop del file CSV
    const onDrop = useCallback((acceptedFiles) => {
        resetState();
        setShowSearchResults(false);
        setActiveMode('upload');
        if (acceptedFiles.length > 0) {
            message.success('CSV file uploaded successfully!');
            setLoading(true);
            setIsCsvLoaded(true);
            acceptedFiles.forEach((file) => {
                const reader = new FileReader();
                reader.onabort = () => console.log('File reading was aborted');
                reader.onerror = () => console.log('File reading has failed');
                reader.onload = async () => {
                    const binaryStr = reader.result;
                    Papa.parse(binaryStr, {
                        header: true,
                        complete: async (results) => {
                            const rawData = results.data.map(row => {
                                const filteredRow = {};
                                columnsOfInterest.forEach(column => {
                                    if (column === 'Name' && row['nome_modello'] !== undefined) {
                                        filteredRow[column] = row['nome_modello'];
                                    } else if (row[column] !== undefined) {
                                        filteredRow[column] = row[column];
                                    } else {
                                        if (['Sku Present?', 'Stato'].includes(column)) {
                                            filteredRow[column] = '';
                                        }
                                    }
                                });
                                return filteredRow;
                            });
                            const addUniqueData = (data) => {
                                const seen = new Set();
                                return data.filter(row => {
                                    if (seen.has(row.skuCode)) return false;
                                    seen.add(row.skuCode);
                                    return true;
                                });
                            };
                            const uniqueData = addUniqueData(rawData);
                            const updatedData = await fetchAndUpdateData(uniqueData);
                            const counts = getStatusCounts(updatedData);
                            setStatusCounts(counts);
                            setCsvData(updatedData);
                            setFilteredData(applyFilters(updatedData));
                            setLoading(false);
                        },
                    });
                };
                reader.readAsText(file);
            });
        }
    }, []);

    const sortByStatus = (data) => {
        const statusOrder = {
            'Disponibile': 1,
            'In Progress': 2,
            'Non Disponibile': 3,
            '': 4
        };
        return data.sort((a, b) => (statusOrder[a['Stato']] || 4) - (statusOrder[b['Stato']] || 4));
    };

    const applyFilters = (data) => {
        const filtered = data.filter(row => {
            return statusFilter === 'All' || row['Stato'] === statusFilter;
        });
        return sortByStatus(filtered);
    };

    useEffect(() => {
        if (!showSearchResults) {
            setFilteredData(applyFilters(csvData));
        }
    }, [csvData, statusFilter, showSearchResults]);

    const getStatusCounts = (data) => {
        const counts = {
            'Disponibile': 0,
            'In Progress': 0,
            'Non Disponibile': 0,
        };
        data.forEach(row => {
            if (counts.hasOwnProperty(row['Stato'])) {
                counts[row['Stato']] += 1;
            }
        });
        return counts;
    };

    const handleStatusClick = (status) => {
        setStatusFilter(status === statusFilter ? 'All' : status);
    };

    const handleReload = () => {
        window.location.reload();
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: '.csv',
    });

    // Definiamo le colonne per Ant Table
    const tableColumns = columnsOfInterest.map(col => ({
        title: col,
        dataIndex: col,
        key: col,
    }));

    return (
        <div style={{ padding: 24, display: 'flex', justifyContent: 'center' }}>
            <Space direction="vertical" size="large" style={{ maxWidth: 600, width: '100%', textAlign: 'center' }}>
                {/* Bottone di reload */}
                {(activeMode === 'search' || activeMode === 'upload') && (
                    <Button type="link" icon={<LeftOutlined />} onClick={handleReload}>
                        Reload
                    </Button>
                )}

                {/* Sezione per l'upload del CSV */}
                {activeMode !== 'search' && (
                    <div
                        {...getRootProps()}
                        style={{
                            border: `2px dashed ${token.colorBorder}`,
                            padding: 24,
                            margin: '0 auto',
                            maxWidth: 400,
                            textAlign: 'center',
                            cursor: 'pointer'
                        }}
                    >
                        <input {...getInputProps()} />
                        <p>For multiple SKU search, drag & drop a CSV file here, or click to select a file</p>
                    </div>
                )}

                {/* Sezione per la ricerca per SKU */}
                {activeMode !== 'upload' && (
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <p>For a single SKU search, enter the SKU code here</p>
                        <Space>
                            <Input
                                placeholder="Insert SKU"
                                value={searchSku}
                                onChange={(e) => setSearchSku(e.target.value)}
                                style={{ width: 200 }}
                            />
                            <Button type="primary" onClick={searchBySku}>
                                Search
                            </Button>
                        </Space>
                    </Space>
                )}

                {loading && (
                    <div style={{ textAlign: 'center' }}>
                        <Spin size="large" />
                    </div>
                )}

                {/* Sezione riassuntiva (status summary) */}
                {isCsvLoaded && !loading && !showSearchResults && activeMode === 'upload' && (
                    <Row gutter={[16, 16]} justify="center">
                        <Col>
                            <HomeCard
                                leftTitle={csvData.length}
                                leftSubtitle="All SKU"
                                onClickFunction={() => handleStatusClick('All')}
                            />
                        </Col>
                        <Col>
                            <HomeCard
                                leftTitle={statusCounts['Disponibile'] === 0 ? "0" : statusCounts['Disponibile']}
                                leftSubtitle="Disponibile"
                                onClickFunction={() => handleStatusClick('Disponibile')}
                            />
                        </Col>
                        <Col>
                            <HomeCard
                                leftTitle={statusCounts['In Progress'] === 0 ? "0" : statusCounts['In Progress']}
                                leftSubtitle="In Progress"
                                onClickFunction={() => handleStatusClick('In Progress')}
                            />
                        </Col>
                        <Col>
                            <HomeCard
                                leftTitle={statusCounts['Non Disponibile'] === 0 ? "0" : statusCounts['Non Disponibile']}
                                leftSubtitle="Non Disponibile"
                                onClickFunction={() => handleStatusClick('Non Disponibile')}
                            />
                        </Col>
                    </Row>
                )}

                {/* Tabella per i risultati della ricerca per SKU */}
                {showSearchResults && activeMode === 'search' && (
                    <Table
                        columns={tableColumns}
                        dataSource={searchResults}
                        rowKey="skuCode"
                        pagination={false}
                    />
                )}

                {/* Tabella per i dati CSV filtrati */}
                {!showSearchResults && filteredData.length > 0 && !loading && activeMode === 'upload' && (
                    <Table
                        columns={tableColumns}
                        dataSource={filteredData}
                        rowKey="skuCode"
                        pagination={false}
                    />
                )}
            </Space>
        </div>
    );
}