import React, { useEffect, useState } from "react";
import { Modal, Table, theme } from "antd";
import {
    getDoc,
    doc,
    query,
    collection,
    where,
    getDocs,
    documentId,
} from "firebase/firestore";
import { db } from "../../data/base";
import AssociatedBrands from "../Profile/AssociatedBrands";
import { fetchSubscriptions, getSubscriptionData } from "../../services/api/homeApi";
import { LinkOutlined } from "@ant-design/icons";
import { fetchSubscriptionsByRole, getBrandsByRef } from "../../services/api/dataConsumptionApi";
import StatCard from "./StatCard";
import dayjs from "dayjs";
import CustomTooltip from "../common/CustomTooltip";

const UserCard = ({ user, setProfileData }) => {
    const { token } = theme.useToken();
    const { firstName, lastName, role, clientRef, catalogsList } = user;
    const [companyName, setCompanyName] = useState("");
    const [brands, setBrands] = useState([]);
    const [isPublishedModalVisible, setIsPublishedModalVisible] = useState(false);
    const [isRevisionModalVisible, setIsRevisionModalVisible] = useState(false);
    const [isLicensesModalVisible, setIsLicensesModalVisible] = useState(false);
    const [loadingLicenses, setLoadingLicenses] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const [percentageData, setPercentageData] = useState([]);

    // Imposta le date
    const startDate = dayjs("2024-01-01").toDate();
    const endDate = dayjs().toDate();
    const bandwidthType = "all";

    // Recupera il companyName dal documento Client
    useEffect(() => {
        const fetchCompanyName = async () => {
            if (!clientRef) return;
            try {
                const clientDoc = await getDoc(doc(db, "Client", clientRef));
                if (clientDoc.exists()) {
                    const clientData = clientDoc.data();
                    setCompanyName(clientData.companyName || clientRef);
                } else {
                    console.warn("No client document found for ref:", clientRef);
                }
            } catch (error) {
                console.error("Error fetching company name:", error);
            }
        };
        fetchCompanyName();
    }, [clientRef]);

    // Recupera le licenze (subscriptions)
    useEffect(() => {
        fetchSubscriptions(setLoadingLicenses, setSubscriptions, setProfileData);
    }, [setProfileData]);

    // Recupera i dati dei brand dalla collezione "Brand" usando i documentId in catalogsList
    useEffect(() => {
        const fetchBrandNames = async () => {
            if (!catalogsList || catalogsList.length === 0) return;
            try {
                const brandsQuery = query(
                    collection(db, "Brand"),
                    where(documentId(), "in", catalogsList)
                );
                const brandsSnapshot = await getDocs(brandsQuery);
                const brandsData = brandsSnapshot.docs.map((docSnap) => {
                    const data = docSnap.data();
                    return {
                        id: docSnap.id,
                        nome_brand: data.nome_brand || docSnap.id,
                        description: data.descrizione || "-",
                        main_brand: data.main_brand || false,
                        web_domain: data.web_domain || "",
                    };
                });
                setBrands(brandsData);
            } catch (error) {
                console.error("Error fetching brand names:", error);
            }
        };
        fetchBrandNames();
    }, [catalogsList]);

    // Recupera ulteriori dati per le subscriptions in base ai brand associati
    useEffect(() => {
        const fetchData = async () => {
            try {
                const allData = [];
                const userBrands = catalogsList || [];
                const fetchedSubs = await fetchSubscriptionsByRole(setSubscriptions);
                const activeSubscriptions = [];

                for (const subItem of fetchedSubs) {
                    if (
                        subItem?.catalogList?.catalogRefList &&
                        Array.isArray(subItem.catalogList.catalogRefList) &&
                        subItem.catalogList.catalogRefList.some((ref) => userBrands.includes(ref))
                    ) {
                        // Recupera i brand associati
                        const brandsResult = await getBrandsByRef(subItem.catalogList.catalogRefList, []);

                        // Otteniamo il valore di consumptionsLimit
                        const consumptionLimit = subItem.catalogList.consumptionsLimit || 1;

                        if (brandsResult && brandsResult.length > 0) {
                            const bandwidthDetails = await getSubscriptionData({
                                startDate,
                                endDate,
                                bandwidthType,
                                brandList: brandsResult,
                                subStartDate: subItem?.startDate,
                                subscriptions: subItem,
                            });

                            allData.push({
                                bandwidthDetails,
                                brandsResult,
                                consumptionLimit,
                            });

                            activeSubscriptions.push(subItem);
                        }
                    }
                }
                setPercentageData(allData);
            } catch (err) {
                console.error("Error fetching data:", err);
            }
        };

        fetchData();
    }, [user, catalogsList]);

    // Imposta il saluto in base all'orario
    const currentHour = new Date().getHours();
    let greeting = "Good morning";
    if (currentHour >= 12 && currentHour < 18) {
        greeting = "Good afternoon";
    } else if (currentHour >= 18 && currentHour < 24) {
        greeting = "Good evening";
    } else if (currentHour >= 0 && currentHour < 6) {
        greeting = "Good night";
    }

    // Funzioni per mostrare/nascondere i modali
    const showPublishedModal = () => setIsPublishedModalVisible(true);
    const showRevisionModal = () => setIsRevisionModalVisible(true);
    const showLicensesModal = () => setIsLicensesModalVisible(true);
    const handlePublishedModalCancel = () => setIsPublishedModalVisible(false);
    const handleRevisionModalCancel = () => setIsRevisionModalVisible(false);
    const handleLicensesCancel = () => setIsLicensesModalVisible(false);

    // Conteggio dei brand
    const countCatalogues = brands?.length;
    const countPublishedCatalogues = brands.filter((b) => b.main_brand === true).length;
    const countRevisionCatalogues = countCatalogues - countPublishedCatalogues;

    // Definizione delle colonne per le tabelle
    const columns = [
        {
            title: "Nome Brand",
            dataIndex: "nome_brand",
            key: "nome_brand",
            width: "20%",
            responsive: ["xs", "sm", "md", "lg", "xl"],
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            width: "70%",
            responsive: ["xs", "sm", "md", "lg", "xl"],
        },
        {
            title: "Action",
            key: "action",
            width: "10%",
            responsive: ["xs", "sm", "md", "lg", "xl"],
            render: (text, record) => {
                const catalogUrl = `https://${record.web_domain}/?c=${record.id}`;

                return (
                    <CustomTooltip title="Go to the online Catalog">
                        <div style={{ textAlign: "center" }}>
                            <a href={catalogUrl} target="_blank" rel="noopener noreferrer">
                                <LinkOutlined style={{ fontSize: "16px", cursor: "pointer" }} />
                            </a>
                        </div>
                    </CustomTooltip>
                );
            },
        },
    ];

    // Separiamo i brand in "published" e "in revisione"
    const publishedBrands = brands.filter((b) => b.main_brand);
    const inRevisionBrands = brands.filter((b) => !b.main_brand);

    const safeValue = (val) => {
        return val === null || val === undefined || isNaN(val) ? 0 : val;
    };

    return (
        <div style={{ margin: "20px auto", fontSize: "16px" }}>
            <div style={{ marginBottom: "20px" }}>
                <h2
                    style={{
                        fontWeight: "bold",
                        fontSize: "1.8rem",
                        color: token.colorTextBlack,
                    }}
                >
                    {greeting}, {firstName} {lastName}
                </h2>
                <p
                    style={{
                        fontSize: "1rem",
                        color: token.colorTextBlack,
                        marginTop: "5px",
                    }}
                >
                    You are{" "}
                    <span style={{ fontWeight: "bold", color: token.colorTextBlack }}>
                        {role}
                    </span>{" "}
                    at{" "}
                    <span style={{ fontWeight: "bold", color: token.colorPrimary }}>
                        {companyName || clientRef}
                    </span>
                </p>
            </div>

            {/* Card: Published Catalogues, Catalogues in Revision, Licenses */}
            <div style={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
                <StatCard
                    title="Published Catalogues"
                    value={safeValue(countPublishedCatalogues)}
                    onClick={showPublishedModal}
                    tooltipTitle="Total number of published catalogues available."
                />
                <StatCard
                    title="Catalogues in Revision"
                    value={safeValue(countRevisionCatalogues)}
                    onClick={showRevisionModal}
                    tooltipTitle="Catalogues currently under revision before publication."
                />
                {!(role === "Modellista" || role === "Validator") && (
                    <StatCard
                        title="Licenses"
                        value={safeValue(subscriptions.length)}
                        onClick={showLicensesModal}
                        tooltipTitle="Total number of active licenses."
                    />
                )}
            </div>

            {/* Modal per Published Catalogues */}
            <Modal
                title="Published Catalogues"
                open={isPublishedModalVisible}
                onCancel={handlePublishedModalCancel}
                footer={null}
                width="80%"
            >
                <Table
                    dataSource={publishedBrands}
                    columns={columns}
                    rowKey="id"
                    pagination={false}
                    size="small"
                />
            </Modal>

            {/* Modal per Catalogues in Revision */}
            <Modal
                title="Catalogues in Revision"
                open={isRevisionModalVisible}
                onCancel={handleRevisionModalCancel}
                footer={null}
                width="80%"
            >
                <Table
                    dataSource={inRevisionBrands}
                    columns={columns}
                    rowKey="id"
                    pagination={false}
                    size="small"
                />
            </Modal>

            {/* Modal per Licenses */}
            <Modal
                title="Licenses"
                open={isLicensesModalVisible}
                onCancel={handleLicensesCancel}
                footer={null}
                width="80%"
            >
                <AssociatedBrands
                    loading={loadingLicenses}
                    subscriptions={subscriptions}
                    percentageData={percentageData}
                />
            </Modal>
        </div>
    );
};

export default UserCard;