import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { notification } from "antd";
import { doc, getDoc, updateDoc, collection, addDoc } from "firebase/firestore";
import { db } from "../data/base";
import ModelContainerFrontEnd from "../components/ModelViewer/ModelContainerFrontEnd";
import ProjectModelViewer from "../components/ModelViewer/ProjectModelViewer";
import CardModelView from "../components/ModelViewer/CardModelView";
import MenuContainer from "../components/ModelViewer/MenuContainer";
import Password from "../components/ModelViewer/Password";
import { brandViewerActions } from "../redux/brandViewer/brandViewer";
import { modelGlassesActions } from "../redux/brandViewer/modelGlasses";
import axios from "axios";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";

const ARShades3dsViewer = ({
    isEditModeOn,
    containerWidth,
    containerHeight,
    setCurrentVariant = () => { },
    _setListVariant = () => { },
}) => {

    const params = useParams();
    const dispatch = useDispatch();

    const [idViewer, setIdViewer] = useState();

    const [listVariant, setListVariant] = useState([]);
    const [lock, setLock] = useState(false);
    const [initialModel, setInitialModel] = useState();
    const [modelHistory, setModelHistory] = useState([]);
    const [pwd, setPwd] = useState("");
    const [initialTime, setInitialTime] = useState();

    const brand = params.TokenBrand;
    const glasses = params.TokenGlasses;
    const project = useSelector((state) => state.brandViewer);

    const [glassesRef, setGlassesRef] = useState(null);
    const [lineRefs, setLineRefs] = useState(null);
    const [glassesSize, setGlassesSize] = useState(null);
    const modelInView = useSelector((state) =>
        state.modelGlasses.find((model) => model.id === initialModel)
    );

    const [initialModelTime, setInitiaModellTime] = useState(new Date());

    const viewer = document.querySelector("model-viewer");

    const [positionMenu, setPositionMenu] = useState("bottom");

    // Per salvarsi l'id del nuovo documento della sessione
    const [documentId, setDocumentId] = useState(null);

    const sessionStartTime = useRef(null);

    const sessionRef = useRef({
        device_id: "",
        device: "",
        device_os: "",
        is_first_session: false,
        ref_catalogo: "",
        data_inizio_sessione: "",
        data_fine_user_session: "",
        glassesRefs: [],
        lista_occhiali_visualizzati: [],
        modelRefs: [],
        lineaRefs: [],
        glassesRefsDoc: null,
        total_time: 0,
        glassesSize: null,
    });

    const updateSessionStartTime = () => {
        sessionStartTime.current = new Date();
    };

    const [sessionExpired, setSessionExpired] = useState(false);

    const [modelAnimations, setModelAnimations] = useState([]);

    useEffect(() => {
        setPositionMenu(project[0]?.menu_position);
    }, [project[0]]);

    const refBrand = useRef(null);

    const updateRefBrand = (brandDocRef) => {
        refBrand.current = brandDocRef;
    };

    useEffect(() => {
        const fetchViewerData = async () => {
            try {
                // Recupera il documento del visualizzatore
                const visualizzatoreDocRef = doc(collection(db, "Visualizzatori3D"), brand);
                const visualizzatoreDoc = await getDoc(visualizzatoreDocRef);

                if (visualizzatoreDoc.exists()) {
                    const data = visualizzatoreDoc.data();

                    setIdViewer(data.id);
                    dispatch(brandViewerActions.setSelectedProject(data));

                    // Imposta il tempo iniziale
                    const date = new Date();
                    setInitialTime(date);
                } else {
                    console.error("Visualizzatore non trovato");
                }
            } catch (error) {
                console.error("Errore durante il recupero del visualizzatore:", error);
            }
        };

        fetchViewerData(); // Chiama la funzione asincrona
    }, [brand, dispatch]); // Dipendenze richieste

    useEffect(() => {
        const fetchGlassesAndModelData = async () => {
            if (idViewer) {
                try {
                    // Recupera il documento degli occhiali
                    const glassesDocRef = doc(
                        collection(db, "Visualizzatori3D", idViewer, "Glasses"),
                        glasses
                    );
                    const glassesDoc = await getDoc(glassesDocRef);

                    if (glassesDoc.exists()) {
                        const glassesData = glassesDoc.data();
                        setListVariant(glassesData.lista_varianti);
                        setInitialModel(glassesData.initial_model);

                        // Recupera il modello iniziale
                        const modelDocRef = doc(collection(db, "Modello"), glassesData.initial_model);
                        const modelDoc = await getDoc(modelDocRef);

                        if (modelDoc.exists()) {
                            const modelData = modelDoc.data();
                            const animations = modelData.modelAnimation || [];
                            setModelAnimations(animations);

                            // Aggiungi l'URL al model history
                            setModelHistory((prevHistory) => [...prevHistory, modelData.urlGlbComplete]);
                        } else {
                            console.error("Modello non trovato");
                        }
                    } else {
                        console.error("Glasses non trovato");
                    }
                } catch (error) {
                    console.error("Errore durante il recupero dei dati:", error);
                }
            }
        };

        fetchGlassesAndModelData(); // Chiama la funzione asincrona
    }, [idViewer, glasses, db]); // Aggiungi tutte le dipendenze richieste

    useEffect(() => {
        const fetchViewerLockData = async () => {
            try {
                // Recupera il documento del visualizzatore
                const docRef = doc(collection(db, "Visualizzatori3D"), brand);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setLock(data.lock);
                    if (data.lock) {
                        setPwd(data.password);
                    }
                } else {
                    console.error("Documento non trovato");
                }
            } catch (error) {
                console.error("Errore durante il recupero del documento:", error);
            }
        };

        fetchViewerLockData(); // Chiama la funzione asincrona
    }, [brand]); // Dipendenza richiesta

    useEffect(() => {
        const fetchVariants = async () => {
            if (listVariant) {
                try {
                    // Itera attraverso le varianti e recupera i dati
                    await Promise.all(
                        listVariant.map(async (v) => {
                            const variantRef = doc(collection(db, "Modello"), v);
                            const variantSnap = await getDoc(variantRef);

                            if (variantSnap.exists()) {
                                const variantData = variantSnap.data();
                                dispatch(
                                    modelGlassesActions.setModel({ data: variantData, id: v })
                                );
                            } else {
                                console.error(`Variante ${v} non trovata`);
                            }
                        })
                    );
                } catch (error) {
                    console.error("Errore durante il recupero delle varianti:", error);
                }
            }
        };

        fetchVariants(); // Chiama la funzione asincrona
    }, [listVariant, dispatch]); // Dipendenze richieste

    useEffect(() => {
        const initializeSession = async () => {
            try {
                // Funzione per ottenere l'ID del dispositivo
                const getDeviceID = async () => {
                    try {
                        const res = await axios.get("https://api.ipify.org/?format=json");
                        return res.data.ip;
                    } catch (error) {
                        console.error("Failed to fetch IP address:", error);
                        return uuidv4(); // UUID di fallback
                    }
                };
                const deviceID = await getDeviceID();

                // Funzione per ottenere la data corrente formattata
                const getCurrentDateTime = () => {
                    const padWithZero = (num) => num.toString().padStart(2, "0");
                    const date = new Date();
                    return {
                        year: date.getFullYear(),
                        month: padWithZero(date.getMonth() + 1),
                        day: padWithZero(date.getDate()),
                        hours: padWithZero(date.getHours()),
                        minutes: padWithZero(date.getMinutes()),
                        seconds: padWithZero(date.getSeconds()),
                        date,
                    };
                };
                const { year, month, day, hours, minutes, seconds, date } = getCurrentDateTime();

                // Recupera i dati del brand e dei contatori
                const getBrandAndCounters = async () => {
                    const visualizzatoreDocRef = doc(db, "Visualizzatori3D", brand);
                    const visualizzatoreDocSnap = await getDoc(visualizzatoreDocRef);
                    if (!visualizzatoreDocSnap.exists()) throw new Error("Visualizzatore non trovato");

                    const docBrand = visualizzatoreDocSnap.data().brand;
                    const brandDocRef = doc(db, "Brand", docBrand);
                    const brandDocSnap = await getDoc(brandDocRef);
                    if (!brandDocSnap.exists()) throw new Error("Brand non trovato");

                    const docDataConsumption = brandDocSnap.data().data_consumption;
                    const contatoriDocRef = doc(db, "Contatori_Brand", docDataConsumption);
                    const contatoriDocSnap = await getDoc(contatoriDocRef);
                    if (!contatoriDocSnap.exists()) throw new Error("Contatori non trovati");

                    return { brandDocRef, contatoriDocRef, contatoriDocSnap };
                };
                const { brandDocRef, contatoriDocRef, contatoriDocSnap } = await getBrandAndCounters();
                const contatoriData = contatoriDocSnap.data();

                // Recupera i dati degli occhiali
                const getGlassesData = async () => {
                    const glassesDocRef = doc(db, "Occhiale", glasses);
                    const glassesDocSnap = await getDoc(glassesDocRef);
                    if (!glassesDocSnap.exists()) throw new Error("Occhiali non trovati");

                    return { glassesDocRef, glassesDocSnap };
                };
                const { glassesDocRef, glassesDocSnap } = await getGlassesData();
                const glassesData = glassesDocSnap.data();
                const sizeRefDoc = glassesData.lista_taglie?.[0] || null;

                // **CORREZIONE:** Ora assegniamo correttamente l'ID del documento occhiale
                sessionRef.current.glassesRefsDoc = glassesDocRef.id;

                setGlassesRef(glassesDocRef);
                setLineRefs(glassesData.lineaRef || null);
                setGlassesSize(sizeRefDoc);
                updateRefBrand(brandDocRef);

                // Determina il browser
                const determineBrowserName = () => {
                    const userAgent = navigator.userAgent;
                    if (userAgent.includes("Firefox")) return "Mozilla Firefox";
                    if (userAgent.includes("Chrome")) return "Google Chrome";
                    if (userAgent.includes("Safari")) return "Apple Safari";
                    if (userAgent.includes("Opera") || userAgent.includes("OPR")) return "Opera";
                    if (userAgent.includes("Trident")) return "Microsoft Internet Explorer";
                    if (userAgent.includes("Edge")) return "Microsoft Edge";
                    return "Unknown";
                };

                // Gestisci la prima sessione (usando i cookie)
                const isFirstSession = !Cookies.get("visited");
                if (isFirstSession) {
                    Cookies.set("visited", "true");
                }

                // Imposta i dati della sessione nella ref
                sessionRef.current.device_id = deviceID;
                sessionRef.current.device_os = determineBrowserName();
                sessionRef.current.glassesSize = sizeRefDoc;
                sessionRef.current.is_first_session = isFirstSession;

                // **CORREZIONE:** Evita duplicati di `lineaRefs` e `glassesRefs`
                sessionRef.current.lineaRefs = [
                    ...new Set([...(sessionRef.current.lineaRefs || []), glassesData.lineaRef]),
                ].filter(Boolean); // Rimuove `null` e duplicati

                sessionRef.current.glassesRefs = [
                    ...new Set([...(sessionRef.current.glassesRefs || []), glassesDocRef.id]),
                ];

                sessionRef.current.ref_catalogo = brandDocRef;
                sessionRef.current.data_inizio_sessione = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;

                // Aggiorna i contatori se necessario
                const updateCountersIfNeeded = async () => {
                    const sameMonthYear = contatoriData.bandwidth_3dviewer.some(
                        (bandw) => bandw.month === date.getMonth() + 1 && bandw.year === year
                    );
                    if (!sameMonthYear) {
                        await updateDoc(contatoriDocRef, {
                            bandwidth_3dviewer: [
                                ...contatoriData.bandwidth_3dviewer,
                                { bandwidth_month: 0, calls_number: 0, month: date.getMonth() + 1, year },
                            ],
                        });
                    }
                };
                await updateCountersIfNeeded();
            } catch (error) {
                console.error("Errore durante l'inizializzazione della sessione:", error);
            }
        };

        initializeSession(); // Chiama la funzione principale
    }, [brand, glasses]);

    useEffect(() => {
        const updateModelHistory = () => {
            if (viewer?.src) {
                setModelHistory((prevHistory) => [...prevHistory, viewer?.src]);
            }
        };

        updateModelHistory(); // Chiama la funzione per aggiornare la cronologia
    }, [viewer?.src]); // Dipendenza richiesta

    const [listCreated, setListCreated] = useState(false);

    function getMeseCorrente() {
        const dataOdierna = new Date();
        const meseCorrente = dataOdierna.getMonth() + 1; // Aggiungi 1 perché i mesi sono indicizzati da 0 a 11
        return meseCorrente;
    }

    function getCurrentYear() {
        const dataOdierna = new Date();
        const annoCorrente = dataOdierna.getFullYear(); // Aggiungi 1 perché i mesi sono indicizzati da 0 a 11
        return annoCorrente;
    }

    useEffect(() => {
        if (!initialModel || lock) return;

        const updateViewerBandwidth = async () => {
            try {
                // Recupera il documento del modello
                const variantRef = doc(collection(db, "Modello"), initialModel);
                const variantSnap = await getDoc(variantRef);
                if (!variantSnap.exists()) throw new Error("Modello non trovato");

                const src = variantSnap.data().urlGlbComplete;
                const fileImg = await fetch(src).then((r) => r.blob());
                const sizeMb = fileImg.size / 1000000;
                const banda = parseFloat(sizeMb.toFixed(2)) + 0.5;

                // Recupera il documento del visualizzatore
                const viewerRef = doc(collection(db, "Visualizzatori3D"), brand);
                const viewerSnap = await getDoc(viewerRef);
                if (!viewerSnap.exists()) throw new Error("Visualizzatore non trovato");

                const docBrand = viewerSnap.data().brand;

                // Recupera il documento del brand
                const brandRef = doc(collection(db, "Brand"), docBrand);
                const brandSnap = await getDoc(brandRef);
                if (!brandSnap.exists()) throw new Error("Brand non trovato");

                const dataConsumptionDocId = brandSnap.data().data_consumption;

                // Recupera il documento dei contatori
                const contatoriRef = doc(collection(db, "Contatori_Brand"), dataConsumptionDocId);
                const contatoriSnap = await getDoc(contatoriRef);
                if (!contatoriSnap.exists()) throw new Error("Contatori non trovati");

                const contatoriData = contatoriSnap.data();
                const meseCorrente = getMeseCorrente();
                const year = getCurrentYear();

                // Aggiorna i dati della banda per il mese e anno correnti
                const updatedData = contatoriData.bandwidth_3dviewer.map((ele) =>
                    ele.month === meseCorrente && ele.year === year
                        ? { ...ele, bandwidth_month: ele.bandwidth_month + banda, calls_number: ele.calls_number + 1 }
                        : ele
                );

                await updateDoc(contatoriRef, { bandwidth_3dviewer: updatedData });
            } catch (error) {
                console.error("Errore durante l'aggiornamento della banda:", error);
            }
        };

        const scheduleUpdate = async () => {
            await updateViewerBandwidth();
            await updateSession();
        };

        setTimeout(() => {
            scheduleUpdate();
        }, 10);
    }, [initialModel, lock, db, brand]); // Dipendenze richieste

    const updateSession = async () => {
        try {
            if (!initialModel) return;

            // Ottieni il riferimento al documento del modello specificato
            const modelRefId = doc(db, "Modello", initialModel);
            const modelSnap = await getDoc(modelRefId);

            if (!modelSnap.exists()) {
                console.error("Modello non trovato in Firestore.");
                return;
            }

            const modelData = modelSnap.data();

            // Calcola il tempo trascorso dall'inizio della visualizzazione del modello
            const date = new Date();
            const timeDiff = Math.floor((date.getTime() - initialModelTime.getTime()) / 1000);

            // Aggiorna il tempo di inizio del modello
            setInitiaModellTime(date);

            // Ottieni il blob del modello e calcola la dimensione del file in MB
            const url = modelData.urlGlbComplete;
            const blob = await fetch(url).then((r) => r.blob());
            const banda = parseFloat((blob.size / 1000000).toFixed(2)); // Calcola la banda in MB

            const session = sessionRef.current;

            if (!listCreated) {
                session.modelRefs = listVariant;
                session.device = window.navigator.userAgent;
                session.lista_occhiali_visualizzati = [
                    {
                        nome_occhiale: modelData.nomeOcchiale,
                        lineaRef: session.lineaRefs[0],
                        glassesRef: session.glassesRefsDoc,
                        listaModelliProvati: [
                            {
                                modelRef: modelRefId,
                                timeStart: date,
                                totalSeconds: 0,
                                tagliaRef: session.glassesSize,
                            },
                        ],
                        timeStart: date,
                        banda_consumata: banda,
                        date: date,
                        totalSeconds: 0,
                    },
                ];
                setListCreated(true);
            } else {
                // Se la lista è già stata creata, aggiorna i dati della sessione
                const currentModel = session.lista_occhiali_visualizzati[0];
                const listModelTry = [...currentModel.listaModelliProvati];

                // Aggiorna il tempo totale per ogni modello provato
                listModelTry.forEach((model) => {
                    if (model.timeStart) {
                        const modelTimeDiff = Math.floor((date.getTime() - model.timeStart.getTime()) / 1000);
                        model.totalSeconds += modelTimeDiff;
                    }
                });

                // Trova il modello corrente e aggiorna il suo tempo di inizio
                const existingModel = listModelTry.find((model) => model.modelRef.id === modelRefId.id);
                if (existingModel) {
                    existingModel.timeStart = date;
                } else {
                    listModelTry.push({
                        modelRef: modelRefId,
                        timeStart: date,
                        totalSeconds: 0,
                        tagliaRef: session.glassesSize,
                    });
                }

                // Calcola il tempo totale per tutti i modelli provati
                const totalModelSeconds = listModelTry.reduce((sum, model) => sum + model.totalSeconds, 0);

                session.lista_occhiali_visualizzati[0] = {
                    ...currentModel,
                    nome_occhiale: modelData.nomeOcchiale,
                    lineaRef: lineRefs,
                    glassesRef: glassesRef,
                    listaModelliProvati: listModelTry,
                    timeStart: date,
                    banda_consumata: currentModel.banda_consumata + banda,
                    totalSeconds: totalModelSeconds,
                };
            }

            // Aggiorna la sessione in Firestore
            if (sessionRef.current && sessionRef.current.device_id) {
                await updateDoc(sessionRef.current, sessionRef.current);
            }
        } catch (error) {
            console.error("Errore nell'aggiornamento della sessione:", error);
        }
    };

    useEffect(() => {
        // Funzione per creare il documento della sessione
        const createSessionDocument = async () => {
            try {
                // Inizializza il documento con alcuni dati iniziali (ad es. l'orario di inizio)
                const initialData = {
                    ...sessionRef.current,
                    data_inizio_sessione: new Date().toISOString(),
                };

                // Riferimento alla collezione "Sessione_Visualizzatori3d"
                const sessioneRef = collection(db, "Sessione_Visualizzatori3d");
                // Crea il documento e ottieni il riferimento
                const docRef = await addDoc(sessioneRef, initialData);
                // Imposta l'ID del documento nello stato
                setDocumentId(docRef.id);
                updateSessionStartTime();
            } catch (error) {
                console.error("Error creating document:", error);
            }
        };

        // Se il documento della sessione non esiste, crealo
        if (!documentId) {
            createSessionDocument();
        }

        // Imposta un intervallo per aggiornare la sessione ogni 10 secondi
        const intervalId = setInterval(() => {
            if (documentId && !sessionExpired) {
                handleUnload(documentId);
            }
        }, 10000);

        // Imposta un timer per fermare la sessione dopo 4 minuti
        const expirationTimerId = setTimeout(() => {
            setSessionExpired(true);
        }, 240000);

        const handleBeforeUnload = (event) => {
            if (documentId && !sessionExpired) {
                handleUnload(documentId);
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            clearInterval(intervalId);
            clearTimeout(expirationTimerId);
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
        // NOTA: Rimuoviamo 'session' dalle dipendenze perché ora usiamo sessionRef
    }, [documentId, sessionExpired]);

    async function handleUnload(docId) {
        if (sessionExpired) return;

        // Calcola la data corrente in modo formattato
        const padWithZero = (number) => number.toString().padStart(2, "0");
        const date = new Date();
        const year = date.getFullYear();
        const month = padWithZero(date.getMonth() + 1);
        const day = padWithZero(date.getDate());
        const hours = padWithZero(date.getHours());
        const minutes = padWithZero(date.getMinutes());
        const seconds = padWithZero(date.getSeconds());

        // Aggiorna i dati della sessione usando sessionRef.current
        sessionRef.current.lista_occhiali_visualizzati.forEach((item) => {
            const currentTime = new Date().getTime();

            if (item.timeStart) {
                const elapsedTime = Math.floor((currentTime - new Date(item.timeStart).getTime()) / 1000);
                if (elapsedTime > 0) {
                    item.totalSeconds += elapsedTime;
                    item.timeStart = new Date(currentTime);
                }
            }

            item.listaModelliProvati.forEach((model) => {
                if (model.timeStart) {
                    const modelElapsedTime = Math.floor((currentTime - new Date(model.timeStart).getTime()) / 1000);
                    if (modelElapsedTime > 0) {
                        model.totalSeconds += modelElapsedTime;
                        model.timeStart = new Date(currentTime);
                    }
                }
            });

            const totalModelSeconds = item.listaModelliProvati.reduce((sum, model) => sum + model.totalSeconds, 0);
            item.totalSeconds = totalModelSeconds;
        });

        let totalTime = 0;
        sessionRef.current.lista_occhiali_visualizzati.forEach((item) => {
            totalTime += item.totalSeconds;
        });
        sessionRef.current.total_time = totalTime;
        sessionRef.current.data_fine_user_session = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;

        // Prepara i dati senza i campi temporanei
        const sessionToSave = {
            ...sessionRef.current,
            lista_occhiali_visualizzati: sessionRef.current.lista_occhiali_visualizzati.map((item) => {
                const { timeStart, ...restItem } = item;
                return {
                    ...restItem,
                    listaModelliProvati: item.listaModelliProvati.map((model) => {
                        const { timeStart, ...restModel } = model;
                        return restModel;
                    }),
                };
            }),
        };

        const sessioneRef = doc(collection(db, "Sessione_Visualizzatori3d"), docId);
        try {
            await updateDoc(sessioneRef, sessionToSave);
        } catch (error) {
            console.error("Error updating session in Firestore:", error);
        }
    }

    function selecteModelInView(project, modelId) {
        setModelHistory([...modelHistory, viewer?.src]);
        setInitialModel(modelId);
    }

    function setSelectedId(modelId) {
        selecteModelInView(project[0], modelId);
    }

    useEffect(() => {
        if (initialModel) {
            setCurrentVariant(initialModel);
            setVariantData(initialModel);
        }
    }, [initialModel]);

    useEffect(() => {
        if (listVariant && listVariant.length) {
            _setListVariant(listVariant);
        }
    }, [listVariant]);

    const setVariantData = async (initialModel) => {
        try {
            // Ottieni il riferimento alla variante
            const variantRef = doc(
                collection(db, "Visualizzatori3D", brand, "Glasses", glasses, "variants"),
                initialModel
            );
            const variantSnap = await getDoc(variantRef);

            const variantData = variantSnap.exists() ? variantSnap.data() : null;

            if (variantData && variantData.shouldApply) {
                return dispatch(brandViewerActions.setSelectedProject(variantData));
            }

            // Ottieni il riferimento al modello ARTemplate
            const modelRef = doc(
                collection(db, "Visualizzatori3D", brand, "Glasses", params?.TokenGlasses, "template"),
                "ARTemplate"
            );
            const modelSnap = await getDoc(modelRef);

            const modelData = modelSnap.exists() ? modelSnap.data() : null;

            if (modelData && modelData.shouldApply) {
                return dispatch(brandViewerActions.setSelectedProject(modelData));
            }

            // Ottieni i dati del visualizzatore principale
            const visualizzatoreRef = doc(collection(db, "Visualizzatori3D"), brand);
            const visualizzatoreSnap = await getDoc(visualizzatoreRef);

            const rootData = visualizzatoreSnap.exists() ? visualizzatoreSnap.data() : null;

            if (rootData) {
                return dispatch(brandViewerActions.setSelectedProject(rootData));
            }

        } catch (error) {
            console.error("Errore durante il recupero dei dati della variante:", error);
        }
    };

    function unlock() {
        setLock(false);
    }

    const handleSelectModel = (modelIdCard) => {
        selecteModelInView(project[0], modelIdCard);
    };

    const handleBrowserWarning = () => {
        notification.warning({
            message: "Browser Not Supported",
            description:
                "The Facebook browser does not support 3D models and augmented reality. Please try another browser.",
            placement: "top",
        });
    };

    return (
        <div>
            {lock ? (
                <Password unlock={unlock} password={pwd} />
            ) : (
                <ModelContainerFrontEnd
                    modelSelected={modelInView}
                    project={project[0]}
                    isEditModeOn={isEditModeOn}
                    containerWidth={containerWidth}
                    containerHeight={containerHeight}
                >
                    {positionMenu === "top" && (
                        <MenuContainer
                            style={"TOP"}
                            menu={project[0]?.menu_type}
                            childCount={listVariant.length}
                            key={project[0]?.menu_type}
                        >
                            {listVariant.map((modelIdCard, i) => (
                                <CardModelView
                                    key={i}
                                    modelId={modelIdCard}
                                    selected={initialModel}
                                    project={project[0]}
                                    selectModel={() => handleSelectModel(modelIdCard)}
                                    cardStyle={project[0]?.menu_type}
                                />
                            ))}
                        </MenuContainer>
                    )}

                    {positionMenu === "left" && (
                        <MenuContainer
                            style={"LEFT"}
                            menu={project[0]?.menu_type}
                            key={project[0]?.menu_type}
                        >
                            {listVariant.map((modelIdCard, i) => (
                                <CardModelView
                                    key={i}
                                    modelId={modelIdCard}
                                    selected={initialModel}
                                    project={project[0]}
                                    selectModel={() => handleSelectModel(modelIdCard)}
                                    cardStyle={project[0]?.menu_type}
                                />
                            ))}
                        </MenuContainer>
                    )}

                    {!navigator.userAgent.match(/FBAV/i) && (
                        <ProjectModelViewer
                            multiViewer
                            title={project.title}
                            initialModel={initialModel}
                            project={project[0]}
                            paramsId={params.TokenGlasses}
                            urlView={`https://studio.arshades.it/Arshades3ds/${project[0]?.id}/glasses/${params.TokenGlasses}`}
                            viewerPage
                            isEditModeOn={isEditModeOn}
                            modelAnimations={modelAnimations}
                        />
                    )}

                    {navigator.userAgent.match(/FBAV/i) &&
                        !navigator.userAgent.match(/(iPod|iPhone|iPad)/) && (
                            <>
                                <img
                                    src={modelInView?.not_supported_image}
                                    style={{ width: "100%" }}
                                    alt="Not supported"
                                />
                                {handleBrowserWarning()}
                            </>
                        )}

                    {positionMenu === "bottom" && (
                        <MenuContainer
                            style={"BOTTOM"}
                            menu={project[0]?.menu_type}
                            key={project[0]?.menu_type}
                            childCount={listVariant.length}
                            selectedId={initialModel}
                            setSelectedId={setSelectedId}
                        >
                            {listVariant.map((modelIdCard, i) => (
                                <CardModelView
                                    key={i}
                                    modelId={modelIdCard}
                                    selected={initialModel}
                                    project={project[0]}
                                    selectModel={() => handleSelectModel(modelIdCard)}
                                    cardStyle={project[0]?.menu_type}
                                />
                            ))}
                        </MenuContainer>
                    )}

                    {positionMenu === "right" && (
                        <MenuContainer
                            style={"RIGHT"}
                            menu={project[0]?.menu_type}
                            key={project[0]?.menu_type}
                        >
                            {listVariant.map((modelIdCard, i) => (
                                <CardModelView
                                    key={i}
                                    modelId={modelIdCard}
                                    selected={initialModel}
                                    project={project[0]}
                                    selectModel={() => handleSelectModel(modelIdCard)}
                                    cardStyle={project[0]?.menu_type}
                                />
                            ))}
                        </MenuContainer>
                    )}
                </ModelContainerFrontEnd>
            )}
        </div>
    );
};

export default ARShades3dsViewer;
