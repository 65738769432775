import { SET_SELECTED_CATALOG } from "../actionType";

const initialState = {
    selectedCatalog: { id: "all", nome_catalog: "All Catalogues" },
};

const catalogReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_CATALOG:
            return {
                ...state,
                selectedCatalog: action.catalog || initialState.selectedCatalog,
            };
        default:
            return state;
    }
};

export default catalogReducer;