export const statusMapping = {
    "Pubblicato": "Published",
    "In Pubblicazione": "In Publication",
    "Rev. Cliente": "Client Rev.",
    "Rev. Spaarkly": "Spaarkly Rev.",
    "Incompleto": "Incomplete",
    "Rev. Modellista": "Modelist Rev."
};

export const genderMapping = {
    "Uomo": "Man",
    "Donna": "Woman",
    "Unisex": "Unisex"
};

export const generateIframeCode = (i, m, variantDepth, setBrandIdSelected, setModalIframe, setIndexModelExportCode, setSelectedModel, setModalText, setIframeVtoString, showModal, messageApi) => {
    if (m.stato !== "Incompleto") {
        // Aggiorna lo stato se necessario
        setBrandIdSelected(m.brandId);
        setModalIframe(prev => !prev);
        setIndexModelExportCode(i);
        setSelectedModel(m);

        // Determina safeId in base alla variantDepth
        const safeId = variantDepth === 1 ? m.id : m.variantId;

        // Controllo per evitare undefined su safeId
        if (!safeId) {
            console.warn("safeId è undefined, impostato valore di default.");
        }

        // Genera la stringa per il 3D Viewer (base e variante)
        const iframeString = `
            <iframe 
                id="spaarkly-container" 
                src="${process.env.REACT_APP_ACTIVE_BASE_URL}/Arshades3ds/${m.mainBrandId}/glasses/${m.glassID}"
                allow="xr-spatial-tracking" 
                style="height: 900px; width: 100%; cursor: default;"> 
            </iframe>
            <style>
                @media screen and (max-width: 992px){
                    #spaarkly-container{ 
                        height: 650px; 
                        width: 100%;
                    }
                }
            </style>
        `;

        const iframeVariantString = `
            <iframe 
                id="spaarkly-container" 
                src="${process.env.REACT_APP_ACTIVE_BASE_URL}/Arshades3d/${m.mainBrandId}/glasses/${m.glassID}/variant/${safeId}"
                allow="xr-spatial-tracking" 
                style="height: 900px; width: 100%; cursor: default;"> 
            </iframe>
            <style>
                @media screen and (max-width: 992px){   
                    #spaarkly-container{ 
                        height: 650px; 
                        width: 100%;
                    }
                }
            </style>
        `;

        // Genera due stringhe per la VTO (base e variante)
        const iframeVtoStringBase = `
            <iframe
                id="arshades-iframe-content"
                class="arshades-iframe"
                allow="camera *; web-share"
                sandbox="allow-same-origin allow-scripts allow-forms"
                src="https://www.webvto.it/?c=${m.mainBrandId}&m=${m.variantId}"
            >
            </iframe>
            <style>
                .arshades-iframe {
                    width: 100%;
                    height: 90vh;
                    border: none;
                }
            </style>
        `;

        const iframeVtoStringVariant = `
            <iframe
                id="arshades-iframe-content"
                class="arshades-iframe"
                allow="camera *; web-share"
                sandbox="allow-same-origin allow-scripts allow-forms"
                src="https://www.webvto.it/?c=${m.mainBrandId}&m=${m.id}"
            >
            </iframe>
            <style>
                .arshades-iframe {
                    width: 100%;
                    height: 90vh;
                    border: none;
                }
            </style>
        `;

        // Determina quale iframe usare per il 3D Viewer
        const iframeToUse = variantDepth === 1 ? iframeVariantString : iframeString;

        // Determina quale iframeVtoString usare
        const iframeVtoToUse = variantDepth === 1 ? iframeVtoStringVariant : iframeVtoStringBase;

        // Imposta SEMPRE sia modalText (per il 3D Viewer) sia iframeVtoString (per la VTO)
        setModalText(iframeToUse);
        setIframeVtoString(iframeVtoToUse);
        showModal();
    } else {
        messageApi.error("Glass is in Incomplete State");
    }
};