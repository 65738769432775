import React, { useEffect, useState } from "react";
import { Table, Card, Row, Col, message, Space, Button } from "antd";
import {
    fetchBrands,
    fetchGlasses,
    fetchLines,
    fetchModels,
    fetchSizes,
} from "../../services/api/gatewaysApi";
import { processCatalogueData } from "../../services/data/gatewaysData";
import SummaryCard from "../Analytics/SummaryCard";
import ColorIndicator from "../Analytics/ColorIndicator";
import SummaryGrid from "../Analytics/SummaryGrid";

const LinesGlassesVariantsAnalytics = ({ data, clearFiltersVisible, onResetFilters }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [analyticsData, setAnalyticsData] = useState({
        brands: [],
        lines: [],
        glasses: [],
        sizes: [],
        models: [],
    });
    const [selectedTable, setSelectedTable] = useState("Lines");

    const summaryData = [
        { title: "Lines", count: analyticsData.lines.length },
        { title: "Glasses", count: analyticsData.glasses.length },
        { title: "Variants", count: analyticsData.models.length },
    ];

    const formatTime = (seconds) => {
        if (!seconds || isNaN(seconds)) return "00:00:00";

        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = Math.floor(seconds % 60);
        return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
            2,
            "0"
        )}:${String(secs).padStart(2, "0")}`;
    };

    useEffect(() => {
        const buildDataStructure = async () => {
            try {
                setLoading(true);
                const processedData = processCatalogueData(data);

                if (processedData.length === 0) {
                    setAnalyticsData({
                        brands: [],
                        lines: [],
                        glasses: [],
                        sizes: [],
                        models: [],
                    });
                    setLoading(false);
                    return;
                }

                const allGlassIds = processedData.flatMap((item) =>
                    item.glasses.map((glass) => glass.id)
                );
                const allSizeIds = processedData.flatMap((item) =>
                    item.sizes.map((size) => size.id)
                );
                const allVariantIds = processedData.flatMap((item) =>
                    item.variants.map((variant) => variant.id)
                );
                const uniqueBrands = processedData.map(
                    (item) => item.catalogueRef
                );

                const brands = await fetchBrands(uniqueBrands);
                const lineIds = brands.flatMap((brand) =>
                    (brand.listaRefsLinea || []).map((ref) => ref.id)
                );
                const lineDetails = await fetchLines(lineIds);
                const glassesDetails = await fetchGlasses(allGlassIds);
                const sizesDetails = await fetchSizes(allSizeIds);
                const modelsDetails = await fetchModels(allVariantIds);

                const enrichedGlasses = glassesDetails.map((glass) => {
                    const matchingGlass = processedData
                        .flatMap((item) => item.glasses)
                        .find((g) => g.id === glass.idOcchiale);
                    return {
                        ...glass,
                        visualizations: matchingGlass
                            ? matchingGlass.visualization
                            : 0,
                        time: matchingGlass
                            ? matchingGlass.visualizationTime
                            : 0,
                    };
                });

                const enrichedLines = lineDetails.map((line) => {
                    const glassesInLine = enrichedGlasses.filter((glass) =>
                        (line.listaRefsOcchiale || []).includes(glass.idOcchiale)
                    );

                    const totalVisualizations = glassesInLine.reduce(
                        (sum, glass) => sum + glass.visualizations,
                        0
                    );
                    const totalTime = glassesInLine.reduce(
                        (sum, glass) => sum + glass.time,
                        0
                    );

                    return {
                        ...line,
                        visualizations: totalVisualizations,
                        time: totalTime,
                    };
                });

                const enrichedModels = modelsDetails.map((model) => {
                    const matchingModel = processedData
                        .flatMap((item) => item.variants)
                        .find((v) => v.id === model.idModello);
                    return {
                        ...model,
                        visualizations: matchingModel
                            ? matchingModel.visualization
                            : 0,
                        time: matchingModel
                            ? matchingModel.visualizationTime
                            : 0,
                    };
                });

                setAnalyticsData({
                    brands,
                    lines: enrichedLines,
                    glasses: enrichedGlasses,
                    sizes: sizesDetails,
                    models: enrichedModels,
                });

                setLoading(false);
            } catch (error) {
                console.error("Errore durante il caricamento dei dati:", error);
                messageApi.error("Errore durante il caricamento dei dati!");
                setLoading(false);
            }
        };

        buildDataStructure();
    }, [data]);

    const handleCardClick = (type) => {
        setSelectedTable(type);
    };

    const renderTable = () => {
        const commonColumns = [
            {
                title: "Name",
                dataIndex: "name",
                key: "name",
                sorter: (a, b) => a.name.localeCompare(b.name),
                width: "40%",
                showSorterTooltip: false,
                render: (_, record) => {
                    if (selectedTable === "Variants") {
                        return (
                            <Space size="small" align="center">
                                <span>{record.name}</span>
                                {record.esaColorFramePrimary && (
                                    <ColorIndicator
                                        primary={record.esaColorFramePrimary}
                                        secondary={record.esaColorFrameSecondary}
                                        type="square"
                                        size="small"
                                    />
                                )}
                                {record.esaColorLensesPrimary && (
                                    <ColorIndicator
                                        primary={record.esaColorLensesPrimary}
                                        secondary={record.esaColorLensesSecondary}
                                        size="small"
                                    />
                                )}
                            </Space>
                        );
                    }
                    return record.name;
                },
            },
            {
                title: "Visualizations",
                dataIndex: "visualizations",
                key: "visualizations",
                sorter: (a, b) => a.visualizations - b.visualizations,
                defaultSortOrder: "descend", // Ordinamento predefinito
                showSorterTooltip: false, // Tooltip rimosso
                width: "30%",
            },
            {
                title: "Time",
                dataIndex: "time",
                key: "time",
                sorter: (a, b) => timeInSeconds(a.time) - timeInSeconds(b.time),
                showSorterTooltip: false, // Tooltip rimosso
                width: "30%",
            },
        ];

        const timeInSeconds = (timeString) => {
            const [hrs, mins, secs] = timeString
                .split(":")
                .map((num) => parseInt(num, 10) || 0);
            return hrs * 3600 + mins * 60 + secs;
        };

        const paginationSettings = {
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50", "100", "200", "500", "1000"],
        };

        if (selectedTable === "Lines") {
            return (
                <Table
                    dataSource={analyticsData.lines.map((line, index) => ({
                        key: line.id || `line-${index}`,
                        name: line.nome_linea,
                        visualizations: line.visualizations,
                        time: formatTime(line.time),
                    }))}
                    rowKey="key"
                    columns={commonColumns}
                    pagination={paginationSettings}
                    size="small"
                    scroll={{ x: "max-content" }}
                />
            );
        } else if (selectedTable === "Glasses") {
            return (
                <Table
                    dataSource={analyticsData.glasses.map((glass, index) => ({
                        key: glass.id || `glass-${index}`,
                        name: glass.nome_modello,
                        visualizations: glass.visualizations,
                        time: formatTime(glass.time),
                    }))}
                    rowKey="key"
                    columns={commonColumns}
                    pagination={paginationSettings}
                    size="small"
                    scroll={{ x: "max-content" }}
                />
            );
        } else if (selectedTable === "Variants") {
            return (
                <Table
                    dataSource={analyticsData.models.map((variant, index) => ({
                        key: variant.id || `variant-${index}`,
                        name: variant.nomeOcchiale,
                        visualizations: variant.visualizations,
                        time: formatTime(variant.time),
                        esaColorFramePrimary: variant.esaColorFramePrimary,
                        esaColorFrameSecondary: variant.esaColorFrameSecondary,
                        esaColorLensesPrimary: variant.esaColorLensesPrimary,
                        esaColorLensesSecondary: variant.esaColorLensesSecondary,
                    }))}
                    rowKey="key"
                    columns={commonColumns}
                    pagination={paginationSettings}
                    size="small"
                    scroll={{ x: "max-content" }}
                />
            );
        }
        return null;
    };

    return (
        <>
            {contextHolder}
            <SummaryGrid>
                {summaryData.map(({ title, count }) => (
                    <div key={title}>
                        <SummaryCard
                            label={title}
                            value={count}
                            loading={loading}
                            active={title === selectedTable}
                            onClick={() => handleCardClick(title)}
                        />
                    </div>
                ))}
            </SummaryGrid>
            <Card
                title={
                    <Row justify="space-between" align="middle" style={{ width: "100%" }}>
                        <Col>
                            <span>{selectedTable}</span>
                        </Col>
                        <Col>
                            {clearFiltersVisible && (
                                <Button type="default" onClick={onResetFilters}>
                                    Clear filters
                                </Button>
                            )}
                        </Col>
                    </Row>
                }
            >
                <Row gutter={[16, 16]} style={{ marginTop: 24 }}>
                    <Col span={24}>{renderTable()}</Col>
                </Row>
            </Card>
        </>
    );
};

export default LinesGlassesVariantsAnalytics;