import React, { useRef, useState, useEffect } from "react";
import styles from "../styles/ARShades3dViewerCustom/ARShades3dsViewerCustom.module.css";
import lensIcon from "../assets/icon/Polarized.svg"
import lensIcon2 from "../assets/icon/lens2.svg"
import lensIcon3 from "../assets/icon/lens3.svg"
import frameIcon from "../assets/icon/montatura1.svg"
import frameIcon2 from "../assets/icon/montatura2.svg"
import Transition from 'react-transition-group/Transition';

function ARShades3dViewerCustom() {
    const modelRef = useRef(null);
    const [showHotspot, setShowHotspot] = useState(false);
    const [showFirstHotspot, setShowFirstHospot] = useState(false)
    const [showSecondHotspot, setShowSecondHotspot] = useState(false);
    const [showThirdHotspot, setShowThirdHotspot] = useState(false);
    const [showHotspotInfoOne, setShowHotspotInfoOne] = useState(false);
    const [showHotspotInfoTwo, setShowHotspotInfoTwo] = useState(false);
    const [showHotspotInfoThree, setShowHotspotInfoThree] = useState(false);

    useEffect(() => {
        function handleModelClick(event) {

            const hotspot = event.target;
            if (hotspot.slot === 'hotspot-1') {

                setShowHotspotInfoOne(true)
                setShowHotspot(false)
                setShowFirstHospot(false);
                setShowSecondHotspot(true);
                setShowHotspotInfoTwo(false);
                setShowHotspotInfoThree(false);
                modelViewer.cameraOrbit = "13.64deg 88.89deg 0.4215m";
                modelViewer.cameraTarget = "0.0m 0.03m 0m";
                modelViewer.fieldOfView = "12deg";
            }
            // Aggiungi questo per gestire il click sul secondo hotspot se necessario
            if (hotspot.slot === 'hotspot-2') {
                setShowSecondHotspot(false);
                setShowHotspotInfoThree(false)
                setShowHotspotInfoTwo(true)
                setShowThirdHotspot(true)
                setShowHotspot(true)
                setShowHotspotInfoOne(false)
                modelViewer.cameraTarget = "0.0m 0.03m 0m";
                modelViewer.cameraOrbit = "-55.31deg 59.24deg 0.854m";
                modelViewer.fieldOfView = "24.9deg"
                // Cosa vuoi fare quando il secondo hotspot viene cliccato?
            }
            if (hotspot.slot === 'hotspot-3') {
                setShowHotspot(true)
                setShowThirdHotspot(false)
                setShowSecondHotspot(true)
                setShowHotspotInfoThree(true)
                setShowHotspotInfoOne(false)
                setShowHotspotInfoTwo(false)
                handleImplodeAnimation()
                modelViewer.cameraTarget = "0.0m 0.03m -0m";
                modelViewer.cameraOrbit = "24.88deg 84.17deg 0.4084m";
                modelViewer.fieldOfView = "35deg"
                // Cosa vuoi fare quando il secondo hotspot viene cliccato?
            }
        }

        function handleModelLoad() {
            const modelViewer = modelRef.current;

            if (modelViewer) {
                modelViewer.animationName = 'Armature_Explosion';
                modelViewer.play({ repetitions: 1 });
                setIsExploded(true);
                setTimeout(() => {
                    modelViewer.pause();
                    // console.log(modelViewer)
                    setShowHotspot(true);
                }, 1660); // Dura 4.52 secondi
            }
        }

        const modelViewer = modelRef.current;

        if (modelViewer) {
            modelViewer.addEventListener('click', handleModelClick);
            modelViewer.addEventListener('load', handleModelLoad); // Aggiungi questo listener
        }

        // Questa funzione di pulizia viene eseguita quando il componente viene smontato.
        return () => {
            if (modelViewer) {
                modelViewer.removeEventListener('click', handleModelClick);
                modelViewer.removeEventListener('load', handleModelLoad); // Rimuovi anche questo listener
            }
        };
    }, []);

    const [isExploded, setIsExploded] = useState(false);

    function handleImplodeAnimation() {
        const modelViewer = modelRef.current;

        if (modelViewer) {
            modelViewer.animationName = 'Armature_Implosion';
            const animationLenght = 1660
            modelViewer.play({ repetitions: 1 });
            setIsExploded(false);

            setTimeout(() => {
                modelViewer.pause();
                setShowHotspot(true);
            }, 1660);
        }
    }

    function handleExplodedAnimation() {
        const modelViewer = modelRef.current;

        if (modelViewer) {
            modelViewer.animationName = 'Armature_Explosion';
            modelViewer.currentTime = 0;
            modelViewer.play({ repetitions: 1 });
            setIsExploded(true);
            setTimeout(() => {
                modelViewer.pause();
                setShowHotspot(true);
            }, 1660);
        }
    }

    return (
        <div className={styles['md-animation-custom']}>
            <Transition in={showHotspotInfoOne} timeout={{ enter: 2500, exit: 500 }}>
                {showHotspotInfoOne =>
                    <div style={{
                        transition: 'all 2s ease',
                        transitionDelay: showSecondHotspot ? '0ms' : '0ms',
                        opacity: showHotspotInfoOne === 'exited' || showHotspotInfoOne === 'exiting' ? 0 : 1, transform: showHotspotInfoOne === 'exited' || showHotspotInfoOne === 'exiting' ? 'translateX(100%)' : 'translate(-50%, 0)'
                    }} className={showHotspotInfoOne ? `${styles['div-info-container-one']}` : `${styles['div-info-container-one-hidden']}`}>
                        <div className={styles['info-div-lens-icon']}> <ul>
                            <li>
                                <div className={styles['md-icon-lens-container']}>
                                    <div className={styles['md-icon-lens']} style={{ backgroundImage: `url(${lensIcon})` }}> </div>
                                    <div className={styles['md-info-lens']}>
                                        <span className={styles['md-icon-lens-title']}>Polarizzate</span><br></br>
                                        POLARIZZATE
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className={styles['md-icon-lens-container']}>
                                    <div className={styles['md-icon-lens']} style={{ backgroundImage: `url(${lensIcon2})` }}> </div>
                                    <div className={styles['md-info-lens']}>
                                        <span className={styles['md-icon-lens-title']}>Colore Lente</span><br></br>
                                        RED MIRROR
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className={styles['md-icon-lens-container']}>
                                    <div className={styles['md-icon-lens']} style={{ backgroundImage: `url(${lensIcon3})` }}> </div>
                                    <div className={styles['md-info-lens']}>
                                        <span className={styles['md-icon-lens-title']}>Classe Lente</span><br></br>
                                        100
                                    </div>
                                </div>
                            </li>
                            {/* Aggiungi più icone qui */}
                        </ul>
                        </div>
                        <div className={`${styles['info-div-lens']}`}>
                            Questi occhiali da sole da uomo uniscono l’expertise di Carrera nel settore eyewear con i dettagli e l’attenzione alla tecnologia che caratterizzano le leggendarie moto sportive Ducati.
                        </div>
                    </div>}
            </Transition>

            <Transition in={showHotspotInfoTwo} timeout={{ enter: 4500, exit: 500 }}>
                {showHotspotInfoTwo =>
                    <div style={{
                        transition: 'all 2s ease',
                        transitionDelay: showThirdHotspot ? '1000ms' : '0ms',
                        opacity: showHotspotInfoTwo === 'exited' || showHotspotInfoTwo === 'exiting' ? 0 : 1, transform: showHotspotInfoTwo === 'exited' || showHotspotInfoTwo === 'exiting' ? 'translateX(-100%)' : 'translate(0%, 0)'
                    }} className={showHotspotInfoTwo ? `${styles['div-info-container-two']}` : `${styles['div-info-container-one-hidden']}`}>
                        <div className={styles['info-div-lens-icon']}> <ul>
                            <li>
                                <div className={styles['md-icon-lens-container']}>
                                    <div className={styles['md-icon-lens']} style={{ backgroundImage: `url(${frameIcon})` }}> </div>
                                    <div className={styles['md-info-lens']}>
                                        <span className={styles['md-icon-lens-title']}>Materiale </span><br></br>
                                        METALLO
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className={styles['md-icon-lens-container']}>
                                    <div className={styles['md-icon-lens']} style={{ backgroundImage: `url(${frameIcon2})` }}> </div>
                                    <div className={styles['md-info-lens']}>
                                        <span className={styles['md-icon-lens-title']}>Forma</span><br></br>
                                        Rotondo / ovale
                                    </div>
                                </div>
                            </li>
                            {/* Aggiungi più icone qui */}
                        </ul>
                        </div>
                        <div className={`${styles['info-div-lens']}`}>
                            La montatura esagonale arrotondata con il doppio ponte crea un dinamico contrasto con la silhouette geometrica delle aste – un tributo al design inconfondibile della Ducati Panigale.</div>
                    </div>}
            </Transition>

            <Transition in={showHotspotInfoThree} timeout={{ enter: 6500, exit: 500 }}>
                {showHotspotInfoThree =>
                    <div style={{
                        transition: 'all 2s ease',
                        transitionDelay: '1000ms',
                        opacity: showHotspotInfoThree === 'exited' || showHotspotInfoThree === 'exiting' ? 0 : 1, transform: showHotspotInfoThree === 'exited' || showHotspotInfoThree === 'exiting' ? 'translateX(100%)' : 'translate(-50%, 0)'
                    }} className={showHotspotInfoThree ? `${styles['div-info-container-three']}` : `${styles['div-info-container-one-hidden']}`}>
                        <div className={`${styles['info-div-lens-two']}`}>
                            Il modello offre una vestibilità perfetta sotto al casco da moto ed è rifinito con tocchi personalizzati Carrera e Ducati.
                        </div>
                    </div>
                }
            </Transition>
            <model-viewer
                environment-image="https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2FTest%2Fphoto_2023-08-31_16-48-17.jpg?alt=media&token=c1f128d0-f805-4733-bb4f-4581860e9174"
                camera-controls="true"
                interaction-prompt="none"
                interpolation-decay={300}
                ref={modelRef}
                id="customModel"
                src="https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2FTest%2FCARDUC005S_Armature%20test_3.glb?alt=media&token=e4798a9e-269c-4286-a476-69f732dd9a71"
                alt="Un modello 3D con animazioni e informazioni"
                class={styles['md-container']}
                camera-orbit="0deg 117.6deg 0.6727m"
                field-of-view="30deg"
                tone-mapping="aces"
                camera-target="0.0m 0.03m 0.01m"
            >
                {showHotspotInfoThree && (
                    <button className={styles['md-animation-button']} onClick={isExploded ? handleImplodeAnimation : handleExplodedAnimation}>{isExploded ? 'Componi' : 'Esplodi'}
                        <span className={styles['play-icon']}></span>
                    </button>
                )}
                {
                    showHotspot && <button className={styles['pulsing-circle']} slot="hotspot-1" data-visibility-attribute="visible" data-surface="11 0 720 657 655 0.166 0.271 0.563">
                        1
                    </button>
                }
                {
                    showSecondHotspot &&
                    <button
                        className={styles['pulsing-circle']}
                        slot="hotspot-2"
                        data-surface="0 0 2987 2 0 0.167 0.446 0.387"
                        data-visibility-attribute="visible"> 2
                    </button>
                }
                {
                    showThirdHotspot &&
                    <button
                        className={styles['pulsing-circle']}
                        slot="hotspot-3"
                        data-surface="15 0 649 653 645 0.405 0.057 0.537"
                        data-visibility-attribute="visible"
                    >3
                    </button>
                }
            </model-viewer>
        </div>
    );
}

export default ARShades3dViewerCustom;
