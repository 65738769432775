import { Layout, Button, theme } from "antd";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { toggleSidebar } from "../../redux/sidebar";

const { Header } = Layout;

function AnalyticsHeader({ title = "Analytics" }) {

    const { token } = theme.useToken();
    const collapsed = useSelector((state) => state.sidebar?.collapsed);
    const broken = useSelector((state) => state.sidebar?.broken);
    const dispatch = useDispatch();

    return (
        <Header
            style={{
                background: token.colorBgBase,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0 16px",
                position: "fixed",
                top: 0,
                left: broken ? "0px" : collapsed ? "80px" : "200px",
                right: 0,
                zIndex: 1000,
                height: "64px",
                transition: "all 0.4s ease",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            }}
        >
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                <h2 style={{ margin: 0, fontSize: "18px", fontWeight: "bold", color: token.colorTextBlack }}>
                    {title}
                </h2>
                {broken && (
                    <Button
                        type="text"
                        icon={<MenuUnfoldOutlined />}
                        onClick={() => dispatch(toggleSidebar())}
                    />
                )}
            </div>
        </Header>
    );
}

export default AnalyticsHeader;