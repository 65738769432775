import React, { useEffect, useRef } from "react";
import { Spin, Card } from "antd";
import Chart from "chart.js/auto";
import { NumberAnimated } from "../../services/home";

const LineChart = ({
    data,
    options,
    filledColor,
    isLoading,
    numberOfSession,
}) => {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);

    useEffect(() => {
        if (isLoading) return;

        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }

        const ctx = chartRef.current.getContext("2d");

        chartInstanceRef.current = new Chart(ctx, {
            type: "line",
            data: data,
            options: {
                ...options,
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    ...options.plugins,
                    fill: true,
                    backgroundColor: filledColor,
                },
            },
        });

        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
        };
    }, [data, options, filledColor, isLoading]);

    return (
        <Card
            title="Number of sessions (last 7 days)"
            variant="bordered"
            style={{
                height: "500px",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                    width: "100%",
                }}
            >
                {isLoading ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "400px",
                        }}
                    >
                        <Spin />
                    </div>
                ) : (
                    <>
                        <div style={{ width: "100%", height: "300px" }}>
                            <canvas
                                ref={chartRef}
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                }}
                            />
                        </div>
                        <div style={{ marginTop: "16px", textAlign: "center" }}>
                            <p
                                style={{
                                    margin: 0,
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                }}
                            >
                                Number of sessions
                            </p>
                            <div
                                style={{
                                    fontSize: "24px",
                                    fontWeight: "bold",
                                }}
                            >
                                <NumberAnimated n={numberOfSession} />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Card>
    );
};

export default LineChart;
