import { Card, Spin, theme } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import "./SummaryCard.css";
import CustomTooltip from "../common/CustomTooltip";

function SummaryCard({ active, label, value, onClick, loading, toolTipText = "Info on this card" }) {
    const { token } = theme.useToken();
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

    return (
        <Card
            hoverable
            onClick={onClick}
            style={{
                backgroundColor: active ? token.colorPrimary : token.colorLightGray,
                color: active ? token.colorTextWhite : token.colorTextBlack,
                textAlign: "center",
                borderRadius: "9px",
                height: "120px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                boxShadow: active ? "0 4px 8px rgba(0, 0, 0, 0.2)" : "none",
            }}
        >
            {active && (
                <div
                    style={{
                        position: "absolute",
                        bottom: "-10px",
                        right: "50%",
                        transform: "translateX(50%)",
                        width: "0",
                        height: "0",
                        borderLeft: "10px solid transparent",
                        borderRight: "10px solid transparent",
                        borderTop: `10px solid ${token.colorPrimary}`,
                    }}
                />
            )}
            {/* Icona dinamica in alto a destra */}
            <div
                style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    cursor: "pointer",
                }}
            >
                <CustomTooltip title={toolTipText}>
                    <InfoCircleOutlined style={{ fontSize: "1rem", color: active ? token.colorTextWhite : token.colorTextBlack }} />
                </CustomTooltip>
            </div>
            <div style={{ fontSize: isTabletOrMobile ? "1.3rem" : "2rem", fontWeight: "bold" }}>
                {loading ? <Spin className={active ? "white-spin" : ""} /> : value}
            </div>
            <div style={{ fontSize: isTabletOrMobile ? "0.7rem" : "0.9rem", fontWeight: "500" }}>
                {label}
            </div>
        </Card>
    );
}

export default SummaryCard;