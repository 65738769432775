import { createSlice } from '@reduxjs/toolkit';

const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState: {
        collapsed: true,
        type: '',
        broken: false,
    },
    reducers: {
        toggleSidebar(state) {
            state.collapsed = !state.collapsed;
        },
        setSidebarState(state, action) {
            state.collapsed = action.payload;
        },
        setSidebarType(state, action) {
            state.type = action.payload;
        },
        setSidebarBroken(state, action) {
            state.broken = action.payload;
        }
    },
});

export const { toggleSidebar, setSidebarState, setSidebarType, setSidebarBroken } = sidebarSlice.actions;
export default sidebarSlice.reducer;