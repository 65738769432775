import { SET_SELECTED_CATALOG } from "../actionType";

export const setSelectedCatalog = (catalog) => (dispatch) => {
    const serializedCatalog = {
        ...catalog,
        listaRefsLinea: catalog.listaRefsLinea?.map((ref) => ref.id) || [],
        skin: catalog.skin?.id || null,
        take_pic_template: catalog.take_pic_template?.id || null,
    };

    dispatch({
        type: SET_SELECTED_CATALOG,
        catalog: serializedCatalog,
    });
};