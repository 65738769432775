import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getFirestore, doc, getDoc, collection, query, where, getDocs } from "firebase/firestore";
import { List, Typography, Avatar, message, Spin, Card, Row, Col, Table, theme } from 'antd';
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import CenteredLoader from "../../components/CenteredLoader";
import { statusMapping } from "../../services/utils/ar3dViewer";

const { Meta } = Card;

const statusLabel = (model) => {
    const statusColors = {
        "Client Rev.": "#E3BA36",
        "Spaarkly Rev.": "#E3BA36",
        "Modelist Rev.": "#E3BA36",
        "Incomplete": "grey",
    };
    return statusColors[model.status] || "grey";
};

const gridStyle = {
    width: '25%',
    textAlign: 'center',
};

function Products() {
    // Ottiene l'ID del brand dai parametri dell'URL
    const { brandId } = useParams();
    const [brand, setBrand] = useState(null); // Stato per memorizzare i dettagli del brand
    const [models, setModels] = useState([]); // Stato per memorizzare i modelli associati al brand
    const [loading, setLoading] = useState(true); // Stato per gestire il caricamento
    const db = getFirestore(); // Inizializza Firestore
    const { token } = theme.useToken();

    useEffect(() => {
        if (!brandId) {
            message.error("ID brand non valido");
            setLoading(false);
            return;
        }

        // Rimuove il prefisso "id=" se presente nell'ID del brand
        const extractedBrandId = brandId.startsWith("id=") ? brandId.replace("id=", "") : brandId;

        const fetchBrandAndModels = async () => {
            setLoading(true);
            try {
                // Recupera i dettagli del brand usando l'ID estratto
                const brandRef = doc(db, "MainBrands", extractedBrandId);
                const brandSnap = await getDoc(brandRef);

                if (!brandSnap.exists()) {
                    message.error("Brand non trovato");
                    setLoading(false);
                    return;
                }

                const brandData = brandSnap.data();
                setBrand(brandData); // Imposta i dettagli del brand nello stato

                // Query Firestore per ottenere i prodotti associati al brand
                const q = query(
                    collection(db, "Products"),
                    where("mainBrandRef", "==", extractedBrandId) // Filtra per ID del brand
                );

                const querySnapshot = await getDocs(q);

                // Filtra e ordina i prodotti con status diverso da "Pubblicato" e "In Pubblicazione"
                const filteredModels = querySnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(product => product.status !== "Pubblicato" && product.status !== "In Pubblicazione")
                    .map(product => ({
                        ...product,
                        status: statusMapping[product.status] || product.status // Applica la mappatura dello stato
                    }))
                    .sort((a, b) => {
                        const order = ['Modelist Rev.', 'Client Rev.', 'Spaarkly Rev.', 'Incomplete'];
                        return order.indexOf(a.status) - order.indexOf(b.status);
                    });

                setModels(filteredModels); // Imposta i modelli filtrati e ordinati nello stato
            } catch (error) {
                console.error("Errore nel recupero dei dati:", error);
                message.error("Errore nel recupero dei dati");
            } finally {
                setLoading(false); // Termina il caricamento
            }
        };

        fetchBrandAndModels();
    }, [brandId, db]);

    const columns = [
        {
            title: 'Image',
            dataIndex: 'imgUrl',
            key: 'imgUrl',
            render: (text) => <img src={text} alt="Product" style={{ width: 50 }} />,
        },
        {
            title: 'Name',
            dataIndex: 'modelName',
            sorter: (a, b) => a.modelName.localeCompare(b.modelName),
            key: 'modelName',
        },
        {
            title: 'Priority',
            dataIndex: 'priority',
            sorter: (a, b) => a.priority - b.priority,
            key: 'priority',
        },
        {
            title: 'Last Update',
            dataIndex: 'lastUpdate',
            sorter: (a, b) => a.lastUpdate.localeCompare(b.lastUpdate),
            key: 'lastUpdate',
        },
        {
            title: 'Main Status',
            dataIndex: 'status',
            sorter: (a, b) => a.status.localeCompare(b.status),
            key: 'status',
            render: (text, model) => (
                <div
                    style={{
                        backgroundColor: statusLabel(model),
                        color: token.white,
                        padding: "4px 8px",
                        borderRadius: "16px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        maxWidth: "130px",
                        textAlign: "center",
                    }}
                >
                    {text}
                </div>
            ),
        },
    ];

    if (loading) return <CenteredLoader backgroundColor="white" />;

    return (
        <div>
            {brand && (
                <>
                    <Typography.Title level={2}>Modelli di {brand.nameBrand}</Typography.Title>
                    <Table
                        columns={columns}
                        dataSource={models}
                        rowKey="id"
                        pagination={false}
                    />
                </>
            )}
        </div>
    );
}

export default Products;
