import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js/auto";
import { Spin, Empty } from "antd";

function SummaryGraphs({ data, isLoading }) {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);

    useEffect(() => {
        if (!data || !chartRef.current) return;

        // Distrugge il grafico precedente se esiste
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        // Crea un nuovo grafico
        chartInstance.current = new Chart(chartRef.current, {
            type: "line",
            data: data,
            options: {
                responsive: true,
                maintainAspectRatio: false, // Disabilita il rapporto d'aspetto
                plugins: {
                    legend: {
                        display: false, // Nasconde la leggenda
                    },
                },
                scales: {
                    x: {
                        grid: { display: false }, // Rimuove la griglia X
                    },
                    y: {
                        beginAtZero: true, // Inizia Y da zero
                    },
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                    },
                },
            },
        });

        // Cleanup per rimuovere il grafico
        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, [data]);

    if (!data || !data.labels || data.labels.length === 0) {
        return (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        );
    }

    return (
        <>
            {isLoading ? (
                <div style={{ textAlign: 'center', padding: '2rem' }}>
                    <Spin size="large" />
                </div>
            ) : (
                <canvas
                    ref={chartRef}
                    style={{
                        marginTop: "10px",
                        maxWidth: "100%",
                        height: "400px",
                    }}
                />
            )}
        </>
    );
};

export default SummaryGraphs;