import { ADD_TO_CART, DECREMENT_QTY, REMOVE_FROM_CART } from "../actionType";
import Products from "../../data/ecommerce/productData";

const INIT_STATE = {
  productItems: Products,
  products: Products,
  cart: [],
};

const cartReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_TO_CART: {
      const { product, qty } = action.payload;
      const existingProduct = state.cart.find((item) => item.id === product.id);

      const updatedCart = existingProduct
        ? state.cart.map((item) =>
          item.id === product.id
            ? {
              ...item,
              qty: item.qty + qty,
              sum: item.price * (item.qty + qty),
            }
            : item
        )
        : [
          ...state.cart,
          {
            ...product,
            qty,
            sum: product.price * qty,
          },
        ];

      return { ...state, cart: updatedCart };
    }

    case DECREMENT_QTY: {
      const { productId } = action;

      const updatedCart = state.cart
        .map((item) =>
          item.id === productId && item.qty > 1
            ? {
              ...item,
              qty: item.qty - 1,
              sum: item.price * (item.qty - 1),
            }
            : item
        )
        .filter((item) => item.qty > 0);

      return { ...state, cart: updatedCart };
    }

    case REMOVE_FROM_CART:
      return {
        ...state,
        cart: state.cart.filter((item) => item.id !== action.product_id.id),
      };

    default:
      return state;
  }
};

export default cartReducer;
