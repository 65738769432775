import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { db } from "../data/base";
import { collection, doc, getDoc, updateDoc, setDoc } from "firebase/firestore";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import ModelContainerFrontEnd from "../components/ModelViewer/ModelContainerFrontEnd";
import ProjectModelViewer from "../components/ModelViewer/ProjectModelViewer";
import Password from "../components/ModelViewer/Password";
import { modelGlassesActions } from "../redux/brandViewer/modelGlasses";
import { brandViewerActions } from "../redux/brandViewer/brandViewer";


function ARShades3dViewer() {
    function formatSessionDate(date) {
        const padWithZero = (num) => num.toString().padStart(2, '0');
        return `${date.getFullYear()}/${padWithZero(date.getMonth() + 1)}/${padWithZero(date.getDate())} ${padWithZero(date.getHours())}:${padWithZero(date.getMinutes())}:${padWithZero(date.getSeconds())}`;
    }

    const params = useParams();
    const dispatch = useDispatch();

    // Stati di base
    const [listVariant, setListVariant] = useState([]);
    const [modelHistory, setModelHistory] = useState([]);
    const [initialTime, setInitialTime] = useState();
    const [refBrand, setRefBrand] = useState();
    const [idViewer, setIdViewer] = useState();

    const [glassesRef, setGlassesRef] = useState(null);
    const [lineRefs, setLineRefs] = useState(null);
    const [glassesSize, setGlassesSize] = useState(null);

    const [initialModelTime, setInitiaModellTime] = useState(new Date());
    const [modelAnimations, setModelAnimations] = useState([]);
    const [isWebView, setIsWebView] = useState(false);

    // Stato per gestire gli errori
    const [error, setError] = useState(null);

    const brand = params.TokenBrand;
    const glasses = params.TokenGlasses;
    const variant = params.TokenVariant;

    const [lock, setLock] = useState(false);
    const project = useSelector((state) => state.brandViewer);
    const modelInView = useSelector((state) =>
        state.modelGlasses.find((model) => model.id === variant)
    );

    const [session, setSession] = useState({
        device_id: "",
        device: "",
        device_os: "",
        is_first_session: false,
        ref_catalogo: "",
        data_inizio_sessione: "",
        data_fine_user_session: "",
        glassesRefs: [],
        lista_occhiali_visualizzati: [],
        modelRefs: [],
        lineaRefs: [],
        glassesRefsDoc: null,
        total_time: 0,
        glassesSize: null,
    });

    // Stati aggiuntivi
    const [initialModel, setInitialModel] = useState();
    const [src, setSrc] = useState("");
    const [pwd, setPwd] = useState("");
    const [listCreated, setListCreated] = useState(false);

    // Stati per la sessione in Firestore
    const [sessionRef, setSessionRef] = useState(null);
    const [totalSessionSeconds, setTotalSessionSeconds] = useState(0);
    const [isSessionExpired, setIsSessionExpired] = useState(false);

    // FUNZIONE DI UNLOCK
    function unlock() {
        setLock(false);
    }

    // Primo useEffect: inizializza il visualizzatore e il modello
    useEffect(() => {
        async function fetchInitialData() {
            try {
                const visualizzatoreRef = doc(db, "Visualizzatori3D", brand);
                const visualizzatoreSnap = await getDoc(visualizzatoreRef);

                if (!visualizzatoreSnap.exists() || !visualizzatoreSnap.data()) {
                    setError("Visualizzatore 3D non trovato o dati mancanti");
                    console.error("Visualizzatore 3D non trovato o dati mancanti");
                    return;
                }

                const data = visualizzatoreSnap.data();
                setIdViewer(data.id);
                dispatch(brandViewerActions.setSelectedProject(data));
                setInitialModel(params.TokenVariant);

                try {
                    const modelRef = await getDoc(doc(db, "Modello", params.TokenVariant));
                    if (modelRef && modelRef.exists() && modelRef.data()) {
                        const animations = modelRef?.data()?.modelAnimation || [];
                        setModelAnimations(animations);
                    } else {
                        console.warn("Modello non trovato o dati mancanti per le animazioni");
                    }
                } catch (modelError) {
                    console.error("Errore nel caricamento delle animazioni del modello:", modelError);
                }

                setInitialTime(new Date());
            } catch (error) {
                console.error("Errore durante l'inizializzazione del visualizzatore:", error);
                setError("Si è verificato un errore durante il caricamento del visualizzatore");
            }
        }
        fetchInitialData();
    }, [brand, dispatch, params.TokenVariant]);

    // Secondo useEffect: carica i dati del modello e imposta altre variabili
    useEffect(() => {
        async function fetchModelData() {
            try {
                const modelRef = await getDoc(doc(db, "Modello", variant));
                const data = modelRef.data();
                dispatch(modelGlassesActions.setModel({ data, id: variant }));
                setSrc(data.urlGlbComplete);

                // Ottiene deviceID (fallback a uuid in caso di errore)
                let deviceID = uuidv4();
                try {
                    const res = await axios.get("https://api.ipify.org/?format=json");
                    deviceID = res.data.ip;
                } catch (error) {
                    console.error("Failed to fetch IP address:", error);
                }
                session.device_id = deviceID;

                let sameMonthYear = false;
                const padWithZero = (number) => number.toString().padStart(2, "0");
                const date = new Date();
                const year = date.getFullYear();
                const month = padWithZero(date.getMonth() + 1);
                const monthDataCons = date.getMonth() + 1;
                const day = padWithZero(date.getDate());
                const hours = padWithZero(date.getHours());
                const minutes = padWithZero(date.getMinutes());
                const seconds = padWithZero(date.getSeconds());

                const docRef = await getDoc(doc(db, "Visualizzatori3D", brand));
                const docBrand = docRef.data().brand;
                const brandRefDoc = doc(db, "Brand", docBrand);
                const brandRefSnap = await getDoc(brandRefDoc);
                const dataConsumptionDoc = brandRefSnap.data().data_consumption;
                const contatoriRef = await getDoc(doc(db, "Contatori_Brand", dataConsumptionDoc));
                const dataConsumption = contatoriRef.data();
                const glassesRefDoc = doc(db, "Occhiale", glasses);
                const glassesDoc = await getDoc(glassesRefDoc);

                // Verifica che glassesDoc e glassesDoc.data() esistano prima di accedere a lista_taglie
                let sizeRefDoc = null;
                if (glassesDoc.exists() && glassesDoc.data() && glassesDoc.data().lista_taglie && glassesDoc.data().lista_taglie.length > 0) {
                    sizeRefDoc = glassesDoc.data().lista_taglie[0];
                }

                setGlassesRef(glassesRefDoc);

                // Verifica che glassesDoc e glassesDoc.data() esistano prima di accedere a lineaRef
                if (glassesDoc.exists() && glassesDoc.data() && glassesDoc.data().lineaRef) {
                    setLineRefs(glassesDoc.data().lineaRef);
                }

                setGlassesSize(sizeRefDoc);
                setRefBrand(brandRefDoc);

                const userAgent = navigator.userAgent;
                let browserName;
                const isWV = /\bwv\b/.test(userAgent);
                setIsWebView(true);

                if (userAgent.indexOf("Firefox") > -1) {
                    browserName = "Mozilla Firefox";
                } else if (userAgent.indexOf("Chrome") > -1) {
                    browserName = "Google Chrome";
                } else if (userAgent.indexOf("Safari") > -1) {
                    browserName = "Apple Safari";
                } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
                    browserName = "Opera";
                } else if (userAgent.indexOf("Trident") > -1) {
                    browserName = "Microsoft Internet Explorer";
                } else if (userAgent.indexOf("Edge") > -1) {
                    browserName = "Microsoft Edge";
                } else {
                    browserName = "Unknown";
                }
                session.device_os = browserName;

                // Verifica se l'utente ha già visitato il sito
                const isFirstSession = !Cookies.get("visited");
                if (!Cookies.get("visited")) {
                    Cookies.set("visited", "true");
                }
                session.glassesSize = sizeRefDoc ? sizeRefDoc : "";
                session.is_first_session = isFirstSession;
                session.glassesRefsDoc = glassesDoc.exists() ? glassesDoc.id : "";

                // Verifica che i dati necessari esistano prima di aggiungerli alla sessione
                if (glassesDoc.exists() && glassesDoc.data() && glassesDoc.data().lineaRef) {
                    session.lineaRefs.push(glassesDoc.data().lineaRef);
                }

                if (glassesDoc.exists()) {
                    session.glassesRefs.push(glassesDoc.id);
                }

                session.ref_catalogo = brandRefDoc;
                session.data_inizio_sessione = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;

                if (dataConsumption && dataConsumption.bandwidth_3dviewer) {
                    dataConsumption.bandwidth_3dviewer.map((bandw) => {
                        if (bandw.month === monthDataCons && bandw.year === year) {
                            sameMonthYear = true;
                        }
                    });
                    if (!sameMonthYear) {
                        const contatori = doc(db, "Contatori_Brand", dataConsumptionDoc);
                        await updateDoc(contatori, {
                            bandwidth_3dviewer: [
                                ...dataConsumption.bandwidth_3dviewer,
                                { bandwidth_month: 0, calls_number: 0, month: monthDataCons, year },
                            ],
                        });
                    }
                }
            } catch (error) {
                console.error("Errore durante il caricamento dei dati del modello:", error);
                // Qui puoi anche settare uno stato per mostrare un messaggio di errore all'utente
            }
        }
        fetchModelData();
    }, [variant, brand, glasses, dispatch, session]);

    // Terzo useEffect: verifica lo stato di lock del visualizzatore
    useEffect(() => {
        async function fetchLock() {
            try {
                const docRef = await getDoc(doc(db, "Visualizzatori3D", brand));
                if (!docRef.exists() || !docRef.data()) {
                    console.error("Documento Visualizzatori3D non trovato o dati mancanti");
                    return;
                }

                const data = docRef.data();
                setLock(data.lock || false);
                if (data.lock) {
                    setPwd(data.password || "");
                }
            } catch (error) {
                console.error("Errore durante il controllo del lock:", error);
                // Imposta un valore di default in caso di errore
                setLock(false);
            }
        }
        fetchLock();
    }, [brand]);

    // Funzioni di supporto per mese e anno correnti
    function getMeseCorrente() {
        return new Date().getMonth() + 1;
    }
    function getCurrentYear() {
        return new Date().getFullYear();
    }

    // Quarto useEffect: aggiornamento dei modelli e dei contatori
    useEffect(() => {
        async function updateModel() {
            if (variant && !lock) {
                setTimeout(async () => {
                    try {
                        if (!modelHistory.includes(idViewer?.src)) {
                            const variantS = await getDoc(doc(db, "Modello", variant));
                            if (!variantS.exists() || !variantS.data() || !variantS.data().urlGlbComplete) {
                                console.error("Dati mancanti per il modello");
                                return;
                            }

                            const srcData = variantS.data().urlGlbComplete;
                            const fileImg = await fetch(srcData).then((r) => r.blob());
                            const sizeMb = fileImg.size / 1000000;
                            const b = sizeMb.toFixed(2);
                            const banda = parseFloat(b);

                            const docRef = await getDoc(doc(db, "Visualizzatori3D", brand));
                            const docBrand = docRef.data().brand;
                            const brandRefSnap = await getDoc(doc(db, "Brand", docBrand));
                            const dataDoc = brandRefSnap.data()?.data_consumption;
                            const contatoriRef = await getDoc(doc(db, "Contatori_Brand", dataDoc));
                            const dataContatori = contatoriRef.data();
                            const meseCorrente = getMeseCorrente();
                            const year = getCurrentYear();

                            if (dataContatori && dataContatori.bandwidth_3dviewer) {
                                dataContatori.bandwidth_3dviewer.map((ele, i) => {
                                    if (meseCorrente === ele.month && year === ele.year) {
                                        const { bandwidth_month, calls_number, month, year } = ele;
                                        dataContatori.bandwidth_3dviewer[i] = {
                                            bandwidth_month: bandwidth_month + banda,
                                            calls_number: calls_number + 1,
                                            month,
                                            year,
                                        };
                                    }
                                });
                                const contatori = doc(db, "Contatori_Brand", dataDoc);
                                await updateDoc(contatori, {
                                    bandwidth_3dviewer: dataContatori.bandwidth_3dviewer,
                                });
                            }
                        }

                        const modelRefId = doc(db, "Modello", variant);
                        const modelRefSnap = await getDoc(modelRefId);

                        if (!modelRefSnap.exists() || !modelRefSnap.data()) {
                            console.error("Modello non trovato o dati mancanti");
                            return;
                        }

                        const modelData = modelRefSnap.data();

                        if (!listCreated) {
                            const date = new Date();
                            const timeDiff = Math.floor((date.getTime() - initialModelTime.getTime()) / 1000);
                            setInitiaModellTime(new Date());
                            const url = modelData.urlGlbComplete;
                            if (!url) {
                                console.error("URL mancante per il modello");
                                return;
                            }

                            const blob = await fetch(url).then((r) => r.blob());
                            const b = blob.size / 1000000;
                            const b1 = b.toFixed(2);
                            const banda = parseFloat(b1) + 0.5;
                            session.modelRefs = listVariant ? listVariant : "";
                            session.device = window.navigator.userAgent;
                            session.lista_occhiali_visualizzati.push({
                                nome_occhiale: modelData?.nomeOcchiale ? modelData?.nomeOcchiale : "",
                                lineaRef: session?.lineaRefs[0] ? session?.lineaRefs[0] : "",
                                glassesRef: session?.glassesRefsDoc ? session?.glassesRefsDoc : "",
                                listaModelliProvati: [
                                    {
                                        modelRef: modelRefId,
                                        timeStart: initialModelTime,
                                        totalSeconds: timeDiff,
                                        tagliaRef: session?.glassesSize ? session?.glassesSize : "",
                                    },
                                ],
                                timeStart: initialModelTime,
                                banda_consumata: banda,
                                date: initialModelTime,
                                totalSeconds: timeDiff,
                            });
                            setListCreated(true);
                        } else {
                            const date = new Date();
                            const timeDiff = Math.floor((date.getTime() - initialModelTime.getTime()) / 1000);
                            setInitiaModellTime(new Date());

                            // Verifica che session.lista_occhiali_visualizzati[0] esista prima di accedervi
                            if (!session.lista_occhiali_visualizzati || !session.lista_occhiali_visualizzati[0]) {
                                console.error("Dati sessione mancanti");
                                return;
                            }

                            const listModelTry = session.lista_occhiali_visualizzati[0].listaModelliProvati;
                            if (!listModelTry) {
                                console.error("listaModelliProvati non trovata");
                                return;
                            }

                            let checkModelExist = false;
                            let totalTime = session.total_time;

                            listModelTry.map((model) => {
                                if (model && model.modelRef && model.modelRef.id === modelRefId.id) {
                                    checkModelExist = true;
                                    model = { ...model, totalSeconds: model.totalSeconds + timeDiff };
                                    totalTime = totalTime + model.totalSeconds;
                                }
                            });

                            if (!checkModelExist) {
                                listModelTry.push({
                                    modelRef: modelRefId,
                                    timeStart: initialTime,
                                    totalSeconds: timeDiff,
                                    tagliaRef: session.glassesSize,
                                });
                                totalTime = totalTime + timeDiff;
                            }

                            const url = modelData.urlGlbComplete;
                            if (!url) {
                                console.error("URL mancante per il modello");
                                return;
                            }

                            const blob = await fetch(url).then((r) => r.blob());
                            const b = blob.size / 1000000;
                            const b1 = b.toFixed(2);
                            session.total_time = session.lista_occhiali_visualizzati[0].totalSeconds;
                            const banda =
                                session.lista_occhiali_visualizzati[0].banda_consumata +
                                parseFloat(b1);
                            session.lista_occhiali_visualizzati[0] = {
                                ...session.lista_occhiali_visualizzati[0],
                                nome_occhiale: modelData.nomeOcchiale,
                                lineaRef: session.lineaRefs[0],
                                glassesRef: glassesRef,
                                listaModelliProvati: [
                                    ...session.lista_occhiali_visualizzati[0].listaModelliProvati,
                                ],
                                timeStart: date,
                                banda_consumata: banda,
                                totalSeconds: 0,
                            };
                        }
                    } catch (error) {
                        console.error("Errore durante l'aggiornamento del modello:", error);
                    }
                }, 1000);
            }
        }
        updateModel();
    }, [variant, lock]);

    // Gestione dell'evento "beforeunload" per aggiornare la sessione
    useEffect(() => {
        function handleUnload() {
            if (!sessionRef) return;

            // Ottieni la data e l'ora attuale
            const date = new Date();
            const timeDiff = Math.floor((date.getTime() - initialModelTime.getTime()) / 1000);

            // Crea una nuova versione aggiornata della sessione
            const updatedSession = {
                ...session,
                total_time: timeDiff,
                data_fine_user_session: formatSessionDate(date),
                lista_occhiali_visualizzati: session.lista_occhiali_visualizzati.map((item) => ({
                    ...item,
                    totalSeconds: timeDiff,
                    listaModelliProvati: item.listaModelliProvati.map((model) => ({
                        ...model,
                        totalSeconds: timeDiff,
                    })),
                })),
            };

            // Aggiorna Firestore con la sessione aggiornata
            updateDoc(sessionRef, updatedSession)
                .then(() => setTotalSessionSeconds(timeDiff))
                .catch((error) => {
                    console.error("Errore nell'aggiornamento della sessione:", error);
                });
        }

        window.addEventListener("beforeunload", handleUnload);
        if (isWebView) return;
        return () => {
            window.removeEventListener("beforeunload", handleUnload);
        };
    }, [session, sessionRef, initialModelTime]);

    // /// SESSION ///
    // Timeout di 4 minuti per la sessione
    useEffect(() => {
        const sessionTimeout = setTimeout(() => {
            setIsSessionExpired(true);
        }, 240000);
        return () => clearTimeout(sessionTimeout);
    }, []);

    // Aggiunge la sessione al database
    useEffect(() => {
        async function addSession() {
            // Controlla se la sessione è già stata creata (persistente)
            try {
                const sessioneRef = doc(collection(db, "Sessione_Visualizzatori3d"));
                await setDoc(sessioneRef, session);
                setSessionRef(sessioneRef);
            } catch (error) {
                console.error("Errore durante la creazione della sessione:", error);
                setError("Si è verificato un errore durante la creazione della sessione");
            }
        }
        addSession();
    }, []);

    // Aggiorna periodicamente la sessione ogni 10 secondi
    useEffect(() => {
        if (sessionRef && !isSessionExpired) {
            const interval = setInterval(() => {
                const date = new Date();
                const timeDiff = Math.floor((date.getTime() - initialModelTime.getTime()) / 1000);

                session.lista_occhiali_visualizzati.forEach((item) => {
                    Object.keys(item.listaModelliProvati).forEach((modelKey) => {
                        const model = item.listaModelliProvati[modelKey];
                        model.totalSeconds = timeDiff;
                    });
                    item.totalSeconds = timeDiff;
                });

                session.total_time = timeDiff;
                session.data_fine_user_session = formatSessionDate(date);

                updateDoc(sessionRef, session).catch((error) => {
                    console.error("Error updating document: ", error);
                });

                setTotalSessionSeconds(timeDiff);
            }, 10000);
            return () => clearInterval(interval);
        }
    }, [sessionRef, initialModelTime, isSessionExpired, session]);


    return (
        <div>
            {error && (
                <div style={{
                    backgroundColor: "#f8d7da",
                    color: "#721c24",
                    padding: "10px",
                    borderRadius: "5px",
                    margin: "10px 0",
                    textAlign: "center"
                }}>
                    {error}
                </div>
            )}
            {lock ? (
                <Password unlock={unlock} password={pwd} />
            ) : (
                <ModelContainerFrontEnd modelSelected={modelInView} project={project[0]} singleView>
                    {!navigator.userAgent.match(/FBAV/i) && (
                        <ProjectModelViewer
                            singleView
                            singleSrc={src}
                            project={project[0]}
                            viewerPage
                            paramsId={params.token}
                            modelAnimations={modelAnimations}
                            urlView={`https://studio.arshades.it/Arshades3d/${project[0]?.id}/glasses/${params.TokenGlasses}/variant/${params.TokenVariant}`}
                        />
                    )}
                    {navigator.userAgent.match(/FBAV/i) &&
                        !navigator.userAgent.match(/(iPod|iPhone|iPad)/) && (
                            <div
                                id={
                                    project?.menu_position === "left" || project?.menu_position === "right"
                                        ? "projectTwo"
                                        : "project"
                                }
                            >
                                <img
                                    src={modelInView?.not_supported_image}
                                    style={{ width: "100%" }}
                                    alt="Not supported"
                                />
                                il broswer di facebook non supporta modelli 3D e realtà aumentata. prova con un altro broswer
                            </div>
                        )}
                </ModelContainerFrontEnd>
            )}
        </div>
    );
}

export default ARShades3dViewer;