import React from "react";
import StatCard from "./StatCard";
import { NumberAnimated } from "../../services/home";

const HomeCard = ({
    leftTitle,
    leftSubtitle,
    rightTitle,
    rightSubtitle,
    onClickFunction,
    tooltipTitle,
}) => {
    // Se leftTitle è null, undefined o NaN, usa rightTitle; altrimenti usa leftTitle. Se entrambi non sono validi, restituisci 0.
    const leftVal =
        leftTitle === null || leftTitle === undefined || isNaN(Number(leftTitle))
            ? null
            : Number(leftTitle);
    const rightVal =
        rightTitle === null || rightTitle === undefined || isNaN(Number(rightTitle))
            ? null
            : Number(rightTitle);
    const value = leftVal !== null ? leftVal : rightVal !== null ? rightVal : 0;

    return (
        <StatCard
            title={leftSubtitle || rightSubtitle}
            value={<NumberAnimated n={value} />}
            onClick={onClickFunction}
            tooltipTitle={tooltipTitle}
            showIcon={false}
        />
    );
};

export default HomeCard;