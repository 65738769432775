// Colori principali del brand
export const brandColors = {
    primary: '#42B1AC',
    secondary: '#CCC492',
    complementary: '#CC92A4',
    black: '#000000',
    white: '#FFFFFF',
    gray: '#d9d9d9',
    lightGray: '#f0f0f0',
    accent: '#CC9292'
};