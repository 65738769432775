import {
  SET_ANALYTICS_FILTER_OPTIONS,
  SET_LINE_GLASS_MODEL,
  VAR_ARRAY,
  GLASS_ARRAY
} from '../actionType';

export const setAnalyticsFilterOptions = (options) => (dispatch) => {
  dispatch({
    type: SET_ANALYTICS_FILTER_OPTIONS,
    options,
  });
};

export const setLineGlassModel = (data) => (dispatch) => {
  const serializedData = {
    lineList: data.lineList.map((line) => ({
      ...line,
      brand: line.brand?.id || null,
      listaRefsOcchiale: line.listaRefsOcchiale?.map((ref) => ref.id) || [],
    })),
    glassList: data.glassList.map((glass) => ({
      ...glass,
      brand: glass.brand?.id || null,
      lineaRef: glass.lineaRef?.id || null,
      lista_categorie: glass.lista_categorie?.map((ref) => ref.id) || [],
      lista_taglie: glass.lista_taglie?.map((ref) => ref.id) || [],
      logLoadingModel: glass.logLoadingModel?.id || null,
    })),
    modelList: data.modelList.map((model) => ({
      ...model,
      brand: model.brand?.id || null,
    })),
  };

  dispatch({
    type: SET_LINE_GLASS_MODEL,
    payload: serializedData,
  });
};

export const setGlassArr = (data) => (dispatch) => {
  dispatch({
    type: GLASS_ARRAY,
    payload: data,
  });
};

export const setVarArr = (data) => (dispatch) => {
  dispatch({
    type: VAR_ARRAY,
    payload: data,
  });
};