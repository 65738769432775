import React, { useEffect, useState } from "react";
import {
    Layout,
    Row,
    Col,
    message,
    Button,
    Spin,
    Modal,
    Typography,
    Checkbox,
    Form,
    Divider,
    DatePicker
} from "antd";
import { DownloadOutlined } from '@ant-design/icons';
import { doc, getDoc } from "firebase/firestore";
import AnalyticsOverviewCard from "../components/Analytics/AnalyticsOverviewCard";
import AnalyticsTable from "../components/Analytics/AnalyticsTable";
import DeviceAnalytics from "../components/Analytics/DeviceAnalytics";
import Filters from "../components/Analytics/Filters";
import FiltersTop from "../components/Analytics/FiltersTop";
import { db } from "../data/base";
import { getAggregatedData } from "../services/api/analyticsApi";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { setAnalyticsFilterOptions } from "../redux/analytics/actions";
import { getMinSecObject } from "../services/utils/analyticsUtils";
import { enrichDataWithSubcollections } from "../services/data/analyticsData";
import { generateReport } from "../services/api/analyticsApi";
import AnalyticsHeader from "../components/Analytics/AnalyticsHeader";
import { setSelectedCatalog } from "../redux/catalog/action";
import { useMediaQuery } from "react-responsive";
import FiltersMobile from "../components/Analytics/FiltersMobile";

const endDate = dayjs().endOf("day");
const startDate = dayjs().subtract(7, "day").startOf("day");

const defaultDateRange = [startDate, endDate];

const { Title, Text } = Typography;

const Analytics = () => {

    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

    const [messageApi, contextHolder] = message.useMessage();

    const [loading, setLoading] = useState(false);
    const [loadingSelect, setLoadingSelect] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const dispatch = useDispatch();
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [tableOverview, setTableOverview] = useState({});
    const [reportData, setReportData] = useState({});
    const [filteredSessionList, setFilteredSessionList] = useState([]);
    const [dateRange, setDateRange] = useState(defaultDateRange);
    const [typeData, setTypeData] = useState("all");
    const [aggregateList, setAggregateList] = useState([]);
    const [filteredAggList, setFilteredAggList] = useState([]);
    const [nomeModello, setNomeModello] = useState();
    const [overviewData, setOverviewData] = useState();
    const [selectedValue, setSelectedValue] = useState(null);

    const selectedCatalog = useSelector((state) => state.catalog.selectedCatalog);
    const filterOptions = useSelector((state) => state.analytics.filterOptions);
    const { line, glass, variant } = filterOptions;
    const lineList = useSelector((state) => state.analytics.lineList);
    const glassList = useSelector((state) => state.analytics.glassList);
    const catalogsList = useSelector((state) => state.config.listaBrand);

    function getModelNameTable(model) {
        setNomeModello(model?.nome_modello);
    }

    async function getDataAnalyticsOverview(data) {
        try {
            const {
                totalSession,
                totalUser,
                totalNewUser,
                totalAvgTime,
                totalAvgEndTime,
                totalAvgSpU,
                ..._data
            } = data;

            const startDate = dateRange[0].format("YYYY/MM/DD HH:mm:ss");
            const endDate = dateRange[1].format("YYYY/MM/DD HH:mm:ss");

            setReportData((prevReportData) => ({
                ...prevReportData,
                catalog: selectedCatalog.nome_brand,
                typeData,
                startDate,
                endDate,
                totalSession,
                totalUser,
                totalNewUser,
                totalAvgEndTime,
                totalAvgSpU,
            }));

            setOverviewData(_data);
        } catch (error) {
            console.error("Errore durante l'aggiornamento dei dati:", error);
        }
    }

    const catalogCache = new Map();

    const getAllCatalogIds = async () => {
        if (catalogCache.has("catalogIds")) {
            return catalogCache.get("catalogIds");
        }

        try {
            if (!catalogsList || catalogsList.length === 0) {
                console.warn("No catalogs found in Redux state");
                return [];
            }

            const batchSize = 10;
            const catalogPromises = [];

            for (let i = 0; i < catalogsList.length; i += batchSize) {
                const batch = catalogsList.slice(i, i + batchSize).map((id) => getDoc(doc(db, "Brand", id)));
                catalogPromises.push(batch);
            }

            let filteredCatalogs = [];
            for (const batch of catalogPromises) {
                const results = await Promise.allSettled(batch);
                const batchResults = results
                    .filter((res) => res.status === "fulfilled" && res.value.exists())
                    .map((res) => res.value.id);

                filteredCatalogs = [...filteredCatalogs, ...batchResults];

                await new Promise((resolve) => setTimeout(resolve, 200));
            }

            // Salva in cache
            catalogCache.set("catalogIds", filteredCatalogs);
            return filteredCatalogs;
        } catch (error) {
            console.error("Error fetching catalog IDs:", error);
            return [];
        }
    };

    useEffect(() => {
        let selectedLine = "";
        let selectedModel = "";
        if (line) {
            selectedLine = lineList?.find((linea) => linea.id === line);
        }
        if (glass) {
            selectedModel = glassList?.find((modello) => modello.id === glass);
        }
        setReportData({
            line: selectedLine?.nome_linea ?? "All",
            model: selectedModel?.nome_modello ?? "All",
        });
    }, [line, glass, filterOptions]);

    const getServiceType = (service) => {
        switch (service) {
            case "app":
                return "APP";
            case "3d":
                return "3D VIEWER";
            case "WebVto":
                return "WEB VTO";
            case "all":
                return "ALL"
            default:
                return "";
        }
    }

    useEffect(() => {
        // Filtra i dati basandosi sulle opzioni del filtro
        let filteredAggs =
            aggregateList?.filter((session) => {
                // Filtro per tipo di servizio
                if (typeData !== "all" && session.service !== getServiceType(typeData)) {
                    return false;
                }

                let result = true;

                // Filtro per linea
                if (
                    !!line &&
                    !session.lineArray.some((value) => {
                        // Aggiorna la sessione con i dati della linea selezionata
                        if (value.lineRef === line) {
                            session.avgEngagementTime = getMinSecObject(value.averageEngagementTime);
                            session.avgSessionTime = getMinSecObject(value.averageSessionTime);
                            session.averageSessionsPerUser = value.averageSessionsPerUser;
                            session.newUsersCount = value.newUsersCount;
                            session.totalSessions = value.numberOfSessions;
                            session.totalUniqueUsers = value.numberOfUniqueUsers;
                        }
                        return value.lineRef === line;
                    })
                ) {
                    result = false;
                }

                // Filtro per occhiali
                const gData = session.glassesArray;
                if (
                    !!glass &&
                    gData &&
                    !gData.some((value) => {
                        // Aggiorna la sessione con i dati degli occhiali selezionati
                        if (value.glassesRef === glass) {
                            session.avgEngagementTime = getMinSecObject(value.averageEngagementTime);
                            session.avgSessionTime = getMinSecObject(value.averageSessionTime);
                            session.averageSessionsPerUser = value.averageSessionsPerUser;
                            session.newUsersCount = value.newUsersCount;
                            session.totalSessions = value.numberOfSessions;
                            session.totalUniqueUsers = value.numberOfUniqueUsers;
                        }
                        return value.glassesRef === glass;
                    })
                ) {
                    result = false;
                }

                // Filtro per varianti
                const vData = session.variantsArray;
                if (
                    !!variant &&
                    vData &&
                    !vData.some((value) => {
                        // Aggiorna la sessione con i dati della variante selezionata
                        if (value.modelRef === variant) {
                            session.avgEngagementTime = getMinSecObject(value.averageEngagementTime);
                            session.avgSessionTime = getMinSecObject(value.averageSessionTime);
                            session.averageSessionsPerUser = value.averageSessionsPerUser;
                            session.newUsersCount = value.newUsersCount;
                            session.totalSessions = value.numberOfSessions;
                            session.totalUniqueUsers = value.numberOfUniqueUsers;
                        }
                        return value.modelRef === variant;
                    })
                ) {
                    result = false;
                }

                return result;
            }) ?? [];

        // Aggiorna le sessioni filtrate con i riferimenti selezionati
        filteredAggs =
            filteredAggs.map((session) => {
                const updatedSession = { ...session };

                if (!!line) {
                    updatedSession.lineaRefs = [line];
                }
                if (!!glass) {
                    updatedSession.glassesRefs = [glass];
                }
                if (!!variant) {
                    updatedSession.modelRef = [variant];
                }

                return updatedSession;
            }) ?? [];

        // Imposta la lista delle sessioni filtrate
        setFilteredAggList(filteredAggs);
    }, [glass, line, variant, typeData, aggregateList]);

    const aggregateCache = new Map();

    const fetchAggregate = async () => {
        try {
            setLoading(true);

            const cacheKey = `${selectedCatalog.id}-${dateRange[0].format("YYYY-MM-DD")}-${dateRange[1].format("YYYY-MM-DD")}`;
            if (aggregateCache.has(cacheKey)) {
                setAggregateList(aggregateCache.get(cacheKey));
                setLoading(false);
                return;
            }

            let allCatalogIds;
            if (selectedCatalog.id?.toLowerCase() === "all") {
                allCatalogIds = await getAllCatalogIds();
            } else {
                allCatalogIds = [selectedCatalog.id];
            }

            if (allCatalogIds.length === 0) {
                console.warn("No valid catalog IDs found.");
                setLoading(false);
                return;
            }

            // Formatta le date
            const startDate = dateRange[0].format("YYYY/MM/DD");
            const endDate = dateRange[1].format("YYYY/MM/DD");

            const batchSize = 5;
            const batchRequests = [];
            for (let i = 0; i < allCatalogIds.length; i += batchSize) {
                batchRequests.push(getAggregatedData(allCatalogIds.slice(i, i + batchSize), startDate, endDate));
            }

            let aggregateData = [];
            for (const batch of batchRequests) {
                const batchData = await batch;
                aggregateData = [...aggregateData, ...batchData];

                await new Promise((resolve) => setTimeout(resolve, 300));
            }

            if (!aggregateData || aggregateData.length === 0) {
                console.warn("No aggregate data found.");
                setAggregateList([]);
                setLoading(false);
                return;
            }

            aggregateCache.set(cacheKey, aggregateData);
            setAggregateList(aggregateData);

            const enrichedData = await enrichDataWithSubcollections(aggregateData);

            aggregateCache.set(cacheKey, enrichedData);
            setAggregateList(enrichedData);
        } catch (error) {
            messageApi.error("Failed to fetch aggregate data. Please try again.");
            console.error("Error in fetchAggregate:", error);
        } finally {
            setLoading(false);
        }
    };

    // Effettua la richiesta quando cambiano i filtri o il catalogo selezionato
    useEffect(() => {
        fetchAggregate();
    }, [selectedCatalog?.id, dateRange, refresh]);

    const handleServiceChanged = (e) => {
        const value = e;
        setTypeData(value);
    }

    const onChangeDate = (dates) => {
        if (dates) {
            setDateRange([dates[0], dates[1]]);
        } else {
            setDateRange(defaultDateRange);
        }
    };

    const handleClearFilter = () => {
        dispatch(
            setAnalyticsFilterOptions({
                line: "",
                glass: "",
                variant: "",
            })
        );
        setDateRange(defaultDateRange);
        setTypeData("all");
        setSelectedValue(null);
        dispatch(setSelectedCatalog({ id: "all", nome_catalog: "All Catalogues" }));

        setRefresh((r) => !r)
    };

    // REPORT
    const [showModal, setShowModal] = useState(false);
    const [catalogProfileData, setCatalogProfileData] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);
    const [allCatalogsSelected, setAllCatalogsSelected] = useState(false);
    const [selectedCatalogs, setSelectedCatalogs] = useState([]);
    const [startDateReport, setStartDateReport] = useState('');
    const [endDateReport, setEndDateReport] = useState('');
    const [error, setError] = useState('');

    const getAllCatalogIdsData = async () => {
        let catalogsListArr = [];

        const catalogs = catalogsList;

        if (!Array.isArray(catalogs) || catalogs.length === 0) {
            console.error("No catalogs found in the provided list");
            return [];
        }

        try {
            const catalogPromises = catalogs.map(async (item) => {
                const catalogRef = doc(db, "Brand", item);
                const catalogSnap = await getDoc(catalogRef);
                if (catalogSnap.exists()) {
                    return { id: catalogSnap.id, ...catalogSnap.data() };
                } else {
                    console.warn(`Brand with ID ${item} does not exist.`);
                    return null;
                }
            });

            catalogsListArr = (await Promise.all(catalogPromises)).filter((catalog) => catalog !== null);

            setCatalogProfileData(catalogsListArr);
        } catch (error) {
            console.error("Error fetching catalog data", error);
            return [];
        }
    };

    const handleOpenDownloadReportModal = async () => {
        getAllCatalogIdsData();
        setShowModal(true);
    };

    const handleCloseModalProva = () => {
        setShowModal(false);
    };

    const handleOptionChange = (checkedValues) => {
        if (checkedValues.includes('all')) {
            setSelectedOption(['all', '3D VIEWER', 'WEB VTO', 'APP']);
        } else if (selectedOption.includes('all')) {
            setSelectedOption([]);
        } else {
            setSelectedOption(checkedValues);
        }
    };

    const handleSelectAllChange = (e) => {
        const isChecked = e.target.checked;
        setAllCatalogsSelected(isChecked);
        if (isChecked) {
            const allCatalogs = catalogProfileData.map(catalog => catalog.id);
            setSelectedCatalogs(allCatalogs);
        } else {
            setSelectedCatalogs([]);
        }
    };

    const handleCatalogChange = (checkedValues) => {
        setSelectedCatalogs(checkedValues);
    };

    const handleStartDateChange = (date) => {
        if (!date) {
            setStartDateReport(null);
            setError('');
            return;
        }

        const value = date.format("YYYY-MM-DD");

        if (endDateReport && dayjs(value).isAfter(dayjs(endDateReport))) {
            setError('Start date cannot be later than end date.');
        } else {
            setStartDateReport(value);
            setError('');
        }
    };

    const handleEndDateChange = (date) => {
        if (!date) {
            setEndDateReport(null);
            setError('');
            return;
        }

        const value = date.format("YYYY-MM-DD");

        if (startDateReport && dayjs(value).isBefore(dayjs(startDateReport))) {
            setError('End date cannot be earlier than start date.');
        } else {
            setEndDateReport(value);
            setError('');
        }
    };

    const isFormValid = () => {
        return startDateReport && endDateReport && !error;
    };

    const handleDownload = async () => {
        setDownloadLoading(true);
        try {
            await generateReport(startDateReport, endDateReport, selectedCatalogs, selectedOption);
        } catch (error) {
            console.error("Errore durante il download del report:", error);
            message.error("Error during download report.");
        } finally {
            setDownloadLoading(false);
        }
    };

    return (
        <Layout>
            <AnalyticsHeader />
            <Layout.Content id="analytics" style={{ backgroundColor: "white" }}>
                {contextHolder}

                {isTabletOrMobile ? (
                    <FiltersMobile
                        setTypeData={setTypeData}
                        dateRange={dateRange}
                        onChangeService={handleServiceChanged}
                        onChangeDate={onChangeDate}
                        onClearFilter={handleClearFilter}
                        handleOpenDownloadReportModal={handleOpenDownloadReportModal}
                        downloadLoading={downloadLoading}
                        typeData={typeData}
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                    />
                ) : (
                    <>
                        <FiltersTop
                            setTypeData={setTypeData}
                            dateRange={dateRange}
                            onChangeService={handleServiceChanged}
                            onChangeDate={onChangeDate}
                            onClearFilter={handleClearFilter}
                            handleOpenDownloadReportModal={handleOpenDownloadReportModal}
                            downloadLoading={downloadLoading}
                        />
                        <Filters
                            typeData={typeData}
                            setTypeData={setTypeData}
                            onChangeService={handleServiceChanged}
                            selectedValue={selectedValue}
                            setSelectedValue={setSelectedValue}
                            setLoadingSelect={setLoadingSelect}
                            loadingSelect={loadingSelect}
                        />
                    </>
                )}

                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <AnalyticsOverviewCard
                            loading={loading}
                            aggregateList={filteredAggList}
                            getData={getDataAnalyticsOverview}
                        />
                    </Col>
                    <Col span={24}>
                        <AnalyticsTable
                            setTableOverview={setTableOverview}
                            loading={loading}
                            loadingSelect={loadingSelect}
                            modelName={getModelNameTable}
                            aggregateList={filteredAggList}
                            onResetFilters={handleClearFilter}
                        />
                    </Col>
                    <Col span={24}>
                        <DeviceAnalytics
                            loading={loading}
                            sessionList={filteredSessionList}
                            aggregateList={filteredAggList}
                            onResetFilters={handleClearFilter}
                        />
                    </Col>
                </Row>

                <Modal
                    title={<Title level={4} style={{ margin: 0 }}>Report Download</Title>}
                    open={showModal}
                    onCancel={handleCloseModalProva}
                    footer={null}
                    width={1000}
                >
                    <Row gutter={[24, 24]}>
                        <Col span={24}>
                            <Title level={5} style={{ marginBottom: 12 }}>
                                Select Date Range
                            </Title>
                            <Row gutter={[24, 0]} justify="start">
                                <Col md={8} sm={12}>
                                    <Form layout="vertical" style={{ marginBottom: 0 }}>
                                        <Form.Item
                                            style={{ marginBottom: 0 }}
                                        >
                                            <DatePicker
                                                format="DD/MM/YYYY"
                                                value={startDateReport ? dayjs(startDateReport, "YYYY-MM-DD") : null}
                                                onChange={(date) => handleStartDateChange(date)}
                                                style={{ width: "100%" }}
                                                placeholder="Select Start Date"
                                                size="middle"
                                            />
                                        </Form.Item>
                                    </Form>
                                </Col>
                                <Col md={8} sm={12}>
                                    <Form layout="vertical" style={{ marginBottom: 0 }}>
                                        <Form.Item
                                            style={{ marginBottom: 0 }}
                                        >
                                            <DatePicker
                                                format="DD/MM/YYYY"
                                                value={endDateReport ? dayjs(endDateReport, "YYYY-MM-DD") : null}
                                                onChange={(date) => handleEndDateChange(date)}
                                                style={{ width: "100%" }}
                                                size="middle"
                                                placeholder="Select End Date"
                                            />
                                        </Form.Item>
                                    </Form>
                                </Col>
                            </Row>
                            {error && <Text type="danger" style={{ display: 'block', marginTop: 8 }}>{error}</Text>}
                        </Col>
                    </Row>

                    <Row gutter={[32, 24]} style={{ marginTop: 16 }}>
                        <Col md={12}>
                            <Title level={5} style={{ marginBottom: 16 }}>
                                Select Service(s)
                            </Title>
                            <div style={{ maxHeight: '300px', overflowY: 'auto', padding: '0 4px' }}>
                                <Checkbox
                                    value="all"
                                    checked={selectedOption.includes("all")}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setSelectedOption(['all', '3D VIEWER', 'WEB VTO', 'APP']);
                                        } else {
                                            setSelectedOption([]);
                                        }
                                    }}
                                    style={{ marginBottom: 8, marginRight: 8 }}
                                >
                                    <Text strong>ALL SERVICES</Text>
                                </Checkbox>
                                <Divider style={{ margin: '12px 0' }} />
                                <Checkbox.Group
                                    style={{ display: 'flex', flexWrap: 'wrap' }}
                                    value={selectedOption.filter(item => item !== 'all')}
                                    onChange={handleOptionChange}
                                >
                                    <Checkbox
                                        value="3D VIEWER"
                                        disabled={selectedOption.includes("all")}
                                        style={{ marginBottom: 12, marginRight: 12 }}
                                    >
                                        <Text>3D/AR VIEWER</Text>
                                    </Checkbox>
                                    <Checkbox
                                        value="WEB VTO"
                                        disabled={selectedOption.includes("all")}
                                        style={{ marginBottom: 12, marginRight: 12 }}
                                    >
                                        <Text>WEB VTO</Text>
                                    </Checkbox>
                                    <Checkbox
                                        value="APP"
                                        disabled={selectedOption.includes("all")}
                                        style={{ marginBottom: 12, marginRight: 12 }}
                                    >
                                        <Text>VTO APPS</Text>
                                    </Checkbox>
                                </Checkbox.Group>
                            </div>
                        </Col>

                        <Col md={12}>
                            <Title level={5} style={{ marginBottom: 16 }}>
                                Select Catalog(ues)
                            </Title>
                            <div style={{ maxHeight: '300px', overflowY: 'auto', padding: '0 4px' }}>
                                <Checkbox
                                    checked={allCatalogsSelected}
                                    onChange={handleSelectAllChange}
                                    style={{ marginBottom: 8, marginRight: 8 }}
                                >
                                    <Text strong>SELECT ALL</Text>
                                </Checkbox>
                                <Divider style={{ margin: '12px 0' }} />
                                <Checkbox.Group
                                    value={selectedCatalogs}
                                    onChange={handleCatalogChange}
                                    style={{ display: 'flex', flexWrap: 'wrap' }}
                                >
                                    {catalogProfileData
                                        .sort((a, b) => a.nome_brand.localeCompare(b.nome_brand))
                                        .map((catalog) => (
                                            <Checkbox
                                                key={catalog.id}
                                                value={catalog.id}
                                                disabled={allCatalogsSelected}
                                                style={{ marginBottom: 8, marginRight: 12 }}
                                            >
                                                <Text>{catalog.nome_brand.toUpperCase()}</Text>
                                            </Checkbox>
                                        ))}
                                </Checkbox.Group>
                            </div>
                        </Col>
                    </Row>

                    <Divider style={{ margin: '24px 0' }} />

                    <Row justify="center" style={{ marginTop: 8 }}>
                        <Button
                            type="primary"
                            onClick={handleDownload}
                            disabled={!isFormValid() || downloadLoading}
                            icon={
                                downloadLoading ? (
                                    <Spin size="small" />
                                ) : (
                                    <DownloadOutlined />
                                )
                            }
                            size="large"
                            style={{ minWidth: 200, height: 40 }}
                        >
                            {downloadLoading ? "Downloading..." : "Proceed to Download"}
                        </Button>
                    </Row>
                </Modal>
            </Layout.Content>
        </Layout>
    );
};

export default Analytics;