import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import './chartSetup';
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./redux/store";
import ErrorBoundary from "./components/ErrorBoundary";
import { ThemeProvider } from "./theme/ThemeContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
          <ThemeProvider>
            <App />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>
);

// Se vuoi iniziare a misurare le performance della tua app, passa una funzione
// per registrare i risultati (ad esempio: reportWebVitals(console.log))
// oppure inviali a un endpoint di analisi. Per maggiori informazioni: https://bit.ly/CRA-vitals
reportWebVitals();