import React, { useEffect, useRef, useState } from "react";
import { Select, message, Button } from "antd";
import { CloseCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { collection, doc, getDoc } from "firebase/firestore";
import { db } from "../../data/base";
import { setSelectedCatalog } from "../../redux/catalog/action";

const { Option } = Select;

const CatalogSelect = () => {
    const [catalogList, setCatalogList] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    const selectRef = useRef(null);

    const dispatch = useDispatch();
    const selectedCatalog = useSelector((state) => state.catalog.selectedCatalog);
    const rxCatalogs = useSelector((state) => state.config.listaBrand);

    const handleCatalogChange = (value) => {
        const selected = catalogList.find((catalog) => catalog.id === value) || { id: "all", nome_brand: "All Catalogues" };
        if (selected.id !== selectedCatalog?.id) {
            dispatch(setSelectedCatalog(selected));
        }
        if (selectRef.current) {
            selectRef.current.blur();
        }
    };

    const getCatalogs = async () => {
        try {
            setIsLoad(false);

            const uniqueRxCatalogs = [...new Set(rxCatalogs)];
            const result = await Promise.all(
                uniqueRxCatalogs.map(async (catalog) => {
                    const catalogDocRef = doc(collection(db, "Brand"), catalog);
                    const catalogData = await getDoc(catalogDocRef);
                    if (catalogData.exists()) {
                        return { id: catalogData.id, ...catalogData.data() };
                    }
                    return null;
                })
            );

            const filteredCatalogs = result.filter((catalog) => catalog !== null);
            setCatalogList([{ id: "all", nome_brand: "All Catalogues" }, ...filteredCatalogs]);
        } catch (error) {
            message.error("Something went wrong while fetching catalogs");
            console.error("Error fetching catalogs:", error);
        } finally {
            setIsLoad(true);
        }
    };

    const resetSelectedCatalog = () => {
        dispatch(setSelectedCatalog({ id: "all", nome_brand: "All Catalogues" }));
    };

    useEffect(() => {
        if (rxCatalogs?.length) {
            getCatalogs();
        } else {
            setCatalogList([{ id: "all", nome_brand: "All Catalogues" }]);
        }
    }, [rxCatalogs]);

    useEffect(() => {
        handleCatalogChange({ target: { value: "all" } });
    }, [catalogList?.length]);

    return (
        <div style={{ position: "relative", minWidth: "250px" }}>
            <Select
                ref={selectRef}
                style={{
                    width: "100%",
                    borderRadius: "8px",
                    borderColor: selectedCatalog?.id !== "all" ? "rgba(66, 177, 172, 1)" : undefined,
                    boxShadow: selectedCatalog?.id !== "all" ? "0 0 0 2px rgba(66, 177, 172, 1)" : undefined,
                }}
                placeholder="Select a catalog"
                value={selectedCatalog?.id || "all"}
                onChange={handleCatalogChange}
                loading={!isLoad}
            >
                {catalogList.map((catalog) => (
                    <Option key={catalog.id} value={catalog.id}>
                        {catalog.nome_brand}
                    </Option>
                ))}
            </Select>
            {selectedCatalog?.id !== "all" && (
                <Button
                    type="text"
                    icon={<CloseCircleOutlined style={{ color: "grey" }} />}
                    onClick={resetSelectedCatalog}
                    style={{
                        position: "absolute",
                        top: "50%",
                        right: "25px",
                        transform: "translateY(-50%)",
                        zIndex: 1,
                    }}
                />
            )}
        </div>

    );
};

export default CatalogSelect;