/**
 * Configurazione del tema di Ant Design
 * Supporto per tema chiaro e scuro
 * Tutti i token disponibili sono documentati qui: https://ant.design/docs/react/customize-theme/
 */

import { theme as antdTheme } from 'antd';
import { brandColors } from './brandColors';

// Definizione delle palette di colori
export const brandPalette = brandColors;

// Variant tokens secondo lo style guide
export const variantTokens = {
    primary: '#42B1AC',
    secondary: '#CCC492',
    accent: '#42B1AC',
    complementary: '#2B7C',
    white: '#ffffff',
    black: '#000000',
};

export const statusTokens = {
    success: '#50B957',
    warning: '#F2BB50',
    error: '#F45564',
    info: '#52973C',
};

export const greyScaleTokens = ['#FAF7F5', '#EFEBE9', '#E7E2E0', '#Dfdad7', '#C8c0bd', '#9c9090', '#8a7d7f'];

export const backgroundTokens = {
    primaryBg: '#eaebf5',
    secondaryBg: '#CCC492',
    greyBg: '#FAF7F5',
    whiteBg: '#ffffff',
};

// Tokens di base per tema chiaro
export const lightBaseTokens = {
    // Colori principali
    colorPrimary: brandColors.primary || variantTokens.primary,
    colorLink: brandColors.accent,
    colorSuccess: statusTokens.success,
    colorError: statusTokens.error,
    colorWarning: statusTokens.warning,
    colorInfo: statusTokens.info,
    colorLightGray: brandColors.lightGray,
    colorGray: brandColors.gray,

    // Colori di base
    colorTextWhite: variantTokens.white,
    colorTextBlack: variantTokens.black,
    colorBgBase: backgroundTokens.whiteBg,
    colorBorder: greyScaleTokens[3],

    // Tipografia
    fontFamily: '"PT Sans", sans-serif',
    fontSizeBase: 14,
    fontSize: 14,
    lineHeight: 1.5714285714285714,
};

// Tokens di base per tema scuro
export const darkBaseTokens = {
    // Colori principali
    colorPrimary: brandColors.primary || variantTokens.primary,
    colorLink: brandColors.accent,
    colorSuccess: statusTokens.success,
    colorError: statusTokens.error,
    colorWarning: statusTokens.warning,
    colorInfo: statusTokens.info,
    colorLightGray: brandColors.lightGray,
    colorGray: brandColors.gray,

    // Colori di base
    colorTextWhite: variantTokens.white,
    colorTextBlack: variantTokens.black,
    colorBgBase: '#141414',
    colorBorder: greyScaleTokens[5],

    // Tipografia
    fontFamily: '"PT Sans", sans-serif',
    fontSizeBase: 14,
    fontSize: 14,
    lineHeight: 1.5714285714285714,
};

// Integrazione delle palette nel sistema dei token
const createTokensFromPalette = (isDark = false) => {
    return {
        // Colori principali
        colorPrimary: variantTokens.primary,
        colorSecondary: variantTokens.secondary,
        colorTertiary: variantTokens.accent,
        colorComplementary: variantTokens.complementary,
        colorAccent: variantTokens.accent,

        // Colori di stato
        colorSuccess: statusTokens.success,
        colorWarning: statusTokens.warning,
        colorError: statusTokens.error,
        colorInfo: statusTokens.info,

        // Sfondi
        colorBgContainer: isDark ? '#1f1f1f' : backgroundTokens.whiteBg,
        colorBgLayout: isDark ? '#141414' : backgroundTokens.primaryBg,
        colorBgSpotlight: isDark ? '#2d2d2d' : backgroundTokens.greyBg,

        // Testo
        colorText: isDark ? variantTokens.white : variantTokens.black,
        colorTextSecondary: isDark ? greyScaleTokens[4] : greyScaleTokens[5],
        colorTextDisabled: isDark ? greyScaleTokens[5] : greyScaleTokens[3],

        // Bordi e riempimenti
        colorBorder: isDark ? greyScaleTokens[5] : greyScaleTokens[3],
        colorBorderSecondary: isDark ? greyScaleTokens[6] : greyScaleTokens[1],
        colorFill: isDark ? greyScaleTokens[6] : greyScaleTokens[0],
        colorFillSecondary: isDark ? greyScaleTokens[5] : greyScaleTokens[1],

        // Colori hover e attivi
        // colorPrimaryHover: isDark ? '#5663CC' : '#7B86E2',
        // colorPrimaryActive: isDark ? '#3D4AB8' : '#5D6AD1',
        // colorSuccessHover: '#73d13d',
        // colorSuccessActive: '#389e0d',
        // colorWarningHover: '#ffc53d',
        // colorWarningActive: '#d48806',
        // colorErrorHover: '#ff7875',
        // colorErrorActive: '#cf1322',
        // colorInfoHover: '#40a9ff',
        // colorInfoActive: '#096dd9',
    };
};

// Configurazione dei temi
export const theme = {
    // Tema chiaro (default)
    light: {
        token: { ...lightBaseTokens, ...createTokensFromPalette(false) },
        // Aggiungiamo i token personalizzati che possono essere utilizzati ma non sono definiti in Ant Design
        customToken: {
            colorSecondary: variantTokens.secondary,
            colorTertiary: variantTokens.accent,
            colorComplementary: variantTokens.complementary,
            colorAccent: variantTokens.accent,
        }
    },

    // Tema scuro
    dark: {
        token: { ...darkBaseTokens, ...createTokensFromPalette(true) },
        algorithm: antdTheme.darkAlgorithm,
        // Aggiungiamo i token personalizzati per il tema scuro
        customToken: {
            colorSecondary: variantTokens.secondary,
            colorTertiary: variantTokens.accent,
            colorComplementary: variantTokens.complementary,
            colorAccent: variantTokens.accent,
        }
    }
};

// Funzione per ottenere il tema in base alla modalità
export const getTheme = (isDarkMode = false) => {
    return isDarkMode ? theme.dark : theme.light;
};

// Funzione per ottenere i colori personalizzati
export const getCustomColors = (isDarkMode = false) => {
    return isDarkMode ? theme.dark.customToken : theme.light.customToken;
};