import React from "react";
import { Button, DatePicker } from "antd";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

function FiltersTop({ dateRange, onChangeDate, onClearFilter, handleOpenDownloadReportModal, downloadLoading }) {

    const rangePresets = [
        { label: "Today", value: [dayjs().startOf("day"), dayjs().endOf("day")] },
        { label: "Yesterday", value: [dayjs().subtract(1, "day").startOf("day"), dayjs().subtract(1, "day").endOf("day")] },
        { label: "Last Week", value: [dayjs().subtract(1, "week").startOf("week"), dayjs().subtract(1, "week").endOf("week")] },
        { label: "This Week", value: [dayjs().startOf("week"), dayjs().endOf("day")] },
        { label: "Last Month", value: [dayjs().subtract(1, "month").startOf("month"), dayjs().subtract(1, "month").endOf("month")] },
        { label: "This Month", value: [dayjs().startOf("month"), dayjs().endOf("day")] },
        { label: "Last 3 Months", value: [dayjs().subtract(3, "month").startOf("day"), dayjs().endOf("day")] },
        { label: "Last 6 Months", value: [dayjs().subtract(6, "month").startOf("day"), dayjs().endOf("day")] },
        { label: "This Year", value: [dayjs().startOf("year"), dayjs().endOf("day")] },
    ];

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "20px",
                width: "100%",
            }}
        >
            <RangePicker
                value={dateRange}
                presets={rangePresets}
                onChange={onChangeDate}
                format="DD/MM/YYYY"
                placement={"bottomLeft"}
            />
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "10px"
            }}>
                <Button
                    type="primary"
                    onClick={handleOpenDownloadReportModal}
                    disabled={downloadLoading}
                >
                    Download Report
                </Button>
                <Button
                    type="default"
                    onClick={onClearFilter}
                >
                    Clear Filters
                </Button>
            </div>
        </div>
    )
};

export default FiltersTop;