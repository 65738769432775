import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { Spin, message, theme } from "antd";
import { useNavigate } from "react-router-dom";
import GlassCard from "../components/AR3dViewer/GlassCard";
import { useSelector } from "react-redux";
import { ArrowLeftOutlined, AppstoreOutlined, UnorderedListOutlined } from "@ant-design/icons";
import IFrameModal from "../components/AR3dViewer/IFrameModal";
import Filters from "../components/AR3dViewer/Filters";
import GlassRow from "../components/AR3dViewer/GlassRow";
import {
    getStates,
    getBrands,
    getTagClasses,
    updateGlassCategories,
    fetchModelVariants,
    fetchGlassesModels,
    fetchGlassesByBrand
} from "../services/api/AR3dViewerApi";
import { generateIframeCode } from "../services/utils/ar3dViewer";
import SummaryGrid from "../components/Analytics/SummaryGrid";

const AR3dViewer = () => {
    const { token } = theme.useToken();
    const [paginatedItems, setPaginatedItems] = useState([]);

    const [messageApi, contextHolder] = message.useMessage();

    const [initialLoading, setInitialLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 25;
    const navigate = useNavigate();
    const loadMoreRef = useRef(null);
    const [glasses, setGlasses] = useState([]);
    const [model, setModel] = useState([]);
    const [lastDoc, setLastDoc] = useState(null);
    const [modelsBrandSelected, setModelsBrandSelected] = useState([]);
    const [selectedModelIndex, setSelectedModelIndex] = useState(null);
    const [selectedModel, setSelectedModel] = useState(null);
    const [loading, setLoading] = useState(false);
    const [variantDepth, setVariantDept] = useState(0);
    const [indexModelExportCode, setIndexModelExportCode] = useState();
    const [refreshKey, setRefreshKey] = useState(0);

    const [modalIframe, setModalIframe] = useState(false);
    const [brandIdSelected, setBrandIdSelected] = useState(null);
    const [open, setOpen] = useState(false);
    const [modalText, setModalText] = useState('');

    const rxBrands = useSelector((state) => state.config.listaBrand);

    // FILTRI
    const [selectedCatalogs, setSelectedCatalogs] = useState([]);
    const [catalogsList, setCatalogsList] = useState([]);
    const [loadingCatalogs, setLoadingCatalogs] = useState(true);

    const [selectedStati, setSelectedStati] = useState([]);
    const [statesList, setStatesList] = useState([]);
    const [loadingStates, setLoadingStates] = useState(true);

    // Nuovi state per i tag
    const [genders, setGenders] = useState([]);
    const [modelTypes, setModelTypes] = useState([]);
    const [frameTypes, setFrameTypes] = useState([]);
    const [lensTreatments, setLensTreatments] = useState([]);
    const [loadingTags, setLoadingTags] = useState(true);

    const [glassIDToCategories, setGlassIDToCategories] = useState({});

    const [selectedGender, setSelectedGender] = useState([]);
    const [selectedModelType, setSelectedModelType] = useState([]);
    const [selectedFrameType, setSelectedFrameType] = useState([]);
    const [selectedLensTreatment, setSelectedLensTreatment] = useState([]);

    // Sposta questa definizione prima di tutti gli useEffect
    const isLoading = useMemo(() => {
        return loading || loadingCatalogs || loadingStates || loadingTags || initialLoading;
    }, [loading, loadingCatalogs, loadingStates, loadingTags, initialLoading]);

    useEffect(() => {
        if (rxBrands.length > 0) {
            getBrands(setLoadingCatalogs, rxBrands, setCatalogsList, messageApi);
        }
    }, [rxBrands]);

    useEffect(() => {
        getStates(setLoadingStates, setStatesList, messageApi);
    }, []);

    const availableTagIDs = useMemo(() => {
        const ids = new Set();
        Object.values(glassIDToCategories).forEach(catArray => {
            catArray.forEach(tagId => ids.add(tagId));
        });
        return ids;
    }, [glassIDToCategories]);

    const filteredGenders = useMemo(() => {
        return genders.filter(tag => availableTagIDs.has(tag.id));
    }, [genders, availableTagIDs]);

    const filteredModelTypes = useMemo(() => {
        return modelTypes.filter(tag => availableTagIDs.has(tag.id));
    }, [modelTypes, availableTagIDs]);

    const filteredFrameTypes = useMemo(() => {
        return frameTypes.filter(tag => availableTagIDs.has(tag.id));
    }, [frameTypes, availableTagIDs]);

    const filteredLensTreatments = useMemo(() => {
        return lensTreatments.filter(tag => availableTagIDs.has(tag.id));
    }, [lensTreatments, availableTagIDs]);

    // Chiamata a `getTagClasses` solo quando abbiamo ID disponibili
    useEffect(() => {
        if (availableTagIDs.size > 0) {
            getTagClasses(setLoadingTags, availableTagIDs, setGenders, setModelTypes, setFrameTypes, setLensTreatments, messageApi);
        }
    }, [availableTagIDs]);

    // Avvolgi le funzioni in useCallback
    const handleOnGlassChange = useCallback(async () => {
        await fetchGlassesModels(glasses, setLoading, setModel, messageApi);
    }, [glasses]);

    const handleOnChangeBrandId = useCallback(async () => {
        setLoading(true);
        await fetchGlassesByBrand(selectedCatalogs, rxBrands, setLoading, setGlasses, messageApi);
    }, [selectedCatalogs, rxBrands]);

    useEffect(() => {
        // Resetta gli stati principali e ricarica i dati relativi ai brand selezionati
        setModel([]);
        setModelsBrandSelected([]);
        setLastDoc(null);
        setPaginatedItems([]); // Reset degli elementi caricati
        setCurrentPage(1); // Torna alla prima pagina
        setVariantDept(0);
        setSelectedModelIndex(null);

        handleOnChangeBrandId();
    }, [selectedCatalogs, handleOnChangeBrandId]);

    const handleOnSelectModelIndex = useCallback(async () => {
        if (selectedModelIndex === null || !model || model.length === 0) {
            return; // Non procedere se non c'è un indice selezionato o non ci sono modelli
        }

        const selectedModel = model[selectedModelIndex];
        if (!selectedModel) {
            console.error("Modello non trovato all'indice:", selectedModelIndex);
            messageApi.error("Errore: modello non trovato");
            return;
        }

        await fetchModelVariants(selectedModel, setLoading, setModelsBrandSelected, messageApi);
    }, [model, selectedModelIndex]);

    // Aggiorna le dipendenze nei useEffect
    useEffect(() => {
        handleOnGlassChange();
    }, [handleOnGlassChange]);

    useEffect(() => {
        handleOnChangeBrandId();
    }, [handleOnChangeBrandId]);

    useEffect(() => {
        handleOnSelectModelIndex();
    }, [handleOnSelectModelIndex]);

    function modelPageHandler(i) {
        if (variantDepth === 0) {

            if (!model[i]) {
                console.error("Modello non trovato all'indice:", i);
                messageApi.error("Errore nella selezione del modello");
                return;
            }

            setSelectedModelIndex(i);
            setIndexModelExportCode(i);
            setVariantDept(variantDepth + 1);
        }
    }

    const isModelView = useMemo(() => {
        return selectedModelIndex === null || selectedModelIndex === "undefined";
    }, [selectedModelIndex]);

    const extractGlassIDFromURL = (url) => {
        if (!url) return null;
        const regex = /glasses\/([^/]+)/;
        const match = url.match(regex);
        return match ? match[1] : null;
    };

    const filteredModelAndVariants = useMemo(() => {
        const items = isModelView ? model : modelsBrandSelected;
        const queryLower = searchQuery.trim().toLowerCase();

        let filteredItems = items.filter((item) =>
            (item.nomeOcchiale && item.nomeOcchiale.toLowerCase().includes(queryLower)) ||
            (item.sku_model && String(item.sku_model).toLowerCase().includes(queryLower)) ||
            (item.eanCode && String(item.eanCode).toLowerCase().includes(queryLower)) ||
            (item.upcCode && String(item.upcCode).toLowerCase().includes(queryLower))
        );

        if (selectedStati.length > 0) {
            filteredItems = filteredItems.filter((item) =>
                selectedStati.includes(item.stato)
            );
        }

        // Creiamo i Set di tag selezionati per ogni tipologia**
        const selectedGenderSet = new Set(selectedGender);
        const selectedModelTypeSet = new Set(selectedModelType);
        const selectedFrameTypeSet = new Set(selectedFrameType);
        const selectedLensTreatmentSet = new Set(selectedLensTreatment);

        const validGlassIDs = new Set();

        Object.entries(glassIDToCategories).forEach(([glassID, tagIDs]) => {
            // Controlliamo se l'occhiale ha almeno un tag per ogni filtro selezionato**
            const matchesGender = selectedGenderSet.size === 0 || tagIDs.some(tagID => selectedGenderSet.has(tagID));
            const matchesModelType = selectedModelTypeSet.size === 0 || tagIDs.some(tagID => selectedModelTypeSet.has(tagID));
            const matchesFrameType = selectedFrameTypeSet.size === 0 || tagIDs.some(tagID => selectedFrameTypeSet.has(tagID));
            const matchesLensTreatment = selectedLensTreatmentSet.size === 0 || tagIDs.some(tagID => selectedLensTreatmentSet.has(tagID));

            // L'occhiale viene incluso solo se rispetta tutti i gruppi di filtri**
            if (matchesGender && matchesModelType && matchesFrameType && matchesLensTreatment) {
                validGlassIDs.add(glassID);
            }
        });

        filteredItems = filteredItems.filter((item) => {
            const idGlass = item.glassID || extractGlassIDFromURL(item.model_viewer_url);
            if (!idGlass) {
                return false;
            }
            return validGlassIDs.has(idGlass);
        });
        return filteredItems;
    }, [
        isModelView,
        model,
        modelsBrandSelected,
        searchQuery,
        selectedStati,
        selectedGender,
        selectedModelType,
        selectedFrameType,
        selectedLensTreatment,
        glassIDToCategories
    ]);

    useEffect(() => {
        updateGlassCategories(model, setGlassIDToCategories, setGenders, setModelTypes, setFrameTypes, setLensTreatments);
    }, [model]);

    const hasMoreItems = useMemo(() => {
        const totalItems = filteredModelAndVariants.length;
        const loadedItems = (currentPage - 1) * itemsPerPage;

        const moreItems = totalItems > loadedItems;

        return moreItems;
    }, [filteredModelAndVariants.length, currentPage, itemsPerPage]);

    const loadMoreItems = useCallback(async () => {
        if (loadingMore || !hasMoreItems) return;

        setLoadingMore(true);

        try {
            const startIndex = (currentPage - 1) * itemsPerPage;
            const endIndex = currentPage * itemsPerPage;

            const newItems = filteredModelAndVariants.slice(startIndex, endIndex);

            // Aggiungi i nuovi elementi a quelli già caricati
            setPaginatedItems((prevItems) => [...prevItems, ...newItems]);

            setCurrentPage((prevPage) => prevPage + 1);
        } catch (error) {
            console.error("Error during lazy loading:", error);
            messageApi.error("Errore durante il caricamento di altri elementi");
        } finally {
            setLoadingMore(false);
        }
    }, [loadingMore, hasMoreItems, filteredModelAndVariants, currentPage, itemsPerPage, messageApi]);

    useEffect(() => {
        const startIndex = 0;
        const endIndex = itemsPerPage;
        const initialItems = filteredModelAndVariants.slice(startIndex, endIndex);

        setPaginatedItems(initialItems); // Imposta i primi elementi
        setCurrentPage(2); // La prossima pagina sarà la seconda
    }, [filteredModelAndVariants, itemsPerPage]);

    // Ora l'effect può usare isLoading
    useEffect(() => {
        if (isLoading) return;

        const currentRef = loadMoreRef.current;

        const observer = new IntersectionObserver(
            (entries) => {
                const [entry] = entries;
                if (entry.isIntersecting && hasMoreItems && !loadingMore && !isLoading) {
                    loadMoreItems();
                }
            },
            {
                root: null,
                rootMargin: "200px",
                threshold: 0.1,
            }
        );

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [hasMoreItems, loadingMore, loadMoreItems, isLoading]);

    // **Effetto per il caricamento iniziale**
    useEffect(() => {
        setModel([]);
        setModelsBrandSelected([]);
        setLastDoc(null);
        handleOnChangeBrandId();
        setCurrentPage(1);
    }, [selectedCatalogs]);

    useEffect(() => {
        if (paginatedItems.length > 0 && initialLoading) {
            setInitialLoading(false);
        }
    }, [paginatedItems, initialLoading]);

    function handleReturnOnGlasses() {
        setSelectedModelIndex(null)
        setVariantDept(variantDepth - 1);
    }

    const [iframeVtoString, setIframeVtoString] = useState("");

    const handleExportCode = (i, m) => {
        generateIframeCode(
            i,
            m,
            variantDepth,
            setBrandIdSelected,
            setModalIframe,
            setIndexModelExportCode,
            setSelectedModel,
            setModalText,
            setIframeVtoString,
            showModal,
            messageApi
        );
    };

    const showModal = () => {
        setOpen(true);
    };

    const copyTextToClipboard = (textToCopy) => {
        if (!textToCopy) {
            console.error("No text to copy");
            return;
        }
        try {
            navigator.clipboard.writeText(textToCopy);
            messageApi.success("Copied to clipboard");
        } catch (error) {
            console.error("Error copying text to clipboard:", error);
            messageApi.error("Error copying text to clipboard");
        }
    };

    const onClickView = (i, m) => {
        if (!m || m.stato === "Incompleto") {
            messageApi.error("Glass is in Incomplete State");
            return;
        }

        // Aggiorna lo stato per il contesto
        setBrandIdSelected(m?.brandId || "");
        setIndexModelExportCode(i);

        // Genera l'URL in modo pulito
        const baseUrl = `${process.env.PUBLIC_URL}/Arshades3d${variantDepth === 1 ? "" : "s"}`;
        const url = variantDepth === 1
            ? `${baseUrl}/${m.brandId}/glasses/${m.glassID}/variant/${m.id}`
            : `${baseUrl}/${m.brandId}/glasses/${m.glassID}`;

        // Apri l'URL in una nuova scheda
        window.open(url, "_blank");
    };

    // Stato per la modalità di visualizzazione: "card" oppure "row"
    const [viewMode, setViewMode] = useState("card");

    // Funzioni per cambiare la modalità in base all'icona cliccata
    const switchToCardView = () => setViewMode("card");
    const switchToRowView = () => setViewMode("row");

    // Aggiungiamo un nuovo effetto per gestire il loader iniziale basato sui filtri
    useEffect(() => {
        if (!loadingCatalogs && !loadingStates && !loadingTags) {
            setInitialLoading(false);
        }
    }, [loadingCatalogs, loadingStates, loadingTags]);

    return (
        <>
            {contextHolder}
            <div key={refreshKey}>
                <Filters
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    selectedCatalogs={selectedCatalogs}
                    setSelectedCatalogs={setSelectedCatalogs}
                    catalogsList={catalogsList}
                    loadingCatalogs={loadingCatalogs}
                    selectedStati={selectedStati}
                    setSelectedStati={setSelectedStati}
                    statesList={statesList}
                    loadingStates={loadingStates}
                    genders={filteredGenders}
                    selectedGender={selectedGender}
                    setSelectedGender={setSelectedGender}
                    modelTypes={filteredModelTypes}
                    selectedModelType={selectedModelType}
                    setSelectedModelType={setSelectedModelType}
                    frameTypes={filteredFrameTypes}
                    selectedFrameType={selectedFrameType}
                    setSelectedFrameType={setSelectedFrameType}
                    lensTreatments={filteredLensTreatments}
                    selectedLensTreatment={selectedLensTreatment}
                    setSelectedLensTreatment={setSelectedLensTreatment}
                    loadingTags={loadingTags}
                />

                {/* Sezione Toggle View sempre visibile */}
                <div style={{ margin: "16px 0", textAlign: "right" }}>
                    <div
                        style={{
                            display: "inline-flex",
                            border: `1px solid ${token.colorBorder}`,
                            borderRadius: "8px",
                            overflow: "hidden",
                        }}
                    >
                        <div
                            onClick={switchToCardView}
                            style={{
                                padding: "8px 16px",
                                cursor: "pointer",
                                backgroundColor: viewMode === "card" ? token.colorPrimary : token.white,
                                color: viewMode === "card" ? token.colorTextWhite : token.colorTextBlack,
                                borderRight: `1px solid ${token.colorBorder}`,
                            }}
                        >
                            <AppstoreOutlined />
                        </div>
                        <div
                            onClick={switchToRowView}
                            style={{
                                padding: "8px 16px",
                                cursor: "pointer",
                                backgroundColor: viewMode === "row" ? token.colorPrimary : token.white,
                                color: viewMode === "row" ? token.colorTextWhite : token.colorTextBlack,
                            }}
                        >
                            <UnorderedListOutlined />
                        </div>
                    </div>
                </div>

                {/* Titolo sempre visibile */}
                <div style={{ display: "flex", direction: "row" }}>
                    {!isModelView && (
                        <ArrowLeftOutlined
                            style={{
                                fontSize: "14px",
                                cursor: "pointer",
                                marginRight: 16,
                            }}
                            onClick={() => handleReturnOnGlasses()}
                        />
                    )}
                    <h3>{isModelView ? "Main Assets" : "Color Variants"}</h3>
                </div>

                {/* Container per il contenuto */}
                <div style={{ position: 'relative', minHeight: '400px' }}>
                    {isLoading ? (
                        <div style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 1
                        }}>
                            <Spin size="large" />
                        </div>
                    ) : (
                        <>
                            {viewMode === "card" ? (
                                <SummaryGrid>
                                    {paginatedItems.map((model, index) => {
                                        if (model.stato?.toLowerCase() !== "incompleto") {
                                            return (
                                                <GlassCard
                                                    key={model.id || index}
                                                    model={model}
                                                    navigate={navigate}
                                                    onClickCard={() => modelPageHandler(index)}
                                                    onClickExportCode={() => handleExportCode(index, model)}
                                                    onClickView={() => onClickView(index, model)}
                                                />
                                            );
                                        }
                                        return null;
                                    })}
                                </SummaryGrid>
                            ) : (
                                <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                                    {paginatedItems.map((model, index) => {
                                        if (model.stato?.toLowerCase() !== "incompleto") {
                                            return (
                                                <GlassRow
                                                    key={model.id || index}
                                                    model={model}
                                                    onClickRow={() => modelPageHandler(index)}
                                                    onClickExportCode={() => handleExportCode(index, model)}
                                                    onClickView={() => onClickView(index, model)}
                                                />
                                            );
                                        }
                                        return null;
                                    })}
                                </div>
                            )}

                            {/* Messaggi di stato */}
                            {!isLoading && !loadingMore && paginatedItems.length === 0 && filteredModelAndVariants.length === 0 && (
                                <div style={{ marginTop: "16px", textAlign: "center" }}>
                                    {isModelView
                                        ? "Currently, there are no validated 3D assets. Please go to the Frame Validation page to validate your assets. Afterwards, you will find the list of 3D viewers here."
                                        : model[selectedModelIndex]?.stato?.toLowerCase() === "incompleto"
                                            ? "This model is in Incomplete state. No color variants are available."
                                            : "No color variants are available for this model. Variants may exist but are in 'Incomplete' state or not yet processed."}
                                </div>
                            )}

                            {/* Scritta "No more items" */}
                            {!loadingMore && !hasMoreItems && paginatedItems.length > 0 && (
                                <div style={{ marginTop: "30px", textAlign: "center" }}>
                                    No more items to load.
                                </div>
                            )}

                            {/* Trigger per il lazy loading */}
                            <div ref={loadMoreRef} style={{ height: "50px", backgroundColor: "transparent" }}>
                                {loadingMore && (
                                    <div style={{ textAlign: "center", marginTop: "30px" }}>
                                        <Spin size="large" />
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>

                <IFrameModal
                    open={open}
                    setOpen={setOpen}
                    modalText={modalText}
                    iframeVtoString={iframeVtoString}
                    handleOk={copyTextToClipboard}
                />
            </div>
        </>
    );
};

export default AR3dViewer;