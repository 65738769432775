import React from "react";
import { Select, Button, Typography } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import ChartFilter from "./ChartFilter";
import CatalogSelect from "./CatalogSelect";

const { Option } = Select;
const { Text } = Typography;

function Filters({ typeData, setTypeData, onChangeService, selectedValue, setSelectedValue, setLoadingSelect, loadingSelect }) {

    const resetToAll = () => {
        setTypeData("all");
        onChangeService("all");
    };

    return (
        (
            <div
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    justifyContent: "flex-start",
                }}
            >
                {/* Select Catalog */}
                <div style={{ flex: "1 1 auto", maxWidth: "250px", minWidth: "150px" }}>
                    <Text>Select Catalog</Text>
                    <div style={{ position: "relative", width: "100%" }}>
                        <CatalogSelect />
                    </div>
                </div>

                {/* Select Line/Glasses */}
                <div style={{ flex: "1 1 auto", maxWidth: "250px", minWidth: "150px" }}>
                    <Text>Select Line/Glasses</Text>
                    <ChartFilter selectedValue={selectedValue} setSelectedValue={setSelectedValue} setLoadingSelect={setLoadingSelect} loadingSelect={loadingSelect} />
                </div>

                {/* Select Service */}
                <div style={{ flex: "1 1 auto", maxWidth: "250px", minWidth: "150px" }}>
                    <Text>Select Service</Text>
                    <div style={{ position: "relative", width: "100%" }}>
                        <Select
                            value={typeData}
                            onChange={(value) => {
                                setTypeData(value);
                                onChangeService(value);
                            }}
                            style={{
                                width: "100%",
                                borderRadius: "8px",
                                borderColor: typeData !== "all" ? "rgba(66, 177, 172, 1)" : undefined,
                                boxShadow: typeData !== "all" ? "0 0 0 2px rgba(66, 177, 172, 1)" : undefined,
                            }}
                            placeholder="Select a service"
                        >
                            <Option value="app">VTO Apps</Option>
                            <Option value="3d">3D/AR Viewer</Option>
                            <Option value="WebVto">Web VTO</Option>
                            <Option value="all">All</Option>
                        </Select>
                        {typeData !== "all" && (
                            <Button
                                type="text"
                                icon={<CloseCircleOutlined style={{ color: "grey" }} />}
                                onClick={resetToAll}
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    right: "25px",
                                    transform: "translateY(-50%)",
                                    zIndex: 1,
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        )
    )
};

export default Filters;