import React, { useEffect, useState } from "react";
import { Card, theme } from "antd";
import SummaryCard from "../Analytics/SummaryCard";
import SummaryGraphs from "../Analytics/SummaryGraphs";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import SummaryGrid from "../Analytics/SummaryGrid";

dayjs.extend(localizedFormat);

function GatewaysOverviewCard({ loading, aggregateList = [] }) {
    const { token } = theme.useToken();
    const [userData, setUserData] = useState({});
    const [sessionData, setSessionData] = useState({});
    const [newUserData, setNewUserData] = useState({});
    const [avgTimeData, setAvgTimeData] = useState({});
    const [avgEngTimeData, setAvgEngTimeData] = useState({});
    const [avgSessionPerUserData, setAvgSessionPerUserData] = useState({});
    const [selectedDataIndex, setSelectedDataIndex] = useState(0);
    const [chartData, setChartData] = useState(null);

    const convertSecondsToMinutes = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return parseFloat((minutes + remainingSeconds / 100).toFixed(2));
    };

    const handleDataIndexChange = (index) => {
        setSelectedDataIndex(index);
    };

    useEffect(() => {
        const deviceIdCounts = {};
        let totalEngagementTime = 0;
        const dailyVisualizationTimes = {};
        const dailyUniqueUsers = {};
        const dailyNewUsers = {};
        let totalSessions = 0;
        let totalNewUsers = 0;

        aggregateList.forEach((session) => {
            const {
                session_start_date,
                page_analitycs_list,
                device_id,
                is_first_session,
            } = session;

            const sessionDate = dayjs(
                session_start_date,
                "YYYY/MM/DD HH:mm:ss"
            );

            if (!sessionDate.isValid()) return; // Escludi date non valide

            const formattedDate = sessionDate.format("YYYY-MM-DD");

            deviceIdCounts[device_id] = (deviceIdCounts[device_id] || 0) + 1;

            if (!dailyUniqueUsers[formattedDate]) {
                dailyUniqueUsers[formattedDate] = new Set();
            }
            dailyUniqueUsers[formattedDate].add(device_id); // Aggiungi il device_id al set della data corrente

            if (!dailyNewUsers[formattedDate]) {
                dailyNewUsers[formattedDate] = new Set();
            }

            // Traccia i nuovi utenti univoci
            if (is_first_session && deviceIdCounts[device_id] === 1) {
                dailyNewUsers[formattedDate].add(device_id); // Aggiungi ai nuovi utenti del giorno
                totalNewUsers++;
            }

            if (page_analitycs_list && Array.isArray(page_analitycs_list)) {
                const totalVisualizationTime = page_analitycs_list.reduce(
                    (sum, page) => sum + (page.visualizazion_time || 0),
                    0
                );

                totalEngagementTime += totalVisualizationTime;

                if (!dailyVisualizationTimes[formattedDate]) {
                    dailyVisualizationTimes[formattedDate] = {
                        totalTime: 0,
                        sessionCount: 0,
                    };
                }

                dailyVisualizationTimes[formattedDate].totalTime +=
                    totalVisualizationTime;
                dailyVisualizationTimes[formattedDate].sessionCount++;
            }

            totalSessions++;
        });

        const totalUsers = Object.keys(deviceIdCounts).length;

        const dailyUserCounts = Object.entries(dailyUniqueUsers)
            .sort(([a], [b]) => new Date(a) - new Date(b))
            .map(([date, users]) => ({
                date,
                userCount: users.size,
            }));

        const dailyAverages = Object.entries(dailyVisualizationTimes)
            .sort(([a], [b]) => new Date(a) - new Date(b))
            .map(([date, { totalTime, sessionCount }]) => ({
                date,
                avgTime: totalTime / sessionCount,
                sessionCount,
                totalTime,
            }));

        const dailyNewUserCounts = Object.entries(dailyNewUsers)
            .sort(([a], [b]) => new Date(a) - new Date(b))
            .map(([date, userSet]) => ({
                date,
                newUserCount: userSet.size,
            }));

        const avgTime =
            dailyAverages.length > 0
                ? dailyAverages.reduce((sum, { avgTime }) => sum + avgTime, 0) /
                dailyAverages.length
                : 0;

        const avgEngTime =
            totalUsers > 0 ? totalEngagementTime / totalUsers : 0;

        const avgSessionsPerUser =
            totalUsers > 0 ? totalSessions / totalUsers : 0;

        setUserData({ total: totalUsers });
        setSessionData({ total: totalSessions });
        setNewUserData({ total: totalNewUsers });
        setAvgTimeData({ total: convertSecondsToMinutes(avgTime) });
        setAvgEngTimeData({ total: convertSecondsToMinutes(avgEngTime) });
        setAvgSessionPerUserData({ total: avgSessionsPerUser.toFixed(2) });

        // Aggiorna il grafico in base alla selezione
        const updateChartData = (index) => {
            const metricMap = [
                {
                    label: "Users",
                    data: dailyUserCounts.map(({ userCount }) => userCount),
                },
                {
                    label: "Sessions",
                    data: dailyAverages.map(({ sessionCount }) => sessionCount),
                },
                {
                    label: "New Users",
                    data: dailyNewUserCounts.map(({ newUserCount }) => newUserCount),
                },
                {
                    label: "Avg Time",
                    data: dailyAverages.map(({ avgTime }) =>
                        convertSecondsToMinutes(avgTime)
                    ),
                },
                {
                    label: "Avg Eng Time",
                    data: dailyAverages.map(({ totalTime }) =>
                        convertSecondsToMinutes(totalTime)
                    ),
                },
                {
                    label: "Avg S/U",
                    data: dailyAverages.map(
                        ({ sessionCount }) => sessionCount / totalUsers
                    ),
                },
            ];

            const selectedMetric = metricMap[index];

            setChartData({
                labels: dailyUserCounts.map(({ date }) =>
                    dayjs(date).format("DD/MMM/YYYY") // Formatting as 01/Jan/2025
                ),
                datasets: [
                    {
                        label: selectedMetric.label, // Nome del dataset
                        data: selectedMetric.data, // Valori del dataset
                        backgroundColor: "transparent", // Sfondo trasparente per la linea
                        borderColor: token.colorPrimary, // Colore della linea
                        pointBackgroundColor: token.colorPrimary, // Colore dei punti
                        pointBorderColor: token.colorTextWhite, // Colore del bordo dei punti
                        pointHoverBackgroundColor: token.colorBgBase, // Colore di sfondo del punto al passaggio del mouse
                        pointHoverBorderColor: token.colorPrimary, // Colore del bordo del punto al passaggio del mouse
                        tension: 0.4, // Curvatura della linea (0 = spezzata, 1 = curva massima)
                    },
                ],
            });
        };

        updateChartData(selectedDataIndex);
    }, [aggregateList, selectedDataIndex]);

    return (
        <>
            <SummaryGrid>
                {[
                    userData,
                    sessionData,
                    newUserData,
                    avgTimeData,
                    avgEngTimeData,
                    avgSessionPerUserData,
                ].map((item, idx) => (
                    <div key={`${item?.total || 0}_${idx}`}>
                        <SummaryCard
                            loading={loading}
                            value={item?.total || 0}
                            active={idx === selectedDataIndex}
                            label={
                                [
                                    "Users",
                                    "Sessions",
                                    "New Users",
                                    "Avg Time",
                                    "Avg Eng Time",
                                    "Avg S/U",
                                ][idx]
                            }
                            onClick={() => handleDataIndexChange(idx)}
                        />
                    </div>
                ))}
            </SummaryGrid>
            <Card title="Overview">
                <SummaryGraphs data={chartData} isLoading={loading} />
            </Card>
        </>
    );
}

export default GatewaysOverviewCard;
