import React, { useState, useEffect } from "react";
import { DatePicker, Button, Row, Col, Spin, Card } from "antd";
import dayjs from "dayjs";
import {
    getSubscriptionData,
    fetchSubscriptionsByRole,
    getBrandsByRef,
} from "../services/api/dataConsumptionApi";
import { getTime } from "../services/utils/dataConsumptionUtils";
import BandwidthCard from "../components/DataConsumption/BandwidthCard";
import utc from "dayjs/plugin/utc";
import { useSelector } from "react-redux";
import noDataImage from "../assets/icon/ARShades_Logo_Sorry.svg";

const { RangePicker } = DatePicker;
dayjs.extend(utc);

const DataConsumption = () => {
    const [loading, setLoading] = useState(true);
    const [brandList, setBrandList] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);
    const [startDate, setStartDate] = useState(dayjs().subtract(1, "year").toDate());
    const [endDate, setEndDate] = useState(dayjs().toDate());
    const [bandwidthType, setBandwidthType] = useState("all");
    const [displayData, setDisplayData] = useState([]);
    const listaBrand = useSelector((state) => state.config.listaBrand);
    const [fetching, setFetching] = useState(false);

    const handleApplyFilter = async () => {
        setLoading(true);
        try {
            const promises = await Promise.all(brandList.map(async (brands) => {
                if (brands.length > 0) {
                    const {
                        barChartData,
                        lineChartData,
                        bandwidthDetails,
                        callsDetails,
                    } = await getSubscriptionData({
                        startDate: dayjs(startDate).toDate(),
                        endDate: dayjs(endDate).toDate(),
                        bandwidthType,
                        brandList: brands,
                        subscriptions,
                    });
                    return { barChartData, lineChartData, bandwidthDetails, callsDetails };
                }
                return null;
            }));
            const allData = (await Promise.all(promises)).filter((data) => data !== null);
            setDisplayData(allData);
        } catch (error) {
            console.error("Error applying filter:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleClearFilter = async () => {
        setLoading(true);
        setStartDate(dayjs().subtract(1, "year").toDate());
        setEndDate(dayjs().toDate());
        setBandwidthType("all");
        await handleApplyFilter();
    };

    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (fetching) return;
            setFetching(true);
            setLoading(true);
            try {
                const allData = [];
                const allBrands = [];
                const userBrands = listaBrand || [];
                // fetchSubscriptionsByRole ora controlla internamente se licenseList è un array valido
                const fetchedSubs = await fetchSubscriptionsByRole(Array.isArray(listaBrand) ? listaBrand : []);
                const activeSubscriptions = [];

                for (const subItem of fetchedSubs) {
                    if (
                        subItem?.catalogList?.catalogRefList &&
                        Array.isArray(subItem.catalogList.catalogRefList) &&
                        subItem.catalogList.catalogRefList.some((ref) => userBrands.includes(ref))
                    ) {
                        const brands = await getBrandsByRef(subItem.catalogList.catalogRefList, []);
                        if (brands.length > 0) {
                            allBrands.push(brands);

                            const {
                                barChartData,
                                lineChartData,
                                bandwidthDetails,
                                callsDetails,
                            } = await getSubscriptionData({
                                startDate,
                                endDate,
                                bandwidthType,
                                brandList: brands,
                                subStartDate: subItem?.startDate,
                                subscriptions: subItem,
                            });

                            allData.push({
                                barChartData,
                                lineChartData,
                                bandwidthDetails,
                                callsDetails,
                                brands,
                            });

                            activeSubscriptions.push(subItem);
                        }
                    }
                }
                setBrandList(allBrands);
                setDisplayData(allData);
                setSubscriptions(activeSubscriptions);
            } catch (error) {
                console.error("Error fetching data:", error);
                setError("Si è verificato un errore durante il recupero dei dati. Riprova più tardi.");
            } finally {
                setFetching(false);
                setLoading(false);
            }
        };

        fetchData();
    }, [startDate, endDate, bandwidthType, listaBrand]);

    useEffect(() => {
        const checkLoading = async () => {
            if (subscriptions.length > 0 && displayData.length >= subscriptions.length) {
                await Promise.all(
                    subscriptions.map(() => new Promise((resolve) => setTimeout(resolve, 0)))
                );
                setLoading(false);
            }
        };
        checkLoading();
    }, [subscriptions, displayData]);

    return (
        <div style={{ position: "relative", minHeight: "80svh" }}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    marginBottom: "20px",
                }}
            >
                <RangePicker
                    picker="month"
                    format="MM/YYYY"
                    value={[dayjs(startDate), dayjs(endDate)]}
                    onChange={(dates) => {
                        if (dates && dates.length === 2) {
                            setStartDate(dates[0].toDate());
                            setEndDate(dates[1].toDate());
                        } else {
                            setStartDate(dayjs().subtract(1, "year").toDate());
                            setEndDate(dayjs().toDate());
                        }
                    }}
                    placement={"bottomRight"}
                />
                <Button onClick={handleClearFilter} style={{ marginLeft: "8px" }}>
                    Clear Filter
                </Button>
            </div>
            {loading ? (
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <Spin size="large" />
                </div>
            ) : subscriptions.length === 0 ? (
                <Card style={{ textAlign: "center", padding: "40px" }}>
                    <img
                        src={noDataImage}
                        alt="No data available"
                        style={{ width: "200px", marginBottom: "20px" }}
                    />
                    <p style={{ fontSize: "16px", color: "#555" }}>
                        No data available to display.
                    </p>
                </Card>
            ) : (
                <Row gutter={[16, 16]}>
                    {subscriptions.map((subItem, index) => (
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} key={index}>
                            {displayData?.[index] && (
                                <Row gutter={[16, 16]}>
                                    <Col span={24}>
                                        <BandwidthCard
                                            displayData={displayData}
                                            index={index}
                                            subItem={subItem}
                                            getTime={getTime}
                                            chartData={displayData[index].barChartData}
                                            callsDetails={displayData[index].callsDetails}
                                            bandwidthData={displayData[index].bandwidthDetails}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    ))}
                </Row>
            )}
        </div>
    );
};

export default DataConsumption;