import React from 'react';
import { Spin } from 'antd';
import { Layout } from 'antd';

const { Content } = Layout;

const CenteredLoader = ({ backgroundColor = '#f0f2f5' }) => {
    return (
        <Content
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundColor: backgroundColor,
            }}
        >
            <Spin size="large" />
        </Content>
    );
};

export default CenteredLoader;
