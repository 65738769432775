export const getTimeInSecs = (data) => {
    let total = data.seconds ?? 0;
    if (data.minutes) {
        total += data.minutes * 60;
    }
    return total;
}

export function convertiSecondiAMinuti(secondi) {
    var minuti = Math.floor(secondi / 60); // Calcola il numero intero dei minuti
    var secondiRimanenti = secondi % 60; // Calcola i secondi rimanenti

    var risultato = minuti + secondiRimanenti / 100; // Combina i minuti e i secondi

    var risultatoLimitato = risultato.toFixed(2); // Limita il risultato ai primi due numeri decimali
    return parseFloat(risultatoLimitato); // Restituisce il valore numerico come float
}

export function getMinSecObject(secondi) {
    var minuti = Math.floor(secondi / 60); // Calcola il numero intero dei minuti
    var secondiRimanenti = secondi % 60; // Calcola i secondi rimanenti
    return { minutes: minuti, seconds: secondiRimanenti }; // Restituisce il valore numerico come float
}

export const getDurationFromSecond = (secs) => {
    const sec_num = parseInt(secs, 10);
    const hours = Math.floor(sec_num / 3600);
    const minutes = Math.floor(sec_num / 60) % 60;
    const seconds = sec_num % 60;
    return [hours, minutes, seconds]
        .map((v) => (v < 10 ? "0" + v : v))
        .filter((v, i) => (v !== "00" || i > 0) && !isNaN(v))
        .join(":");
};