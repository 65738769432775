import React, { useEffect, useRef, useState, useMemo } from "react";
import { Chart } from "chart.js/auto";
import { Table, Row, Col, Card, Button, theme } from "antd";
import SummaryCard from "./SummaryCard";
import { useSelector } from "react-redux";
import SummaryGrid from "./SummaryGrid";

function DeviceAnalytics({ aggregateList = [], loading, onResetFilters }) {
    const { token } = theme.useToken();
    const [devicesData, setDevicesData] = useState({});
    const [selectedTab, setSelectedTab] = useState("All Devices");
    const filterOptions = useSelector((state) => state.analytics.filterOptions);
    const selectedCatalog = useSelector((state) => state.catalog.selectedCatalog);

    const barChartRef = useRef(null);
    const pieChartRef = useRef(null);
    const barChartInstance = useRef(null);
    const pieChartInstance = useRef(null);

    const mobileCategories = [
        "android",
        "android instant",
        "ios",
        "ios app clip",
        "fbav (mobile)",
        "instagram (mobile)",
        "samsungbrowser (mobile)",
        "chrome (mobile)",
        "safari (mobile)",
        "firefox (mobile)",
    ];

    const desktopCategories = [
        "safari (desktop)",
        "chrome (desktop)",
        "firefox (desktop)",
        "samsungbrowser (desktop)",
    ];

    // Filtra i dati dai dispositivi
    const DeviceFilter = (aggList) => {
        const deviceStats = { mobile: 0, desktop: 0, allDevice: 0, misc: [] };
        const deviceTypeSet = new Set();

        let allDevices = 0;

        aggList.forEach(({ deviceData }) => {
            if (deviceData) {
                deviceData.forEach((device) => {
                    const deviceType = device.type?.toLowerCase();
                    const count = device.count ?? 0;

                    // Evita duplicati usando Set
                    if (!deviceTypeSet.has(device.type)) {
                        deviceTypeSet.add(device.type);
                        deviceStats.misc.push({ name: device.type, count });
                    } else {
                        const index = deviceStats.misc.findIndex((el) => el.name === device.type);
                        deviceStats.misc[index].count += count;
                    }

                    // Classifica tra mobile e desktop
                    if (mobileCategories.some((cat) => deviceType?.includes(cat))) {
                        deviceStats.mobile += count;
                    } else if (desktopCategories.some((cat) => deviceType?.includes(cat))) {
                        deviceStats.desktop += count;
                    }

                    allDevices += count;
                });
            }
        });

        deviceStats.allDevice = allDevices;
        return deviceStats;
    };

    // Raggruppa e somma i dati per browser
    function groupAndJoinData(data) {
        if (!Array.isArray(data)) return []; // Verifica che 'data' sia un array valido

        const groupedData = new Map();

        data.forEach((item) => {
            const browserName = item?.name?.split(" ")[0]?.toLowerCase() ?? "unknown";
            if (!groupedData.has(browserName)) {
                groupedData.set(browserName, { name: browserName.toUpperCase(), count: 0 });
            }
            groupedData.get(browserName).count += item.count ?? 0;
        });

        return Array.from(groupedData.values()).sort((a, b) => b.count - a.count);
    }

    // Aggiorna il grafico a barre
    const updateBarChart = (labels, dataPoints) => {
        if (!barChartRef.current) return; // Controlla se il riferimento è disponibile

        if (barChartInstance.current) {
            barChartInstance.current.destroy();
        }

        const ctx = barChartRef.current.getContext("2d");
        barChartInstance.current = new Chart(ctx, {
            type: "bar",
            data: {
                labels,
                datasets: [
                    {
                        label: "Device Count",
                        data: dataPoints,
                        backgroundColor: token.colorPrimary,
                        borderRadius: 8,
                    },
                ],
            },
            options: {
                responsive: true,
                plugins: { legend: { display: false } },
            },
        });
    };

    // Aggiorna il grafico a torta
    const updatePieChart = (mobileCount, desktopCount) => {
        if (!pieChartRef.current) return; // Controlla se il riferimento è disponibile

        if (pieChartInstance.current) {
            pieChartInstance.current.destroy();
        }

        const ctx = pieChartRef.current.getContext("2d");
        pieChartInstance.current = new Chart(ctx, {
            type: "doughnut",
            data: {
                labels: ["Mobile Devices", "Desktop Devices"],
                datasets: [
                    {
                        data: [mobileCount, desktopCount],
                        backgroundColor: [token.colorPrimary, token.colorSecondary],
                    },
                ],
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        display: true,
                        position: "bottom", // Posiziona la legenda in basso
                    },
                },
            },
        });
    };

    // Primo useEffect per calcolare i dati
    useEffect(() => {
        const deviceStats = DeviceFilter(aggregateList);

        if (!aggregateList || aggregateList.length === 0) {
            setDevicesData({});
            return;
        }

        setDevicesData(deviceStats);
    }, [aggregateList]);

    // Secondo useEffect per aggiornare i grafici
    useEffect(() => {
        if (!devicesData || !devicesData.misc || devicesData.misc.length === 0) {
            return;
        }

        const groupedData = groupAndJoinData(devicesData.misc);
        const labels = groupedData.map((e) => e.name);
        const dataPoints = groupedData.map((e) => e.count);

        updateBarChart(labels, dataPoints);
        updatePieChart(devicesData.mobile, devicesData.desktop);
    }, [devicesData]);

    // Secondo useEffect: aggiorna in base alla scheda selezionata
    useEffect(() => {
        if (!devicesData?.misc || devicesData.misc.length === 0) {
            updateBarChart([], []);
            return;
        }

        let filteredList = [];

        if (selectedTab === "Mobile Devices") {
            filteredList = devicesData.misc.filter((el) =>
                mobileCategories.includes(el.name?.toLowerCase())
            );
        } else if (selectedTab === "Desktop Devices") {
            filteredList = devicesData.misc.filter((el) =>
                desktopCategories.includes(el.name?.toLowerCase())
            );
        } else {
            filteredList = devicesData.misc;
        }

        const groupedData = groupAndJoinData(filteredList);
        const labels = groupedData.map((e) => e.name);
        const dataPoints = groupedData.map((e) => e.count);

        updateBarChart(labels, dataPoints);
    }, [selectedTab, devicesData?.misc]);

    const handleTabChange = (tab) => {
        setSelectedTab(tab);
    };

    // Definizione delle colonne per la tabella
    const columns = [
        { title: "Device", dataIndex: "name", key: "name", width: "75%" },
        { title: "Count", dataIndex: "count", key: "count", width: "25%", sorter: (a, b) => a.count - b.count },
    ];

    // Mappa per le schede
    const tabMap = useMemo(
        () => ({
            "All Devices": devicesData.allDevice,
            "Mobile Devices": devicesData.mobile,
            "Desktop Devices": devicesData.desktop,
        }),
        [devicesData]
    );

    const tooltipTextMap = {
        "All Devices": "The operating system in use by the users or the app version",
        "Mobile Devices": "The mobile operating system in use by the users or the app version",
        "Desktop Devices": "The desktop operating system in use by the users or the app version",
    };

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });

    const handleTableChange = (paginationInfo) => {
        setPagination({
            ...pagination,
            current: paginationInfo.current,
            pageSize: paginationInfo.pageSize,
        });
    };

    return (
        <>
            <SummaryGrid>
                {Object.entries(tabMap).map(([key, value]) => (
                    <div key={key}>
                        <SummaryCard
                            label={key}
                            value={value !== undefined ? value : 0}
                            loading={loading}
                            active={key === selectedTab}
                            onClick={() => handleTabChange(key)}
                            toolTipText={tooltipTextMap[key]}
                        />
                    </div>
                ))}
            </SummaryGrid>

            {/* Grafico */}
            <Card
                title={
                    <Row justify="space-between" align="middle" style={{ width: "100%" }}>
                        <Col>
                            <span>Devices Analytics</span>
                        </Col>
                        <Col>
                            {(filterOptions.line !== "" ||
                                filterOptions.variant !== "" ||
                                filterOptions.glass !== "" ||
                                selectedCatalog?.id !== "all") && (
                                    <Button type="default" onClick={onResetFilters}>
                                        Clear filters
                                    </Button>
                                )}
                        </Col>
                    </Row>
                }
            >
                <Row gutter={[16, 16]} style={{ marginBottom: "2rem" }}>
                    {(!devicesData || !devicesData.misc || devicesData.misc.length === 0) ? (
                        <></>
                    ) : (
                        <>
                            <Col xs={24} md={15} style={{ display: "flex", justifyContent: "center" }}>
                                <div style={{ width: "100%", height: "400px" }}>
                                    <canvas
                                        ref={barChartRef}
                                        style={{
                                            marginTop: "10px",
                                            maxWidth: "100%",
                                            maxHeight: "100%",
                                            height: "100%",
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col xs={24} md={9} style={{ display: "flex", justifyContent: "center" }}>
                                <div style={{ width: "100%", height: "380px" }}>
                                    <canvas
                                        ref={pieChartRef}
                                        style={{
                                            marginTop: "10px",
                                            maxWidth: "100%",
                                            maxHeight: "100%",
                                            height: "100%",
                                        }}
                                    />
                                </div>
                            </Col>
                        </>
                    )}
                </Row>
                <Table
                    columns={columns}
                    size="small"
                    dataSource={devicesData.misc}
                    rowKey="name"
                    pagination={{
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        showSizeChanger: true,
                        pageSizeOptions: [10, 20, 50, 100, 200, 500],
                    }}
                    onChange={handleTableChange}
                    scroll={{ x: 1000 }}
                    style={{ width: "100%" }}
                />
            </Card>
        </>
    );
};

export default DeviceAnalytics;