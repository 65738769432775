import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import styles from "../../styles/ModelViewer/ModelContainer.module.css";
import { Spin } from "antd";
import CustomTooltip from "../common/CustomTooltip";

function CardModelView(props) {
    const model = useSelector((state) =>
        state.modelGlasses.find((model) => model.id === props.modelId)
    );

    const [cardStyle, setCardStyle] = useState(props.cardStyle);
    const cardRef = useRef(null);

    useEffect(() => {
        if (props.modelId === props.selected && cardRef.current) {
            cardRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
        }
    }, [props.modelId, props.selected]);

    const canvasRef = useRef(null);
    const [processedImage, setProcessedImage] = useState(null);

    const removeWhiteBackground = (image) => {
        const canvas = canvasRef.current;

        if (!canvas) {
            console.error("Canvas non trovato");
            return;
        }

        const ctx = canvas.getContext("2d", { willReadFrequently: true });

        if (!ctx) {
            console.error("Impossibile ottenere il contesto 2D del canvas");
            return;
        }

        canvas.width = image.width;
        canvas.height = image.height;

        ctx.drawImage(image, 0, 0);

        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;

        for (let i = 0; i < data.length; i += 4) {
            const red = data[i];
            const green = data[i + 1];
            const blue = data[i + 2];
            if (red > 240 && green > 240 && blue > 240) {
                data[i + 3] = 0;
            }
        }

        ctx.putImageData(imageData, 0, 0);

        setProcessedImage(canvas.toDataURL("image/png"));
    };

    useEffect(() => {
        let isMounted = true;

        const img = new Image();
        img.src = model?.poster3 ? model?.poster3 : model?.poster;
        img.crossOrigin = "anonymous";
        img.onload = () => {
            if (isMounted && canvasRef.current) {
                removeWhiteBackground(img);
            }
        };

        return () => {
            isMounted = false;
        };
    }, [model?.poster3, model?.poster]);

    return (
        <>
            {cardStyle === "circle" && (
                <CustomTooltip title={`Model: ${model?.coloreFrame || "Unknown"}`}>
                    <div
                        className={
                            props.modelId === props.selected
                                ? styles["itemDotSelected"]
                                : styles["itemDot"]
                        }
                        onClick={props.selectModel}
                        style={{
                            width: `${props.modelId === props.selected
                                ? props.project.items_with_selected
                                : props.project.items_with
                                }`,
                            height: `${props.modelId === props.selected
                                ? props.project.items_with_selected
                                : props.project.items_with
                                }`,
                            background: `linear-gradient(90deg, ${model?.esaColorFramePrimary} 49%, ${model?.esaColorLensesPrimary} 51%)`,
                            border: "1px solid black",
                        }}
                    ></div>
                </CustomTooltip>
            )}

            {cardStyle === "plane" && (
                <div
                    ref={cardRef}
                    className={
                        props.modelId === props.selected
                            ? styles["itemClassicSelected"]
                            : styles["itemClassic"]
                    }
                    onClick={props.selectModel}
                >
                    {processedImage ? (
                        <img
                            src={processedImage}
                            alt={model?.nomeOcchiale}
                            className={styles["image_plane"]}
                        />
                    ) : (
                        <Spin style={{ position: "absolute", top: "40%", left: "50%", transform: "translate(-50%, -50%)" }} />
                    )}
                    <p
                        style={{
                            fontSize: "10px",
                            textAlign: "center",
                            marginBottom: "5px",
                        }}
                    >
                        {model?.coloreFrame}
                    </p>
                    {props.modelId === props.selected && <p className={styles["selected-indicator"]}></p>}
                </div>
            )}

            {cardStyle === "card" && (
                <div
                    className={
                        props.modelId === props.selected
                            ? styles["itemSelected"]
                            : styles["item"]
                    }
                    onClick={props.selectModel}
                    style={{ backgroundImage: `url(${model?.poster3 ? model?.poster3 : model?.poster})` }}
                ></div>
            )}

            <canvas ref={canvasRef} style={{ display: "none" }} />
        </>
    );
}

export default CardModelView;