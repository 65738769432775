import React, { useEffect, useRef } from "react";
import { Spin, Card } from "antd";
import Chart from "chart.js/auto";
import { NumberAnimated } from "../../services/home";

const PieChart = ({ data, options, totalCount, isLoading }) => {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);

    useEffect(() => {
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }

        if (!isLoading && chartRef.current) {
            const modifiedData = {
                ...data,
                datasets: data.datasets.map((dataset) => ({
                    ...dataset,
                    data:
                        dataset.data.length === 1
                            ? [...dataset.data, 0]
                            : dataset.data,
                })),
            };

            const ctx = chartRef.current.getContext("2d");
            chartInstanceRef.current = new Chart(ctx, {
                type: "doughnut",
                data: modifiedData,
                options: {
                    ...options,
                    circumference: 180,
                    rotation: -90,
                },
            });
        }

        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
        };
    }, [data, options, isLoading]);

    return (
        <Card
            title="Number of users (last 7 days)"
            variant="bordered"
            style={{
                height: "500px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
            }}
        >
            <div
                style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                }}
            >
                {isLoading ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "400px",
                        }}
                    >
                        <Spin />
                    </div>
                ) : (
                    <>
                        <canvas
                            ref={chartRef}
                            style={{
                                margin: "0 auto",
                                maxWidth: "100%",
                            }}
                        />
                        <div>
                            <p
                                style={{
                                    margin: 0,
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                }}
                            >
                                Total Count
                            </p>
                            <div
                                style={{ fontSize: "24px", fontWeight: "bold" }}
                            >
                                <NumberAnimated n={totalCount} />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Card>
    );
};

export default PieChart;
