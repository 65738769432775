import React, { useState } from "react";
import { Form, Input, Button, Checkbox, message, theme } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone, LockOutlined, MailOutlined } from "@ant-design/icons";
import { doc, getDoc } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { db } from "../../data/base";

const LoginForm = ({ onSubmit, setIsForgotPassword }) => {

    const { token } = theme.useToken();

    const [form] = Form.useForm();
    const [password, setPassword] = useState("");
    const [rememberPassword, setRememberPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const auth = getAuth();

    // Gestisce il login
    const handleLogin = async (values) => {
        const { email, password } = values;
        setLoading(true);

        try {
            // Login con Firebase
            await signInWithEmailAndPassword(auth, email, password);

            // Recupera permessi utente da Firestore
            await checkUserPermissions(email);

            // Callback personalizzato se fornito
            if (onSubmit) {
                await onSubmit(values);
            }

            // Salva la password se necessario
            if (rememberPassword) {
                savePassword(email, password);
            }
        } catch (error) {
            console.error("Login error:", error);
            messageApi.error("Login failed. Please check your credentials.");
            form.resetFields(["password"]);
        } finally {
            setLoading(false);
        }
    };

    // Verifica permessi utente
    const checkUserPermissions = async (email) => {
        try {
            const lowerCaseEmail = email.toLowerCase();
            const userDocRef = doc(db, "Profile", lowerCaseEmail);
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists()) {
                const data = userDoc.data();
                const canPlaceOrders = data.canPlaceOrders ? "true" : "false";
                localStorage.setItem("canPlaceOrders", canPlaceOrders);
            } else {
                localStorage.setItem("canPlaceOrders", "false");
            }
        } catch (error) {
            console.error("Error fetching user permissions:", error);
            localStorage.setItem("canPlaceOrders", "false");
        }
    };

    // Salva la password in localStorage
    const savePassword = (email, password) => {
        const encoded = btoa(password); // Codifica base64 semplice
        localStorage.setItem(`password_${email}`, encoded);
    };

    // Recupera e compila la password salvata quando l'utente inserisce l'email
    const handleBlur = (e) => {
        const email = e.target.value;
        const savedPassword = localStorage.getItem(`password_${email}`);
        if (savedPassword) {
            setPassword(atob(savedPassword)); // Decodifica base64
            form.setFieldsValue({ password: atob(savedPassword) });
        }
    };

    return (
        <div>
            {contextHolder}
            <div style={{ textAlign: "center", marginBottom: "20px" }}>
                <h2 style={{ fontSize: "24px", fontWeight: "bold" }}>Login</h2>
            </div>
            <Form
                form={form}
                layout="vertical"
                onFinish={handleLogin}
                autoComplete="on"
                requiredMark={false}
            >
                <Form.Item
                    label={<span style={{ fontWeight: "bold" }}>Email address</span>}
                    name="email"
                    style={{ marginBottom: "16px" }}
                    rules={[
                        {
                            required: true,
                            message: "Email is required",
                        },
                        {
                            validator: (_, value) =>
                                !value || /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
                                    ? Promise.resolve()
                                    : Promise.reject(new Error("Please enter a valid email address")),
                        },
                    ]}
                >
                    <Input
                        prefix={<MailOutlined style={{ color: token.colorTextBlack }} />}
                        placeholder="Email address"
                        onBlur={handleBlur}
                    />
                </Form.Item>

                <Form.Item
                    label={<span style={{ fontWeight: "bold" }}>Password</span>}
                    name="password"
                    style={{ marginBottom: "16px" }}
                    initialValue={password}
                    rules={[
                        {
                            required: true,
                            message: "Password is required",
                        },
                    ]}
                >
                    <Input.Password
                        prefix={<LockOutlined style={{ color: token.colorTextBlack }} />}
                        placeholder="Password"
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>

                <Form.Item style={{ marginBottom: "16px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Checkbox
                            checked={rememberPassword}
                            onChange={(e) => setRememberPassword(e.target.checked)}
                        >
                            Remember me
                        </Checkbox>
                        <Button type="link" onClick={() => setIsForgotPassword(true)} style={{ padding: 0 }}>
                            Forgot password?
                        </Button>
                    </div>
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        style={{ width: "100%" }}
                    >
                        Login
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default LoginForm;