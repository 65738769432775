import React, { useState } from 'react';
import { Button, Row, Col, DatePicker, message } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import {
    collection,
    query,
    where,
    getDocs,
    doc,
    updateDoc,
    orderBy,
    limit,
    startAfter,
    deleteField
} from 'firebase/firestore';
import { db } from '../../data/base';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const { RangePicker } = DatePicker;

const DeleteUserMetrics = () => {
    const [loading, setLoading] = useState(false);
    const [dateRange, setDateRange] = useState([dayjs(), dayjs()]);
    const [totalOperations, setTotalOperations] = useState(0);
    const [completedOperations, setCompletedOperations] = useState(0);

    const rangePresets = [
        {
            label: 'Today',
            value: [dayjs().startOf('day'), dayjs().endOf('day')],
        },
        {
            label: 'Yesterday',
            value: [
                dayjs().subtract(1, 'day').startOf('day'),
                dayjs().subtract(1, 'day').endOf('day'),
            ],
        },
        {
            label: 'This Week',
            value: [dayjs().startOf('week'), dayjs().endOf('day')],
        },
        {
            label: 'Last Week',
            value: [
                dayjs().subtract(1, 'week').startOf('week'),
                dayjs().subtract(1, 'week').endOf('week'),
            ],
        },
        {
            label: 'This Month',
            value: [dayjs().startOf('month'), dayjs().endOf('day')],
        },
        {
            label: 'Last Month',
            value: [
                dayjs().subtract(1, 'month').startOf('month'),
                dayjs().subtract(1, 'month').endOf('month'),
            ],
        },
    ];

    // Utility function to add a delay
    const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const deleteUserMetrics = async (startDate, endDate) => {
        const sessionCollectionRef = collection(db, 'Sessione');
        let successCount = 0;
        let lastDoc = null;
        const batchSize = 100;

        try {
            const formattedStartDate = dayjs(startDate).format('YYYY/MM/DD HH:mm:ss');
            const formattedEndDate = dayjs(endDate).format('YYYY/MM/DD HH:mm:ss');

            const countQuery = query(
                sessionCollectionRef,
                where('data_inizio_sessione', '>=', formattedStartDate),
                where('data_inizio_sessione', '<=', formattedEndDate)
            );

            const countSnapshot = await getDocs(countQuery);
            const totalDocsCount = countSnapshot.docs.length;
            setTotalOperations(totalDocsCount);

            if (totalDocsCount === 0) {
                const testSnapshot = await getDocs(query(sessionCollectionRef, limit(5)));
                testSnapshot.forEach(docSnap => {
                    if (docSnap.data().hasOwnProperty('data_inizio_sessione')) {
                        if (docSnap.data().data_inizio_sessione.toDate) {
                        }
                    }
                });

                return 0;
            }

            while (true) {
                let q = query(
                    sessionCollectionRef,
                    where('data_inizio_sessione', '>=', formattedStartDate),
                    where('data_inizio_sessione', '<=', formattedEndDate),
                    orderBy('data_inizio_sessione'),
                    limit(batchSize)
                );

                if (lastDoc) {
                    q = query(q, startAfter(lastDoc));
                }

                const snapshot = await getDocs(q);
                const totalDocs = snapshot.docs.length;

                if (totalDocs === 0) {
                    break;
                }

                for (let i = 0; i < totalDocs; i++) {
                    const docSnap = snapshot.docs[i];
                    const docRef = doc(db, 'Sessione', docSnap.id);
                    const docData = docSnap.data();

                    if (!docData.data_inizio_sessione) {
                        continue;
                    }

                    let dataInizioSessione;
                    if (docData.data_inizio_sessione.toDate) {
                        dataInizioSessione = docData.data_inizio_sessione.toDate();
                    } else if (typeof docData.data_inizio_sessione === 'string') {
                        dataInizioSessione = dayjs(docData.data_inizio_sessione, 'YYYY/MM/DD HH:mm:ss').toDate();
                    } else {
                        continue;
                    }

                    if (dataInizioSessione > endDate) {
                        break;
                    }

                    if (!docData.hasOwnProperty('user_metrics')) {
                        continue;
                    }

                    try {
                        await updateDoc(docRef, {
                            user_metrics: deleteField()
                        });
                        successCount++;
                        setCompletedOperations(successCount);
                    } catch (updateError) {
                        console.error(`Errore durante l'aggiornamento del documento ${docSnap.id}:`, updateError);
                    }

                    await sleep(200);
                }

                lastDoc = snapshot.docs[snapshot.docs.length - 1];
            }

            return successCount;
        } catch (error) {
            console.error('Errore durante la cancellazione:', error);
            throw error;
        }
    };

    const handleClickMetrics = async () => {
        if (!dateRange[0] || !dateRange[1]) {
            message.error('Please select a date range');
            return;
        }

        try {
            setLoading(true);
            setCompletedOperations(0);

            const startDate = dayjs(dateRange[0]).tz('Europe/Rome').startOf('day').toDate();
            const endDate = dayjs(dateRange[1]).tz('Europe/Rome').endOf('day').toDate();

            const successCount = await deleteUserMetrics(startDate, endDate);
            message.success(`Successfully deleted user_metrics from ${successCount} documents`);
        } catch (error) {
            console.error('Errore durante l\'esecuzione delle operazioni:', error);
            message.error('Error during user_metrics deletion');
        }

        setLoading(false);
    };

    const buttonProgress = totalOperations > 0
        ? Math.round((completedOperations / totalOperations) * 100)
        : 0;

    return (
        <Row gutter={16} style={{ padding: 10 }}>
            <Col span={8}><strong>Delete User Metrics</strong></Col>
            <Col span={8}>
                <RangePicker
                    style={{ width: '100%' }}
                    value={dateRange}
                    onChange={setDateRange}
                    format="DD MMM, YYYY"
                    presets={rangePresets}
                />
            </Col>
            <Col span={8}>
                <Button
                    onClick={handleClickMetrics}
                    disabled={loading}
                    type="primary"
                >
                    {loading ? (
                        <>
                            <SyncOutlined spin style={{ marginRight: 8 }} />
                            Deleting... {buttonProgress}%
                        </>
                    ) : (
                        'Execute'
                    )}
                </Button>
            </Col>
        </Row>
    );
};

export default DeleteUserMetrics;