import React, { useEffect, useState } from "react";
import { Button, Input, Form, Modal, message, Alert, Typography } from "antd";
import { auth } from "../../data/base";
import {
    PhoneAuthProvider,
    RecaptchaVerifier,
    multiFactor,
    sendEmailVerification,
} from "firebase/auth";

const { Title } = Typography;

const MFASetup = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [isVerifying, setIsVerifying] = useState(false);
    const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [isCheckingEmail, setIsCheckingEmail] = useState(true);

    useEffect(() => {
        console.log("ReCAPTCHA Verifier Config:", auth._agentRecaptchaConfig);
        console.log("Firebase Auth instance:", auth);
        console.log("Auth config:", auth.config);
        console.log("Current user:", auth.currentUser);
        auth.appVerificationDisabledForTesting = true;
    }, []);

    // Verifica se l'email dell'utente è verificata
    useEffect(() => {
        const checkEmailVerification = async () => {
            try {
                const user = auth.currentUser;
                if (user) {
                    await user.reload(); // Aggiorna lo stato dell'utente
                    setIsEmailVerified(user.emailVerified); // Controlla lo stato della verifica
                }
            } catch (error) {
                console.error("Error checking email verification:", error);
                message.error("Failed to check email verification status.");
            } finally {
                setIsCheckingEmail(false);
            }
        };

        checkEmailVerification();
    }, []);

    // Invia l'email di verifica
    const sendVerificationEmail = async () => {
        auth.appVerificationDisabledForTesting = true;
        try {
            const user = auth.currentUser;
            if (user) {
                await sendEmailVerification(user);
                message.success("Verification email sent. Please check your inbox.");
            } else {
                message.error("User not authenticated. Please log in.");
            }
        } catch (error) {
            console.error("Error sending email verification:", error);
            message.error("Failed to send verification email: " + error.message);
        }
    };

    // Validazione del numero di telefono
    const isPhoneNumberValid = (number) => {
        const phoneRegex = /^\+[1-9]\d{1,14}$/; // Formato E.164
        return phoneRegex.test(number);
    };

    // Inizializza il reCAPTCHA v3
    const initializeRecaptcha = () => {
        try {
            const container = document.getElementById("recaptcha-container");
            if (!container) {
                throw new Error("reCAPTCHA container not found in DOM.");
            }

            console.log("Initializing reCAPTCHA...");
            console.log("ReCAPTCHA Verifier Config:", auth._agentRecaptchaConfig); // Log aggiunto qui

            const verifier = new RecaptchaVerifier(
                "recaptcha-container",
                {
                    size: "invisible",
                    callback: () => {
                        message.success("reCAPTCHA verified.");
                    },
                    "expired-callback": () => {
                        message.error("reCAPTCHA expired. Please try again.");
                    },
                },
                auth
            );

            console.log("reCAPTCHA Verifier created:", verifier);
            verifier.render().then((widgetId) => {
                console.log("reCAPTCHA rendered successfully. Widget ID:", widgetId);
            });
            setRecaptchaVerifier(verifier);
        } catch (error) {
            console.error("Error initializing reCAPTCHA v3:", error);
            message.error("Failed to initialize reCAPTCHA.");
        }
    };

    // Invia il codice di verifica al numero di telefono
    const sendVerificationCode = async () => {
        try {
            console.log("Current user before sending code:", auth.currentUser); // Log utente corrente
            console.log("ReCAPTCHA Verifier Config:", auth._agentRecaptchaConfig); // Configurazione reCAPTCHA

            if (!phoneNumber) {
                console.error("Phone number is empty.");
                message.error("Phone number is required.");
                return;
            }

            if (!isPhoneNumberValid(phoneNumber)) {
                console.error("Invalid phone number format:", phoneNumber);
                message.error(
                    "Please enter a valid phone number in international format (e.g., +1234567890)."
                );
                return;
            }

            // if (!recaptchaVerifier) {
            //     console.log("reCAPTCHA not initialized. Initializing now...");
            //     initializeRecaptcha();
            // }

            console.log("Sending verification code to:", phoneNumber);
            const phoneAuthProvider = new PhoneAuthProvider(auth);
            const verificationId = await phoneAuthProvider.verifyPhoneNumber(
                phoneNumber,
                recaptchaVerifier
            );

            console.log("Verification ID received:", verificationId);
            window.verificationId = verificationId;
            message.success("Verification code sent.");
        } catch (error) {
            console.error("Error sending verification code:", error);
            message.error("Error sending verification code: " + error.message);
        }
    };

    // Verifica il codice di verifica e abilita MFA
    const verifyCode = async () => {
        try {
            setIsVerifying(true);

            const verificationId = window.verificationId; // Recupera l'ID di verifica
            if (!verificationId || !verificationCode) {
                throw new Error("Invalid verification code or ID.");
            }

            const credential = PhoneAuthProvider.credential(verificationId, verificationCode);
            const user = auth.currentUser;

            if (!user) {
                throw new Error("User not authenticated. Please log in.");
            }

            await multiFactor(user).enroll(credential, "Phone");
            message.success("Multi-Factor Authentication (MFA) enabled successfully!");

            // Resetta lo stato
            setIsModalVisible(false);
            setPhoneNumber("");
            setVerificationCode("");
        } catch (error) {
            console.error("Error enabling MFA:", error);
            message.error("Error enabling MFA: " + error.message);
        } finally {
            setIsVerifying(false);
        }
    };

    // Render condizionale mentre controlliamo l'email
    if (isCheckingEmail) {
        return <p>Loading...</p>;
    }

    return (
        <div>
            {/* <Title level={4}>Multi-Factor Authentication</Title>
            {!isEmailVerified && (
                <Alert
                    style={{ marginBottom: '16px' }}
                    message="Email not verified"
                    description="Please verify your email to enable multi-factor authentication."
                    type="warning"
                    showIcon
                    action={
                        <Button size="small" type="primary" onClick={sendVerificationEmail}>
                            Send Verification Email
                        </Button>
                    }
                />
            )}

            <Button
                type="primary"
                onClick={() => setIsModalVisible(true)}
                disabled={!isEmailVerified}
            >
                Enable Multi-Factor Authentication
            </Button> */}

            {/* Modale */}
            <Modal
                title="Enable Multi-Factor Authentication"
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
            >
                <Form layout="vertical">
                    {/* Numero di telefono */}
                    <Form.Item label="Phone Number">
                        <Input
                            placeholder="Enter phone number"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                    </Form.Item>

                    {/* Contenitore reCAPTCHA */}
                    <div id="recaptcha-container" style={{ display: "none" }} />

                    {/* Invia il codice di verifica */}
                    <Button type="primary" block onClick={sendVerificationCode}>
                        Send Verification Code
                    </Button>

                    {/* Codice di verifica */}
                    <Form.Item
                        label="Verification Code"
                        style={{ marginTop: 20, marginBottom: 20 }}
                    >
                        <Input
                            placeholder="Enter verification code"
                            value={verificationCode}
                            onChange={(e) => setVerificationCode(e.target.value)}
                        />
                    </Form.Item>

                    {/* Verifica e abilita MFA */}
                    <Button
                        type="primary"
                        block
                        onClick={verifyCode}
                        loading={isVerifying}
                        disabled={!verificationCode}
                    >
                        Verify and Enable MFA
                    </Button>
                </Form>
            </Modal>
        </div>
    );
};

export default MFASetup;