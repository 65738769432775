import React, { useEffect, useState } from 'react';
import { Button, Typography, Select, DatePicker, Tag, Modal } from 'antd';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Text } = Typography;

function FiltersMobile({ dateRange, handleOpenDownloadReportModal, downloadLoading, onChangeDate, onClearFilter, selectedGateway, handleGatewayChange, catalogueRefsList }) {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => setIsModalOpen(true);
    const handleCancel = () => setIsModalOpen(false);

    const defaultRange = [
        dayjs().subtract(7, "day").startOf("day"),
        dayjs().endOf("day"),
    ];

    const clearDateRange = () => {
        onChangeDate(defaultRange);
    };

    const rangePresets = [
        { label: "Today", value: [dayjs().startOf("day"), dayjs().endOf("day")] },
        { label: "Yesterday", value: [dayjs().subtract(1, "day").startOf("day"), dayjs().subtract(1, "day").endOf("day")] },
        { label: "Last Week", value: [dayjs().subtract(1, "week").startOf("week"), dayjs().subtract(1, "week").endOf("week")] },
        { label: "This Week", value: [dayjs().startOf("week"), dayjs().endOf("day")] },
        { label: "Last Month", value: [dayjs().subtract(1, "month").startOf("month"), dayjs().subtract(1, "month").endOf("month")] },
        { label: "This Month", value: [dayjs().startOf("month"), dayjs().endOf("day")] },
        { label: "Last 3 Months", value: [dayjs().subtract(3, "month").startOf("day"), dayjs().endOf("day")] },
        { label: "Last 6 Months", value: [dayjs().subtract(6, "month").startOf("day"), dayjs().endOf("day")] },
        { label: "This Year", value: [dayjs().startOf("year"), dayjs().endOf("day")] },
    ];

    const GatewayTag = ({ gateway, selectedGateway, handleGatewayChange }) => (
        <Tag
            closable
            onClose={() => handleGatewayChange(selectedGateway.filter(g => g !== gateway.value))}
            style={{ cursor: "pointer", whiteSpace: "normal", wordBreak: "break-word" }}
        >
            Gateway: {gateway.label}
        </Tag>
    );

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "10px", width: "100%" }}>
            {/* Top Buttons */}
            <div style={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: "space-between" }}>
                <Button
                    type="primary"
                    onClick={handleOpenDownloadReportModal}
                    disabled={downloadLoading}
                    style={{ flex: "1 1 auto", minWidth: "120px" }}
                >
                    Download Report
                </Button>
                <Button
                    type="default"
                    onClick={showModal}
                    style={{ flex: "1 1 auto", minWidth: "120px" }}
                >
                    Open Filters
                </Button>
            </div>

            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                {/* Tag per Date Range */}
                {dateRange &&
                    !(dateRange[0].isSame(defaultRange[0], "day") && dateRange[1].isSame(defaultRange[1], "day")) && (
                        <Tag
                            closable
                            onClose={clearDateRange}
                            style={{ cursor: "pointer" }}
                        >
                            Date Range: {dateRange[0].format("DD/MM/YYYY")} - {dateRange[1].format("DD/MM/YYYY")}
                        </Tag>
                    )}

                {/* Tag per i Gateway selezionati */}
                {selectedGateway.length > 0 &&
                    selectedGateway.map((gatewayValue) => {
                        const gateway = catalogueRefsList.find((g) => g.value === gatewayValue);
                        return (
                            gateway && (
                                <GatewayTag
                                    key={gatewayValue}
                                    gateway={gateway}
                                    selectedGateway={selectedGateway}
                                    handleGatewayChange={handleGatewayChange}
                                />
                            )
                        );
                    })}
            </div>

            {/* Modal */}
            <Modal
                title="Filters"
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null}
                centered
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                        width: "100%",
                    }}
                >
                    {/* Range Picker */}
                    <div>
                        <Text>Select Date Range</Text>
                        <RangePicker
                            value={dateRange}
                            presets={rangePresets}
                            onChange={onChangeDate}
                            format="DD/MM/YYYY"
                            placement="bottomLeft"
                            style={{
                                width: "100%",
                                marginTop: "8px",
                            }}
                        />
                    </div>

                    <div style={{ flex: "1 1 auto" }}>
                        <Text>Select Gateways</Text>
                        <div style={{ position: "relative", width: "100%" }}>
                            <Select
                                mode="multiple"
                                value={selectedGateway}
                                onChange={handleGatewayChange}
                                style={{ width: "100%" }}
                                placeholder="Select Gateways"
                                allowClear={false}
                                showSearch={false}
                            >
                                {catalogueRefsList.map((gateway) => (
                                    <Option key={gateway.value} value={gateway.value}>
                                        {gateway.label}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "20px",
                        gap: "10px",
                    }}
                >
                    <Button
                        type="default"
                        onClick={() => {
                            onClearFilter();
                            setIsModalOpen(false);
                        }}
                    >
                        Clear Filters
                    </Button>
                    <Button type="primary" onClick={() => setIsModalOpen(false)}>
                        Apply
                    </Button>
                </div>
            </Modal>
        </div>
    )
}

export default FiltersMobile;