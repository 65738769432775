import {
  GET_LIST,
  GET_LIST_SUCCESS,
  ADD_NEW_ITEM,
  REMOVE_ITEM,
  MARK_ALL_ITEMS,
  SELECTED_ITEM,
} from "../actionType";
import todoData from "../../data/todo/todos.json";

const INIT_STATE = {
  allTodoItems: [],
  todoItems: [],
  loading: false,
};

const todoReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LIST: {
      const allTodoItems = todoData.data;
      return {
        ...state,
        loading: false,
        allTodoItems,
        todoItems: allTodoItems,
      };
    }

    case GET_LIST_SUCCESS: {
      const liveItems = action.payload.filter(
        (item) => item.status !== "deleted"
      );
      return {
        ...state,
        loading: true,
        allTodoItems: liveItems,
        todoItems: action.payload,
      };
    }

    case ADD_NEW_ITEM: {
      const newItem = {
        id: state.allTodoItems.length + 1,
        title: action.payload.task,
      };
      const updatedItems = [...state.allTodoItems, newItem];
      return {
        ...state,
        loading: true,
        allTodoItems: updatedItems,
        todoItems: updatedItems,
      };
    }

    case SELECTED_ITEM: {
      const updatedItems = state.allTodoItems.map((item) =>
        item.id === action.payload.itemId
          ? { ...item, status: action.payload.status }
          : item
      );
      return {
        ...state,
        loading: true,
        allTodoItems: updatedItems,
        todoItems: updatedItems,
      };
    }

    case MARK_ALL_ITEMS: {
      const updatedItems = state.allTodoItems.map((item) => ({
        ...item,
        status: action.payload ? "pending" : "completed",
      }));
      return {
        ...state,
        loading: true,
        allTodoItems: updatedItems,
        todoItems: updatedItems,
      };
    }

    case REMOVE_ITEM: {
      const updatedItems = state.allTodoItems.map((item) =>
        item.id === action.payload ? { ...item, status: "deleted" } : item
      );
      return {
        ...state,
        loading: true,
        allTodoItems: updatedItems,
        todoItems: updatedItems,
      };
    }

    default:
      return state;
  }
};

export default todoReducer;
