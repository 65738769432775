import React from "react";
import { Table, Spin, Card, Row, Col, Button } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import CustomTooltip from "../common/CustomTooltip";

const EventsAnalytics = ({ data, loading, clearFiltersVisible, onResetFilters }) => {
    const formatEventName = (eventName) => {
        return eventName
            .replace(/_/g, " ")
            .replace(/\b\w/g, (char) => char.toUpperCase());
    };

    const processAnalyticsData = (dataCollection) => {
        if (!dataCollection || dataCollection.length === 0) return [];

        const combinedAnalytics = dataCollection.flatMap((item) =>
            (item.events_analytics_list || []).map((event) => ({
                ...event,
                device_id: item.device_id,
            }))
        );

        const eventMap = combinedAnalytics.reduce((acc, event) => {
            Object.entries(event).forEach(([key, value]) => {
                if (
                    key !== "device_id" &&
                    key !== "brand_page_scroll_perc" &&
                    key !== "acceptance_terms" &&
                    (typeof value === "number" ? value > 0 : value === true)
                ) {
                    if (!acc[key]) {
                        acc[key] = {
                            eventName: key,
                            eventCount: 0,
                            uniqueUsers: new Set(),
                        };
                    }
                    acc[key].eventCount += value === true ? 1 : value;
                    acc[key].uniqueUsers.add(event.device_id);
                }
            });
            return acc;
        }, {});

        return Object.values(eventMap)
            .map((event, index) => {
                const { eventName, eventCount, uniqueUsers } = event;
                const totalUsers = uniqueUsers.size;
                const avgEventPerUser =
                    totalUsers > 0 ? (eventCount / totalUsers).toFixed(2) : 0;

                return {
                    key: `${eventName}-${index}`,
                    eventName: formatEventName(eventName),
                    eventCount,
                    totalUsers,
                    avgEventPerUser,
                };
            })
            .sort((a, b) => b.eventCount - a.eventCount);
    };

    const tableData = processAnalyticsData(data);

    const columns = [
        {
            title: (
                <span>
                    Events{" "}
                    <CustomTooltip title="The name of the event being tracked.">
                        <InfoCircleOutlined />
                    </CustomTooltip>
                </span>
            ),
            dataIndex: "eventName",
            key: "eventName",
            sorter: (a, b) => a.eventName.localeCompare(b.eventName),
            showSorterTooltip: false,
            width: "25%",
        },
        {
            title: (
                <span>
                    Count{" "}
                    <CustomTooltip title="The total number of times the event was triggered.">
                        <InfoCircleOutlined />
                    </CustomTooltip>
                </span>
            ),
            dataIndex: "eventCount",
            key: "eventCount",
            sorter: (a, b) => a.eventCount - b.eventCount,
            defaultSortOrder: "descend", // ✅ Ordinamento predefinito decrescente
            showSorterTooltip: false,
            width: "25%",
        },
        {
            title: (
                <span>
                    Tot Users{" "}
                    <CustomTooltip title="The number of unique devices that triggered the event.">
                        <InfoCircleOutlined />
                    </CustomTooltip>
                </span>
            ),
            dataIndex: "totalUsers",
            key: "totalUsers",
            sorter: (a, b) => a.totalUsers - b.totalUsers,
            showSorterTooltip: false,
            width: "25%",
        },
        {
            title: (
                <span>
                    Avg E/U{" "}
                    <CustomTooltip title="The average number of times each user triggered the event.">
                        <InfoCircleOutlined />
                    </CustomTooltip>
                </span>
            ),
            dataIndex: "avgEventPerUser",
            key: "avgEventPerUser",
            sorter: (a, b) => a.avgEventPerUser - b.avgEventPerUser,
            showSorterTooltip: false,
            width: "25%",
        },
    ];

    return (
        <div style={{ width: "100%", marginTop: 16 }}>
            <Card
                title={
                    <Row justify="space-between" align="middle" style={{ width: "100%" }}>
                        <Col>
                            <span>Events Analytics</span>
                        </Col>
                        <Col>
                            {clearFiltersVisible === true && (
                                <Button
                                    type="default"
                                    onClick={onResetFilters}
                                >
                                    Clear filters
                                </Button>
                            )}
                        </Col>
                    </Row>
                }
            >
                <Spin spinning={loading}>
                    <Table
                        dataSource={tableData}
                        rowKey="key"
                        columns={columns}
                        pagination={{
                            defaultPageSize: 10,
                            showSizeChanger: true,
                            pageSizeOptions: [
                                "10",
                                "20",
                                "50",
                                "100",
                                "200",
                                "500",
                                "1000",
                            ],
                        }}
                        size="small"
                        style={{ marginTop: "16px" }}
                        scroll={{ x: "max-content" }}
                    />
                </Spin>
            </Card>
        </div>
    );
};

export default EventsAnalytics;