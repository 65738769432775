import React, { useEffect, useState, useMemo } from "react";
import { Layout, Menu, message, Badge, Modal } from "antd";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import {
    CheckCircleFilled,
    HddOutlined,
    HomeOutlined,
    LineChartOutlined,
    SettingOutlined,
    ShoppingCartOutlined,
    UngroupOutlined,
    LogoutOutlined,
} from "@ant-design/icons";
import { signOut } from "firebase/auth";
import { auth } from "../data/base";
import logo from "../assets/images/logo.png";
import styles from "../styles/Dashboard/Dashboard.module.css";
import { theme } from "antd";
import {
    doc,
    collection,
    query,
    where,
    orderBy,
    getDocs,
    getDoc,
} from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import {
    setSidebarState,
    setSidebarType,
    setSidebarBroken,
} from "../redux/sidebar";
import { useMediaQuery } from "react-responsive";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import { db } from "../data/base";
import { secureGetItem } from "../data/utils";

const { Header, Sider, Content } = Layout;

const DashboardLayout = () => {
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const { token } = theme.useToken();
    const [selectedTitle, setSelectedTitle] = useState("Home");
    const [currentMenuItem, setCurrentMenuItem] = useState("home");
    const [mainMenuKey, setMainMenuKey] = useState(null);
    const [bottomMenuKey, setBottomMenuKey] = useState(null);
    const [notifCount, setNotifCount] = useState(0);
    const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);
    const [openKeys, setOpenKeys] = useState([]);

    const navigate = useNavigate();
    const location = useLocation();

    const dispatch = useDispatch();
    const collapsed = useSelector((state) => state.sidebar?.collapsed);
    const broken = useSelector((state) => state.sidebar?.broken);

    // Ottieni role ed email da Redux o da secureGetItem
    const userPermissions = useSelector((state) => state.auth?.permissions);
    const role = userPermissions?.role || secureGetItem("role");
    const email = userPermissions?.email || secureGetItem("email");

    // Stato per controllare la visibilità di analytics-gateways
    const [analyticsGatewaysVisible, setAnalyticsGatewaysVisible] = useState(false);

    // Reindirizza al login se mancano i dati sensibili
    useEffect(() => {
        if (!email || !role) {
            message.error("Sessione scaduta o dati mancanti. Effettua nuovamente l'accesso.");
            navigate("/login");
        }
    }, [email, role, navigate]);

    const handleLogout = async () => {
        const logoutMessageKey = "logoutMessage";
        try {
            await signOut(auth);
            message.success({
                content: "Logout successful",
                key: logoutMessageKey,
                duration: 2,
            });
            navigate("/login");
        } catch (error) {
            message.error({
                content: "Error logging out",
                key: logoutMessageKey,
                duration: 2,
            });
            console.error(error);
        }
    };

    useEffect(() => {
        const checkAnalyticsAccess = async () => {
            if (!email) return;
            try {
                // Recupera il documento Profile usando l'email
                const profileRef = doc(db, "Profile", email);
                const profileSnap = await getDoc(profileRef);

                if (!profileSnap.exists()) return;

                const profileData = profileSnap.data();
                const catalogsList = profileData.catalogsList;

                if (!Array.isArray(catalogsList) || catalogsList.length === 0) return;

                // Query sui brand per trovare quelli con isGatewayCatalog: true
                const brandQuery = query(
                    collection(db, "Brand"),
                    where("__name__", "in", catalogsList) // Filtra i cataloghi presenti in catalogsList
                );

                const brandSnapshot = await getDocs(brandQuery);

                let found = false;
                brandSnapshot.forEach(doc => {
                    const brandData = doc.data();
                    if (brandData.isGatewayCatalog === true) {
                        found = true;
                    }
                });

                setAnalyticsGatewaysVisible(found);
            } catch (error) {
                console.error("Errore nel controllo di Analytics Gateway:", error);
            }
        };

        checkAnalyticsAccess();
    }, [email]);

    // Definizione dei menu in base al ruolo e alla presenza del servizio "ARShades Gateway"
    const menuItems = useMemo(() => {
        const items = [
            {
                key: "home",
                icon: <HomeOutlined style={{ fontSize: "20px" }} />,
                label: "Home",
            },
            {
                key: "ar3dviewer",
                icon: <UngroupOutlined style={{ fontSize: "20px" }} />,
                label: "3D AR Viewer",
            },
            {
                key: "framevalidation",
                icon: (
                    <Badge
                        count={notifCount}
                        overflowCount={10}
                        size="small"
                        offset={collapsed ? [5, 10] : [120, -2]}
                        style={{
                            zIndex: 999999,
                            fontSize: "9px",
                            height: "12px",
                            width: "12px",
                            lineHeight: "12px",
                        }}
                    >
                        <CheckCircleFilled style={{ fontSize: "20px" }} />
                    </Badge>
                ),
                label: "Frame Validation",
            },
        ];

        // Aggiungere Analytics e Data Consumption solo per ruoli "Admin" e "Cliente"
        if (role === "Admin" || role === "Cliente") {
            if (analyticsGatewaysVisible) {
                // Se sono presenti sia VTOs che Gateways, aggiungi il menu con sotto-menu
                items.push({
                    key: "analytics",
                    icon: <LineChartOutlined style={{ fontSize: "20px" }} />,
                    label: "Analytics",
                    children: [
                        { key: "analytics-vtos", label: "VTOs" },
                        { key: "analytics-gateways", label: "Gateways" },
                    ],
                });
            } else {
                // Se c'è solo VTOs, aggiungi direttamente il menu standalone
                items.push({
                    key: "analytics-vtos",
                    icon: <LineChartOutlined style={{ fontSize: "20px" }} />,
                    label: "Analytics VTO",
                });
            }

            items.push({
                key: "dataconsumption",
                icon: <SpeedOutlinedIcon style={{ fontSize: "22px" }} />,
                label: "Data Consumption",
            });
        }

        // Se l'utente è Admin, aggiungiamo le pagine Admin e Order Page
        if (role === "Admin") {
            items.push(
                {
                    key: "admin",
                    icon: <HddOutlined style={{ fontSize: "20px" }} />,
                    label: "Admin",
                },
                {
                    key: "orderpage",
                    icon: <ShoppingCartOutlined style={{ fontSize: "20px" }} />,
                    label: "Order Page",
                }
            );
        }

        return items;
    }, [notifCount, collapsed, role, analyticsGatewaysVisible]);

    const bottomMenuItems = useMemo(
        () => [
            {
                key: "profile",
                icon: <SettingOutlined style={{ fontSize: "20px" }} />,
                label: "Profile",
            },
            {
                key: "logout",
                icon: <LogoutOutlined style={{ fontSize: "20px" }} />,
                label: "Logout",
                onClick: () => setIsLogoutModalVisible(true),
            },
        ],
        []
    );

    const handleMenuClick = ({ key }) => {
        if (isMobile) {
            dispatch(setSidebarState(true));
        }

        const findParentAndChild = (items, key) => {
            for (const item of items) {
                if (item.key === key) return { parentKey: item.key, childKey: null };
                if (item.children) {
                    const childItem = item.children.find((child) => child.key === key);
                    if (childItem) return { parentKey: item.key, childKey: childItem.key };
                }
            }
            return null;
        };

        const selectedItem =
            findParentAndChild(menuItems, key) ||
            bottomMenuItems.find((item) => item.key === key);

        if (selectedItem) {
            if (selectedItem.onClick) {
                selectedItem.onClick();
                return;
            }
            setMainMenuKey(selectedItem.parentKey || key);
            setCurrentMenuItem(
                selectedItem.childKey || selectedItem.parentKey || key
            );
            setOpenKeys([selectedItem.parentKey || key]);

            const path = selectedItem.path || `/${key}`;
            navigate(path);
        }
    };

    useEffect(() => {
        const path = location.pathname === "/" ? "/home" : location.pathname;

        const findParentAndChild = (items, path) => {
            for (const item of items) {
                if (`/${item.key}` === path) return { parentKey: item, childKey: null };
                if (item.children) {
                    const childItem = item.children.find(
                        (child) => `/${child.key}` === path
                    );
                    if (childItem) return { parentKey: item, childKey: childItem };
                }
            }
            return null;
        };

        const selectedItem = findParentAndChild(menuItems, path);

        if (selectedItem) {
            setMainMenuKey(selectedItem.parentKey.key);
            setCurrentMenuItem(
                selectedItem.childKey?.key || selectedItem.parentKey.key
            );
            setSelectedTitle(
                selectedItem.childKey
                    ? selectedItem.childKey.label
                    : selectedItem.parentKey.label
            );
        }

        const selectedBottomItem = bottomMenuItems.find(
            (item) => `/${item.key}` === path
        );

        if (selectedBottomItem) {
            setSelectedTitle(selectedBottomItem.label);
            setBottomMenuKey(selectedBottomItem.key);
            setMainMenuKey(null);
        }

        if (location.pathname === "/") {
            navigate("/home");
        }

        localStorage.setItem("lastVisitedPage", location.pathname);
    }, [location, menuItems, bottomMenuItems, navigate]);

    const fetchNotifications = async () => {
        if (!email) return;

        try {
            const accRef = doc(db, "Profile", email);
            const notifsQuery = query(
                collection(db, "Lista_Notification"),
                where("accRef", "==", accRef),
                where("status", "==", "unread"),
                orderBy("sendTime", "desc")
            );
            const notifsSnapshot = await getDocs(notifsQuery);
            setNotifCount(notifsSnapshot.size);
        } catch (error) {
            console.error("Error fetching notifications:", error);
        }
    };

    useEffect(() => {
        fetchNotifications();
    }, []);

    // Determina se è un dispositivo mobile
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

    const handleMouseEnter = () => {
        if (!isMobile) {
            dispatch(setSidebarState(false));
        }
    };

    const handleMouseLeave = () => {
        if (!isMobile) {
            dispatch(setSidebarState(true));
        }
    };

    const isFrameValidationRoute = location.pathname === "/framevalidation";
    const isAnalyticsRoute = location.pathname === "/analytics";
    const isGatewaysRoute = location.pathname === "/gateways";

    return (
        <Layout style={{ height: "100vh", width: "100vw", overflow: "hidden" }}>
            <Sider
                trigger={null}
                collapsible
                collapsed={collapsed}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className={
                    collapsed ? styles["sidebar-collapsed"] : styles["sidebar-expanded"]
                }
                style={{
                    height: "100vh",
                    transition: "all 0.4s ease",
                    zIndex: 1001,
                    position: "fixed",
                    left: 0,
                    top: 0,
                    bottom: 0,
                }}
                breakpoint="md"
                collapsedWidth={isMobile ? 0 : 80}
                onBreakpoint={(broken) => {
                    dispatch(setSidebarBroken(broken));
                }}
                onCollapse={(collapsed, type) => {
                    dispatch(setSidebarType(type));
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "100%",
                    }}
                >
                    <div>
                        <div
                            style={{
                                padding: "16px",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row",
                                gap: "8px",
                            }}
                        >
                            <img
                                src={logo}
                                alt="Logo"
                                style={{
                                    width: "40px",
                                    height: "40px",
                                    marginLeft: "8px",
                                }}
                            />
                            {!collapsed && (
                                <span
                                    style={{
                                        color: "white",
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    AR Studio
                                </span>
                            )}
                        </div>
                        {/* Menu principale */}
                        <Menu
                            theme="dark"
                            mode="inline"
                            selectedKeys={[currentMenuItem]}
                            openKeys={collapsed ? [] : openKeys}
                            onOpenChange={(keys) => setOpenKeys(keys)}
                            onClick={handleMenuClick}
                            items={menuItems}
                        />
                    </div>
                    <div style={{ marginBottom: "64px" }}>
                        <Menu
                            theme="dark"
                            mode="inline"
                            selectedKeys={[bottomMenuKey]}
                            onClick={handleMenuClick}
                            items={bottomMenuItems}
                        />
                    </div>
                </div>
            </Sider>
            <Layout>
                {!isAnalyticsRoute && !isGatewaysRoute && !isFrameValidationRoute && (
                    <Header
                        style={{
                            background: token.colorBgBase,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "0 16px",
                            position: "fixed",
                            top: 0,
                            left: broken ? 0 : collapsed ? "80px" : "200px",
                            right: 0,
                            zIndex: 1000,
                            height: "64px",
                            transition: "all 0.4s ease",
                        }}
                    >
                        {/* Titolo */}
                        <div
                            style={{
                                flex: "1",
                                display: "flex",
                                alignItems: "center",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                fontWeight: "bold",
                                fontSize: window.innerWidth < 768 ? "14px" : "18px",
                            }}
                        >
                            <h2
                                style={{
                                    margin: 0,
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                }}
                            >
                                {selectedTitle ? selectedTitle : "Home"}
                            </h2>
                        </div>
                    </Header>
                )}
                <Content
                    style={{
                        marginLeft: broken ? "16px" : isFrameValidationRoute ? "0" : "96px",
                        marginRight: isFrameValidationRoute ? "0" : "16px",
                        marginTop: isFrameValidationRoute ? "0" : "80px",
                        marginBottom: isFrameValidationRoute ? "0" : "24px",
                        padding: isFrameValidationRoute ? "0" : "24px",
                        minHeight: "280px",
                        background: isFrameValidationRoute ? "white" : colorBgContainer,
                        borderRadius: isFrameValidationRoute ? "0" : borderRadiusLG,
                        height: "calc(100vh - 64px)",
                        overflowY: isFrameValidationRoute ? "hidden" : "auto",
                    }}
                >
                    <Outlet />
                </Content>
            </Layout>
            {/* Modale di conferma Logout */}
            <Modal
                title="Confirm Logout"
                open={isLogoutModalVisible}
                onOk={() => {
                    setIsLogoutModalVisible(false);
                    handleLogout();
                }}
                onCancel={() => setIsLogoutModalVisible(false)}
                okText="Logout"
                cancelText="Cancel"
            >
                <p>Are you sure you want to logout?</p>
            </Modal>
        </Layout>
    );
};

export default DashboardLayout;