import { produce } from "immer";
import {
  SET_ANALYTICS_FILTER_OPTIONS,
  SET_LINE_GLASS_MODEL,
  OVERVIEW_DATA,
  GLASS_ARRAY,
  VAR_ARRAY,
} from "../actionType";

const INIT_STATE = {
  filterOptions: {
    line: "",
    glass: "",
    variant: "",
  },
  lineList: [],
  glassList: [],
  modelList: [],
  overview: {
    uniqueUser: 0,
    newUser: 0,
    sessions: 0,
    avgTime: 0,
  },
  glassArray: {},
  variantArray: {},
};

const analyticsReducer = (state = INIT_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_ANALYTICS_FILTER_OPTIONS:
        draft.filterOptions = action.options;
        break;
      case SET_LINE_GLASS_MODEL:
        draft.lineList = action.payload.lineList || [];
        draft.glassList = action.payload.glassList || [];
        draft.modelList = action.payload.modelList || [];
        break;
      case OVERVIEW_DATA:
        draft.overview = {
          ...draft.overview,
          ...action.payload,
        };
        break;
      case GLASS_ARRAY:
        draft.glassArray = {
          ...draft.glassArray,
          ...action.payload,
        };
        break;
      case VAR_ARRAY:
        draft.variantArray = {
          ...draft.variantArray,
          ...action.payload,
        };
        break;
      default:
        break;
    }
  });

export default analyticsReducer;