import { collection, query, where, getDocs, getDoc, doc } from "firebase/firestore";
import { db } from "../../data/base";
import dayjs from "dayjs";
import { getMetricsForReport, generateExcelFile } from "../data/analyticsData";
import { secureGetItem } from "../../data/utils";

export const getAggregatedData = async (brandIds, startDate, endDate) => {
    try {
        // Preparazione della query
        const q = query(
            collection(db, "Aggregate_Session"),
            where("date", ">=", startDate),
            where("date", "<=", endDate)
        );

        // Esecuzione della query
        const snapshot = await getDocs(q);

        // Filtraggio dei risultati in base ai brandIds
        const filteredResult = snapshot.docs.filter((session) =>
            brandIds.includes(session.data().brand)
        );

        // Creazione di un array di oggetti con i dati e gli ID dei documenti
        const aggregatedData = filteredResult.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }));

        return aggregatedData;
    } catch (error) {
        console.error("Error fetching aggregated data:", error);
        return [];
    }
};

export async function generateReport(startDate, endDate, selectedBrands, selectedOptions) {
    const formatDate = (date) => dayjs(date).format("YYYY/MM/DD");
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    try {
        const brandsArray = Array.isArray(selectedBrands) ? selectedBrands.map(String) : [];

        // 1️⃣ Fetch delle sessioni in base alle date
        const sessionQuery = query(
            collection(db, "Aggregate_Session"),
            where("date", ">=", formattedStartDate),
            where("date", "<=", formattedEndDate)
        );
        const sessionSnapshot = await getDocs(sessionQuery);
        const aggregateList = sessionSnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }));

        // 2️⃣ Fetch dei brand selezionati
        const brandPromises = brandsArray.map(async (id) => {
            const brandDoc = await getDoc(doc(db, "Brand", id));
            if (brandDoc.exists()) {
                return { id, ...brandDoc.data() };
            }
            console.warn(`⚠️ Brand ${id} not found in Firestore`);
            return null;
        });

        const validResults = (await Promise.all(brandPromises)).filter(Boolean);
        const brandIds = new Set(validResults.map((brand) => brand.id));

        // 3️⃣ Filtra le sessioni per i brand selezionati
        const filteredAggregateList = aggregateList.filter((item) => brandIds.has(item.brand));

        // 4️⃣ Prepara i risultati per la tabella principale (overall per brand)
        let resultsArray = validResults.map((brand) => {
            const brandSessions = filteredAggregateList.filter((data) => data.brand === brand.id);
            const metrics = getMetricsForReport(brandSessions);
            return {
                ...metrics,
                nomeBrand: brand.nome_brand,
            };
        });

        // 5️⃣ Calcolo totali filtrati per brand e servizio selezionato
        const totals = getMetricsForReport(
            filteredAggregateList.filter(
                (item) =>
                    selectedOptions.includes("all") ||
                    selectedOptions.includes(item.service)
            )
        );

        resultsArray.push({
            ...totals,
            nomeBrand: "TOTALS",
        });

        // 6️⃣ Preparazione dei dati per le categorie specifiche (web vto, 3d viewer, app)
        const allData = {};
        const selectedCategories = [];

        const addCategoryData = (category, data) => {
            const categoryResults = validResults.map((brand) => {
                const filteredCategoryList = data.filter((item) => item.brand === brand.id);
                const result = getMetricsForReport(filteredCategoryList);
                return {
                    ...result,
                    nomeBrand: brand.nome_brand,
                };
            });

            const categoryTotals = getMetricsForReport(data);
            categoryResults.push({
                ...categoryTotals,
                nomeBrand: "TOTALS",
            });

            allData[category] = categoryResults;
            selectedCategories.push(category);
        };

        if (selectedOptions.includes("all")) {
            // Selezione "ALL SERVICES": calcola sia il totale generale che le singole categorie
            addCategoryData("ALL SERVICES", filteredAggregateList);

            ["WEB VTO", "3D VIEWER", "APP"].forEach((category) => {
                const categoryData = filteredAggregateList.filter((item) => item.service === category);
                addCategoryData(category, categoryData);
            });
        } else {
            // Selezione specifica di servizi
            selectedOptions.forEach((option) => {
                const categoryData = filteredAggregateList.filter((item) => item.service === option);
                addCategoryData(option, categoryData);
            });
        }

        // 7️⃣ Genera il file Excel finale
        generateExcelFile(allData, selectedCategories);
    } catch (error) {
        console.error("❌ Errore nella generazione del report:", error);
        throw new Error("Impossibile generare il report");
    }
}

export const getAllBrandIdsData = async (setBrandProfileData) => {
    let brandsList = [];

    const brands = secureGetItem("brands");
    if (!brands) {
        console.error("No brands found");
        return;
    }

    try {
        // Se brands è già un array (secureGetItem deserializza JSON)
        const brandsListArray = Array.isArray(brands) ? brands : brands.split(",");

        // Mappa ogni ID brand in una promessa che risolve in un oggetto brand
        const brandPromises = brandsListArray.map(async (item) => {
            try {
                const brandDoc = await getDoc(doc(db, "Brand", item));
                if (brandDoc.exists()) {
                    return { id: brandDoc.id, ...brandDoc.data() };
                } else {
                    console.warn(`Brand with ID ${item} does not exist.`);
                    return null;
                }
            } catch (error) {
                console.error(`Error fetching data for brand ID ${item}:`, error);
                return null;
            }
        });

        // Aspetta che tutte le promesse siano risolte
        brandsList = (await Promise.all(brandPromises)).filter(Boolean);

        // Aggiorna lo stato con i dati dei brand
        setBrandProfileData(brandsList);
    } catch (error) {
        console.error("Error fetching brand data:", error);
    }
};

export const fetchSubcollectionData = async (docId, subcollectionName) => {
    try {
        // Ottieni il riferimento alla sottocollezione
        const subcollectionRef = collection(db, "Aggregate_Session", docId, subcollectionName);

        // Recupera i documenti dalla sottocollezione
        const subcollectionSnapshot = await getDocs(subcollectionRef);

        // Mappa i documenti in un array di oggetti
        const subcollectionData = subcollectionSnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }));

        return subcollectionData;
    } catch (error) {
        console.error(`Error fetching subcollection data (${subcollectionName}):`, error);
        return [];
    }
};