import { getTimeInSecs, convertiSecondiAMinuti } from '../utils/analyticsUtils';
import { fetchSubcollectionData } from '../api/analyticsApi';
import * as XLSX from "xlsx";
import dayjs from "dayjs";

export function getMetricsForReport(aggregateList) {
    let totalUsers = 0;
    let totalNewUsers = 0;
    let totalSession = 0;
    let totalAvgSessionTime = 0;
    let totalAvgEngTime = 0;
    let totalSessionPerUser = 0;

    // Itera attraverso l'elenco aggregato per calcolare i totali
    aggregateList.forEach(item => {
        totalUsers += item.totalUniqueUsers;
        totalNewUsers += item.newUsersCount;
        totalSession += item.totalSessions;
        totalAvgSessionTime += getTimeInSecs(item.avgSessionTime) * item.totalSessions;
        totalSessionPerUser += item.totalSessions / (item.totalUniqueUsers || 1);
        totalAvgEngTime += getTimeInSecs(item.avgSessionTime) * item.totalSessions;
    });

    // Calcola il tempo medio di sessione e l'engagement time medio
    const avgSessionTimeTotal = totalSession ? convertiSecondiAMinuti(totalAvgSessionTime / totalSession) : 0;
    const avgEngTimeTotal = totalUsers ? convertiSecondiAMinuti(totalAvgEngTime / totalUsers) : 0;
    const avgSessionPerUserTotal = totalUsers ? (totalSession / totalUsers).toFixed(2) : 0;

    // Ritorna solo i totali
    return {
        totalUsers,
        totalNewUsers,
        totalSession,
        avgSessionTimeTotal,
        avgEngTimeTotal,
        avgSessionPerUserTotal
    };
}

export function generateExcelFile(data, selectedOptions) {
    const wb = XLSX.utils.book_new();

    Object.keys(data).forEach((category) => {
        const formattedData = data[category].map((item) => ({
            nomeBrand: item.nomeBrand,
            avgEngTimeTotal: item.avgEngTimeTotal,
            avgSessionPerUserTotal: item.avgSessionPerUserTotal,
            avgSessionTimeTotal: item.avgSessionTimeTotal,
            totalNewUsers: item.totalNewUsers,
            totalSession: item.totalSession,
            totalUsers: item.totalUsers,
        }));

        const ws = XLSX.utils.json_to_sheet(formattedData, {
            header: [
                "nomeBrand",
                "avgEngTimeTotal",
                "avgSessionPerUserTotal",
                "avgSessionTimeTotal",
                "totalNewUsers",
                "totalSession",
                "totalUsers",
            ],
        });

        const sheetNames = {
            "ALL SERVICES": "ALL SERVICES",
            "WEB VTO": "WEB VTO",
            "3D VIEWER": "3D-AR VIEWER",
            "APP": "VTO APPS",
            Combined: "COMBINED DATA",
        };

        const sheetName = sheetNames[category] || "Report";
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
    });

    // Utilizzo di Day.js per ottenere la data attuale
    const dateString = dayjs().format("YYYY-MM-DD");

    let fileName = `ARShades_Report_${dateString}`;

    // Lista delle opzioni che rappresentano tutte le scelte, ora con 'VTO APPS' al posto di 'APP'
    const allOptions = ["ALL SERVICES", "WEB VTO", "3D VIEWER", "VTO APPS"];

    // Controlla se selectedOptions contiene tutte le opzioni, includendo la verifica per 'all' in minuscolo
    const allOptionsSelected =
        allOptions.every((option) =>
            selectedOptions.map((o) => o.toUpperCase()).includes(option.toUpperCase())
        ) ||
        selectedOptions.map((o) => o.toUpperCase()).includes("ALL");

    if (allOptionsSelected) {
        fileName += "-(ALL)";
    } else if (selectedOptions.length) {
        let optionsString = selectedOptions.map((option) => option.toUpperCase()).join(",");
        // Sostituisci 'all' con 'ALL' e 'APP' con 'VTO APPS' nella stringa delle opzioni se presenti
        optionsString = optionsString.replace(/\ball\b/g, "ALL").replace(/\bAPP\b/g, "VTO APPS");
        fileName += `-(${optionsString})`;
    }

    fileName += ".xlsx";

    XLSX.writeFile(wb, fileName);
}

export const enrichDataWithSubcollections = async (aggregateData) => {
    try {
        const enrichedDataPromises = aggregateData.map(async (doc) => {
            const glassesAnalytics = await fetchSubcollectionData(
                doc.id,
                "glassesAnalytics"
            );

            const glassesArray =
                glassesAnalytics.length > 0
                    ? glassesAnalytics[0].glassesArray || []
                    : [];
            const variantsArray =
                glassesAnalytics.length > 0
                    ? glassesAnalytics[0].variantsArray || []
                    : [];

            return { ...doc, glassesArray, variantsArray };
        });

        const enrichedData = await Promise.all(enrichedDataPromises);
        return enrichedData;
    } catch (error) {
        console.error("Error enriching data with subcollections:", error);
        return aggregateData;
    }
};