import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Spin, Row, Col, theme } from "antd";
import {
    getModelsFromFirebase,
    dashboardData as defaultDashboardData,
    fetchAggregateData,
    returnDashboardData,
    pieData,
    pieOptions,
    lineData,
    lineOptions,
} from "../services/home";
import HomeCard from "../components/Home/HomeCard";
import LineChart from "../components/Home/LineChart";
import PieChart from "../components/Home/PieChart";
import UserCard from "../components/Home/UserCard";

const Home = () => {
    const { token } = theme.useToken();
    const [models, setModels] = useState([]);
    const [numberOfUsers, setNumberOfUsers] = useState(0);
    const [numberOfSession, setNumberOfSession] = useState(0);
    const [sessionWeekWiseCount, setSessionWeekWiseCount] = useState([]);
    // Stato per i dati del dashboard (inizializzato come array vuoto)
    const [dashboard, setDashboard] = useState([]);
    const [profileData, setProfileData] = useState({});

    const [isFetchingModels, setIsFetchingModels] = useState(false);
    const [isFetchingDashboard, setIsFetchingDashboard] = useState(false);
    const isLoading = isFetchingModels || isFetchingDashboard;

    // Redux state
    const rxBrands = useSelector((state) => state.config.listaBrand);
    const selectedBrand = useSelector((state) => state.brand.selectedBrand);
    const config = useSelector((state) => state.config);

    // Fetch models based on the selected brand
    const fetchModels = useCallback(async () => {
        const brand = selectedBrand?.id
            ? selectedBrand
            : { ...selectedBrand, id: "all" };
        setIsFetchingModels(true);
        try {
            const fetchedModels = await getModelsFromFirebase(brand, rxBrands);
            setModels(fetchedModels);
        } catch (error) {
            console.error("Error fetching models:", error);
        } finally {
            setIsFetchingModels(false);
        }
    }, [selectedBrand, rxBrands]);

    // Fetch aggregate data based on the selected brand
    const fetchDashboardData = useCallback(async () => {
        const brand = selectedBrand?.id
            ? selectedBrand
            : { ...selectedBrand, id: "all" };
        setIsFetchingDashboard(true);
        try {
            await fetchAggregateData(
                brand,
                setNumberOfUsers,
                setNumberOfSession,
                setIsFetchingDashboard,
                setSessionWeekWiseCount,
                rxBrands
            );
        } catch (error) {
            console.error("Error fetching dashboard data:", error);
        } finally {
            setIsFetchingDashboard(false);
        }
    }, [selectedBrand, rxBrands]);

    // Fetch models e dashboard data quando il brand selezionato cambia
    useEffect(() => {
        fetchModels();
        fetchDashboardData();
    }, [selectedBrand, fetchModels, fetchDashboardData]);

    // Aggiorna i dati del dashboard quando models o config cambiano
    useEffect(() => {
        const updateDashboard = async () => {
            const dashboardResult = await returnDashboardData(
                models,
                defaultDashboardData
            );
            setDashboard(dashboardResult);
        };
        if (models.length > 0) {
            updateDashboard();
        }
    }, [models, config]);

    // Aggiorna le etichette del line chart quando sessionWeekWiseCount cambia
    useEffect(() => {
        if (!lineData?.labels?.length) {
            // Genera etichette per gli ultimi 7 giorni terminanti ieri
            for (let i = 6; i >= 0; i--) {
                const date = new Date();
                // Sottrai (i+1) giorni per partire da ieri
                date.setDate(date.getDate() - (i + 1));
                const label = date.toLocaleDateString("en-US", {
                    weekday: "long",
                });
                lineData.labels.push(label);
            }
        }
        lineData.datasets[0].data = sessionWeekWiseCount;
    }, [sessionWeekWiseCount]);

    const lineChartOptions = { ...lineOptions };

    const lineChartData = {
        ...lineData,
        datasets: [
            {
                ...lineData.datasets[0],
                data: sessionWeekWiseCount,
                borderColor: token.colorPrimary,
                backgroundColor: "rgba(76, 175, 175, 0.2)",
                borderWidth: 2,
            },
        ],
    };

    const pieChartOptions = {
        ...pieOptions,
        plugins: {
            legend: {
                position: "top",
                labels: {
                    font: { size: 12 },
                },
            },
        },
    };

    const pieChartData = {
        ...pieData,
        datasets: [
            {
                ...pieData.datasets[0],
                data: numberOfUsers === 0 ? [0, 1] : [numberOfUsers],
            },
        ],
    };

    const tooltipMap = [
        "Overall count of all 3D assets in the system.",
        "Number of primary 3D assets used in projects.",
        "3D assets that have been successfully published.",
        "3D assets pending validation before approval.",
        "3D assets currently in development and not yet finalized.",
    ];

    // Se il loader globale è attivo, mostra lo spinner centrato
    if (isLoading) {
        return (
            <div
                style={{
                    minHeight: "calc(100vh - 200px)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Spin size="large" />
            </div>
        );
    }

    return (
        <div>
            <Col xs={24}>
                <UserCard user={profileData} setProfileData={setProfileData} />
            </Col>
            <div style={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
                {dashboard.map((item, index) => (
                    <HomeCard
                        key={index}
                        leftTitle={item.leftTitle}
                        leftSubtitle={item.leftSubtitle}
                        rightTitle={item.rightTitle}
                        rightSubtitle={item.rightSubtitle}
                        tooltipTitle={
                            tooltipMap[index] || "Informazioni non disponibili"
                        }
                        style={{ boxSizing: "border-box", width: "100%" }}
                    />
                ))}
            </div>
            <div style={{ marginTop: "16px" }}>
                <Row gutter={[16, 16]} align="stretch">
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <PieChart
                            data={pieChartData}
                            options={pieChartOptions}
                            totalCount={numberOfUsers}
                            isLoading={isLoading}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <LineChart
                            data={lineChartData}
                            options={lineChartOptions}
                            isLoading={isLoading}
                            numberOfSession={numberOfSession}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Home;