import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { getHdr } from "./HDRConstant.js";
import ButtonAr from "./ButtonAr";
import styles from "../../styles/ModelViewer/ProjectModelView.module.css";
import "../../index.css";
import playIcon from "../../assets/icon/play_icon.svg";
import { Button, Spin, message } from "antd";
import '@google/model-viewer';
import arLogoSrc from "../../assets/images/logo/ARSHADES_AR_LOGO.svg";

function ProjectModelViewer(props) {
    const project = useSelector((state) => state.brandViewer);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const mirror = queryParams?.get("mirror");
    const selected = props.initialModel;
    const isEditModeOn = props.isEditModeOn;

    const [isWebView, setIsWebView] = useState(false);
    const modelViewerRef = useRef();

    const [isUsingProjectSettings, setIsUsingProjectSettings] = useState(false);
    const [isValidatedMode, setIsValidateMode] = useState(false);

    const [isFirstAnimation, setIsFirstAnimation] = useState(false);
    const [isAnimatedOn, setIsAnimatedOn] = useState(false);
    const [hasAnimations, setHasAnimations] = useState(false);
    const [animationDuration, setAnimationDuration] = useState(0);
    const [animationName, setAnimationName] = useState("");
    const [animationTitle, setAnimationTitle] = useState("");

    const [positionMenu, setPositionMenu] = useState("bottom");

    const [progress, setProgress] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);

    const comeBackTitle = "Come Back";

    useEffect(() => {
        if (queryParams?.get("sceneSettings") === "neutral" && !shouldHideSwitchButton()) {
            setIsValidateMode(true);
        }
    }, [isValidatedMode]);

    useEffect(() => {
        const modelViewer = modelViewerRef.current;

        const handleLoad = () => {
            const animationsAvailable =
                modelViewer.availableAnimations && modelViewer.availableAnimations.length > 0;
            setHasAnimations(animationsAvailable);

            // Reset degli stati dell'animazione
            setIsFirstAnimation(false);
            setIsAnimatedOn(false);

            if (modelViewer.availableAnimations && modelViewer.availableAnimations.length > 0) {
                modelViewer.animationName = modelViewer.availableAnimations[0];
                setAnimationName(modelViewer.availableAnimations[0]);

                // Reset del tempo dell'animazione
                modelViewer.currentTime = 0;

                if (modelViewer.availableAnimations[0] === "explosion_glasses_an_1") {
                    setAnimationDuration(3.0);
                    setAnimationTitle("EXPAND");
                } else if (modelViewer.availableAnimations[0] === "CO_FULL_AN_2") {
                    setAnimationDuration(1.2);
                    setAnimationTitle("APPLY CLIP-ONS");
                } else if (modelViewer.availableAnimations[0] === "IL_FULL_AN_1") {
                    setAnimationDuration(3.19);
                    setAnimationTitle("CHANGE LENSES");
                } else {
                    setAnimationDuration(1.2);
                    setAnimationTitle("APPLY CLIP-ONS");
                }
            }
        };

        if (modelViewer) {
            modelViewer.addEventListener("load", handleLoad);
        }

        return () => {
            if (modelViewer) {
                modelViewer.removeEventListener("load", handleLoad);
            }
        };
    }, [selected]);

    const toggleSettings = () => {
        setIsUsingProjectSettings((prevState) => !prevState);
    };

    const startAnimation = async () => {
        const modelViewer = modelViewerRef.current;

        modelViewer.addEventListener("finished", handleAnimationFinished);

        if (!isFirstAnimation) {
            modelViewer.timeScale = 1;
            modelViewer.interpolationDecay = 200;

            setTimeout(() => {
                if (props.onAnimationStateChange) {
                    props.onAnimationStateChange(true);
                }

                setTimeout(async () => {
                    setIsAnimatedOn(true);
                    await modelViewer.updateComplete;
                    modelViewer.play({ repetitions: 1 });
                    setIsFirstAnimation(true);

                    setTimeout(() => {
                        modelViewer.pause();
                        modelViewer.currentTime = animationDuration;
                        setIsAnimatedOn(false);
                    }, 1000 * animationDuration);
                }, 500);
            }, 400);
        } else {
            modelViewer.currentTime = animationDuration;
            await modelViewer.updateComplete;
            modelViewer.timeScale = -1;
            setIsAnimatedOn(true);
            modelViewer.play({ repetitions: 1 });
        }
    };

    const handleAnimationFinished = () => {
        if (props.onAnimationStateChange) {
            props.onAnimationStateChange(false);
        }
        setIsFirstAnimation(false);
        setIsAnimatedOn(false);

        // Ripristina il titolo dell'animazione originale
        if (animationName === "IL_FULL_AN_1") {
            setAnimationTitle("CHANGE LENSES");
        } else if (animationName === "explosion_glasses_an_1") {
            setAnimationTitle("EXPAND");
        } else if (animationName === "CO_FULL_AN_2") {
            setAnimationTitle("APPLY CLIP-ONS");
        }
    };

    async function loadModel(url) {
        const modelViewer = modelViewerRef.current;

        if (!url) {
            console.warn("URL del modello non fornito.");
            return;
        }

        try {
            const response = await fetch(url);

            // Gestione degli errori di risposta
            if (!response.ok) {
                const errorText = await response.text();
                console.error(`Errore nel caricamento del modello. Status: ${response.status}`);
                console.error("Corpo della risposta:", errorText);
                return;
            }

            // Verifica del tipo di contenuto
            const contentType = response.headers.get("Content-Type");

            if (!isValidModelContentType(contentType)) {
                if (contentType?.includes("text/html")) {
                    console.warn("Contenuto HTML ricevuto invece di un modello.");
                    return;
                }
                console.error("Tipo di contenuto inaspettato:", contentType);
                const responseText = await response.text();
                console.error('Corpo della risposta:', responseText);
                return;
            }

            // Creazione di un blob URL per il modello
            const blob = await response.blob();
            const urlBlob = URL.createObjectURL(blob);

            // Imposta l'URL del modello nel viewer
            if (modelViewer) {
                modelViewer.src = urlBlob;
            }
        } catch (error) {
            console.error("Errore nel caricamento del modello:", error);
        }
    }

    // Funzione di utilità per validare i tipi di contenuto
    function isValidModelContentType(contentType) {
        return (
            contentType &&
            (contentType.includes("model/gltf-binary") || contentType.includes("application/octet-stream"))
        );
    }

    useEffect(() => {
        const checkIfWebView = () => {
            const userAgent = navigator.userAgent;
            return /\bwv\b/.test(userAgent);
        };

        if (checkIfWebView() || mirror) {
            setIsWebView(true);
        }
    }, [mirror]); // Dipendenze: esegui l'effetto solo quando `mirror` cambia

    const modelInView = useSelector((state) =>
        state.modelGlasses.find((model) => model.id === selected)
    );

    useEffect(() => {
        setPositionMenu(project[0]?.menu_position);
    }, []);

    const [url, setUrl] = useState("");

    const [fieldOfView, setFieldOfView] = useState(
        project[0]?.fieldOfView ?? "22deg"
    );

    const urlVista = props.urlView;

    useEffect(() => {
        if (window.innerWidth <= 700) {
            setFieldOfView("32deg");
        }
    }, []);

    useEffect(() => {
        // Funzione per gestire il caricamento del modello
        const handleModelLoad = () => {
            if (props.singleView) {
                loadModel(props.singleSrc);
                setUrl(props.singleSrc);
            } else {
                if (modelInView?.urlGlbComplete) {
                    loadModel(modelInView?.urlGlbComplete);
                }
                if (project && project[0]) {
                    loadModel(project[0].url);
                }
            }
        };

        handleModelLoad();
    }, [props.singleSrc, project]);

    const hdrNum = project[0]?.hdr;
    const hdrPath = getHdr(hdrNum);

    const defaultValues = {
        backgroundColor: "#ffffff",
        background_image: "",
        background_boolean_image: false,
    };

    const shouldHideSwitchButton = () => {
        return (
            project[0]?.background_color === defaultValues.backgroundColor &&
            project[0]?.background_image === defaultValues.background_image &&
            project[0]?.background_boolean_image === defaultValues.background_boolean_image &&
            hdrPath === 0
        );
    };

    const MODEL_INSTANCE = {
        src: isEditModeOn ? "" : project[0]?.url,
        alt: project[0]?.alt,
        cameraControls: isWebView ? true : project[0]?.cameraControls,
        autoRotate: isWebView ? false : project[0]?.autoRotate,
        disableZoom: isWebView ? false : project[0]?.disableZoom,
        lightExposition: project[0]?.lightExposition,
        shadowSmooth: project[0]?.shadowSmooth,
        shadowIntensity: project[0]?.shadowIntensity,
        poster: modelInView?.poster3 ? modelInView?.poster3 : modelInView?.poster,
        ios: modelInView?.ios,
        hdr: isValidatedMode && !isUsingProjectSettings ? null : hdrPath,
        ar: "true",
        background_image: isValidatedMode && !isUsingProjectSettings ? "" : project[0]?.background_image,
        skybox: project[0]?.transparent ? "" : project[0]?.skybox,
        fieldOfView: project[0]?.fieldOfView,
        backgroundColor:
            isValidatedMode && !isUsingProjectSettings
                ? "#ffffff"
                : !project[0]?.transparent && !isWebView
                    ? project[0]?.background_color ?? "#ffffff00"
                    : "#ffffff00",
        background_boolean_image:
            isValidatedMode && !isUsingProjectSettings
                ? false
                : project[0]?.background_boolean_image === "true",
        title: project[0]?.modelTitle ?? false,
        exposure: project[0]?.lightExposition ?? "1.0",
        ref: modelViewerRef,
        cameraOrbit: project[0]?.cameraOrbit ?? "",
    };

    function castValues(value, type) {
        return !value ? null : value;
    }

    useEffect(() => {
        const style = document.createElement("style");
        style.innerHTML = `
            :root {
                --poster-color: rgba(255, 255, 255, 0);
            }
        `;
        document.head.appendChild(style);
        return () => {
            document.head.removeChild(style);
        };
    }, []);

    useEffect(() => {
        function onProgress(e) {
            setProgress(Math.ceil(e.detail.totalProgress * 100));
        }

        function onLoad() {
            setIsLoaded(true);
        }

        const modelViewer = document.querySelector("model-viewer");
        modelViewer.addEventListener("progress", onProgress);
        modelViewer.addEventListener("load", onLoad);

        return () => {
            modelViewer.removeEventListener("progress", onProgress);
            modelViewer.removeEventListener("load", onLoad);
        };
    }, []);

    useEffect(() => {
        const modelViewer = modelViewerRef.current;

        const handleLoad = () => {
            if (modelViewer) {
                modelViewer.cameraOrbit = project[0]?.cameraOrbit || "auto auto 120deg";
                modelViewer.cameraControls = true;
            }
        };

        if (modelViewer) {
            modelViewer.addEventListener('load', handleLoad);
            return () => modelViewer.removeEventListener('load', handleLoad);
        }
    }, [project[0]?.cameraOrbit]);

    const activateAR = () => {
        const modelViewer = modelViewerRef.current;
        if (modelViewer) {
            // Troviamo il bottone AR nativo e simuliamo il click
            const nativeARButton = modelViewer.querySelector('button[slot="ar-button"]');
            if (nativeARButton) {
                nativeARButton.click();
            } else {
                // Se non troviamo il bottone nativo, proviamo ad attivare l'AR direttamente
                if (modelViewer.canActivateAR) {
                    modelViewer.activateAR();
                } else {
                    console.log("AR non disponibile su questo dispositivo");
                    message.warning("AR non disponibile su questo dispositivo");
                }
            }
        }
    };

    useEffect(() => {
        const modelViewer = modelViewerRef.current;
        if (modelViewer) {
            // Nascondiamo il bottone AR nativo
            const nativeARButton = modelViewer.querySelector('button[slot="ar-button"]');
            if (nativeARButton) {
                nativeARButton.style.display = 'none';
            }
        }
    }, []);

    useEffect(() => {
        const handleTouchMove = (event) => {
            const modelViewer = modelViewerRef.current;
            if (modelViewer && event.target.closest('model-viewer') === modelViewer) {
                event.stopPropagation();
            }
        };

        document.addEventListener('touchmove', handleTouchMove, { passive: true });

        return () => {
            document.removeEventListener('touchmove', handleTouchMove);
        };
    }, []);

    return (
        <>
            {/* Spinner di caricamento */}
            {progress < 100 && !isLoaded && (
                <div className={styles.loading}>
                    <div className={styles.spinner}></div>
                    <Spin size="large" />
                </div>
            )}

            {/* Visualizzazione del modello per schermi piccoli */}
            {window.innerWidth <= 599 && (
                <model-viewer
                    src={isEditModeOn ? MODEL_INSTANCE.src : ""}
                    disable-tap
                    disable-pan
                    alt={MODEL_INSTANCE.alt}
                    ref={MODEL_INSTANCE.ref}
                    camera-controls={castValues(MODEL_INSTANCE.cameraControls, "camera-control")}
                    auto-rotate={castValues(MODEL_INSTANCE.autoRotate, "auto-rotate")}
                    rotation-per-second={MODEL_INSTANCE.rotationPerSecond}
                    disable-zoom={MODEL_INSTANCE.disableZoom || undefined}
                    exposure={MODEL_INSTANCE.exposure}
                    shadow-intensity={MODEL_INSTANCE.shadowIntensity}
                    shadow-softness={MODEL_INSTANCE.shadowSmooth}
                    field-of-view={MODEL_INSTANCE.fieldOfView}
                    tone-mapping="aces"
                    min-camera-orbit='auto auto 200%'
                    max-camera-orbit='auto auto 200%'
                    min-field-of-view='24deg'
                    max-field-of-view='30deg'
                    ar={isWebView}
                    ar-modes={"webxr quick-look scene-viewer"}
                    environment-image={MODEL_INSTANCE.hdr ? MODEL_INSTANCE.hdr : null}
                    skybox-image={MODEL_INSTANCE.skybox ? MODEL_INSTANCE.hdr : null}
                    allow="xr-spatial-tracking"
                    progress
                    style={
                        isMobile && !props.singleView
                            ? {
                                height: "83%",
                                width: "100%",
                                backgroundColor: MODEL_INSTANCE.backgroundColor,
                                backgroundPosition: 'bottom center',
                                backgroundSize: 'cover',
                                backgroundImage: MODEL_INSTANCE.background_boolean_image
                                    ? `url(${MODEL_INSTANCE.background_image})`
                                    : "",
                                backgroundSize: 'cover',
                                backgroundPosition: 'bottom center',
                            }
                            : {
                                height: "100%",
                                width: "100%",
                                backgroundColor: MODEL_INSTANCE.backgroundColor,
                                backgroundPosition: 'bottom center',
                                backgroundSize: 'cover',
                                backgroundImage: MODEL_INSTANCE.background_boolean_image
                                    ? `url(${MODEL_INSTANCE.background_image})`
                                    : "",
                            }
                    }
                    id={
                        props?.project?.menu_position === "left" ||
                            props?.project?.menu_position === "right"
                            ? "projectTwo"
                            : "project"
                    }
                >
                    <div id="progress-bar-id" slot="progress-bar"></div>
                    <div id="lazy-load-poster" slot="poster"></div>

                    {props.multiViewer && MODEL_INSTANCE.title && (
                        <h3 className={styles.nomeOcchiale}>{modelInView?.nomeOcchiale}</h3>
                    )}

                    <ButtonAr project={project} urlView={urlVista} modelViewer={modelViewerRef.current} />

                    {hasAnimations && (
                        <div
                            className={styles.animationContainer}
                            style={{ display: isAnimatedOn ? "none" : "block" }}
                        >
                            <Button
                                onClick={startAnimation}
                                className={styles.animation_button}
                                icon={<img src={playIcon} className={styles.clipped_icon_button} alt="Play" />}
                            >
                                {isFirstAnimation ? comeBackTitle : animationTitle}
                            </Button>
                        </div>
                    )}

                    <button
                        slot="ar-button"
                        type="button"
                        onClick={activateAR}
                        className="ant-btn css-dev-only-do-not-override-1iagsxf ant-btn-circle ant-btn-default ant-btn-color-default ant-btn-variant-outlined ant-btn-icon-only ButtonAr_buttonARBottom__K3RB9"
                        style={{
                            position: 'absolute',
                            bottom: '16px',
                            right: '16px',
                            zIndex: 100
                        }}
                    >
                        <span className="ant-btn-icon">
                            <img
                                src={arLogoSrc}
                                alt="AR View"
                                style={{ width: '40px', height: '40px' }}
                            />
                        </span>
                    </button>
                </model-viewer>
            )}

            {/* Visualizzazione del modello per schermi grandi */}
            {window.innerWidth > 599 && (
                <>
                    <div className={styles.viewAction}>
                        {isValidatedMode && !shouldHideSwitchButton() && (
                            <Button onClick={toggleSettings} className={styles.validationButton}>
                                {isUsingProjectSettings ? "Validation Mode" : "Public Mode"}
                            </Button>
                        )}
                    </div>

                    <model-viewer
                        disable-tap
                        src={isEditModeOn ? MODEL_INSTANCE.src : ""}
                        disable-pan
                        alt={MODEL_INSTANCE.alt}
                        ref={MODEL_INSTANCE.ref}
                        camera-controls={castValues(MODEL_INSTANCE.cameraControls, "camera-controls")}
                        auto-rotate={castValues(MODEL_INSTANCE.autoRotate, "auto-rotate")}
                        rotation-per-second={MODEL_INSTANCE.rotationPerSecond}
                        disable-zoom={MODEL_INSTANCE.disableZoom || undefined}
                        exposure={MODEL_INSTANCE.exposure}
                        shadow-intensity={MODEL_INSTANCE.shadowIntensity}
                        shadow-softness={MODEL_INSTANCE.shadowSmooth}
                        camera-orbit={isEditModeOn ? MODEL_INSTANCE.cameraOrbit : ""}
                        field-of-view={MODEL_INSTANCE.fieldOfView}
                        tone-mapping="aces"
                        min-camera-orbit={window.innerWidth < 1224 ? 'auto auto 180%' : 'auto auto 120%'}
                        max-camera-orbit={window.innerWidth < 1224 ? 'auto auto 180%' : 'auto auto 120%'}
                        min-field-of-view='6deg'
                        max-field-of-view='32deg'
                        ar={isWebView}
                        ar-modes={"webxr quick-look scene-viewer"}
                        environment-image={MODEL_INSTANCE.hdr ? MODEL_INSTANCE.hdr : null}
                        xr-environment
                        skybox-image={MODEL_INSTANCE.skybox ? MODEL_INSTANCE.hdr : null}
                        allow="xr-spatial-tracking"
                        progress
                        style={
                            props.singleView ||
                                props?.project?.menu_position === "left" ||
                                props?.project?.menu_position === "right"
                                ? {
                                    height: "100%",
                                    width: "100%",
                                    backgroundColor: MODEL_INSTANCE.backgroundColor,
                                    backgroundSize: 'cover',
                                    backgroundImage: MODEL_INSTANCE.background_boolean_image
                                        ? `url(${MODEL_INSTANCE.background_image})`
                                        : "",
                                }
                                : {
                                    height: "83svh",
                                    width: "100%",
                                    backgroundColor: MODEL_INSTANCE.backgroundColor,
                                    backgroundSize: 'cover',
                                    backgroundImage: MODEL_INSTANCE.background_boolean_image
                                        ? `url(${MODEL_INSTANCE.background_image})`
                                        : "",
                                }
                        }
                        id={
                            props?.project?.menu_position === "left" ||
                                props?.project?.menu_position === "right"
                                ? "projectTwo"
                                : "project"
                        }
                    >
                        <div id="progress-bar-id" slot="progress-bar"></div>

                        {props.multiViewer && MODEL_INSTANCE.title && (
                            <h3 className={styles.nomeOcchiale}>{modelInView?.nomeOcchiale}</h3>
                        )}

                        <ButtonAr project={project} urlView={urlVista} modelViewer={modelViewerRef.current} />

                        {hasAnimations && (
                            <div
                                className={styles.animationContainer}
                                style={{ display: isAnimatedOn ? "none" : "block" }}
                            >
                                <Button
                                    onClick={startAnimation}
                                    className={styles.animation_button}
                                    icon={<img src={playIcon} className={styles.clipped_icon_button} alt="Play" />}
                                >
                                    {isFirstAnimation ? comeBackTitle : animationTitle}
                                </Button>
                            </div>
                        )}

                        <button
                            slot="ar-button"
                            type="button"
                            onClick={activateAR}
                            className="ant-btn css-dev-only-do-not-override-1iagsxf ant-btn-circle ant-btn-default ant-btn-color-default ant-btn-variant-outlined ant-btn-icon-only ButtonAr_buttonARBottom__K3RB9"
                            style={{
                                position: 'absolute',
                                bottom: '16px',
                                right: '16px',
                                zIndex: 100
                            }}
                        >
                            <span className="ant-btn-icon">
                                <img
                                    src={arLogoSrc}
                                    alt="AR View"
                                    style={{ width: '40px', height: '40px' }}
                                />
                            </span>
                        </button>
                    </model-viewer>
                </>
            )}
        </>
    );
}

export default ProjectModelViewer;