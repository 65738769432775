import React, { useRef, useEffect } from "react";
import { Card, Typography } from "antd";

const { Title, Text } = Typography;

const HomeCard = ({ leftTitle, leftSubtitle, rightTitle, rightSubtitle, onClickFunction }) => {
    const leftSubtitleRef = useRef(null);
    const rightSubtitleRef = useRef(null);
    const hasBothSubtitles = leftSubtitle && rightSubtitle && leftTitle && rightTitle;

    useEffect(() => {
        if (leftSubtitleRef.current || rightSubtitleRef.current) {
            const subtitleWidth =
                (leftSubtitleRef.current?.offsetWidth || 0) +
                (rightSubtitleRef.current?.offsetWidth || 0);
            const cardWidth = subtitleWidth + (hasBothSubtitles ? 450 : 166);
            const cardElement = leftSubtitleRef.current?.closest(".custom-card");
            if (cardElement) {
                cardElement.style.width = `${cardWidth}px`;
            }
        }
    }, [hasBothSubtitles]);

    return (
        <Card onClick={onClickFunction} className="custom-card" hoverable>
            <div className="card-inner" style={{ display: "flex", flexDirection: "row" }}>
                <div className="left-content">
                    {leftTitle && (
                        <Title level={2} className="card-text" style={{ margin: 0 }}>
                            {leftTitle}
                        </Title>
                    )}
                    {leftSubtitle && (
                        <Text type="secondary" ref={leftSubtitleRef} className="card-text">
                            {leftSubtitle}
                        </Text>
                    )}
                </div>
                {hasBothSubtitles && <div className="both-subtitle" />}
                <div className="right-content extend-right-content">
                    {rightTitle && (
                        <Title level={2} className="card-text" style={{ margin: 0 }}>
                            {rightTitle === "NaN" ? 0 : rightTitle}
                        </Title>
                    )}
                    {rightSubtitle && (
                        <Text type="secondary" ref={rightSubtitleRef} className="card-text">
                            {rightSubtitle}
                        </Text>
                    )}
                </div>
            </div>
        </Card>
    );
};

export default HomeCard;