import { db } from "../../data/base";
import {
    collection,
    getDocs,
    doc,
    getDoc,
    where,
    query,
} from "firebase/firestore";
import dayjs from "dayjs";
import {
    processCallsData,
    processSDKCallsData,
    processBandwidthData,
} from "../data/dataConsumptionData.js";
import {
    calculateTotals,
    calculateTotalBandwidth,
    generateLineChartData,
    generateBarChartData,
    parseSubscriptionStartDate,
    parseDate,
    getDateRange,
    filterValidConsumption,
    calculateCallDetails,
} from "../utils/dataConsumptionUtils";
import { secureGetItem } from "../../data/utils";

export const getBrandsByRef = async (rxBrands) => {
    const brands = [];

    const storedBrands = secureGetItem("brands") || [];

    if (!Array.isArray(storedBrands)) {
        console.error("Errore: userBrands non è un array valido", storedBrands);
        return brands;
    }

    for (const brand of rxBrands) {
        if (storedBrands.includes(brand)) {
            try {
                const brandDocRef = doc(db, "Brand", brand);
                const brandSnapshot = await getDoc(brandDocRef);
                if (brandSnapshot.exists()) {
                    brands.push({ id: brand, ...brandSnapshot.data() });
                }
            } catch (error) {
                console.error(
                    `Errore durante il recupero del brand con ID ${brand}:`,
                    error
                );
            }
        }
    }

    return brands;
};

export const getSubscriptionData = async ({
    startDate,
    endDate,
    bandwidthType,
    brandList,
    subStartDate,
    subscriptions,
}) => {
    try {
        const snapshot = await getDocs(collection(db, "Contatori_Brand"));

        const subDate = parseDate(subStartDate);
        const checkDate = parseDate(subscriptions?.endDate);
        const currentDate = dayjs();

        // Filtriamo i dati validi
        const validConsumption = filterValidConsumption(
            snapshot,
            subscriptions,
            checkDate,
            currentDate
        );

        // Elaboriamo i dati di banda
        const bandwidthData = processBandwidthData(
            validConsumption,
            bandwidthType,
            startDate,
            endDate,
            subDate
        );

        // Elaboriamo i dati delle chiamate
        const callsData = processCallsData(
            validConsumption,
            bandwidthType,
            startDate,
            endDate,
            subDate
        );

        // Totale chiamate
        const totalCalls = calculateTotals(callsData);

        // Dettagli chiamate
        const callsDetails = {
            totalCalls,
            dateRange: getDateRange(callsData),
        };

        // Calcoliamo la larghezza di banda totale
        const [dataSet, totalBandwidth] = calculateTotalBandwidth(bandwidthData);

        // Dettagli larghezza di banda
        const bandwidthDetails = {
            totalBandWidth: (totalBandwidth / 1024).toFixed(2), // Convertiamo KB in MB
            dateRange: getDateRange(bandwidthData),
        };

        // Creiamo i dati per i grafici
        const lineChartData = generateLineChartData(
            startDate,
            endDate,
            bandwidthData,
            dataSet
        );

        const barChartData = generateBarChartData(startDate, endDate, callsData);

        // Ritorniamo i dati
        return { barChartData, lineChartData, bandwidthDetails, callsDetails };

    } catch (error) {
        console.error("❌ Errore in getSubscriptionData:", error);
        return { barChartData: [], lineChartData: [], bandwidthDetails: {}, callsDetails: {} };
    }
};

export const getStartDate = async ({ listOfBrandId }) => {
    try {
        let farthestStartDate = null;

        // Usa Promise.all per gestire le operazioni asincrone in parallelo
        const startDates = await Promise.all(
            listOfBrandId.map(async (docID) => {
                const docRef = doc(db, "Brand", docID);
                const docSnapshot = await getDoc(docRef);

                if (docSnapshot.exists()) {
                    const brandData = docSnapshot.data();
                    return brandData.startDate;
                }
                return null; // Ritorna null se il documento non esiste
            })
        );

        // Filtra i valori nulli e trova la data più lontana
        startDates.forEach((brandStartDate) => {
            if (brandStartDate) {
                if (!farthestStartDate || brandStartDate > farthestStartDate) {
                    farthestStartDate = brandStartDate;
                }
            }
        });

        return farthestStartDate;
    } catch (error) {
        console.error("Error fetching start date:", error);
        return null; // Ritorna null in caso di errore
    }
};

export const fetchClientData = async (accRef) => {
    try {
        const clientDocRef = doc(db, "Client", accRef);
        const clientDoc = await getDoc(clientDocRef);

        if (!clientDoc.exists()) {
            console.error("Client document not found");
            return null;
        }

        return clientDoc.data();
    } catch (error) {
        console.error("Errore nel recuperare i dati del client:", error);
        return null;
    }
};

export const fetchSubscriptionsByRole = async (licenseList) => {
    try {
        let subscriptions = [];

        if (secureGetItem("role") === "Admin" || secureGetItem("role") === "Cliente") {
            const subscriptionQuery = query(
                collection(db, "ARShades_Subscription"),
                where("isRefactored", "==", true)
            );
            const subscriptionSnapshot = await getDocs(subscriptionQuery);
            subscriptions = await Promise.all(
                subscriptionSnapshot.docs.map(async (docRef) => {
                    const docSnapshot = await getDoc(
                        doc(db, "ARShades_Subscription", docRef.id)
                    );
                    return docSnapshot.exists() ? docSnapshot.data() : null;
                })
            );
        } else {
            subscriptions = await Promise.all(
                licenseList.map(async (docRef) => {
                    const subscriptionDoc = await getDoc(
                        doc(db, "ARShades_Subscription", docRef)
                    );
                    return subscriptionDoc.exists() ? subscriptionDoc.data() : null;
                })
            );
        }

        const currentDate = dayjs();
        const filteredSubscriptions = subscriptions.filter((subscription) => {
            const endDate = subscription?.endDate?.seconds
                ? dayjs.unix(subscription.endDate.seconds)
                : null;
            return (
                subscription?.status === "Active" &&
                (!endDate || endDate.isAfter(currentDate))
            );
        });

        return filteredSubscriptions;
    } catch (error) {
        console.error("Errore nel recuperare le subscription:", error);
        return [];
    }
};

export const processSubscriptions = async (
    subscriptionList,
    accRef,
    isBrandLicence
) => {
    const activeSubQuery = query(
        collection(db, "ARShades_Subscription"),
        where("clientRef", "==", accRef),
        where("status", "==", "Active"),
        where(
            "type",
            "in",
            isBrandLicence
                ? [
                    "ARShades Branded Licence",
                    "ARShades Smart Licence",
                    "ARShades Additional Licence",
                ]
                : ["ARShades SDK Licence", "ARPD Meter SDK License"]
        )
    );

    const activeSubsSnapshot = await getDocs(activeSubQuery);
    const activeSubscriptions = [];

    for (const el of subscriptionList) {
        el.brands = await fetchBrands(el.catalogList?.catalogRefList || []);
        activeSubscriptions.push(el);
    }

    return activeSubscriptions;
};

export const fetchBrands = async (catalogRefList) => {
    const brands = [];
    for (const brandRef of catalogRefList) {
        if (brandRef) {
            const brandDoc = await getDoc(doc(db, "Brand", brandRef));
            if (brandDoc.exists()) {
                brands.push(brandDoc.data());
            }
        }
    }
    return brands;
};

export const getMeterSDKLicense = async ({
    startDate,
    endDate,
    bandwidthType,
    subscription,
}) => {
    try {
        const data = await fetchConsumptionData(
            subscription.arpd_meter_consumption
        );

        if (!data) {
            console.warn("No consumption data found.");
            return { barChartData: {}, callsDetails: {} };
        }

        const subDate = parseSubscriptionStartDate(subscription.start_date);

        const callsData = processSDKCallsData(data, bandwidthType, subDate);

        const callsDetails = calculateCallDetails(callsData);

        const barChartData = generateBarChartData(
            startDate,
            endDate,
            callsData
        );

        return { barChartData, callsDetails };
    } catch (error) {
        console.error(
            "Errore durante il recupero dei dati della licenza:",
            error
        );
        return { barChartData: {}, callsDetails: {} };
    }
};

export const fetchConsumptionData = async (arpdMeterConsumptionId) => {
    try {
        const docRef = doc(db, "API_Consumption", arpdMeterConsumptionId);
        const snapshot = await getDoc(docRef);
        return snapshot.exists() ? snapshot.data() : null;
    } catch (error) {
        console.error("Errore durante il fetch dei dati di consumo:", error);
        return null;
    }
};

export const getGatewayConsumptionData = async (setLoading, setConsumptionData) => {
    try {
        setLoading(true);

        // Determina se l'utente è un Admin o Cliente
        if (secureGetItem("role") === "Admin" || secureGetItem("role") === "Cliente") {
            // ... existing code ...
        }
    } catch (error) {
        // ... existing code ...
    }
};
