import { db } from "../../data/base.js";
import { collection, doc, getDoc } from "firebase/firestore";

export const fetchBrands = async (uniqueBrands) => {
    try {
        const result = await Promise.all(
            uniqueBrands.map(async (brandId) => {
                const brandDocRef = doc(collection(db, "Brand"), brandId);
                const brandData = await getDoc(brandDocRef);
                if (brandData.exists()) {
                    return { id: brandData.id, ...brandData.data() };
                }
                return null;
            })
        );

        return result.filter((brand) => brand !== null);
    } catch (error) {
        console.error("Errore durante il recupero dei brand:", error);
        throw error;
    }
};

export const fetchLines = async (lineIds) => {
    try {
        const result = await Promise.all(
            lineIds.map(async (lineId) => {
                const lineDocRef = doc(collection(db, "Linea"), lineId);
                const lineData = await getDoc(lineDocRef);
                if (lineData.exists()) {
                    return {
                        idLinea: lineId,
                        nome_linea: lineData.data().nome_linea,
                        listaRefsOcchiale: (lineData.data().listaRefsOcchiale || [])
                            .filter((ref) => ref && typeof ref.id === "string")
                            .map((ref) => ref.id)
                    };
                }
                return null;
            })
        );

        return result.filter((line) => line !== null);
    } catch (error) {
        console.error("Errore durante il recupero delle linee:", error);
        throw error;
    }
};

export const fetchGlasses = async (glassIds) => {
    try {
        const result = await Promise.all(
            glassIds.map(async (glassId) => {
                const glassDocRef = doc(collection(db, "Occhiale"), glassId);
                const glassData = await getDoc(glassDocRef);
                if (glassData.exists()) {
                    return {
                        idOcchiale: glassId,
                        nome_modello: glassData.data().nome_modello,
                        // Gestione di lista_taglie come array di DocumentReference
                        lista_taglie: (glassData.data().lista_taglie || [])
                            .filter((ref) => ref && ref.id)
                            .map((ref) => ref.id)
                    };
                }
                return null;
            })
        );

        return result.filter((glass) => glass !== null);
    } catch (error) {
        console.error("Errore durante il recupero degli occhiali:", error);
        throw error;
    }
};

export const fetchSizes = async (sizeIds) => {
    try {
        const result = await Promise.all(
            sizeIds.map(async (sizeId) => {
                const sizeDocRef = doc(collection(db, "Taglia"), sizeId);
                const sizeData = await getDoc(sizeDocRef);
                if (sizeData.exists()) {
                    return {
                        idTaglia: sizeId,
                        size: sizeData.data().size,
                        listaRefModels: (sizeData.data().listaRefModels || [])
                            .filter((ref) => ref && ref.id)
                            .map((ref) => ref.id)
                    };
                }
                return null;
            })
        );

        return result.filter((size) => size !== null);
    } catch (error) {
        console.error("Errore durante il recupero delle taglie:", error);
        throw error;
    }
};

export const fetchModels = async (modelIds) => {
    try {
        const result = await Promise.all(
            modelIds.map(async (modelId) => {
                const modelDocRef = doc(collection(db, "Modello"), modelId);
                const modelData = await getDoc(modelDocRef);
                if (modelData.exists()) {
                    return {
                        idModello: modelId,
                        nomeOcchiale: modelData.data().nomeOcchiale,
                        esaColorFramePrimary: modelData.data().esaColorFramePrimary,
                        esaColorFrameSecondary: modelData.data().esaColorFrameSecondary,
                        esaColorLensesPrimary: modelData.data().esaColorLensesPrimary,
                        esaColorLensesSecondary: modelData.data().esaColorLensesSecondary
                    };
                }
                return null;
            })
        );

        return result.filter((model) => model !== null);
    } catch (error) {
        console.error("Errore durante il recupero dei modelli:", error);
        throw error;
    }
};