export const processCatalogueData = (collection) => {
    const catalogueMap = {};

    collection.forEach((item) => {
        const { catalogue_refs_list, page_analitycs_list } = item;

        if (!catalogue_refs_list || !Array.isArray(catalogue_refs_list)) return;
        if (!Array.isArray(page_analitycs_list)) return;

        catalogue_refs_list.forEach((catalogueRef) => {
            if (!catalogueMap[catalogueRef]) {
                catalogueMap[catalogueRef] = {
                    glasses: new Map(),
                    sizes: new Map(),
                    variants: new Map(),
                };
            }

            const relevantPages = page_analitycs_list.filter(
                (page) =>
                    page.page_name.startsWith("VTO") ||
                    page.page_name.startsWith("SingleGlassesPage")
            );

            relevantPages.forEach((page) => {
                const { visualizazion_time } = page;
                const pageParts = page.page_name.split(" - ");

                if (pageParts.length === 4) {
                    const [, idGlasse, idSize, idVariant] = pageParts;

                    const updateMap = (map, id, time) => {
                        if (!map.has(id)) {
                            map.set(id, {
                                visualization: 0,
                                visualizationTime: 0,
                            });
                        }
                        const current = map.get(id);
                        map.set(id, {
                            visualization: current.visualization + 1,
                            visualizationTime: current.visualizationTime + time,
                        });
                    };

                    updateMap(
                        catalogueMap[catalogueRef].glasses,
                        idGlasse,
                        visualizazion_time
                    );
                    updateMap(
                        catalogueMap[catalogueRef].sizes,
                        idSize,
                        visualizazion_time
                    );
                    updateMap(
                        catalogueMap[catalogueRef].variants,
                        idVariant,
                        visualizazion_time
                    );
                }
            });
        });
    });

    return Object.entries(catalogueMap).map(([catalogueRef, details]) => ({
        catalogueRef,
        glasses: Array.from(details.glasses, ([id, data]) => ({
            id,
            visualization: data.visualization,
            visualizationTime: data.visualizationTime,
        })),
        sizes: Array.from(details.sizes, ([id, data]) => ({
            id,
            visualization: data.visualization,
            visualizationTime: data.visualizationTime,
        })),
        variants: Array.from(details.variants, ([id, data]) => ({
            id,
            visualization: data.visualization,
            visualizationTime: data.visualizationTime,
        })),
    }));
};
