import React, { useEffect, useRef, useState } from 'react';
import { Modal, Tabs, theme } from 'antd';
import hljs from 'highlight.js';
import 'highlight.js/styles/agate.css';

const IFrameModal = ({ open, setOpen, modalText, iframeVtoString, handleOk }) => {
    const { token } = theme.useToken();
    const codeRef3D = useRef(null);
    const codeRefVTO = useRef(null);
    // Gestione dello stato della tab attiva: "1" per il 3D Viewer, "2" per VTO
    const [activeKey, setActiveKey] = useState("1");

    const normalizeIndentation = (text) => {
        const lines = text.split("\n");
        const minIndent = Math.min(
            ...lines
                .filter((line) => line.trim().length > 0)
                .map((line) => line.match(/^(\s*)/)[1].length)
        );
        return lines.map((line) => line.slice(minIndent)).join("\n");
    };

    const resetAndHighlight = () => {
        if (codeRef3D.current) {
            codeRef3D.current.innerHTML = '';
            codeRef3D.current.textContent = normalizeIndentation(modalText || '');
            codeRef3D.current.removeAttribute('data-highlighted');
            hljs.highlightElement(codeRef3D.current);
        }
        if (codeRefVTO.current) {
            codeRefVTO.current.innerHTML = '';
            codeRefVTO.current.textContent = normalizeIndentation(iframeVtoString || '');
            codeRefVTO.current.removeAttribute('data-highlighted');
            hljs.highlightElement(codeRefVTO.current);
        }
    };

    useEffect(() => {
        if (open) {
            resetAndHighlight();
        }
    }, [open, modalText, iframeVtoString]);

    const handleCancel = () => {
        setOpen(false);
    };

    const items = [
        {
            label: '3D Viewer',
            key: '1',
            forceRender: true,
            children: (
                <pre
                    style={{
                        overflowX: "auto",
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                        padding: "16px",
                        backgroundColor: "#333333",
                        color: "#f8f8f2",
                        borderRadius: "8px",
                    }}
                >
                    <code ref={codeRef3D} className="hljs language-html"></code>
                </pre>
            ),
        },
        {
            label: 'VTO',
            key: '2',
            forceRender: true,
            children: (
                <pre
                    style={{
                        overflowX: "auto",
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                        padding: "16px",
                        backgroundColor: "#333333",
                        color: "#f8f8f2",
                        borderRadius: "8px",
                    }}
                >
                    <code ref={codeRefVTO} className="hljs language-html"></code>
                </pre>
            ),
        },
    ];

    // Handler per il pulsante OK che copia il testo del tab attivo
    const onModalOk = () => {
        // Se il tab attivo è "1" copia modalText, altrimenti iframeVtoString
        const textToCopy = activeKey === "1" ? modalText : iframeVtoString;
        handleOk(textToCopy);
    };

    return (
        <Modal
            title="IFrame Codes"
            open={open}
            onCancel={handleCancel}
            cancelButtonProps={{ style: { display: 'none' } }}
            onOk={onModalOk}
            okText="Copy"
            width={"80%"}
        >
            <Tabs
                defaultActiveKey="1"
                activeKey={activeKey}
                onChange={setActiveKey}
                type="card"
                items={items}
            />
        </Modal>
    );
};

export default IFrameModal;