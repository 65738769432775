import dayjs from "dayjs";
import { fetchBrands, fetchGlasses, fetchLines, fetchModels } from "../api/gatewaysApi";
import { processCatalogueData } from "../data/gatewaysData";
import axios from "axios";
import { db } from "../../data/base";
import { doc, updateDoc, getDoc } from "firebase/firestore";

export const calculateGatewaysOverview = (data) => {
    if (!Array.isArray(data)) {
        console.error("Errore: dati non validi per calculateGatewaysOverview");
        return [];
    }

    const gatewayStats = {};

    data.forEach((session) => {
        const { session_start_date, page_analitycs_list, device_id, is_first_session, gateway_name } = session;

        if (!gateway_name) return;

        // Se il gateway non è già nella mappa, lo inizializziamo
        if (!gatewayStats[gateway_name]) {
            gatewayStats[gateway_name] = {
                users: new Set(),
                sessions: 0,
                newUsers: new Set(),
                totalEngagementTime: 0,
                totalTimeSpent: 0,
                dailyVisualizationTimes: {},
                dailyUniqueUsers: {},
                dailyNewUsers: {},
            };
        }

        const gatewayData = gatewayStats[gateway_name];

        // Convertiamo la data della sessione
        const sessionDate = dayjs(session_start_date, "YYYY/MM/DD HH:mm:ss");
        if (!sessionDate.isValid()) return;
        const formattedDate = sessionDate.format("YYYY-MM-DD");

        // Tracciamo gli utenti unici per Gateway
        if (!gatewayData.dailyUniqueUsers[formattedDate]) {
            gatewayData.dailyUniqueUsers[formattedDate] = new Set();
        }
        gatewayData.dailyUniqueUsers[formattedDate].add(device_id);
        gatewayData.users.add(device_id);

        // Tracciamo i nuovi utenti per Gateway
        if (!gatewayData.dailyNewUsers[formattedDate]) {
            gatewayData.dailyNewUsers[formattedDate] = new Set();
        }
        if (is_first_session && !gatewayData.newUsers.has(device_id)) {
            gatewayData.dailyNewUsers[formattedDate].add(device_id);
            gatewayData.newUsers.add(device_id);
        }

        // Contiamo il numero di sessioni per Gateway
        gatewayData.sessions++;

        // Sommiamo il tempo di visualizzazione totale per Gateway
        if (page_analitycs_list && Array.isArray(page_analitycs_list)) {
            const sessionTotalTime = page_analitycs_list.reduce(
                (sum, page) => sum + (page.visualizazion_time || 0),
                0
            );

            gatewayData.totalEngagementTime += sessionTotalTime;
            gatewayData.totalTimeSpent += sessionTotalTime;

            if (!gatewayData.dailyVisualizationTimes[formattedDate]) {
                gatewayData.dailyVisualizationTimes[formattedDate] = {
                    totalTime: 0,
                    sessionCount: 0,
                };
            }

            gatewayData.dailyVisualizationTimes[formattedDate].totalTime += sessionTotalTime;
            gatewayData.dailyVisualizationTimes[formattedDate].sessionCount++;
        }
    });

    // Costruiamo l'array di output per il report Excel
    return Object.entries(gatewayStats).map(([gateway, stats]) => {
        // Calcoliamo le medie giornaliere
        const dailyAverages = Object.entries(stats.dailyVisualizationTimes)
            .sort(([a], [b]) => new Date(a) - new Date(b))
            .map(([date, { totalTime, sessionCount }]) => ({
                date,
                avgTime: totalTime / sessionCount,
                sessionCount,
                totalTime,
            }));

        const avgTime =
            dailyAverages.length > 0
                ? dailyAverages.reduce((sum, { avgTime }) => sum + avgTime, 0) / dailyAverages.length
                : 0;

        return {
            Gateway: gateway,
            Users: stats.users.size,
            Sessions: stats.sessions,
            "New Users": stats.newUsers.size,
            "Avg Time": convertSecondsToMinutes(avgTime),
            "Avg Eng Time": convertSecondsToMinutes(stats.totalEngagementTime / (stats.users.size || 1)),
            "Avg S/U": (stats.sessions / (stats.users.size || 1)).toFixed(2),
        };
    });
};

// 📌 Funzione di supporto per convertire i secondi in minuti
const convertSecondsToMinutes = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return parseFloat((minutes + remainingSeconds / 100).toFixed(2));
};

// 📌 Funzione per il calcolo di Lines, Glasses e Variants
export const calculateLinesGlassesVariants = async (data) => {
    try {
        if (!Array.isArray(data) || data.length === 0) {
            console.warn("Dati vuoti o non validi per calculateLinesGlassesVariants");
            return { lines: [], glasses: [], variants: [] };
        }

        // **Processiamo i dati del catalogo**
        const processedData = processCatalogueData(data);
        if (processedData.length === 0) {
            console.warn("Nessun dato trovato dopo la trasformazione.");
            return { lines: [], glasses: [], variants: [] };
        }

        // **Recuperiamo i dati dal database**
        const uniqueBrands = processedData.map((item) => item.catalogueRef);
        const brands = await fetchBrands(uniqueBrands);

        const lineDetails = await fetchLines(
            brands.flatMap((brand) => (brand.listaRefsLinea || []).map((ref) => ref.id))
        );

        const glassesDetails = await fetchGlasses(
            processedData.flatMap((item) => item.glasses.map((glass) => glass.id))
        );

        const modelsDetails = await fetchModels(
            processedData.flatMap((item) => item.variants.map((variant) => variant.id))
        );

        // **Arricchiamo i dati per gli occhiali**
        const enrichedGlasses = glassesDetails.map((glass) => {
            const matchingGlass = processedData
                .flatMap((item) => item.glasses)
                .find((g) => g.id === glass.idOcchiale);

            return {
                id: glass.idOcchiale,
                name: glass.nome_modello,
                visualizations: matchingGlass ? matchingGlass.visualization : 0,
                time: matchingGlass ? matchingGlass.visualizationTime : 0,
            };
        });

        // **Arricchiamo i dati per le linee**
        const enrichedLines = lineDetails.map((line) => {
            // **Troviamo gli occhiali associati a questa linea**
            const glassesInLine = enrichedGlasses.filter((glass) =>
                (line.listaRefsOcchiale || []).includes(glass.id)
            );

            // **Sommiamo visualizzazioni e tempo per la linea**
            const totalVisualizations = glassesInLine.reduce((sum, glass) => sum + (glass.visualizations || 0), 0);
            const totalTime = glassesInLine.reduce((sum, glass) => sum + (glass.time || 0), 0);

            return {
                name: line.nome_linea,
                visualizations: totalVisualizations,
                time: formatTime(totalTime),
            };
        });

        // **Arricchiamo i dati per le varianti**
        const enrichedModels = modelsDetails.map((model) => {
            const matchingModel = processedData
                .flatMap((item) => item.variants)
                .find((v) => v.id === model.idModello);

            return {
                name: model.nomeOcchiale,
                visualizations: matchingModel ? matchingModel.visualization : 0,
                time: formatTime(matchingModel ? matchingModel.visualizationTime : 0),
            };
        });

        // **Dati Finali**
        const result = {
            lines: enrichedLines,
            glasses: enrichedGlasses.map((glass) => ({
                name: glass.name,
                visualizations: glass.visualizations,
                time: formatTime(glass.time),
            })),
            variants: enrichedModels,
        };
        return result;

    } catch (error) {
        console.error("Errore durante il calcolo delle Lines, Glasses e Variants:", error);
        return { lines: [], glasses: [], variants: [] };
    }
};

// 📌 **Funzione per convertire i secondi in formato HH:mm:ss**
const formatTime = (seconds) => {
    if (!seconds || isNaN(seconds)) return "00:00:00";

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
};

// 📌 Funzione per il calcolo dei dati Device Analytics
export const calculateDeviceAnalytics = (data, selectedTab = "All Devices") => {
    if (!Array.isArray(data) || data.length === 0) {
        console.warn("Dati vuoti o non validi per calculateDeviceAnalytics");
        return {
            summary: [],
            browserUsage: [],
            deviceTypeDistribution: [],
        };
    }

    const browserCounts = {};
    const deviceTypeCounts = { mobile: 0, desktop: 0, tablet: 0 };

    data.forEach(({ device_os }) => {
        const browser = device_os?.split(" - ")[0]?.trim() || "Unknown";
        const deviceType = device_os?.split(" - ")[1]?.toLowerCase() || "unknown";

        if (!browserCounts[browser]) browserCounts[browser] = 0;
        browserCounts[browser]++;

        if (deviceType.includes("mobile")) {
            deviceTypeCounts.mobile++;
        } else if (deviceType.includes("desktop")) {
            deviceTypeCounts.desktop++;
        } else if (deviceType.includes("tablet")) {
            deviceTypeCounts.tablet++;
        }
    });

    // **Filtriamo i browser in base al tab selezionato**
    let filteredCounts = { ...browserCounts };

    if (selectedTab === "Mobile Devices") {
        filteredCounts = data
            .filter(({ device_os }) => device_os?.toLowerCase().includes("mobile"))
            .reduce((acc, { device_os }) => {
                const browser = device_os?.split(" - ")[0]?.trim() || "Unknown";
                acc[browser] = (acc[browser] || 0) + 1;
                return acc;
            }, {});
    } else if (selectedTab === "Desktop Devices") {
        filteredCounts = data
            .filter(({ device_os }) => device_os?.toLowerCase().includes("desktop"))
            .reduce((acc, { device_os }) => {
                const browser = device_os?.split(" - ")[0]?.trim() || "Unknown";
                acc[browser] = (acc[browser] || 0) + 1;
                return acc;
            }, {});
    }

    return {
        summary: [
            { label: "All Devices", total: deviceTypeCounts.mobile + deviceTypeCounts.desktop + deviceTypeCounts.tablet },
            { label: "Mobile Devices", total: deviceTypeCounts.mobile },
            { label: "Desktop Devices", total: deviceTypeCounts.desktop },
        ],
        browserUsage: Object.entries(filteredCounts).map(([name, count]) => ({ name, count })),
        deviceTypeDistribution: [
            { type: "Mobile", count: deviceTypeCounts.mobile },
            { type: "Desktop", count: deviceTypeCounts.desktop },
            { type: "Tablet", count: deviceTypeCounts.tablet },
        ],
    };
};

// 📌 Funzione per il calcolo di Pages Analytics
export const calculatePagesAnalytics = (data) => {
    if (!Array.isArray(data) || data.length === 0) {
        console.warn("Dati vuoti o non validi per calculatePagesAnalytics");
        return [];
    }

    const combinedAnalytics = data.flatMap((item) => item.page_analitycs_list || []);

    const totalVisualisations = combinedAnalytics.length;

    const pageMap = combinedAnalytics.reduce((acc, page) => {
        let { page_name, visualizazion_time = 0 } = page;

        // **Normalizziamo i nomi delle pagine**
        if (page_name.startsWith('VTO -')) {
            page_name = 'VTO';
        } else if (page_name.startsWith('SingleGlassesPage -')) {
            page_name = 'SingleGlassesPage';
        }

        if (!acc[page_name]) {
            acc[page_name] = { pageName: page_name, visualizazion_time: 0, visualisations: 0 };
        }
        acc[page_name].visualizazion_time += visualizazion_time;
        acc[page_name].visualisations += 1;

        return acc;
    }, {});

    return Object.values(pageMap).map((page) => {
        const { pageName, visualizazion_time, visualisations } = page;
        const avgTimeSpent = visualisations > 0 ? Math.floor(visualizazion_time / visualisations) : 0;

        return {
            page: pageName,
            visits: visualisations,
            percentVisualisations: totalVisualisations > 0
                ? ((visualisations / totalVisualisations) * 100).toFixed(2)
                : 0,
            totalTimeSpent: formatTime(visualizazion_time),
            avgTimeSpent: formatTime(avgTimeSpent),
        };
    });
};

// 📌 Funzione per il calcolo di Events Analytics
export const calculateEventsAnalytics = (data) => {
    if (!Array.isArray(data) || data.length === 0) {
        console.warn("Dati vuoti o non validi per calculateEventsAnalytics");
        return [];
    }

    const combinedAnalytics = data.flatMap((item) =>
        (item.events_analytics_list || []).map((event) => ({
            ...event,
            device_id: item.device_id,
        }))
    );

    const eventMap = combinedAnalytics.reduce((acc, event) => {
        Object.entries(event).forEach(([key, value]) => {
            if (
                key !== 'device_id' &&
                key !== 'brand_page_scroll_perc' &&
                key !== 'acceptance_terms' &&
                (typeof value === 'number' ? value > 0 : value === true)
            ) {
                if (!acc[key]) {
                    acc[key] = { eventName: key, eventCount: 0, uniqueUsers: new Set() };
                }
                acc[key].eventCount += value === true ? 1 : value;
                acc[key].uniqueUsers.add(event.device_id);
            }
        });
        return acc;
    }, {});

    return Object.values(eventMap).map((event) => {
        const { eventName, eventCount, uniqueUsers } = event;
        const totalUsers = uniqueUsers.size;
        const avgEventPerUser = totalUsers > 0 ? (eventCount / totalUsers).toFixed(2) : 0;

        return {
            event: formatEventName(eventName),
            count: eventCount,
            totalUsers,
            avgEventPerUser,
        };
    });
};

// **Funzione per formattare il nome degli eventi**
const formatEventName = (eventName) => {
    return eventName
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());
};

/**
 * Normalizza i dati di geolocalizzazione
 * @param {Object} item - Oggetto contenente i dati di geolocalizzazione
 * @returns {Object} Oggetto normalizzato con country, region, city, latitude, longitude
 */
const normalizeGeolocationData = (item) => {
    let country = 'Unknown';
    let region = 'Unknown';
    let city = 'Unknown';
    let latitude = null;
    let longitude = null;

    // Gestione della stringa geoLocation
    if (typeof item.geoLocation === 'string' && item.geoLocation.trim() !== '' && !item.geoLocation.includes('Unknown - Unknown')) {
        const parts = item.geoLocation.split(' - ');
        country = parts[0] || 'Unknown';

        // Se abbiamo 3 parti (Stato - Regione - Città)
        if (parts.length >= 3) {
            region = parts[1] || 'Unknown';
            city = parts[2] || 'Unknown';
        }
        // Se abbiamo 2 parti (Stato - Città)
        else if (parts.length === 2) {
            city = parts[1] || 'Unknown';
            region = 'Unknown';
        }
    }

    // Gestione delle coordinate
    if (typeof item.latitude === 'number' && typeof item.longitude === 'number') {
        latitude = item.latitude;
        longitude = item.longitude;
    }

    // Valuta se tutti i dati di geolocalizzazione sono "Unknown"
    const isAllUnknown = country === 'Unknown' && region === 'Unknown' && city === 'Unknown';
    const hasValidCoordinates = latitude !== null && longitude !== null;

    // Se tutti i dati di geolocalizzazione sono "Unknown" ma abbiamo coordinate valide,
    // utilizziamo comunque il formato standard ma con informazioni coordinate
    if (isAllUnknown && hasValidCoordinates) {
        return {
            country: "Unknown", // Manteniamo il formato standard con Unknown
            region: "Unknown",
            city: "Unknown",
            latitude,
            longitude,
            geoLocation: `Unknown - Unknown - Unknown`, // Formato consistente
            hasValidData: hasValidCoordinates // Le coordinate sono valide
        };
    }

    // Determina se i dati sono validi (non tutti Unknown o ha coordinate)
    const hasValidData = !isAllUnknown || hasValidCoordinates;

    return {
        country,
        region,
        city,
        latitude,
        longitude,
        geoLocation: (typeof item.geoLocation === 'string' && item.geoLocation.trim() !== '')
            ? item.geoLocation
            : `${country} - ${region} - ${city}`,
        hasValidData
    };
};

/**
 * Ottiene i dati di geolocalizzazione da un indirizzo IP
 * @param {string} ip - Indirizzo IP
 * @returns {Promise<{latitude: number, longitude: number, geoId: string}>}
 */
export const getGeoLocationFromIP = async (ip) => {
    try {
        // Verifica che l'IP sia valido
        const ipRegex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
        if (!ipRegex.test(ip)) {
            console.warn(`⚠️ Indirizzo IP non valido: ${ip}`);
            return { latitude: null, longitude: null, geoId: "Unknown - Unknown - Unknown" };
        }

        // Utilizziamo una funzione di supporto che tenta di utilizzare diversi servizi
        try {
            // Prima proviamo con il servizio MaxMind tramite il proxy interno
            try {
                // Utilizziamo il proxy interno aziendale che a sua volta chiama MaxMind
                const response = await axios.post(
                    "https://us-central1-arshades-7e18a.cloudfunctions.net/getLocationFromIP",
                    { data: { ip } },
                    { headers: { "Content-Type": "application/json" } }
                );

                if (response.data && response.data.result && response.data.result.success) {
                    const { country, region, city } = response.data.result;

                    // Estrai coordinate dalla risposta 
                    const latitude = response.data.result.location?.latitude ||
                        response.data.result.latitude ||
                        response.data.result.lat ||
                        null;

                    const longitude = response.data.result.location?.longitude ||
                        response.data.result.longitude ||
                        response.data.result.lon ||
                        null;

                    // Converte in numeri se necessario
                    const parsedLat = latitude ? parseFloat(latitude) : null;
                    const parsedLon = longitude ? parseFloat(longitude) : null;

                    if (parsedLat && parsedLon) {
                        const countryName = country?.name || "Unknown";
                        const regionName = region?.name || "Unknown";
                        const cityName = city || "Unknown";

                        // Formato standardizzato
                        const geoId = `${countryName} - ${regionName} - ${cityName}`;

                        if (countryName !== "Unknown" || regionName !== "Unknown" || cityName !== "Unknown") {
                            return {
                                latitude: parsedLat,
                                longitude: parsedLon,
                                geoId
                            };
                        }
                    }
                } else {
                    console.warn(`⚠️ Il proxy interno ha restituito una risposta non valida per IP ${ip}`);
                }
            } catch (proxyError) {
                console.warn(`⚠️ Errore durante la chiamata al proxy interno: ${proxyError.message}`);
            }

            // Se il proxy interno fallisce, proviamo con MaxMind direttamente
            try {
                const response = await fetch(`https://api.maxmind.com/geoip/v2.1/city/${ip}?demo=1`, {
                    mode: 'cors',
                    headers: {
                        'Accept': 'application/json'
                    }
                });

                if (response.ok) {
                    const data = await response.json();

                    // Verifica la validità dei dati di risposta
                    if (data.location && data.location.latitude && data.location.longitude) {
                        // Estrai i dati di geolocalizzazione
                        const latitude = data.location.latitude;
                        const longitude = data.location.longitude;

                        // Estrai informazioni sul paese, regione e città
                        const country = data.country ? data.country.names.en : "Unknown";
                        const region = data.subdivisions && data.subdivisions.length > 0 ? data.subdivisions[0].names.en : "Unknown";
                        const city = data.city ? data.city.names.en : "Unknown";

                        // Formattazione standard per la stringa geoLocation
                        const geoId = `${country} - ${region} - ${city}`;

                        // Verifica se abbiamo ottenuto dati significativi
                        if (country !== "Unknown" || region !== "Unknown" || city !== "Unknown") {
                            return { latitude, longitude, geoId };
                        }
                    }
                } else {
                    console.warn(`⚠️ MaxMind ha restituito un errore: ${response.status} ${response.statusText}`);
                }
            } catch (maxmindError) {
                console.warn(`⚠️ Errore durante la chiamata a MaxMind: ${maxmindError.message}`);
            }

            // Se MaxMind fallisce, proviamo con ipapi.co
            try {
                const ipapiResponse = await fetch(`https://ipapi.co/${ip}/json/`);

                if (ipapiResponse.ok) {
                    const ipapiData = await ipapiResponse.json();

                    if (ipapiData.latitude && ipapiData.longitude) {
                        const latitude = ipapiData.latitude;
                        const longitude = ipapiData.longitude;
                        const country = ipapiData.country_name || "Unknown";
                        const region = ipapiData.region || "Unknown";
                        const city = ipapiData.city || "Unknown";

                        const geoId = `${country} - ${region} - ${city}`;

                        if (country !== "Unknown" || region !== "Unknown" || city !== "Unknown") {
                            return { latitude, longitude, geoId };
                        }
                    }
                } else {
                    console.warn(`⚠️ ipapi.co ha restituito un errore: ${ipapiResponse.status}`);
                }
            } catch (ipapiError) {
                console.warn(`⚠️ Errore durante la chiamata a ipapi.co: ${ipapiError.message}`);
            }

            // Se anche ipapi.co fallisce, proviamo con ipinfo.io
            try {
                const ipinfoResponse = await fetch(`https://ipinfo.io/${ip}/json`);

                if (ipinfoResponse.ok) {
                    const ipinfoData = await ipinfoResponse.json();

                    if (ipinfoData.loc) {
                        const [latitude, longitude] = ipinfoData.loc.split(',').map(coord => parseFloat(coord));

                        if (!isNaN(latitude) && !isNaN(longitude)) {
                            const country = ipinfoData.country ? ipinfoData.country : "Unknown";
                            const region = ipinfoData.region || "Unknown";
                            const city = ipinfoData.city || "Unknown";

                            const geoId = `${country} - ${region} - ${city}`;

                            if (country !== "Unknown" || region !== "Unknown" || city !== "Unknown") {
                                return { latitude, longitude, geoId };
                            }
                        }
                    }
                } else {
                    console.warn(`⚠️ ipinfo.io ha restituito un errore: ${ipinfoResponse.status}`);
                }
            } catch (ipinfoError) {
                console.warn(`⚠️ Errore durante la chiamata a ipinfo.io: ${ipinfoError.message}`);
            }

            // Se anche ipinfo.io fallisce, proviamo con ipgeolocation.io
            try {
                const ipgeoResponse = await fetch(`https://api.ipgeolocation.io/ipgeo?apiKey=d9c8ca199bd742f594549744d221c417&ip=${ip}`);

                if (ipgeoResponse.ok) {
                    const ipgeoData = await ipgeoResponse.json();

                    if (ipgeoData.latitude && ipgeoData.longitude) {
                        const latitude = parseFloat(ipgeoData.latitude);
                        const longitude = parseFloat(ipgeoData.longitude);
                        const country = ipgeoData.country_name || "Unknown";
                        const region = ipgeoData.state_prov || "Unknown";
                        const city = ipgeoData.city || "Unknown";

                        const geoId = `${country} - ${region} - ${city}`;

                        if (country !== "Unknown" || region !== "Unknown" || city !== "Unknown") {
                            return { latitude, longitude, geoId };
                        }
                    }
                } else {
                    console.warn(`⚠️ ipgeolocation.io ha restituito un errore: ${ipgeoResponse.status}`);
                }
            } catch (ipgeoError) {
                console.warn(`⚠️ Errore durante la chiamata a ipgeolocation.io: ${ipgeoError.message}`);
            }

            // Se anche ipgeolocation.io fallisce, proviamo con ip-api.com
            try {
                const ipApiComResponse = await fetch(`https://ip-api.com/json/${ip}?fields=country,regionName,city,lat,lon,status`);

                if (ipApiComResponse.ok) {
                    const ipApiComData = await ipApiComResponse.json();

                    if (ipApiComData.status === "success" && ipApiComData.lat && ipApiComData.lon) {
                        const latitude = ipApiComData.lat;
                        const longitude = ipApiComData.lon;
                        const country = ipApiComData.country || "Unknown";
                        const region = ipApiComData.regionName || "Unknown";
                        const city = ipApiComData.city || "Unknown";

                        const geoId = `${country} - ${region} - ${city}`;

                        if (country !== "Unknown" || region !== "Unknown" || city !== "Unknown") {
                            return { latitude, longitude, geoId };
                        }
                    }
                } else {
                    console.warn(`⚠️ ip-api.com ha restituito un errore: ${ipApiComResponse.status}`);
                }
            } catch (ipApiComError) {
                console.warn(`⚠️ Errore durante la chiamata a ip-api.com: ${ipApiComError.message}`);
            }

            // Ultima spiaggia: prova con abstractapi.com
            try {
                const abstractApiKey = "d15f9b2af53949c9b3dfd6fd70a47b67";
                const abstractApiResponse = await fetch(`https://ipgeolocation.abstractapi.com/v1/?api_key=${abstractApiKey}&ip_address=${ip}`);

                if (abstractApiResponse.ok) {
                    const abstractApiData = await abstractApiResponse.json();

                    if (abstractApiData.latitude && abstractApiData.longitude) {
                        const latitude = parseFloat(abstractApiData.latitude);
                        const longitude = parseFloat(abstractApiData.longitude);
                        const country = abstractApiData.country || "Unknown";
                        const region = abstractApiData.region || "Unknown";
                        const city = abstractApiData.city || "Unknown";

                        const geoId = `${country} - ${region} - ${city}`;

                        if (country !== "Unknown" || region !== "Unknown" || city !== "Unknown") {
                            return { latitude, longitude, geoId };
                        }
                    }
                } else {
                    console.warn(`⚠️ abstractapi.com ha restituito un errore: ${abstractApiResponse.status}`);
                }
            } catch (abstractApiError) {
                console.warn(`⚠️ Errore durante la chiamata a abstractapi.com: ${abstractApiError.message}`);
            }

        } catch (error) {
            console.error(`❌ Errore durante il recupero della geolocalizzazione per IP ${ip}:`, error);
        }

        // Se arriviamo qui, tutti i tentativi sono falliti
        console.warn(`⚠️ Nessun servizio è riuscito a fornire dati di geolocalizzazione per IP ${ip}`);
        return { latitude: null, longitude: null, geoId: "Unknown - Unknown - Unknown" };
    } catch (error) {
        console.error(`❌ Errore durante il recupero della geolocalizzazione per IP ${ip}:`, error);
        return { latitude: null, longitude: null, geoId: "Unknown - Unknown - Unknown" };
    }
};

/**
 * Calcola i dati di geolocalizzazione per il report sui gateway
 * @param {Array} data - Dati dei gateway
 * @returns {Promise<Object>} - Dati di geolocalizzazione calcolati
 */
export const calculateGeolocationData = async (data) => {
    const countries = {};
    const cities = {};
    const locations = {};
    const uniqueDeviceIds = new Set();
    let totalItemsCount = 0;
    let validItems = 0;
    let skippedItems = 0;

    if (!data || data.length === 0) {
        return {
            countries: [],
            cities: [],
            locations: [],
            uniqueDeviceIds: 0,
            totalItems: 0
        };
    }

    if (data.length > 0 && !data[0].id) {
        console.warn('⚠️ Warning: Data objects do not have document IDs. This will prevent updating Firestore.');
    }

    // Filtriamo e normalizziamo i dati
    for (const item of data) {
        totalItemsCount++;

        // Verifica che l'item abbia informazioni di geolocalizzazione valide
        const hasValidGeoInfo = typeof item.geoLocation === 'string' &&
            item.geoLocation.trim() !== '' &&
            !item.geoLocation.includes('Unknown - Unknown - Unknown');

        // Se non abbiamo informazioni di geolocalizzazione valide, tentiamo di ottenerle solo se abbiamo un IP valido
        if (!hasValidGeoInfo && item.device_id) {
            const ipRegex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
            if (!ipRegex.test(item.device_id)) {
                // IP non valido, saltiamo questo record
                console.warn(`⚠️ Skipping item with invalid IP: ${item.device_id}`);
                skippedItems++;
                continue;
            }

            try {
                // Tentiamo di ottenere informazioni dall'IP
                const geoData = await getGeoLocationFromIP(item.device_id);

                // Solo se abbiamo ottenuto dati validi, aggiorniamo il documento
                if (geoData.latitude && geoData.longitude && geoData.geoId &&
                    !geoData.geoId.includes('Unknown - Unknown - Unknown')) {

                    // Aggiorna il database solo se abbiamo un ID documento
                    if (item.id) {
                        try {
                            const docRef = doc(db, "Session_Gateway", item.id);
                            await updateDoc(docRef, {
                                latitude: geoData.latitude,
                                longitude: geoData.longitude,
                                geoLocation: geoData.geoId
                            });
                        } catch (error) {
                            console.error(`❌ Error updating database for item ${item.id}:`, error);
                        }
                    }

                    // Aggiorniamo l'item con i nuovi dati
                    item.latitude = geoData.latitude;
                    item.longitude = geoData.longitude;
                    item.geoLocation = geoData.geoId;

                    // Ora l'item ha dati validi
                } else {
                    // Non abbiamo ottenuto dati validi, saltiamo questo record
                    console.warn(`⚠️ Couldn't get valid geolocation data for IP: ${item.device_id}`);
                    skippedItems++;
                    continue;
                }
            } catch (error) {
                console.error(`❌ Error retrieving geolocation from IP:`, error);
                skippedItems++;
                continue;
            }
        }

        // Verifichiamo ancora se abbiamo dati validi dopo il tentativo di recupero
        const updatedHasValidGeoInfo = typeof item.geoLocation === 'string' &&
            item.geoLocation.trim() !== '' &&
            !item.geoLocation.includes('Unknown - Unknown - Unknown');

        const updatedHasValidCoordinates = typeof item.latitude === 'number' &&
            typeof item.longitude === 'number';

        // Procedi solo se abbiamo dati validi
        if (updatedHasValidGeoInfo || updatedHasValidCoordinates) {
            validItems++;

            // Track unique users by device_id
            if (item.device_id) {
                uniqueDeviceIds.add(item.device_id);
            }

            // Extract country and city from the location string
            let country = 'Unknown';
            let city = 'Unknown';
            let region = 'Unknown';

            if (updatedHasValidGeoInfo) {
                const parts = item.geoLocation.split(' - ');
                country = parts[0] || 'Unknown';
                region = parts.length > 1 ? parts[1] : 'Unknown';
                city = parts.length > 2 ? parts[2] : 'Unknown';
            }

            // Count countries
            if (country !== 'Unknown') {
                if (!countries[country]) {
                    countries[country] = 1;
                } else {
                    countries[country]++;
                }
            }

            // Count cities
            if (city !== 'Unknown') {
                const cityKey = `${city} (${country})`;
                if (!cities[cityKey]) {
                    cities[cityKey] = 1;
                } else {
                    cities[cityKey]++;
                }
            }

            // Count locations
            if (updatedHasValidGeoInfo) {
                if (!locations[item.geoLocation]) {
                    locations[item.geoLocation] = 1;
                } else {
                    locations[item.geoLocation]++;
                }
            }
        } else {
            skippedItems++;
            console.warn(`⚠️ Skipping item with invalid geolocation data`);
        }
    }

    // Preparazione dei dati per il report
    return {
        countries: Object.entries(countries)
            .map(([country, count]) => ({ country, count }))
            .sort((a, b) => b.count - a.count),
        cities: Object.entries(cities)
            .map(([city, count]) => ({ city, count }))
            .sort((a, b) => b.count - a.count),
        locations: Object.entries(locations)
            .map(([location, count]) => ({ location, count }))
            .sort((a, b) => b.count - a.count),
        uniqueDeviceIds: uniqueDeviceIds.size,
        totalItems: totalItemsCount,
        validItems: validItems,
        skippedItems: skippedItems
    };
};

/**
 * Corregge i dati di geolocalizzazione nel database
 * @param {string} customFormatToFix - Formato personalizzato da correggere (es. "Location with coordinates")
 * @returns {Promise<number>} Numero di documenti corretti
 */
export const fixGeolocationDataInDatabase = async (customFormatToFix = "Location with coordinates") => {
    try {
        // Importiamo i moduli necessari
        const { collection, query, where, getDocs } = require("firebase/firestore");

        // Cerca i documenti con il formato personalizzato
        let docsToFix = [];

        // 1. Prima cerca documenti che contengono il formato personalizzato
        const formatQuery = query(
            collection(db, "Session_Gateway"),
            where("geoLocation", ">=", customFormatToFix),
            where("geoLocation", "<=", customFormatToFix + "\uf8ff")
        );

        const formatQuerySnapshot = await getDocs(formatQuery);
        docsToFix = formatQuerySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));

        // 2. Aggiungi anche documenti con geoLocation vuota o non valida
        const emptyQuery = query(
            collection(db, "Session_Gateway"),
            where("geoLocation", "==", "")
        );

        const emptyQuerySnapshot = await getDocs(emptyQuery);
        const emptyDocs = emptyQuerySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        docsToFix = [...docsToFix, ...emptyDocs];

        // 3. Aggiungi anche documenti con geoLocation "Unknown - Unknown - Unknown"
        const unknownQuery = query(
            collection(db, "Session_Gateway"),
            where("geoLocation", "==", "Unknown - Unknown - Unknown")
        );

        const unknownQuerySnapshot = await getDocs(unknownQuery);
        const unknownDocs = unknownQuerySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        docsToFix = [...docsToFix, ...unknownDocs];

        // 4. Correggi i documenti trovati
        let correctedCount = 0;

        for (const document of docsToFix) {
            // Verifica che l'oggetto abbia un device_id valido (che dovrebbe essere un IP)
            if (!document.device_id) {
                console.warn(`⚠️ Documento ${document.id} non ha un device_id. Impossibile geolocalizzare.`);
                continue;
            }

            const ipRegex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
            if (!ipRegex.test(document.device_id)) {
                console.warn(`⚠️ Documento ${document.id} ha un device_id che non sembra essere un indirizzo IP valido: ${document.device_id}`);
                continue;
            }

            try {
                // Usa il servizio di geocoding per ottenere il nome della località
                const geoData = await getGeoLocationFromIP(document.device_id);

                // Solo se abbiamo ottenuto dati validi, aggiorniamo il database
                if (geoData.latitude && geoData.longitude && geoData.geoId &&
                    !geoData.geoId.includes('Unknown - Unknown - Unknown')) {

                    const docRef = doc(db, "Session_Gateway", document.id);
                    await updateDoc(docRef, {
                        latitude: geoData.latitude,
                        longitude: geoData.longitude,
                        geoLocation: geoData.geoId
                    });

                    correctedCount++;
                } else {
                    console.warn(`⚠️ Non è stato possibile ottenere informazioni geolocalizzate per ${document.device_id}`);
                }
            } catch (error) {
                console.error(`❌ Errore nell'aggiornamento del documento ${document.id}:`, error);
            }
        }
        return correctedCount;
    } catch (error) {
        console.error("❌ Errore durante la correzione dei dati di geolocalizzazione:", error);
        return 0;
    }
};