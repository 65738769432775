import React, { useReducer } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../data/base';
import { Button, message, Table } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import styles from './AdminStyling.module.css';

const initialState = {
    taglia: { loading: false, msg: "", list: {} },
    glass: { loading: false, msg: "", list: {} },
    model: { loading: false, msg: "", list: {} },
    sizeIsOrphan: { loading: false, msg: "", list: [] },
};

function reducer(state, action) {
    switch (action.type) {
        case 'SIZ':
            return { ...state, taglia: { ...state.taglia, ...action.value } };
        case 'GLA':
            return { ...state, glass: { ...state.glass, ...action.value } };
        case 'MOD':
            return { ...state, model: { ...state.model, ...action.value } };
        case 'SIO':
            return { ...state, sizeIsOrphan: { ...state.sizeIsOrphan, ...action.value } };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}

const DbTestComponent = () => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const groupByCode = (list = [], category = 'codiceVariant') => {
        return list.reduce((acc, item) => {
            const itemCode = item[category];
            if (!acc[itemCode]) {
                acc[itemCode] = [];
            }
            acc[itemCode].push(item);
            return acc;
        }, {});
    };

    const testOcchiale = async () => {
        dispatch({ type: 'GLA', value: { loading: true } });
        const occhialeSnapshot = await getDocs(collection(db, "Occhiale"));
        const allList = [];
        const duplicates = [];
        const docs = occhialeSnapshot.docs;
        for (let i = 0; i < docs.length; i++) {
            const glass = docs[i].data();
            glass.id = docs[i].id;
            dispatch({ type: 'GLA', value: { msg: `${i} of ${allList.length} processing` } });
            const findDuplicate = allList.find(el => el.codiceOcchiale === glass.codiceOcchiale);
            if (findDuplicate !== undefined) {
                if (!duplicates.find(el => el.codiceOcchiale === findDuplicate.codiceOcchiale)) {
                    duplicates.push(findDuplicate);
                }
                duplicates.push(glass);
            } else {
                allList.push(glass);
            }
        }
        if (duplicates.length === 0) message.success("Test passed");
        else message.error("Test failed! Duplicate codes detected");
        dispatch({
            type: 'GLA',
            value: {
                list: groupByCode(duplicates, 'codiceOcchiale'),
                loading: false,
                msg: `${duplicates.length} duplicate found`
            }
        });
    };

    const testTaglia = async () => {
        try {
            dispatch({ type: 'SIZ', value: { loading: true } });
            const tagliaSnapshot = await getDocs(collection(db, "Taglia"));
            const allList = [];
            const duplicates = [];
            const docs = tagliaSnapshot.docs;
            for (let i = 0; i < docs.length; i++) {
                const taglia = docs[i].data();
                taglia.id = docs[i].id;
                dispatch({ type: 'SIZ', value: { msg: `${i} of ${allList.length} processing` } });
                const findDuplicate = allList.find(el => el.codiceTaglia === taglia.codiceTaglia);
                if (findDuplicate !== undefined) {
                    if (!duplicates.find(el => el.codiceTaglia === findDuplicate.codiceTaglia)) {
                        duplicates.push(findDuplicate);
                    }
                    duplicates.push(taglia);
                } else {
                    allList.push(taglia);
                }
            }
            if (duplicates.length === 0) message.success("Test passed");
            else message.error("Test Failed! Duplicate codes detected");
            dispatch({
                type: 'SIZ',
                value: {
                    list: groupByCode(duplicates, 'codiceTaglia'),
                    loading: false,
                    msg: `${duplicates.length} size duplicate found`
                }
            });
        } catch (e) {
            console.log(e);
        }
    };

    const testModello = async () => {
        try {
            dispatch({ type: 'MOD', value: { loading: true } });
            const modelloSnapshot = await getDocs(collection(db, "Modello"));
            const allList = [];
            const duplicates = [];
            const docs = modelloSnapshot.docs;
            for (let i = 0; i < docs.length; i++) {
                const modello = docs[i].data();
                modello.id = docs[i].id;
                dispatch({ type: 'MOD', value: { msg: `${i} of ${allList.length} processing` } });
                const findDuplicate = allList.find(el => el.codiceVariante === modello.codiceVariante);
                if (findDuplicate !== undefined) {
                    if (!duplicates.find(el => el.codiceVariante === findDuplicate.codiceVariante)) {
                        duplicates.push(findDuplicate);
                    }
                    duplicates.push(modello);
                } else {
                    allList.push(modello);
                }
            }
            if (duplicates.length === 0) message.success("Test passed");
            else message.error("Test Failed! Duplicate codes detected");
            dispatch({
                type: 'MOD',
                value: {
                    msg: `${duplicates.length} variant duplicate found`,
                    list: groupByCode(duplicates, 'codiceVariante'),
                    loading: false
                }
            });
        } catch (e) {
            console.log(e);
            dispatch({ type: 'MOD', value: { loading: false } });
        }
    };

    const isTagliaOrphan = async () => {
        try {
            dispatch({ type: 'SIO', value: { loading: true } });
            const tagliaSnapshot = await getDocs(collection(db, "Taglia"));
            const occhialeSnapshot = await getDocs(collection(db, "Occhiale"));
            const referencedSet = new Set();
            occhialeSnapshot.forEach(doc => {
                const data = doc.data();
                if (data.lista_taglie && Array.isArray(data.lista_taglie)) {
                    data.lista_taglie.forEach(taglia => {
                        referencedSet.add(taglia.id);
                    });
                }
            });
            const unReferencedList = tagliaSnapshot.docs
                .filter(doc => !referencedSet.has(doc.id))
                .map(doc => ({ id: doc.id, ...doc.data() }));
            dispatch({
                type: 'SIO',
                value: { msg: `${unReferencedList.length} unreferenced size found`, list: unReferencedList, loading: false }
            });
            if (unReferencedList.length === 0) message.success("Test passed");
            else message.error("Test Failed! Unreferenced sizes detected");
        } catch (e) {
            console.log(e);
        }
    };

    const columns = [
        {
            title: 'Test Name',
            dataIndex: 'name',
            key: 'name',
            width: '40%',
            render: text => <strong>{text}</strong>
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '40%',
            render: (_, record) => record.state.msg
        },
        {
            title: 'Action',
            key: 'action',
            width: '20%',
            align: 'center',
            render: (_, record) => (
                <Button
                    type="primary"
                    onClick={record.onClick}
                    disabled={record.state.loading}
                >
                    {record.state.loading ? (
                        <>
                            <SyncOutlined spin style={{ marginRight: 8 }} />
                            Testing...
                        </>
                    ) : 'Test'}
                </Button>
            )
        }
    ];

    const data = [
        {
            key: '1',
            name: 'Test Glass Code',
            state: state.glass,
            onClick: testOcchiale
        },
        {
            key: '2',
            name: 'Test Size Code',
            state: state.taglia,
            onClick: testTaglia
        },
        {
            key: '3',
            name: 'Test Variants',
            state: state.model,
            onClick: testModello
        },
        {
            key: '4',
            name: 'Check Variant Reference',
            state: state.sizeIsOrphan,
            onClick: isTagliaOrphan
        }
    ];

    return (
        <div className={styles["client-list-container"]}>
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                variant="bordered"
                size="small"
                style={{ width: '100%' }}
            />
        </div>
    );
};

export default DbTestComponent;