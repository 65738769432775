export const barChartData = {
    labels: ['Mon', 'Tue', 'Wen', 'Thus', 'Fri', 'Sat', 'Sun'],
    datasets: [
        {
            label: 'y',
            data: [35, 59, 80, 81, 56, 55, 40],
            borderColor: '#7e37d8',
            backgroundColor: 'rgba(126,  55, 216, 0.6)',
            borderWidth: 2,
        },
        {
            label: 'z',
            data: [28, 48, 40, 19, 86, 27, 90],
            borderColor: '#06b5dd',
            backgroundColor: 'rgba(6, 181, 221, 0.8)',
            borderWidth: 2,
        }
    ]
};

export const barChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
        legend: {
            display: true,
        }
    }
};

export const lineChartData = {
    labels: ['', '10', '20', '30', '40', '50', '60', '70', '80'],
    datasets: [
        {
            label: 'Dataset 1',
            backgroundColor: '#41b1ac',
            borderColor: '#158df7',
            data: [10, 20, 40, 30, 0, 20, 10, 30, 10],
            tension: 0.4
        },
        {
            label: 'Dataset 2',
            backgroundColor: '#41b1ac',
            borderColor: '#7e37d8',
            data: [20, 40, 10, 20, 40, 30, 40, 10, 20],
            tension: 0.4
        },
        {
            label: 'Dataset 3',
            backgroundColor: '#41b1ac',
            borderColor: '#fd517d',
            data: [60, 10, 40, 30, 80, 30, 20, 90, 0],
            tension: 0.4
        }
    ]
};

export const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: true
        }
    },
    scales: {
        x: {
            grid: {
                display: false
            }
        },
        y: {
            beginAtZero: true
        }
    }
};

export const doughnutData = {
    labels: ['Download Sales', 'In-Store Sales', 'Mail-Order Sales'],
    datasets: [
        {
            data: [300, 50, 100],
            backgroundColor: ['#7e37d8', '#fe80b2', '#80cf00'],
        }
    ]
};

export const doughnutOptions = {
    responsive: true,
    plugins: {
        legend: {
            display: true,
        },
        tooltip: {
            callbacks: {
                label: function (context) {
                    const total = context.dataset.data.reduce((acc, value) => acc + value, 0);
                    const percentage = ((context.raw / total) * 100).toFixed(2);
                    return `${percentage}% (${context.raw})`;
                }
            }
        }
    }
};

export const polarData = {
    labels: [
        'Download Sales',
        'In-Store Sales',
        'Mail Sales',
        'Telesales',
        'Corporate Sales'
    ],
    datasets: [
        {
            data: [300, 50, 100, 40, 120],
            backgroundColor: ['#7e37d8', '#80cf00', '#fe80b2', '#06b5dd', '#fd517d']
        }
    ]
};

export const polarOptions = {
    responsive: true,
    plugins: {
        legend: {
            display: true
        }
    }
};

export const radarData = {
    labels: ['Ford', 'Chevy', 'Toyota', 'Honda', 'Mazda'],
    datasets: [
        {
            label: 'Dataset 1',
            data: [12, 3, 5, 18, 7],
            backgroundColor: 'rgba(6, 181, 221, 0.5)',
            borderColor: '#06b5dd',
            pointBackgroundColor: '#44a6f2',
            pointBorderColor: '#06b5dd',
            pointHoverBackgroundColor: '#06b5dd',
            pointHoverBorderColor: 'rgba(6, 181, 221, 0.4)',
        }
    ]
};

export const radarOptions = {
    responsive: true,
    plugins: {
        legend: {
            display: true,
        }
    }
};

export const getLinearGraphData = (label, record) => {
    const labels = Object.keys(record);
    const data = Object.values(record);

    return {
        labels,
        datasets: [
            {
                label, // Nome del dataset
                data, // Valori del dataset
                backgroundColor: 'transparent', // Sfondo trasparente per la linea
                borderColor: '#5388D8', // Colore della linea
                pointBackgroundColor: '#7e37d8', // Colore dei punti
                pointBorderColor: '#fff', // Colore del bordo dei punti
                pointHoverBackgroundColor: '#000', // Colore di sfondo del punto al passaggio del mouse
                pointHoverBorderColor: 'rgba(30, 166, 236, 1)', // Colore del bordo del punto al passaggio del mouse
                tension: 0.4, // Curvatura della linea (0 = spezzata, 1 = curva massima)
            }
        ]
    };
};