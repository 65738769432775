import { SET_SELECTED_BRAND } from "../actionType";

const initialState = {
  selectedBrand: { id: "all", nome_brand: "All Brand" },
};

const brandReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_BRAND:
      return {
        ...state,
        selectedBrand: action.brand || initialState.selectedBrand, // Usa il fallback
      };
    default:
      return state;
  }
};

export default brandReducer;