import React from 'react';
import { Tooltip } from 'antd';

const CustomTooltip = ({
    children,
    title,
    placement = 'top',
    ...rest
}) => {
    return (
        <Tooltip
            title={title}
            placement={placement}
            color='#001629'
            arrow={{ pointAtCenter: true }}
            {...rest}
        >
            {children}
        </Tooltip>
    );
};

export default CustomTooltip;