import React, { useState, useEffect } from 'react';

const getColumnsCount = () => {
    const width = window.innerWidth;
    if (width >= 1024) return 6;
    if (width >= 768) return 3;
    return 2;
};

const SummaryGrid = ({ children }) => {
    const [columns, setColumns] = useState(getColumnsCount());

    useEffect(() => {
        const handleResize = () => setColumns(getColumnsCount());
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div
            style={{
                display: "grid",
                gap: "16px",
                gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
                justifyContent: "start",
                marginBottom: "2rem",
                marginTop: "2rem",
            }}
        >
            {children}
        </div>
    );
};

export default SummaryGrid;