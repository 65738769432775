import React, { useEffect, useRef, useState } from "react";
import { Table, Row, Col, Card, Spin, Empty, Button, theme } from "antd";
import { Chart } from "chart.js/auto";
import SummaryCard from "../Analytics/SummaryCard";
import SummaryGrid from "../Analytics/SummaryGrid";

function DeviceAnalytics({ data = [], loading, clearFiltersVisible, onResetFilters }) {
    const { token } = theme.useToken();
    const [browserData, setBrowserData] = useState([]);
    const [selectedTab, setSelectedTab] = useState("All Devices");
    const barChartRef = useRef(null);
    const pieChartRef = useRef(null);
    const barChartInstance = useRef(null);
    const pieChartInstance = useRef(null);

    const [deviceSummary, setDeviceSummary] = useState({
        allDevices: 0,
        mobileDevices: 0,
        desktopDevices: 0,
    });

    // Tooltip per le SummaryCard
    const tooltipTextMap = {
        "All Devices": "The operating system in use by the users or the app version",
        "Mobile Devices": "The mobile operating system in use by the users or the app version",
        "Desktop Devices": "The desktop operating system in use by the users or the app version",
    };

    // Logica di aggiornamento grafico a barre
    const updateBarChart = (labels, dataPoints) => {
        if (!barChartRef.current) return;

        if (barChartInstance.current) {
            barChartInstance.current.destroy();
        }

        const ctx = barChartRef.current.getContext("2d");
        barChartInstance.current = new Chart(ctx, {
            type: "bar",
            data: {
                labels,
                datasets: [
                    {
                        label: "Browser Usage",
                        data: dataPoints,
                        backgroundColor: token.colorPrimary,
                        borderRadius: 8,
                    },
                ],
            },
            options: {
                responsive: true,
                plugins: { legend: { display: false } },
                scales: {
                    x: {
                        title: {
                            display: false,
                        },
                    },
                    y: {
                        title: {
                            display: false,
                        },
                    },
                },
            },
        });
    };

    // Logica di aggiornamento grafico a torta
    const updatePieChart = (mobileCount, desktopCount, tabletCount) => {
        if (!pieChartRef.current) return;

        if (pieChartInstance.current) {
            pieChartInstance.current.destroy();
        }

        const ctx = pieChartRef.current.getContext("2d");
        pieChartInstance.current = new Chart(ctx, {
            type: "doughnut",
            data: {
                labels: ["Mobile", "Desktop", "Tablet"],
                datasets: [
                    {
                        data: [mobileCount, desktopCount, tabletCount],
                        backgroundColor: [token.colorPrimary, "#001629", "#8C54FF"],
                    },
                ],
            },
            options: {
                responsive: true,
                plugins: { legend: { display: true, position: "bottom" } },
            },
        });
    };

    const processData = (data) => {
        const browserCounts = {};
        const deviceTypeCounts = { mobile: 0, desktop: 0, tablet: 0 };

        data.forEach(({ device_os }) => {
            const browser = device_os?.split(" - ")[0]?.trim() || "Unknown";
            let deviceType = device_os?.split(" - ")[1]?.toLowerCase() || "unknown";

            // Conta i browser
            if (!browserCounts[browser]) {
                browserCounts[browser] = 0;
            }
            browserCounts[browser]++;

            // Assegna "mobile" come default se non è desktop, tablet o mobile
            if (deviceType.includes("desktop")) {
                deviceTypeCounts.desktop++;
            } else if (deviceType.includes("tablet")) {
                deviceTypeCounts.tablet++;
            } else {
                deviceTypeCounts.mobile++; // Default a "mobile"
            }
        });

        return { browserCounts, deviceTypeCounts };
    };

    useEffect(() => {
        if (!data || data.length === 0) return;

        // Elaborazione dei dati completi per summary, tabella e pie chart
        const { browserCounts, deviceTypeCounts } = processData(data);

        setDeviceSummary({
            allDevices: deviceTypeCounts.mobile + deviceTypeCounts.desktop + deviceTypeCounts.tablet,
            mobileDevices: deviceTypeCounts.mobile,
            desktopDevices: deviceTypeCounts.desktop,
        });

        const sortedBrowserData = Object.entries(browserCounts)
            .map(([name, count], index) => ({
                key: `${name}-${index}`,
                name,
                count,
            }))
            .sort((a, b) => b.count - a.count);

        // Aggiorna la tabella
        setBrowserData(sortedBrowserData);

        // Aggiorna il pie chart
        updatePieChart(deviceTypeCounts.mobile, deviceTypeCounts.desktop, deviceTypeCounts.tablet);
    }, [data]); // dipende solo da "data"

    useEffect(() => {
        if (!data || data.length === 0) return;

        // Filtra i dati solo per il bar chart in base al selectedTab
        let filteredData = data;
        if (selectedTab === "Mobile Devices") {
            filteredData = data.filter(({ device_os }) =>
                device_os?.toLowerCase().includes("mobile")
            );
        } else if (selectedTab === "Desktop Devices") {
            filteredData = data.filter(({ device_os }) =>
                device_os?.toLowerCase().includes("desktop")
            );
        }

        // Elabora i dati filtrati per aggiornare il bar chart
        const { browserCounts } = processData(filteredData);
        const sortedBrowserData = Object.entries(browserCounts)
            .map(([name, count], index) => ({
                key: `${name}-${index}`,
                name,
                count,
            }))
            .sort((a, b) => b.count - a.count);

        updateBarChart(
            sortedBrowserData.map(({ name }) => name),
            sortedBrowserData.map(({ count }) => count)
        );
    }, [selectedTab, data]); // si attiva al cambio di selectedTab

    const handleTabChange = (tab) => {
        setSelectedTab(tab);
    };

    return (
        <>
            <SummaryGrid>
                {[
                    { key: "All Devices", value: deviceSummary.allDevices },
                    { key: "Mobile Devices", value: deviceSummary.mobileDevices },
                    { key: "Desktop Devices", value: deviceSummary.desktopDevices },
                ].map(({ key, value }) => (
                    <div key={key}>
                        <SummaryCard
                            label={key}
                            value={value !== undefined ? value : 0}
                            loading={loading}
                            active={key === selectedTab}
                            onClick={() => handleTabChange(key)}
                            toolTipText={tooltipTextMap[key]}
                        />
                    </div>
                ))}
            </SummaryGrid>

            {/* Grafici e tabella */}
            <Card
                title={
                    <Row justify="space-between" align="middle" style={{ width: "100%" }}>
                        <Col>
                            <span>Devices Analytics</span>
                        </Col>
                        <Col>
                            {clearFiltersVisible === true && (
                                <Button type="default" onClick={onResetFilters}>
                                    Clear filters
                                </Button>
                            )}
                        </Col>
                    </Row>
                }
            >
                {loading ? (
                    <div style={{ textAlign: "center", padding: "2rem" }}>
                        <Spin size="large" />
                    </div>
                ) : data.length === 0 ? (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                ) : (
                    <>
                        <Row gutter={[16, 16]} style={{ marginBottom: "2rem" }}>
                            <Col xs={24} md={15} style={{ display: "flex", justifyContent: "center" }}>
                                <div style={{ width: "100%", height: "400px" }}>
                                    <canvas
                                        ref={barChartRef}
                                        style={{
                                            marginTop: "10px",
                                            maxWidth: "100%",
                                            maxHeight: "100%",
                                            height: "100%",
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col xs={24} md={9} style={{ display: "flex", justifyContent: "center" }}>
                                <div style={{ width: "100%", height: "380px" }}>
                                    <canvas
                                        ref={pieChartRef}
                                        style={{
                                            marginTop: "10px",
                                            maxWidth: "100%",
                                            maxHeight: "100%",
                                            height: "100%",
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Table
                            size="small"
                            columns={[
                                {
                                    title: "Browser",
                                    dataIndex: "name",
                                    key: "name",
                                    sorter: (a, b) => a.name.localeCompare(b.name),
                                    showSorterTooltip: false,
                                    width: "75%",
                                },
                                {
                                    title: "Count",
                                    dataIndex: "count",
                                    key: "count",
                                    sorter: (a, b) => a.count - b.count,
                                    defaultSortOrder: "descend",
                                    showSorterTooltip: false,
                                    width: "25%",
                                },
                            ]}
                            dataSource={browserData}
                            rowKey="key"
                            pagination={{
                                defaultPageSize: 10,
                                showSizeChanger: true,
                                pageSizeOptions: ["10", "20", "50", "100", "200", "500", "1000"],
                            }}
                            scroll={{ x: "max-content" }}
                            style={{ width: "100%" }}
                        />
                    </>
                )}
            </Card>
        </>
    );
}

export default DeviceAnalytics;