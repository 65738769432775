import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Select, Button } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { setAnalyticsFilterOptions, setLineGlassModel } from '../../redux/analytics/actions';
import { collection, query, where, getDocs, getDoc, documentId, doc } from 'firebase/firestore';
import { db as firebaseDB } from '../../data/base';
import { openDB } from 'idb';

const DB_NAME = 'ChartFilterDB';
const STORE_NAME = 'lineGlassModelStore';

// Inizializza IndexedDB
const initIDB = async () => {
    return await openDB(DB_NAME, 1, {
        upgrade(db) {
            if (!db.objectStoreNames.contains(STORE_NAME)) {
                db.createObjectStore(STORE_NAME);
            }
        },
    });
};

const { Option, OptGroup } = Select;

/**
 * Sanitizza i dati in arrivo da Firebase.
 * Se trova un oggetto con _delegate (documentReference), restituisce un plain object con id e (eventuale) path.
 */
const sanitizeFirebaseData = (data) => {
    return JSON.parse(
        JSON.stringify(data, (key, value) => {
            if (value && typeof value === 'object') {
                if (value._delegate) {
                    return { id: value.id, path: value.path };
                }
                if (value instanceof Array) {
                    return value.map((item) =>
                        item && item._delegate ? { id: item.id, path: item.path } : item
                    );
                }
            }
            return value;
        })
    );
};

/**
 * Salva i dati in IndexedDB.
 * I dati vengono salvati in forma plain object (già sanitizzati).
 */
const saveToIndexedDB = async (key, data) => {
    try {
        const idb = await initIDB();
        const sanitizedData = sanitizeFirebaseData(data);
        await idb.put(STORE_NAME, sanitizedData, key);
    } catch (error) {
        console.error('Errore nel salvataggio in IndexedDB:', error);
    }
};

/**
 * Carica i dati dalla cache di IndexedDB.
 * I dati vengono restituiti come plain object, esattamente come sono stati salvati.
 */
const loadFromIndexedDB = async (key) => {
    try {
        const idb = await initIDB();
        return await idb.get(STORE_NAME, key);
    } catch (error) {
        console.error('Errore nel recupero da IndexedDB:', error);
        return null;
    }
};

function ChartFilter({ selectedValue, setSelectedValue, loadingSelect, setLoadingSelect }) {

    const dispatch = useDispatch();
    const [filteredGlassList, setFilteredGlassList] = useState([]);

    // Redux selectors
    const filterOptions = useSelector((state) => state.analytics.filterOptions);
    const lineList = useSelector((state) => state.analytics.lineList) || [];
    const glassList = useSelector((state) => state.analytics.glassList) || [];
    const modelDocList = useSelector((state) => state.analytics.modelList) || [];
    const selectedCatalog = useSelector((state) => state.catalog.selectedCatalog);
    const listaRefsLinea = useSelector((state) => state.catalog.selectedCatalog?.listaRefsLinea);
    const listaCatalog = useSelector((state) => state.config.listaBrand) || [];

    const { line, glass } = filterOptions;

    // Gestione del cambio selezione
    const handleSelectionChange = (optionName, value) => () => {
        const updatedFilterOptions = { ...filterOptions };
        if (optionName === 'line') {
            updatedFilterOptions.line = value ?? '';
            updatedFilterOptions.glass = '';
            updatedFilterOptions.variant = '';
        } else if (optionName === 'glass') {
            const selectedGlass = glassList.find((item) => item && item.id === value);
            const selectedLine = lineList.find((item) => item && item.id === selectedGlass?.lineaRef?.id);
            updatedFilterOptions.glass = selectedGlass?.id ?? '';
            updatedFilterOptions.line = selectedLine?.id ?? '';
            updatedFilterOptions.variant = '';
        } else if (optionName === 'variant') {
            const selectedModel = modelDocList.find((item) => item && item.id === value);
            const selectedGlass = glassList.find((item) => item && item.id === selectedModel?.glassId);
            const selectedLine = lineList.find((item) => item && item.id === selectedGlass?.lineaRef?.id);
            updatedFilterOptions.variant = selectedModel?.id ?? '';
            updatedFilterOptions.glass = selectedGlass?.id ?? '';
            updatedFilterOptions.line = selectedLine?.id ?? '';
        }
        dispatch(setAnalyticsFilterOptions(updatedFilterOptions));
    };

    // Suddivide un array in chunk di dimensione fissa
    function chunkArray(array, size = 10) {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size));
        }
        return result;
    }

    /**
     * Recupera tutte le linee, modelli e occhiali da Firebase.
     * Se i dati sono già in cache (IndexedDB), li utilizza e salta le chiamate a Firebase.
     */
    const getAllLineModels = async () => {
        try {
            // 1. Controlla la cache
            const cachedData = await loadFromIndexedDB('cachedLineGlassModel');
            if (cachedData) {
                dispatch(setLineGlassModel(cachedData));
                return;
            }

            // 2. Recupera dati da Firebase
            const chunks = chunkArray(listaCatalog);
            let allCatalogs = [];
            for (const chunk of chunks) {
                const catalogQuery = query(
                    collection(firebaseDB, 'Brand'),
                    where(documentId(), 'in', chunk)
                );
                const snapshot = await getDocs(catalogQuery);
                const catalogs = snapshot.docs.map((docSnap) => ({
                    id: docSnap.id,
                    ...docSnap.data(),
                }));
                allCatalogs = [...allCatalogs, ...catalogs];
            }

            // 3. Recupera dati di linea, occhiali e modelli
            const promises = allCatalogs.map((catalog) =>
                getLineGlassModelUtility(catalog?.listaRefsLinea)
            );
            const allData = await Promise.all(promises);
            const lines = [];
            const glasses = [];
            const models = [];
            allData.forEach((item) => {
                lines.push(...(item?.lineList || []));
                glasses.push(...(item?.glassList || []));
                models.push(...(item?.modelList || []));
            });
            // 4. Filtra eventuali dati undefined
            const newData = {
                lineList: lines.filter((item) => item && item.id),
                glassList: glasses.filter((item) => item && item.id),
                modelList: models.filter((item) => item && item.id),
            };
            // 5. Salva in Redux e nella cache
            dispatch(setLineGlassModel(newData));
            await saveToIndexedDB('cachedLineGlassModel', newData);
        } catch (error) {
            console.error('Errore durante il caricamento di tutti i modelli:', error);
        }
    };

    /**
     * Recupera i dati (linee, occhiali, modelli) a partire da una lista di riferimenti.
     * Utilizza una funzione throttle per limitare le richieste simultanee.
     */
    const getLineGlassModelUtility = async (listRef) => {
        try {
            const throttle = async (tasks, limit) => {
                const results = [];
                for (let i = 0; i < tasks.length; i += limit) {
                    const chunk = tasks.slice(i, i + limit);
                    results.push(...(await Promise.all(chunk.map((task) => task()))));
                }
                return results;
            };

            const references = listRef.map((ref) =>
                typeof ref === 'string'
                    ? doc(firebaseDB, 'LineCollection', ref)
                    : ref
            );
            const lineDocs = await throttle(
                references.map((ref) => () => getDoc(ref)),
                10
            );
            const lines = lineDocs
                .filter((docSnap) => docSnap.exists())
                .map((docSnap) => ({ id: docSnap.id, ...docSnap.data() }));

            const glassRefs = lines.flatMap((line) => line.listaRefsOcchiale || []);
            const glassDocs = await throttle(
                glassRefs.map((ref) => () => getDoc(ref)),
                10
            );
            const glasses = glassDocs
                .filter((docSnap) => docSnap.exists())
                .map((docSnap) => ({ id: docSnap.id, ...docSnap.data() }));

            const variantRefs = glasses.flatMap((glass) => glass.lista_taglie || []);
            const variantDocs = await throttle(
                variantRefs.map((ref) => () => getDoc(ref)),
                10
            );
            const modelRefs = variantDocs.flatMap(
                (variant) => variant.data()?.listaRefModels || []
            );
            const modelDocs = await throttle(
                modelRefs.map((ref) => () => getDoc(ref)),
                10
            );
            const models = modelDocs
                .filter((docSnap) => docSnap.exists())
                .map((docSnap) => ({ id: docSnap.id, ...docSnap.data() }));

            return {
                lineList: lines,
                glassList: glasses,
                modelList: models,
            };
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    };

    /**
     * Recupera i dati a partire da una lista di riferimenti "Linea".
     */
    const getLineGlassModel = async (listRef) => {
        try {
            if (!listRef || !listRef.length) {
                console.error('No references provided or available.');
                return;
            }
            const references = listRef.map((ref) =>
                typeof ref === 'string' ? doc(firebaseDB, 'Linea', ref) : ref
            );
            const obj = await getLineGlassModelUtility(references);
            return obj;
        } catch (error) {
            console.error('Errore durante getLineGlassModel:', error);
        }
    };

    // Effettua il fetch dei dati quando cambia il catalogo selezionato
    useEffect(() => {
        const fetchData = async () => {
            setLoadingSelect(true);
            try {
                resetSelect();

                if (!selectedCatalog || !selectedCatalog.id || selectedCatalog.id === 'all') {
                    await getAllLineModels();
                } else if (listaRefsLinea) {
                    const data = await getLineGlassModel(listaRefsLinea);
                    if (data) {
                        const { lineList, glassList, modelList } = data;
                        dispatch(setLineGlassModel({ lineList, glassList, modelList }));
                    }
                }
            } catch (error) {
                console.error('Errore durante il fetch dei dati:', error);
            } finally {
                setLoadingSelect(false);
            }
        };

        fetchData();
    }, [selectedCatalog?.id, listaRefsLinea]);

    // Aggiorna la lista degli occhiali filtrati in base alla linea selezionata
    useEffect(() => {
        if (!line) {
            setFilteredGlassList(glassList);
            return;
        }
        const lineDoc = (lineList || []).find((item) => item && item.id === line);
        const lineGlassListIds = (lineDoc?.listaRefsOcchiale || [])
            .filter((item) => item && item.id)
            .map((item) => item.id);
        const filtered = (glassList || []).filter(
            (item) => item && lineGlassListIds.includes(item.id)
        );
        setFilteredGlassList(filtered);
    }, [glassList, line, lineList]);

    // Reset dei dati quando cambia il catalogo selezionato
    const [previousCatalog, setPreviousCatalog] = useState(null);
    useEffect(() => {
        if (previousCatalog !== selectedCatalog?.id) {
            dispatch(setLineGlassModel({ lineList: [], glassList: [], modelList: [] }));
            setFilteredGlassList([]);
            setPreviousCatalog(selectedCatalog?.id);
        }
    }, [selectedCatalog?.id, previousCatalog, dispatch]);

    // Testo del dropdown
    const dropdownToggleText = () => {
        const selectedLine = (lineList || []).find((item) => item && item.id === line);
        const selectedGlass = (glassList || []).find((item) => item && item.id === glass);
        if (selectedGlass) {
            return selectedGlass.nome_modello ?? selectedLine?.nome_linea;
        }
        return selectedLine?.nome_linea ?? 'All';
    };

    // Calcola le liste uniche con controlli difensivi
    const uniqueLineList = Array.from(
        new Map((lineList || []).filter(item => item && item.id).map(item => [item.id, item])).values()
    );
    const uniqueGlassList = Array.from(
        new Map((glassList || []).filter(item => item && item.id).map(item => [item.id, item])).values()
    );

    const handleChange = (value) => {
        if (!value || typeof value !== 'string' || !value.includes('-')) {
            console.error('Invalid value passed to handleChange:', value);
            handleSelectionChange('line', '')();
            handleSelectionChange('glass', '')();
            return;
        }
        const [type, id] = value.split('-');
        if (type === 'line') {
            handleSelectionChange('line', id)();
        } else if (type === 'glass') {
            handleSelectionChange('glass', id)();
        }
    };

    const resetSelect = () => {
        setSelectedValue(null);
        handleSelectionChange('line', '')();
        handleSelectionChange('glass', '')();
    };

    return (
        <div style={{ position: 'relative', minWidth: '250px' }}>
            <Select
                style={{
                    width: '100%',
                    borderRadius: '8px',
                    borderColor: selectedValue ? 'rgba(66, 177, 172, 1)' : undefined,
                    boxShadow: selectedValue ? '0 0 0 2px rgba(66, 177, 172, 1)' : undefined,
                }}
                showSearch
                placeholder={loadingSelect ? <Spin size="small" /> : dropdownToggleText()}
                value={selectedValue}
                onChange={(value) => {
                    if (!value) {
                        resetSelect();
                    } else {
                        setSelectedValue(value);
                        handleChange(value);
                    }
                }}
                optionFilterProp="label"
                disabled={loadingSelect}
            >
                <OptGroup label="All Lines">
                    {uniqueLineList.map((item) => (
                        <Option key={`line-${item.id}`} value={`line-${item.id}`} label={item.nome_linea}>
                            {item.nome_linea}
                        </Option>
                    ))}
                </OptGroup>
                <OptGroup label="All Glasses">
                    {uniqueGlassList.map((item) => (
                        <Option key={`glass-${item.id}`} value={`glass-${item.id}`} label={item.nome_modello}>
                            {item.nome_modello}
                        </Option>
                    ))}
                </OptGroup>
            </Select>
            {selectedValue && (
                <Button
                    type="text"
                    icon={<CloseCircleOutlined style={{ color: 'grey' }} />}
                    onClick={resetSelect}
                    style={{
                        position: 'absolute',
                        top: '50%',
                        right: '25px',
                        transform: 'translateY(-50%)',
                        zIndex: 1,
                    }}
                />
            )}
        </div>
    );
}

export default ChartFilter;