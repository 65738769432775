import React, { useEffect, useState } from "react";
import { Button, Input, Form, Typography, Spin, message, Row, Col } from "antd";
import { getAuth, updatePassword, EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { setProfileImage } from "../redux/profile/actions";
import { uploadImage } from "../services/profile";
import MFASetup from "../components/Profile/MFASetup";
import { db } from "../data/base";
import { secureGetItem } from "../data/utils";
import { useLocation, useNavigate } from "react-router-dom";

const { Title } = Typography;

const UserProfile = () => {
    const dispatch = useDispatch();
    const photoURL = useSelector((state) => state.profile.profileImage); // State to manage profile image
    const [form] = Form.useForm();
    const [clientData, setClientData] = useState({});
    const [isAdmin, setIsAdmin] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [editValues, setEditValues] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const navigate = useNavigate();

    const clientId = secureGetItem("ref_cliente")?.trim();
    const email = secureGetItem("email")?.toLowerCase();

    const [userInfo, setUserInfo] = useState({});
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [isImageLoading, setImageLoading] = useState(false);
    const [loading, setLoading] = useState(true);

    const auth = getAuth();

    // Fetch user info on component load
    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                if (!clientId || !email) {
                    throw new Error("Client ID or email not found in localStorage.");
                }

                const clientDoc = await getDoc(doc(db, "Client", clientId));
                const profileDoc = await getDoc(doc(db, "Profile", email));

                if (clientDoc.exists() && profileDoc.exists()) {
                    const clientData = clientDoc.data();
                    const profileData = profileDoc.data();

                    setUserInfo({
                        firstName: profileData.firstName,
                        lastName: profileData.lastName,
                        company: clientData.companyName,
                        role: profileData.role,
                        email: email,
                        collectionId: clientId,
                    });

                    dispatch(setProfileImage(clientData.photoURL || ""));
                } else {
                    throw new Error("User data not found in database.");
                }
            } catch (error) {
                message.error("Error fetching user data: " + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserInfo();
    }, [db, dispatch]);

    // Handle profile image change
    const changeAvatar = async (file) => {
        try {
            setImageLoading(true);
            const downloadURL = await uploadImage(file);
            await updateDoc(doc(db, "Client", userInfo.collectionId), { photoURL: downloadURL });
            dispatch(setProfileImage(downloadURL)); // Update the Redux state
            message.success("Profile image updated successfully.");
        } catch (error) {
            message.error("Error updating profile image: " + error.message);
        } finally {
            setImageLoading(false);
        }
    };

    // Change password
    const changePassword = async () => {
        try {
            if (newPassword !== repeatPassword) {
                throw new Error("New passwords do not match.");
            }

            const user = auth.currentUser;
            const credential = EmailAuthProvider.credential(user.email, currentPassword);

            await reauthenticateWithCredential(user, credential);
            await updatePassword(user, newPassword);

            setCurrentPassword("");
            setNewPassword("");
            setRepeatPassword("");

            message.success("Password changed successfully.");
        } catch (error) {
            message.error("Error changing password: " + error.message);
        }
    };

    if (loading) {
        return <Spin />;
    }

    return (
        <div>
            <MFASetup />
            <Title level={4}>Personal information</Title>
            <Form layout="vertical">
                {/* Profile Image */}
                {/* <Form.Item label="Profile Image">
                    <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                        <Avatar
                            size={100}
                            src={photoURL}
                            icon={!photoURL && <UserOutlined />}
                        />
                        <Upload
                            showUploadList={false}
                            customRequest={({ file }) => changeAvatar(file)}
                            accept="image/*"
                        >
                            <Button icon={<UploadOutlined />} loading={isImageLoading}>
                                Change Avatar
                            </Button>
                        </Upload>
                    </div>
                </Form.Item> */}

                {/* Personal Information */}
                <Row gutter={[16, 0]}>
                    <Col xs={24} sm={12} lg={8}>
                        <Form.Item label="First Name">
                            <Input value={userInfo.firstName} disabled />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} lg={8}>
                        <Form.Item label="Last Name">
                            <Input value={userInfo.lastName} disabled />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} lg={8}>
                        <Form.Item label="Company">
                            <Input value={userInfo.company} disabled />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} lg={8}>
                        <Form.Item label="Role">
                            <Input value={userInfo.role} disabled />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} lg={8}>
                        <Form.Item label="Email">
                            <Input value={userInfo.email} disabled />
                        </Form.Item>
                    </Col>
                </Row>

                {/* Change Password */}
                <Title level={5}>Change Password</Title>
                <Row gutter={[16, 0]}>
                    <Col xs={24} sm={12} lg={8}>
                        <Form.Item label="Current Password">
                            <Input.Password
                                placeholder="Enter current password"
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} lg={8}>
                        <Form.Item label="New Password">
                            <Input.Password
                                placeholder="Enter new password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} lg={8}>
                        <Form.Item label="Repeat New Password">
                            <Input.Password
                                placeholder="Repeat new password"
                                value={repeatPassword}
                                onChange={(e) => setRepeatPassword(e.target.value)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <Form.Item>
                            <Button
                                type="primary"
                                onClick={changePassword}
                                disabled={!currentPassword || !newPassword || !repeatPassword}
                            >
                                Save Password
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default UserProfile;