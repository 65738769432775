import { ADD_TO_WISHLIST, REMOVE_FROM_WISHLIST } from "../actionType";

const initialState = { list: [] };

export default function wishlistReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TO_WISHLIST: {
      const productId = action.payload.product.id;

      const exists = state.list.some((product) => product.id === productId);

      if (exists) {
        return state;
      }

      return {
        ...state,
        list: [...state.list, action.payload.product],
      };
    }

    case REMOVE_FROM_WISHLIST: {
      const productId = action.product_id.id;

      return {
        ...state,
        list: state.list.filter((item) => item.id !== productId),
      };
    }

    default:
      return state;
  }
}
