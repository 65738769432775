import React, { useState, useEffect } from 'react';
import { Card, Spin, Typography, Empty, Row, Col, Table, Tag, Radio, message } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import StatCard from '../Home/StatCard';
import { getGeoLocationFromIP } from '../../services/utils/gatewayReportCalculation';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../data/base';
import CustomTooltip from "../common/CustomTooltip";

// Solving the icon issue in React-Leaflet
// Necessary because default icon imports don't work well in React
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
    iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
    shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const { Text } = Typography;

// CartoDB Light map provider configuration
const mapProvider = {
    url: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
};

const GeolocationMap = ({ data, loading, clearFiltersVisible, onResetFilters }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [processing, setProcessing] = useState(true);
    const [mapData, setMapData] = useState([]);
    const [center, setCenter] = useState([20, 0]); // Default: Center of the world
    const zoom = 2; // Lower zoom level to show more of the world
    const [locationStats, setLocationStats] = useState([]);
    const [countryStats, setCountryStats] = useState([]);
    const [cityStats, setCityStats] = useState([]);
    const [totalLocations, setTotalLocations] = useState(0);
    const [uniqueUsers, setUniqueUsers] = useState(0);
    const [locationType, setLocationType] = useState('country'); // 'country' or 'city'

    // Custom icon for markers
    const customIcon = new L.Icon({
        iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
        shadowSize: [41, 41]
    });

    useEffect(() => {
        if (!data || loading) return;

        const processData = async () => {
            setProcessing(true);
            try {
                // Create an array for markers
                let markers = [];

                // Collect statistics by location
                const locationCounts = {};
                const countryCounts = {};
                const cityCounts = {};

                // Track unique device IDs
                const uniqueDeviceIds = new Set();

                // Solo i dati che hanno geolocalizzazione valida
                let validItems = [];

                // Filtra e normalizza i dati
                for (const item of data) {
                    if (typeof item.geoLocation === 'object' && item.geoLocation !== null) {
                        const geoObj = item.geoLocation;
                        if (geoObj.geoId) {
                            item.geoLocation = geoObj.geoId;
                        }
                        if ((typeof item.latitude !== 'number' || isNaN(item.latitude)) && typeof geoObj.latitude === 'number') {
                            item.latitude = geoObj.latitude;
                        }
                        if ((typeof item.longitude !== 'number' || isNaN(item.longitude)) && typeof geoObj.longitude === 'number') {
                            item.longitude = geoObj.longitude;
                        }
                    }

                    // Verifica che l'item abbia informazioni di geolocalizzazione valide
                    const hasValidGeoInfo = typeof item.geoLocation === 'string' &&
                        item.geoLocation.trim() !== '' &&
                        !item.geoLocation.includes('Unknown - Unknown - Unknown');

                    const hasValidCoordinates = typeof item.latitude === 'number' &&
                        typeof item.longitude === 'number' &&
                        item.latitude !== 0 &&
                        item.longitude !== 0;

                    // Se non abbiamo informazioni di geolocalizzazione valide, tentiamo di ottenerle solo se abbiamo un IP valido
                    if (hasValidGeoInfo && hasValidCoordinates) {
                        // Both geo info and coordinates are precise, include the item as-is
                        validItems.push(item);
                    } else if (item.device_id) {
                        // Either geo info is missing or coordinates are imprecise; try to update using maxmind
                        const ipRegex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
                        if (!ipRegex.test(item.device_id)) {
                            console.warn(`⚠️ Skipping item with invalid IP: ${item.device_id}`);
                            continue;
                        }
                        try {
                            const geoData = await getGeoLocationFromIP(item.device_id);
                            if (geoData.latitude && geoData.longitude && geoData.geoId &&
                                !geoData.geoId.includes('Unknown - Unknown - Unknown')) {
                                // Update the database if an ID is present
                                if (item.id) {
                                    try {
                                        const docRef = doc(db, "Session_Gateway", item.id);
                                        await updateDoc(docRef, {
                                            latitude: geoData.latitude,
                                            longitude: geoData.longitude,
                                            geoLocation: geoData.geoId
                                        });
                                    } catch (error) {
                                        console.error(`❌ Error updating database for item ${item.id}:`, error);
                                    }
                                }
                                validItems.push({
                                    ...item,
                                    latitude: geoData.latitude,
                                    longitude: geoData.longitude,
                                    geoLocation: geoData.geoId
                                });
                            } else {
                                console.warn(`⚠️ Couldn't get valid geolocation data for IP: ${item.device_id}`);
                            }
                        } catch (error) {
                            console.error(`❌ Error retrieving geolocation from IP:`, error);
                        }
                    }
                }

                // Ora processiamo solo i dati validi
                validItems.forEach(item => {
                    // Track unique users by device_id
                    if (item.device_id) {
                        uniqueDeviceIds.add(item.device_id);
                    }

                    // Extract country and city from the location string
                    let country = 'Unknown';
                    let city = 'Unknown';
                    let hasValidGeoInfo = false;

                    if (typeof item.geoLocation === 'string' && item.geoLocation.trim() !== '' &&
                        !item.geoLocation.includes('Unknown - Unknown - Unknown')) {
                        const parts = item.geoLocation.split(' - ');
                        country = parts[0] || 'Unknown';
                        city = parts.length > 2 ? parts[2] : (parts.length > 1 ? parts[1] : 'Unknown');
                        hasValidGeoInfo = country !== 'Unknown' || city !== 'Unknown';
                    }

                    // Se abbiamo informazioni valide, aggiungiamole alle statistiche
                    if (hasValidGeoInfo) {
                        // For complete location statistics
                        const locationKey = item.geoLocation;
                        if (!locationCounts[locationKey]) {
                            locationCounts[locationKey] = 1;
                        } else {
                            locationCounts[locationKey]++;
                        }

                        // For country statistics
                        if (country !== 'Unknown') {
                            if (!countryCounts[country]) {
                                countryCounts[country] = 1;
                            } else {
                                countryCounts[country]++;
                            }
                        }

                        // For city statistics
                        if (city !== 'Unknown') {
                            const cityKey = `${city} (${country})`;
                            if (!cityCounts[cityKey]) {
                                cityCounts[cityKey] = 1;
                            } else {
                                cityCounts[cityKey]++;
                            }
                        }
                    }

                    if (typeof item.latitude === 'number' && typeof item.longitude === 'number') {
                        markers.push({
                            position: [item.latitude, item.longitude],
                            country,
                            city,
                            gateway_name: item.gateway_name,
                            location: item.geoLocation,
                            count: 1
                        });
                    }
                });

                setMapData(markers);

                // Location statistics
                const locStats = Object.entries(locationCounts)
                    .map(([location, count]) => ({ location, count, key: location }))
                    .sort((a, b) => b.count - a.count);

                // Country statistics
                const countryStatsData = Object.entries(countryCounts)
                    .map(([country, count]) => ({ location: country, count, key: country }))
                    .sort((a, b) => b.count - a.count);

                // City statistics
                const cityStatsData = Object.entries(cityCounts)
                    .map(([city, count]) => ({ location: city, count, key: city }))
                    .sort((a, b) => b.count - a.count);

                setLocationStats(locStats);
                setCountryStats(countryStatsData);
                setCityStats(cityStatsData);
                setTotalLocations(locStats.reduce((sum, stat) => sum + stat.count, 0));
                setUniqueUsers(uniqueDeviceIds.size);

                // Calculate the center of the map based on available data
                if (markers.length > 0) {
                    const sumLat = markers.reduce((sum, item) => sum + item.position[0], 0);
                    const sumLng = markers.reduce((sum, item) => sum + item.position[1], 0);
                    setCenter([sumLat / markers.length, sumLng / markers.length]);
                }
            } catch (error) {
                console.error("Error processing geolocation data:", error);
                messageApi.error('Error processing geolocation data');
            } finally {
                setProcessing(false);
            }
        };
        processData();
    }, [data, loading]);

    const getColumns = () => {
        if (locationType === 'country') {
            return [
                {
                    title: (
                        <span>
                            Country{' '}
                            <CustomTooltip title="The name of the country from the geolocation data.">
                                <InfoCircleOutlined />
                            </CustomTooltip>
                        </span>
                    ),
                    dataIndex: 'location',
                    key: 'location',
                    render: text => <Tag color="blue">{text}</Tag>,
                    sorter: (a, b) => a.location.localeCompare(b.location),
                    showSorterTooltip: false,
                    width: '50%',
                },
                {
                    title: (
                        <span>
                            Visits{' '}
                            <CustomTooltip title="The total number of visits from this location.">
                                <InfoCircleOutlined />
                            </CustomTooltip>
                        </span>
                    ),
                    dataIndex: 'count',
                    key: 'count',
                    render: count => <span>{count}</span>,
                    sorter: (a, b) => a.count - b.count,
                    defaultSortOrder: 'descend',
                    showSorterTooltip: false,
                    width: '30%',
                },
                {
                    title: (
                        <span>
                            %{' '}
                            <CustomTooltip title="The percentage of visits this location represents relative to the total visits.">
                                <InfoCircleOutlined />
                            </CustomTooltip>
                        </span>
                    ),
                    key: 'percentage',
                    render: (_, record) => {
                        const percentage = totalLocations ? (record.count / totalLocations) * 100 : 0;
                        return <Text>{percentage.toFixed(1)}%</Text>;
                    },
                    sorter: (a, b) => {
                        const percA = totalLocations ? (a.count / totalLocations) * 100 : 0;
                        const percB = totalLocations ? (b.count / totalLocations) * 100 : 0;
                        return percA - percB;
                    },
                    showSorterTooltip: false,
                    width: '20%',
                }
            ];
        } else {
            return [
                {
                    title: (
                        <span>
                            City{' '}
                            <CustomTooltip title="The city name from the geolocation data.">
                                <InfoCircleOutlined />
                            </CustomTooltip>
                        </span>
                    ),
                    dataIndex: 'location',
                    key: 'location',
                    render: text => {
                        // Format is "City (Country)"
                        const match = text.match(/(.+) \((.+)\)/);
                        if (match) {
                            return (
                                <CustomTooltip title={`${match[1]}, ${match[2]}`}>
                                    <span>
                                        <Tag color="purple">{match[1]}</Tag>
                                        <Tag color="blue">{match[2]}</Tag>
                                    </span>
                                </CustomTooltip>
                            );
                        }
                        return <Tag color="purple">{text}</Tag>;
                    },
                    sorter: (a, b) => {
                        const cityA = a.location.match(/(.+) \((.+)\)/) ? a.location.match(/(.+) \((.+)\)/)[1] : a.location;
                        const cityB = b.location.match(/(.+) \((.+)\)/) ? b.location.match(/(.+) \((.+)\)/)[1] : b.location;
                        return cityA.localeCompare(cityB);
                    },
                    showSorterTooltip: false,
                    width: '50%',
                },
                {
                    title: (
                        <span>
                            Visits{' '}
                            <CustomTooltip title="The total number of visits from this location.">
                                <InfoCircleOutlined />
                            </CustomTooltip>
                        </span>
                    ),
                    dataIndex: 'count',
                    key: 'count',
                    render: count => <span>{count}</span>,
                    sorter: (a, b) => a.count - b.count,
                    defaultSortOrder: 'descend',
                    showSorterTooltip: false,
                    width: '30%',
                },
                {
                    title: (
                        <span>
                            %{' '}
                            <CustomTooltip title="The percentage of visits this location represents relative to the total visits.">
                                <InfoCircleOutlined />
                            </CustomTooltip>
                        </span>
                    ),
                    key: 'percentage',
                    render: (_, record) => {
                        const percentage = totalLocations ? (record.count / totalLocations) * 100 : 0;
                        return <Text>{percentage.toFixed(1)}%</Text>;
                    },
                    sorter: (a, b) => {
                        const percA = totalLocations ? (a.count / totalLocations) * 100 : 0;
                        const percB = totalLocations ? (b.count / totalLocations) * 100 : 0;
                        return percA - percB;
                    },
                    showSorterTooltip: false,
                    width: '20%',
                }
            ];
        }
    };

    const handleLocationTypeChange = (e) => {
        setLocationType(e.target.value);
    };

    // Determine which data to display based on the selected location type
    const getStatsData = () => {
        switch (locationType) {
            case 'country':
                return countryStats;
            case 'city':
                return cityStats;
            default:
                return locationStats;
        }
    };

    return (
        <>
            {contextHolder}
            <Card
                title="Geolocation"
                variant="bordered"
                style={{ width: '100%', marginTop: '16px' }}
            >
                {processing || loading ? (
                    <div style={{ textAlign: 'center', padding: '50px' }}>
                        <Spin size="large" />
                    </div>
                ) : mapData.length === 0 ? (
                    <Empty description="No geolocation data available" />
                ) : (
                    <>
                        {/* Stats Cards Row */}
                        <div style={{ display: 'flex', gap: '16px', marginBottom: '24px', flexWrap: 'wrap' }}>
                            <StatCard
                                title={locationType === 'country' ? "Unique Countries" : "Unique Cities"}
                                value={locationType === 'country' ? countryStats.length : cityStats.length}
                                tooltipTitle={`Number of unique ${locationType === 'country' ? 'countries' : 'cities'} in the data`}
                                showIcon={false}
                            />
                            <StatCard
                                title="Unique Users"
                                value={uniqueUsers}
                                tooltipTitle="Number of unique users based on device ID"
                                showIcon={false}
                            />
                        </div>

                        <Row gutter={[24, 24]}>
                            {/* Map Column - Now on the left */}
                            <Col xs={24} lg={12}>
                                <div style={{ height: '600px', width: '100%' }}>
                                    <MapContainer
                                        center={center}
                                        zoom={zoom}
                                        minZoom={2}
                                        maxZoom={18}
                                        style={{ height: '100%', width: '100%' }}
                                        scrollWheelZoom={true}
                                    >
                                        <TileLayer
                                            attribution={mapProvider.attribution}
                                            url={mapProvider.url}
                                        />
                                        <MarkerClusterGroup
                                            chunkedLoading
                                            spiderfyOnMaxZoom={false}
                                            disableClusteringAtZoom={18}
                                            maxClusterRadius={(zoom) => {
                                                // Quando lo zoom è alto (valore più grande), riduciamo il raggio di clustering
                                                // rendendo molto meno probabile la formazione di cluster
                                                return zoom > 10 ? 1 : 80; // 80 è il valore di default
                                            }}
                                        >
                                            {mapData.map((item, index) => (
                                                <Marker
                                                    key={`marker-${item.position[0]}-${item.position[1]}-${index}`}
                                                    position={item.position}
                                                    icon={customIcon}
                                                    interactive={item.count <= 1}
                                                >
                                                    {item.count <= 1 && (
                                                        <Popup>
                                                            <div>
                                                                <strong>{item.gateway_name}</strong><br />
                                                                <span>{item.location}</span><br />
                                                                <span>Visits: {item.count}</span>
                                                            </div>
                                                        </Popup>
                                                    )}
                                                </Marker>
                                            ))}
                                        </MarkerClusterGroup>
                                    </MapContainer>
                                </div>
                            </Col>

                            {/* Table Column - Now on the right */}
                            <Col xs={24} lg={12}>
                                <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Text strong>Location Data</Text>
                                    <Radio.Group
                                        value={locationType}
                                        onChange={handleLocationTypeChange}
                                        optionType="button"
                                        buttonStyle="solid"
                                    >
                                        <Radio.Button value="country">Countries</Radio.Button>
                                        <Radio.Button value="city">Cities</Radio.Button>
                                    </Radio.Group>
                                </div>
                                <Table
                                    dataSource={getStatsData().sort((a, b) => b.count - a.count)}
                                    columns={getColumns()}
                                    pagination={{
                                        pageSize: 10,
                                        showSizeChanger: false,
                                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
                                    }}
                                    size="small"
                                    variant="bordered"
                                    rowKey="key"
                                    sortDirections={['descend', 'ascend']}
                                    scroll={{ x: 'max-content' }}
                                    tableLayout="fixed"
                                />
                            </Col>
                        </Row>
                    </>
                )}
            </Card>
        </>
    );
};

export default GeolocationMap;
