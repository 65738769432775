import { Card, Typography, Row, Col, theme } from "antd";
import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js/auto";
import { secureGetItem } from "../../data/utils";

const { Title } = Typography;

const BandwidthCard = ({
    displayData,
    index,
    subItem,
    getTime,
    chartData,
    callsDetails,
    isLoading,
    bandwidthData,
}) => {
    const { token } = theme.useToken();
    const role = secureGetItem("role");

    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);

    useEffect(() => {
        const ctx = chartRef.current?.getContext("2d");

        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }

        if (ctx && chartData) {
            chartInstanceRef.current = new Chart(ctx, {
                type: "bar",
                data: chartData,
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            display: true,
                        },
                    },
                    scales: {
                        x: {
                            title: {
                                display: false,
                                text: "Month/Year",
                            },
                        },
                        y: {
                            title: {
                                display: false,
                                text: "Value",
                            },
                        },
                    },
                    maintainAspectRatio: false,
                },
            });
        }

        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
        };
    }, [chartData]);

    const totalBandwidth = bandwidthData?.totalBandWidth || 0;
    const consumptionLimit = subItem?.catalogList?.consumptionsLimit || 1;
    const usedPercentage = Math.min(
        (totalBandwidth / consumptionLimit) * 100,
        100
    );
    const surplusPercentage =
        totalBandwidth > consumptionLimit
            ? (totalBandwidth / consumptionLimit - 1) * 100
            : 0;

    const getLabelStyle = () => {
        if (usedPercentage <= 10) {
            return {
                left: `${usedPercentage}%`,
                transform: "translate(10%, -50%)",
                color: token.colorTextBlack,
            };
        } else {
            return {
                left: `${Math.min(usedPercentage, 100)}%`,
                transform: "translate(-115%, -50%)",
                color: token.colorTextWhite,
            };
        }
    };

    return (
        <Card
            title={
                <Row
                    justify="space-between"
                    align="middle"
                    style={{ width: "100%" }}
                >
                    <Col>
                        <span>
                            {subItem.subscriptionType} - {subItem.store}
                        </span>
                    </Col>
                    <Col>
                        <Col>
                            <span>
                                Period: {getTime(subItem.startDate)} -{" "}
                                {getTime(subItem.endDate)}
                            </span>
                        </Col>
                    </Col>
                </Row>
            }
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                }}
            >
                <div style={{ margin: 0 }}>
                    <Title level={5}>Bandwidth</Title>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "baseline",
                        gap: "10px",
                        margin: 0,
                    }}
                >
                    <Title level={3} style={{ margin: 0 }}>
                        {totalBandwidth} GB
                    </Title>
                    <Title level={4} style={{ color: "grey", margin: 0 }}>
                        / {consumptionLimit} GB
                    </Title>
                </div>
            </div>
            <div
                style={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    width: "100%",
                    position: "relative",
                }}
            >
                <div
                    style={{
                        position: "relative",
                        width: "100%",
                        height: "16px",
                        backgroundColor: token.colorBorder,
                        borderRadius: "10px",
                        overflow: "hidden",
                        border: `1px solid ${token.colorBorder}`,
                    }}
                >
                    <div
                        style={{
                            width: `${Math.min(usedPercentage, 100)}%`,
                            height: "100%",
                            backgroundColor: token.colorTextBlack,
                            borderRadius: "10px",
                        }}
                    />
                    <span
                        style={{
                            position: "absolute",
                            top: "50%",
                            ...getLabelStyle(),
                            fontWeight: "bold",
                            fontSize: "12px",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {usedPercentage.toFixed(2)}%
                    </span>
                </div>
                <div
                    style={{
                        position: "absolute",
                        top: "230%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    {surplusPercentage > 0 && (
                        <div style={{ textAlign: "center", marginTop: "10px" }}>
                            <p>
                                Quota exceeded without additional costs by{" "}
                                <span style={{ fontWeight: "bold" }}>
                                    {(totalBandwidth - consumptionLimit).toFixed(2)}
                                </span>{" "}
                                GB
                            </p>
                        </div>
                    )}
                </div>
            </div>
            {role === "Admin" && (
                <>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "5px",
                        }}
                    >
                        <div style={{ margin: 0 }}>
                            <Title level={5}>Number of calls</Title>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "baseline",
                                gap: "10px",
                                margin: 0,
                            }}
                        >
                            <Title level={3} style={{ margin: 0 }}>
                                {callsDetails.totalCalls}
                            </Title>
                        </div>
                    </div>
                    <div
                        style={{
                            width: "100%",
                            height: "250px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <canvas
                            ref={chartRef}
                            style={{
                                marginTop: "10px",
                                maxWidth: "100%",
                                maxHeight: "250px",
                            }}
                        />
                    </div>
                </>
            )}
        </Card>
    );
};

export default BandwidthCard;
