import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../data/base";
import {
    Layout,
    Row,
    Col,
    Button,
    Spin,
    Modal,
    Typography,
    Checkbox,
    Form,
    Divider,
    Select,
    DatePicker,
    App
} from "antd";
import AnalyticsHeader from "../components/Analytics/AnalyticsHeader";
import GatewaysOverviewCard from "../components/Gateways/GatewaysOverviewCard";
import DeviceAnalytics from "../components/Gateways/DeviceAnalytics";
import PagesAnalytics from "../components/Gateways/PagesAnalytics";
import EventsAnalytics from "../components/Gateways/EventsAnalytics";
import LinesGlassesVariantsAnalytics from "../components/Gateways/LinesGlassesVariantsAnalytics";
import GeolocationMap from "../components/Gateways/GeolocationMap";
import FiltersTop from "../components/Analytics/FiltersTop";
import dayjs from "dayjs";
import { fetchBrands } from "../services/api/gatewaysApi";
import { DownloadOutlined } from '@ant-design/icons';
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import {
    calculateDeviceAnalytics,
    calculateGatewaysOverview,
    calculateLinesGlassesVariants,
    calculatePagesAnalytics,
    calculateEventsAnalytics,
    calculateGeolocationData
} from "../services/utils/gatewayReportCalculation";
import FiltersMobile from "../components/Gateways/FiltersMobile";

const { Option } = Select;
const { Text, Title } = Typography;

// Imposta il filtro iniziale agli ultimi sette giorni
const endDate = dayjs().endOf("day");
const startDate = dayjs().subtract(7, "day").startOf("day");
const defaultDateRange = [startDate, endDate];

function Gateways() {
    const { message: messageApi } = App.useApp();

    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [dateRange, setDateRange] = useState(defaultDateRange);
    const [catalogueRefsList, setCatalogueRefsList] = useState([]);
    const [selectedGateway, setSelectedGateway] = useState([]);

    // REPORT
    const [showModal, setShowModal] = useState(false);
    const [selectedOption, setSelectedOption] = useState([]);
    const [startDateReport, setStartDateReport] = useState('');
    const [endDateReport, setEndDateReport] = useState('');
    const [error, setError] = useState('');
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [reportData, setReportData] = useState([]);

    // CLEAR FILTERS BUTTON VISIBILITY
    const [clearFiltersVisible, setClearFiltersVisible] = useState(false);

    // Stato per le sezioni del report da includere
    const [reportSections, setReportSections] = useState({
        overview: false,
        linesGlasses: false,
        deviceAnalytics: false,
        pagesAnalytics: false,
        eventsAnalytics: false,
        geolocation: false
    });

    const fetchGateways = async (startDate, endDate, selectedGateways = []) => {
        try {
            setLoading(true);

            let gatewaysQuery = collection(db, "Session_Gateway");
            if (startDate && endDate) {
                gatewaysQuery = query(
                    gatewaysQuery,
                    where("session_start_date", ">=", startDate),
                    where("session_start_date", "<=", endDate)
                );
            }

            const querySnapshot = await getDocs(gatewaysQuery);
            let fetchedData = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));

            // Estrai i gateway univoci basati su gateway_name e catalogue_refs_list
            const uniqueGateways = Array.from(
                new Map(
                    fetchedData
                        .filter((item) => item.catalogue_refs_list?.length > 0 && item.gateway_name)
                        .map((item) => [
                            item.catalogue_refs_list[0],
                            { value: item.catalogue_refs_list[0], label: item.gateway_name },
                        ])
                ).values()
            );

            // Recupera i brand con i loro nomi
            const brandIds = uniqueGateways.map((gateway) => gateway.value);
            const brands = await fetchBrands(brandIds);

            const gatewayOptions = uniqueGateways.map((gateway) => {
                const brand = brands.find((b) => b.id === gateway.value);
                return {
                    value: gateway.value,
                    label: `${gateway.label} - ${brand ? brand.nome_brand : "No Brand"}`,
                };
            });

            setCatalogueRefsList(gatewayOptions);

            // Applica il filtro solo se `selectedGateways` non è vuoto
            if (selectedGateways.length > 0) {
                fetchedData = fetchedData.filter((item) =>
                    item.catalogue_refs_list.some((ref) => selectedGateways.includes(ref))
                );
            }

            setData(fetchedData);
        } catch (error) {
            console.error("Error retrieving data:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchGatewaysForReport = async (startDate, endDate, selectedGateways = []) => {
        try {
            let gatewaysQuery = collection(db, "Session_Gateway");

            if (startDate && endDate) {
                gatewaysQuery = query(
                    gatewaysQuery,
                    where("session_start_date", ">=", startDate),
                    where("session_start_date", "<=", endDate)
                );
            }

            const querySnapshot = await getDocs(gatewaysQuery);
            let fetchedData = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));

            // Applica il filtro solo se `selectedGateways` non è vuoto e non contiene "all"
            if (selectedGateways.length > 0 && !selectedGateways.includes("all")) {
                fetchedData = fetchedData.filter((item) =>
                    item.catalogue_refs_list.some((ref) => selectedGateways.includes(ref))
                );
            }

            return fetchedData; // Restituiamo i dati senza modificare lo stato globale
        } catch (error) {
            console.error("Error retrieving data for the report:", error);
            messageApi.error("Error retrieving data for the report.");
            return [];
        }
    };

    const fetchFilteredDataForReport = async () => {
        setDownloadLoading(true);

        try {
            // Convert dates to required format
            const formattedStartDate = startDateReport ? dayjs(startDateReport).format("YYYY/MM/DD") : null;
            const formattedEndDate = endDateReport ? dayjs(endDateReport).format("YYYY/MM/DD") : null;

            // If "all" is the only element, pass `null`
            // Otherwise remove only "all" and keep other options
            const cleanedSelectedOption = selectedOption.length === 1 && selectedOption.includes("all")
                ? null
                : selectedOption.filter(option => option !== "all");

            const fetchedReportData = await fetchGatewaysForReport(formattedStartDate, formattedEndDate, cleanedSelectedOption);

            if (!Array.isArray(fetchedReportData)) {
                console.error("Error: report data is not an array");
                return [];
            }

            setReportData(fetchedReportData); // Aggiorniamo solo `reportData`
            return fetchedReportData;
        } catch (error) {
            console.error("Error retrieving data for the report:", error);
            messageApi.error("Error retrieving data for the report.");
            return [];
        } finally {
            setDownloadLoading(false);
        }
    };

    const handleDateChange = (dates) => {
        if (dates && dates.length === 2) {
            const [startDate, endDate] = dates;
            setDateRange(dates);
            setClearFiltersVisible(true);
            fetchGateways(startDate.format("YYYY/MM/DD"), endDate.format("YYYY/MM/DD"), selectedGateway);
        } else {
            setDateRange(null);
            setClearFiltersVisible(selectedGateway.length > 0);
            fetchGateways(null, null, selectedGateway);
        }
    };

    const handleGatewayChange = (values) => {
        setSelectedGateway(values);
        setClearFiltersVisible(values.length > 0 || dateRange !== defaultDateRange);
        const [startDate, endDate] = dateRange || [];
        fetchGateways(startDate?.format("YYYY/MM/DD"), endDate?.format("YYYY/MM/DD"), values);
    };

    const handleClearFilter = () => {
        setDateRange(defaultDateRange);
        setSelectedGateway([]);
        setClearFiltersVisible(false);
        fetchGateways(startDate.format("YYYY/MM/DD"), endDate.format("YYYY/MM/DD"));
    };

    useEffect(() => {
        fetchGateways(startDate.format("YYYY/MM/DD"), endDate.format("YYYY/MM/DD"), []);
    }, []);

    const handleCloseModalGateway = () => {
        setShowModal(false);
    };

    const handleStartDateChange = (date) => {
        if (!date) {
            setStartDateReport(null);
            setError('');
            return;
        }

        const value = date.format("YYYY-MM-DD");

        if (endDateReport && dayjs(value).isAfter(dayjs(endDateReport))) {
            setError('Start date cannot be later than end date.');
        } else {
            setStartDateReport(value);
            setError('');
        }
    };

    const handleEndDateChange = (date) => {
        if (!date) {
            setEndDateReport(null);
            setError('');
            return;
        }

        const value = date.format("YYYY-MM-DD");

        if (startDateReport && dayjs(value).isBefore(dayjs(startDateReport))) {
            setError('End date cannot be earlier than start date.');
        } else {
            setEndDateReport(value);
            setError('');
        }
    };

    const handleReportSectionChange = (e) => {
        const { name, checked } = e.target;

        if (name === 'all') {
            // Se "all" è selezionato, seleziona tutte le sezioni
            setReportSections({
                overview: checked,
                linesGlasses: checked,
                deviceAnalytics: checked,
                pagesAnalytics: checked,
                eventsAnalytics: checked,
                geolocation: checked
            });
        } else {
            // Altrimenti, aggiorna solo la sezione specifica
            setReportSections(prev => ({
                ...prev,
                [name]: checked
            }));
        }
    };

    const isAllSectionsSelected = () => {
        return Object.values(reportSections).every(value => value === true);
    };

    const isAnySectionSelected = () => {
        return Object.values(reportSections).some(value => value === true);
    };

    const isFormValid = () => {
        return startDateReport && endDateReport && !error && isAnySectionSelected();
    };

    const handleOpenDownloadReportModal = async () => {
        setShowModal(true);
    };

    const handleOptionsChange = (e) => {
        const { value, checked } = e.target;

        setSelectedOption((prevSelectedOption) => {
            let updatedSelectedOption;

            if (checked) {
                updatedSelectedOption = [...prevSelectedOption, value];
            } else {
                updatedSelectedOption = prevSelectedOption.filter((gateway) => gateway !== value);
            }

            // Se "all" è selezionato e viene deselezionata una voce, rimuoviamo "all"
            if (prevSelectedOption.includes("all") && !checked) {
                updatedSelectedOption = updatedSelectedOption.filter((item) => item !== "all");
            }

            // Se tutte le checkbox (tranne "All") sono selezionate, selezioniamo anche "All"
            if (updatedSelectedOption.length === catalogueRefsList.length) {
                return ["all", ...updatedSelectedOption];
            }

            // Se "All" è selezionato, inviamo `null` al server
            return updatedSelectedOption.includes("all") ? null : updatedSelectedOption;
        });
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedOption(["all", ...catalogueRefsList.map((g) => g.value)]);
        } else {
            setSelectedOption([]);
        }
    };

    const handleReportDownload = async () => {
        setDownloadLoading(true);

        try {
            let filteredData = reportData;

            // Se non abbiamo già i dati del report, li recuperiamo
            if (!Array.isArray(filteredData) || filteredData.length === 0) {
                filteredData = await fetchFilteredDataForReport();
            }

            if (!Array.isArray(filteredData) || filteredData.length === 0) {
                messageApi.warning("No data available for the report.");
                return;
            }

            const workbook = XLSX.utils.book_new();

            // **Calculate data for each section**
            const overviewData = reportSections.overview ? calculateGatewaysOverview(filteredData) : null;
            const linesGlassesData = reportSections.linesGlasses ? await calculateLinesGlassesVariants(filteredData) : null;
            const deviceAnalyticsData = reportSections.deviceAnalytics ? calculateDeviceAnalytics(filteredData) : null;
            const pagesAnalyticsData = reportSections.pagesAnalytics ? calculatePagesAnalytics(filteredData) : null;
            const eventsAnalyticsData = reportSections.eventsAnalytics ? calculateEventsAnalytics(filteredData) : null;
            const geolocationData = reportSections.geolocation ? await calculateGeolocationData(filteredData) : null;

            // **Function to create Excel sheets only if they have valid data**
            const createSheet = (data, sheetName) => {
                if (!data || (Array.isArray(data) && data.length === 0)) {
                    console.warn(`Sheet "${sheetName}" not created: empty data.`);
                    return;
                }
                const ws = XLSX.utils.json_to_sheet(data);
                XLSX.utils.book_append_sheet(workbook, ws, sheetName);
            };

            // **Add data to worksheets only for selected sections**
            if (reportSections.overview) {
                createSheet(overviewData, "Overview");
            }

            if (reportSections.linesGlasses) {
                if (linesGlassesData && Array.isArray(linesGlassesData.lines) && linesGlassesData.lines.length > 0) {
                    createSheet(linesGlassesData.lines, "Lines");
                }
                if (linesGlassesData && Array.isArray(linesGlassesData.glasses) && linesGlassesData.glasses.length > 0) {
                    createSheet(linesGlassesData.glasses, "Glasses");
                }
                if (linesGlassesData && Array.isArray(linesGlassesData.variants) && linesGlassesData.variants.length > 0) {
                    createSheet(linesGlassesData.variants, "Variants");
                }
            }

            if (reportSections.deviceAnalytics) {
                if (deviceAnalyticsData && Array.isArray(deviceAnalyticsData.summary) && deviceAnalyticsData.summary.length > 0) {
                    createSheet(deviceAnalyticsData.summary, "Device Summary");
                }
                if (deviceAnalyticsData && Array.isArray(deviceAnalyticsData.browserUsage) && deviceAnalyticsData.browserUsage.length > 0) {
                    createSheet(deviceAnalyticsData.browserUsage, "Browser Usage");
                }
                if (deviceAnalyticsData && Array.isArray(deviceAnalyticsData.deviceTypeDistribution) && deviceAnalyticsData.deviceTypeDistribution.length > 0) {
                    createSheet(deviceAnalyticsData.deviceTypeDistribution, "Device Distribution");
                }
            }

            if (reportSections.pagesAnalytics) {
                if (Array.isArray(pagesAnalyticsData) && pagesAnalyticsData.length > 0) {
                    createSheet(pagesAnalyticsData, "Pages Analytics");
                }
            }

            if (reportSections.eventsAnalytics) {
                if (Array.isArray(eventsAnalyticsData) && eventsAnalyticsData.length > 0) {
                    createSheet(eventsAnalyticsData, "Events Analytics");
                }
            }

            if (reportSections.geolocation && geolocationData) {
                // Creiamo fogli separati per ciascun tipo di dati di geolocalizzazione
                if (Array.isArray(geolocationData.locations) && geolocationData.locations.length > 0) {
                    createSheet(geolocationData.locations, "Geo Locations");
                }
                if (Array.isArray(geolocationData.countries) && geolocationData.countries.length > 0) {
                    createSheet(geolocationData.countries, "Geo Countries");
                }
                if (Array.isArray(geolocationData.cities) && geolocationData.cities.length > 0) {
                    createSheet(geolocationData.cities, "Geo Cities");
                }

                // Creiamo un foglio per le statistiche di geolocalizzazione
                const geoStats = [
                    { stat: "Total Records", value: geolocationData.totalItems },
                    { stat: "Valid Records", value: geolocationData.validItems },
                    { stat: "Skipped Records", value: geolocationData.skippedItems },
                    { stat: "Unique Devices", value: geolocationData.uniqueDeviceIds }
                ];
                createSheet(geoStats, "Geo Statistics");
            }

            // **Save the file**
            const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
            const dataBlob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });

            saveAs(dataBlob, `Report_${startDateReport}_${endDateReport}.xlsx`);

            messageApi.success("Report downloaded successfully!");
        } catch (error) {
            console.error("Error during report download:", error);
            messageApi.error("Error downloading the report.");
        } finally {
            setDownloadLoading(false);
            setShowModal(false);
        }
    };

    return (
        <App>
            <Layout>
                <AnalyticsHeader title="Gateways" />
                <Layout.Content id="gateways" style={{ backgroundColor: "white" }}>
                    {/* Filtri */}
                    {
                        isTabletOrMobile ? (
                            <FiltersMobile
                                dateRange={dateRange}
                                handleOpenDownloadReportModal={handleOpenDownloadReportModal}
                                downloadLoading={downloadLoading}
                                onChangeDate={handleDateChange}
                                onClearFilter={handleClearFilter}
                                selectedGateway={selectedGateway}
                                handleGatewayChange={handleGatewayChange}
                                catalogueRefsList={catalogueRefsList}
                            />
                        ) : (
                            <>
                                <Row gutter={[16, 16]}>
                                    <Col span={24}>
                                        <FiltersTop
                                            dateRange={dateRange}
                                            onChangeDate={handleDateChange}
                                            onClearFilter={handleClearFilter}
                                            handleOpenDownloadReportModal={handleOpenDownloadReportModal}
                                            downloadLoading={loading}
                                        />
                                    </Col>
                                </Row>
                                {/* Filtro Gateway */}
                                <div style={{ flex: "1 1 auto", maxWidth: "400px", minWidth: "150px" }}>
                                    <Text>Select Gateways</Text>
                                    <div style={{ position: "relative", width: "100%" }}>
                                        <Select
                                            mode="multiple"
                                            value={selectedGateway}
                                            onChange={handleGatewayChange}
                                            style={{ width: "100%" }}
                                            placeholder="Select Gateways"
                                            allowClear={false}
                                            showSearch={false}
                                        >
                                            {catalogueRefsList.map((gateway) => (
                                                <Option key={gateway.value} value={gateway.value}>
                                                    {gateway.label}
                                                </Option>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                            </>
                        )
                    }

                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <GatewaysOverviewCard
                                loading={loading}
                                aggregateList={data}
                            />
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <LinesGlassesVariantsAnalytics
                                data={data}
                                loading={loading}
                                clearFiltersVisible={clearFiltersVisible}
                                onResetFilters={handleClearFilter}
                            />
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <DeviceAnalytics
                                data={data}
                                loading={loading}
                                clearFiltersVisible={clearFiltersVisible}
                                onResetFilters={handleClearFilter}
                            />
                        </Col>
                    </Row>

                    {isTabletOrMobile ? (
                        <>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <PagesAnalytics
                                        data={data}
                                        loading={loading}
                                        clearFiltersVisible={clearFiltersVisible}
                                        onResetFilters={handleClearFilter}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <EventsAnalytics
                                        data={data}
                                        loading={loading}
                                        clearFiltersVisible={clearFiltersVisible}
                                        onResetFilters={handleClearFilter}
                                    />
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <PagesAnalytics
                                    data={data}
                                    loading={loading}
                                    clearFiltersVisible={clearFiltersVisible}
                                    onResetFilters={handleClearFilter}
                                />
                            </Col>
                            <Col span={12}>
                                <EventsAnalytics
                                    data={data}
                                    loading={loading}
                                    clearFiltersVisible={clearFiltersVisible}
                                    onResetFilters={handleClearFilter}
                                />
                            </Col>
                        </Row>
                    )}

                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <GeolocationMap
                                data={data}
                                loading={loading}
                                clearFiltersVisible={clearFiltersVisible}
                                onResetFilters={handleClearFilter}
                            />
                        </Col>
                    </Row>

                    <Modal
                        title={<Title level={4} style={{ margin: 0 }}>Report Download</Title>}
                        open={showModal}
                        onCancel={handleCloseModalGateway}
                        footer={null}
                        width={1000}
                    >
                        <Row gutter={[24, 24]}>
                            <Col span={24}>
                                <Title level={5} style={{ marginBottom: 12 }}>
                                    Select Date Range
                                </Title>
                                <Row gutter={[24, 0]} justify="start">
                                    <Col md={8} sm={12}>
                                        <Form layout="vertical" style={{ marginBottom: 0 }}>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                            >
                                                <DatePicker
                                                    format="DD/MM/YYYY"
                                                    value={startDateReport ? dayjs(startDateReport, "YYYY-MM-DD") : null}
                                                    onChange={(date) => handleStartDateChange(date)}
                                                    style={{ width: "100%" }}
                                                    placeholder="Select Start Date"
                                                    size="middle"
                                                />
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                    <Col md={8} sm={12}>
                                        <Form layout="vertical" style={{ marginBottom: 0 }}>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                            >
                                                <DatePicker
                                                    format="DD/MM/YYYY"
                                                    value={endDateReport ? dayjs(endDateReport, "YYYY-MM-DD") : null}
                                                    onChange={(date) => handleEndDateChange(date)}
                                                    style={{ width: "100%" }}
                                                    size="middle"
                                                    placeholder="Select End Date"
                                                />
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                </Row>
                                {error && <Text type="danger" style={{ display: 'block', marginTop: 8 }}>{error}</Text>}
                            </Col>
                        </Row>

                        <Row gutter={[32, 24]} style={{ marginTop: 16 }}>
                            <Col md={12}>
                                <Title level={5} style={{ marginBottom: 16 }}>
                                    Select Gateway(s)
                                </Title>
                                <div style={{ maxHeight: '300px', overflowY: 'auto', padding: '0 4px' }}>
                                    <Checkbox.Group
                                        value={selectedOption}
                                        onChange={() => { }}
                                        style={{ display: 'flex', flexDirection: 'column' }}
                                    >
                                        <Checkbox
                                            value="all"
                                            checked={selectedOption.includes("all")}
                                            onChange={handleSelectAll}
                                            style={{ marginBottom: 8 }}
                                        >
                                            <Text strong>All Gateways</Text>
                                        </Checkbox>
                                        <Divider style={{ margin: '12px 0' }} />
                                        {catalogueRefsList.map((gateway) => (
                                            <Checkbox
                                                key={gateway.value}
                                                value={gateway.value}
                                                checked={selectedOption.includes(gateway.value)}
                                                onChange={handleOptionsChange}
                                                style={{ marginBottom: 8 }}
                                            >
                                                {gateway.label}
                                            </Checkbox>
                                        ))}
                                    </Checkbox.Group>
                                </div>
                            </Col>

                            <Col md={12}>
                                <Title level={5} style={{ marginBottom: 16 }}>
                                    Select Report Section(s)
                                </Title>
                                <div style={{ maxHeight: '300px', overflowY: 'auto', padding: '0 4px' }}>
                                    <Checkbox
                                        name="all"
                                        checked={isAllSectionsSelected()}
                                        onChange={handleReportSectionChange}
                                        style={{ marginBottom: 8 }}
                                    >
                                        <Text strong>All Sections</Text>
                                    </Checkbox>
                                    <Divider style={{ margin: '12px 0' }} />
                                    <Checkbox
                                        name="overview"
                                        checked={reportSections.overview}
                                        onChange={handleReportSectionChange}
                                        style={{ marginBottom: 12 }}
                                    >
                                        Overview
                                    </Checkbox>
                                    <Checkbox
                                        name="linesGlasses"
                                        checked={reportSections.linesGlasses}
                                        onChange={handleReportSectionChange}
                                        style={{ marginBottom: 12 }}
                                    >
                                        Lines & Glasses
                                    </Checkbox>
                                    <Checkbox
                                        name="deviceAnalytics"
                                        checked={reportSections.deviceAnalytics}
                                        onChange={handleReportSectionChange}
                                        style={{ marginBottom: 12 }}
                                    >
                                        Device Analytics
                                    </Checkbox>
                                    <Checkbox
                                        name="pagesAnalytics"
                                        checked={reportSections.pagesAnalytics}
                                        onChange={handleReportSectionChange}
                                        style={{ marginBottom: 12 }}
                                    >
                                        Pages Analytics
                                    </Checkbox>
                                    <Checkbox
                                        name="eventsAnalytics"
                                        checked={reportSections.eventsAnalytics}
                                        onChange={handleReportSectionChange}
                                        style={{ marginBottom: 12 }}
                                    >
                                        Events Analytics
                                    </Checkbox>
                                    <Checkbox
                                        name="geolocation"
                                        checked={reportSections.geolocation}
                                        onChange={handleReportSectionChange}
                                    >
                                        Geolocation Data
                                    </Checkbox>
                                </div>
                            </Col>
                        </Row>

                        <Divider style={{ margin: '24px 0' }} />

                        <Row justify="center" style={{ marginTop: 8 }}>
                            <Button
                                type="primary"
                                onClick={handleReportDownload}
                                disabled={!isFormValid() || downloadLoading}
                                icon={
                                    downloadLoading ? (
                                        <Spin size="small" />
                                    ) : (
                                        <DownloadOutlined />
                                    )
                                }
                                size="large"
                                style={{ minWidth: 200, height: 40 }}
                            >
                                {downloadLoading ? "Downloading..." : "Proceed to Download"}
                            </Button>
                        </Row>
                    </Modal>
                </Layout.Content>
            </Layout>
        </App>
    );
}

export default Gateways;