import React, { useEffect, useRef, useState } from "react";
import { Spin, theme } from "antd";
import { EyeOutlined, CodeOutlined } from "@ant-design/icons";
import { statusMapping } from "../../services/utils/ar3dViewer";
import CustomTooltip from "../common/CustomTooltip";

const GlassRow = ({ model, onClickExportCode, onClickView, onClickRow }) => {
    const { token } = theme.useToken();
    const canvasRef = useRef(null);
    const [processedImage, setProcessedImage] = useState(null);

    const removeWhiteBackground = (image) => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext("2d", { willReadFrequently: true });
        if (!ctx) return;
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;
        for (let i = 0; i < data.length; i += 4) {
            const red = data[i];
            const green = data[i + 1];
            const blue = data[i + 2];
            if (red > 240 && green > 240 && blue > 240) {
                data[i + 3] = 0;
            }
        }
        ctx.putImageData(imageData, 0, 0);
        setProcessedImage(canvas.toDataURL("image/png"));
    };

    useEffect(() => {
        let isMounted = true;
        const img = new Image();
        img.src = model.poster3 ? model.poster3 : model.poster;
        img.crossOrigin = "anonymous";
        img.onload = () => {
            if (isMounted && canvasRef.current) {
                removeWhiteBackground(img);
            }
        };
        return () => {
            isMounted = false;
        };
    }, [model.poster3, model.poster]);

    const displayStatus = statusMapping[model.stato] || model.stato;

    const statusColor =
        model.stato === "Pubblicato"
            ? token.colorSuccess
            : model.stato === "Incompleto"
                ? token.colorGray
                : token.colorWarning;

    return (
        <div
            onClick={onClickRow}
            style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: token.colorBgBase,
                border: `1px solid ${token.colorBorder}`,
                borderRadius: "8px",
                padding: "0 16px",
                cursor: "pointer",
                height: "90px",
            }}
        >
            {/* Immagine */}
            <div
                style={{
                    width: "90px",
                    height: "70px",
                    flexShrink: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: "12px",
                }}
            >
                {processedImage ? (
                    <img
                        src={processedImage}
                        alt={model.nomeOcchiale}
                        style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "contain",
                        }}
                    />
                ) : (
                    <Spin />
                )}
                <canvas ref={canvasRef} style={{ display: "none" }} />
            </div>
            {/* Dettagli */}
            <div style={{ flex: 1, paddingRight: "12px" }}>
                <div style={{ fontWeight: "bold", fontSize: "16px" }}>
                    {model.nomeOcchiale}
                </div>
                <div style={{ marginTop: "4px" }}>
                    <div
                        style={{
                            backgroundColor: statusColor,
                            color: token.colorTextWhite,
                            padding: "4px 8px",
                            borderRadius: "16px",
                            fontSize: "12px",
                            fontWeight: "bold",
                            display: "inline-block",
                        }}
                    >
                        {displayStatus}
                    </div>
                </div>
            </div>
            {/* Azioni */}
            <div style={{ display: "flex", gap: "16px" }}>
                <CustomTooltip title="Iframe codes">
                    <CodeOutlined
                        style={{ fontSize: "20px", cursor: "pointer", color: token.colorPrimary }}
                        onClick={(e) => {
                            e.stopPropagation();
                            onClickExportCode();
                        }}
                    />
                </CustomTooltip>
                <CustomTooltip title="3D AR viewer">
                    <EyeOutlined
                        style={{ fontSize: "20px", cursor: "pointer", color: token.colorPrimary }}
                        onClick={(e) => {
                            e.stopPropagation();
                            onClickView();
                        }}
                    />
                </CustomTooltip>
            </div>

        </div>
    );
};

export default GlassRow;