import React from 'react';
import { Button } from 'antd';
import { BulbOutlined, BulbFilled } from '@ant-design/icons';

/**
 * Componente per il toggle del tema chiaro/scuro (attualmente nascosto)
 * 
 * @param {Object} props - Proprietà del componente
 * @param {boolean} props.isDarkMode - Se il tema corrente è scuro
 * @param {Function} props.toggleTheme - Funzione per cambiare il tema
 * @returns {JSX.Element} - Componente React
 */
const ThemeToggle = ({ isDarkMode = false, toggleTheme }) => {
    // Componente nascosto, impostato su tema chiaro di default
    return (
        <Button
            type="text"
            icon={isDarkMode ? <BulbOutlined /> : <BulbFilled />}
            onClick={toggleTheme}
            title={isDarkMode ? 'Passa al tema chiaro' : 'Passa al tema scuro'}
            style={{ marginLeft: 8, display: 'none' }} /* Nascosto con display: none */
        />
    );
};

export default ThemeToggle;