import React, { useState } from "react";
import { Modal, Button, Select, Typography, DatePicker, Tag } from "antd";
import ChartFilter from "./ChartFilter";
import CatalogSelect from "./CatalogSelect";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { setSelectedCatalog } from "../../redux/catalog/action";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Text } = Typography;

function FiltersMobile({
    dateRange,
    onChangeDate,
    onClearFilter,
    handleOpenDownloadReportModal,
    downloadLoading,
    typeData,
    setTypeData,
    onChangeService,
    selectedValue,
    setSelectedValue,
}) {

    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const selectedCatalog = useSelector((state) => state.catalog.selectedCatalog);

    const showModal = () => setIsModalOpen(true);
    const handleCancel = () => setIsModalOpen(false);

    const resetToAll = () => {
        setTypeData("all");
        onChangeService("all");
    };

    const defaultRange = [
        dayjs().subtract(7, "day").startOf("day"),
        dayjs().endOf("day"),
    ];


    const clearDateRange = () => {
        onChangeDate(defaultRange);
    };
    const clearCatalog = () => dispatch(setSelectedCatalog({ id: "all", nome_brand: "All Catalogues" }));
    const clearLineGlass = () => setSelectedValue(null);
    const clearService = () => setTypeData("all");

    const rangePresets = [
        { label: "Today", value: [dayjs().startOf("day"), dayjs().endOf("day")] },
        { label: "Yesterday", value: [dayjs().subtract(1, "day").startOf("day"), dayjs().subtract(1, "day").endOf("day")] },
        { label: "Last Week", value: [dayjs().subtract(1, "week").startOf("week"), dayjs().subtract(1, "week").endOf("week")] },
        { label: "This Week", value: [dayjs().startOf("week"), dayjs().endOf("day")] },
        { label: "Last Month", value: [dayjs().subtract(1, "month").startOf("month"), dayjs().subtract(1, "month").endOf("month")] },
        { label: "This Month", value: [dayjs().startOf("month"), dayjs().endOf("day")] },
        { label: "Last 3 Months", value: [dayjs().subtract(3, "month").startOf("day"), dayjs().endOf("day")] },
        { label: "Last 6 Months", value: [dayjs().subtract(6, "month").startOf("day"), dayjs().endOf("day")] },
        { label: "This Year", value: [dayjs().startOf("year"), dayjs().endOf("day")] },
    ];

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "10px", width: "100%" }}>
            {/* Top Buttons */}
            <div style={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: "space-between" }}>
                <Button
                    type="primary"
                    onClick={handleOpenDownloadReportModal}
                    disabled={downloadLoading}
                    style={{ flex: "1 1 auto", minWidth: "120px" }}
                >
                    Download Report
                </Button>
                <Button
                    type="default"
                    onClick={showModal}
                    style={{ flex: "1 1 auto", minWidth: "120px" }}
                >
                    Open Filters
                </Button>
            </div>

            {/* Selected Filters Section */}
            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                {/* Mostra il Tag solo se il valore non è quello predefinito */}
                {dateRange &&
                    !(dateRange[0].isSame(defaultRange[0], "day") && dateRange[1].isSame(defaultRange[1], "day")) && (
                        <Tag
                            closable
                            onClose={clearDateRange}
                            style={{ cursor: "pointer" }}
                        >
                            Date Range: {dateRange[0].format("DD/MM/YYYY")} - {dateRange[1].format("DD/MM/YYYY")}
                        </Tag>
                    )}

                {/* Tag per il Catalog */}
                {selectedCatalog && selectedCatalog?.id !== "all" && (
                    <Tag
                        closable
                        onClose={clearCatalog}
                        style={{ cursor: "pointer" }}
                    >
                        Catalog: {selectedCatalog?.nome_brand}
                    </Tag>
                )}

                {/* Tag per Line/Glass */}
                {selectedValue && (
                    <Tag
                        closable
                        onClose={clearLineGlass}
                        style={{ cursor: "pointer" }}
                    >
                        Line/Glass: {selectedValue}
                    </Tag>
                )}

                {/* Tag per il Service */}
                {typeData && typeData !== "all" && (
                    <Tag
                        closable
                        onClose={clearService}
                        style={{ cursor: "pointer" }}
                    >
                        Service: {typeData}
                    </Tag>
                )}
            </div>

            {/* Modal */}
            <Modal
                title="Filters"
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null}
                centered
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                        width: "100%",
                    }}
                >
                    {/* Range Picker */}
                    <div>
                        <Text>Select Date Range</Text>
                        <RangePicker
                            value={dateRange}
                            presets={rangePresets}
                            onChange={onChangeDate}
                            format="DD/MM/YYYY"
                            placement="bottomLeft"
                            style={{
                                width: "100%",
                                marginTop: "8px",
                            }}
                        />
                    </div>

                    {/* Select Catalog */}
                    <div>
                        <Text>Select Catalog</Text>
                        <div style={{ position: "relative", width: "100%", marginTop: "8px" }}>
                            <CatalogSelect />
                        </div>
                    </div>

                    {/* Select Line/Glasses */}
                    <div>
                        <Text>Select Line/Glasses</Text>
                        <div style={{ position: "relative", width: "100%", marginTop: "8px" }}>
                            <ChartFilter selectedValue={selectedValue} setSelectedValue={setSelectedValue} />
                        </div>
                    </div>

                    {/* Select Service */}
                    <div>
                        <Text>Select Service</Text>
                        <div style={{ position: "relative", width: "100%", marginTop: "8px" }}>
                            <Select
                                value={typeData}
                                onChange={(value) => {
                                    setTypeData(value);
                                    onChangeService(value);
                                }}
                                style={{
                                    width: "100%",
                                    borderRadius: "8px",
                                    borderColor: typeData !== "all" ? "rgba(66, 177, 172, 1)" : undefined,
                                    boxShadow: typeData !== "all" ? "0 0 0 2px rgba(66, 177, 172, 1)" : undefined,
                                }}
                                placeholder="Select a service"
                            >
                                <Option value="app">VTO Apps</Option>
                                <Option value="3d">3D/AR Viewer</Option>
                                <Option value="WebVto">Web VTO</Option>
                                <Option value="all">All</Option>
                            </Select>
                            {typeData !== "all" && (
                                <Button
                                    type="text"
                                    icon={<CloseCircleOutlined style={{ color: "grey" }} />}
                                    onClick={resetToAll}
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        right: "25px",
                                        transform: "translateY(-50%)",
                                        zIndex: 1,
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "20px",
                        gap: "10px",
                    }}
                >
                    <Button
                        type="default"
                        onClick={() => {
                            onClearFilter();
                            setIsModalOpen(false);
                        }}
                    >
                        Clear Filters
                    </Button>
                    <Button type="primary" onClick={() => setIsModalOpen(false)}>
                        Apply
                    </Button>
                </div>
            </Modal>
        </div>
    );
}

export default FiltersMobile;