import React, { useEffect, useState, useRef } from "react";
import { message } from "antd";
import { getAuth, signOut } from "firebase/auth";
import { logStorageOut, secureGetItem } from "../data/utils";
import AnalyticsHeader from "../components/Analytics/AnalyticsHeader";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

function FrameValidation() {
    const broken = useSelector((state) => state.sidebar?.broken);
    const [token, setToken] = useState("");
    const iframeRef = useRef(null);
    const auth = getAuth();
    const [loading, setLoading] = useState(true);
    const [frameData, setFrameData] = useState(null);
    const [isError, setIsError] = useState(false);
    const { id } = useParams();
    const messageApi = message.useMessage();

    useEffect(() => {
        const storedToken = secureGetItem("csToken");
        if (storedToken) {
            setToken(storedToken);
        } else {
            message.warning("Your session is expired. Please login again.");
        }
    }, []);

    // useEffect(() => {
    //     const sendTokenToIframe = () => {
    //         if (iframeRef.current && token) {
    //             iframeRef.current.contentWindow.postMessage({ type: "updateToken", token }, "*");
    //         }
    //     };

    //     sendTokenToIframe();

    //     const interval = setInterval(() => {
    //         sendTokenToIframe();
    //     }, 5000);

    //     return () => clearInterval(interval);
    // }, [token]);

    const logOut = async () => {
        try {
            logStorageOut();
            await signOut(auth);
            message.success("Your session is expired. Please login again.");
        } catch (error) {
            message.error("Error during logout: " + error.message);
        }
    };

    // useEffect(() => {
    //     const unsubscribe = auth.onIdTokenChanged(async (user) => {
    //         if (user) {
    //             const newToken = await user.getIdToken(true);
    //             localStorage.setItem("csToken", newToken);
    //             setToken(newToken);
    //         } else {
    //             setToken(null);
    //             localStorage.removeItem("csToken");
    //         }
    //     });

    //     return () => unsubscribe();
    // }, []);

    useEffect(() => {
        const handleMessage = (event) => {
            // if (event.origin !== `${process.env.REACT_APP_ACTIVE_BASE_URL_DASH}/?csToken=${token}`) return;

            // if (event.data?.type === "requestToken") {
            //     iframeRef.current.contentWindow.postMessage({ type: "updateToken", token }, event.origin);
            // }

            if (event.data?.isLogout) {
                logOut();
            }
        };

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === "csToken" && event.oldValue !== event.newValue) {
                // La chiave csToken è cambiata, ma dobbiamo verificare se il valore crittografato è cambiato
                // Fare logout per sicurezza
                logOut();
            }
        };

        window.addEventListener("storage", handleStorageChange);

        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    return (
        <div>
            {broken && <AnalyticsHeader title="Frame Validation" />}
            <iframe
                ref={iframeRef}
                src={`${process.env.REACT_APP_ACTIVE_BASE_URL_DASH}/?csToken=${token}`}
                title="ARShades Validation"
                sandbox="allow-popups allow-same-origin allow-scripts allow-downloads allow-forms allow-clipboard-write"
                style={{
                    width: broken ? '100svw' : 'calc(100svw - 64px)',
                    height: broken ? 'calc(100svh - 64px)' : '100svh',
                    boxSizing: 'border-box',
                    border: '0px',
                    margin: broken ? '64px 0px 0px 0px' : '0px',
                    marginLeft: broken ? '0px' : '64px',
                    transition: 'none',
                    zIndex: -90,
                    overflowY: 'hidden',
                }}
            ></iframe>
        </div>
    );
}

export default FrameValidation;