import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_ESK;

export function encodePassword(password) {

  if (!secretKey || secretKey.length !== 40) {
    throw new Error("Invalid or missing secret key");
  }

  const key = CryptoJS.enc.Utf8.parse(secretKey.slice(0, 32));
  const iv = CryptoJS.enc.Utf8.parse(secretKey.slice(32, 40));
  const encrypted = CryptoJS.AES.encrypt(password, key, { iv });
  return encrypted.toString();
}

export function decodePassword(encodedPassword) {
  if (!secretKey || secretKey.length !== 40) {
    throw new Error("Invalid or missing secret key");
  }

  const key = CryptoJS.enc.Utf8.parse(secretKey.slice(0, 32));
  const iv = CryptoJS.enc.Utf8.parse(secretKey.slice(32, 40));
  const decrypted = CryptoJS.AES.decrypt(encodedPassword, key, { iv });
  return decrypted.toString(CryptoJS.enc.Utf8);
}

export function generateOTP() {
  const otp = Math.floor(100000 + Math.random() * 900000);
  return otp.toString();
}

export function getPassword(username) {
  const key = process.env.REACT_APP_AR_INITIALS;
  const pw = localStorage.getItem(key + username);

  if (pw && pw !== "null") return decodePassword(pw);
  return '';
}

export function storePassword(username, password) {
  const key = process.env.REACT_APP_AR_INITIALS;
  localStorage.setItem(key + username, password);
}

export function logStorageOut() {
  const version = localStorage.getItem('version');
  const email = secureGetItem('email');
  const encpw = email ? localStorage.getItem(process.env.REACT_APP_AR_INITIALS + email) : null;
  localStorage.clear();
  localStorage.setItem('version', version);
  if (email && encpw) {
    localStorage.setItem(process.env.REACT_APP_AR_INITIALS + email, encpw);
  }
}

// Funzione per crittografare e salvare dati in localStorage
export function secureSetItem(key, value) {
  if (!value) return;

  // Converti oggetti in stringhe JSON
  const stringValue = typeof value === 'object' ? JSON.stringify(value) : String(value);

  // Crittografa il valore
  const encryptedValue = encodePassword(stringValue);

  // Salva il valore crittografato
  localStorage.setItem(key, encryptedValue);
}

// Funzione per recuperare e decrittografare dati dal localStorage
export function secureGetItem(key) {
  const encryptedValue = localStorage.getItem(key);

  if (!encryptedValue) return null;

  try {
    // Decrittografa il valore
    const decryptedValue = decodePassword(encryptedValue);

    // Prova a convertire il risultato in JSON se possibile
    try {
      return JSON.parse(decryptedValue);
    } catch (e) {
      // Se non è JSON, restituisci la stringa
      return decryptedValue;
    }
  } catch (error) {
    console.error(`Errore nella decrittografia di ${key}:`, error);
    return null;
  }
}