import dayjs from "dayjs";

export const parseDate = (date) => {
    if (!date) return null;
    if (typeof date === "string") {
        const [day, month, year] = date.split("/");
        return dayjs(`${year}-${month}-${day}`, "YYYY-MM-DD");
    }
    return dayjs.unix(date.seconds + date.nanoseconds / 1e9);
};

export const calculateTotals = (data) => data.reduce((acc, item) => acc + (item?.calls || 0), 0);

export const sortByYearAndMonth = (array) => array.sort((a, b) => a.year - b.year || a.month - b.month);

export const getDataConsumptionUnitValue = (valueInMb, month, year) => {
    // Calcola il numero di giorni nel mese specificato usando dayjs
    const daysInMonth = dayjs(`${year}-${month}-01`).daysInMonth();

    // Converte il valore da MB a GB
    const valueInGb = valueInMb / 1024;

    // Moltiplica per il numero di giorni nel mese
    const result = valueInGb * daysInMonth;

    // Divide per 5 e moltiplica per 1024
    const finalResult = (result / 5) * 1024;

    return finalResult;
}

export const filterValidConsumption = (snapshot, subscriptions, checkDate, currentDate) => {
    return snapshot.docs
        .filter((doc) => subscriptions?.consumption_list?.consumptionRef === doc.id)
        .map((doc) => doc.data());
};

export const filterValidGetDataConsumption = (snapshot, brandList) => {
    const validBrands = brandList.filter((brand) => {
        const [dayCheck, monthCheck, yearCheck] = brand?.endDate?.split("/");
        const checkDate = dayjs(`${yearCheck}-${monthCheck}-${dayCheck}`);
        return dayjs().isBefore(checkDate);
    });

    return snapshot.docs
        .filter((doc) =>
            validBrands.some((brand) => brand.data_consumption === doc.id)
        )
        .map((doc) => doc.data());
};

export const calculateCallDetails = (callsData) => {
    const totalCalls = callsData.reduce((acc, item) => acc + (item?.calls || 0), 0);

    const startingDate =
        callsData.length > 0
            ? `${callsData[0]?.month}/${callsData[0]?.year}`
            : "";
    const endingDate =
        callsData.length > 0
            ? `${callsData[callsData.length - 1]?.month}/${callsData[callsData.length - 1]?.year}`
            : "";

    return {
        totalCalls,
        dateRange: `${startingDate} - ${endingDate}`,
    };
};

export const calculateTotalBandwidth = (bandwidthData) => {
    let cumulativeBandwidth = 0;
    const sortedData = bandwidthData.sort(
        (a, b) => a.year - b.year || a.month - b.month
    );

    const cumulativeData = sortedData.map((item) => {
        cumulativeBandwidth += item.bandwidth;
        return { ...item, bandwidth: cumulativeBandwidth };
    });

    return [cumulativeData, cumulativeBandwidth];
};

export const generateLineChartData = (startDate, endDate, bandwidthData, dataSet) => ({
    labels: generateMissingData(startDate, endDate, bandwidthData).map(
        (item) => `${item?.month || ""}/${item?.year || ""}`
    ),
    datasets: [
        {
            label: "Monthly Bandwidth (GB)",
            data: generateMissingData(startDate, endDate, bandwidthData).map(
                (item) => (item?.bandwidth / 1024).toFixed(2) || 0
            ),
            borderColor: "#41b1ac",
            backgroundColor: "#41b1ac60",
            fill: true,
        },
        {
            label: "Total Bandwidth (GB)",
            data: generateMissingData(startDate, endDate, dataSet).map(
                (item) => (item?.bandwidth / 1024).toFixed(2) || 0
            ),
            borderColor: "#ff2464",
            backgroundColor: "#ff246460",
            fill: true,
        },
    ],
});

export const generateBarChartData = (startDate, endDate, callsData) => ({
    labels: generateMissingData(startDate, endDate, callsData).map(
        (item) => `${item?.month || ""}/${item?.year || ""}`
    ),
    datasets: [
        {
            label: "Calls",
            data: generateMissingData(startDate, endDate, callsData).map(
                (item) => item?.calls || 0
            ),
            backgroundColor: "#241F45",
        },
    ],
});

export const getDateRange = (data) => {
    if (!data.length) return "";
    const startDate = `${data[0]?.month}/${data[0]?.year}`;
    const endDate = `${data[data.length - 1]?.month}/${data[data.length - 1]?.year}`;
    return `${startDate} - ${endDate}`;
};

export const generateMissingData = (startDate, endDate, data, isBandwidth) => {
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    const missingData = [];

    let current = start;
    while (current.isBefore(end, "month")) {
        const year = current.year();
        const month = current.month() + 1; // Mesi in dayjs partono da 0

        if (!data.some((item) => item.year === year && item.month === month)) {
            missingData.push({ year, month, bandwidth: null });
        }

        current = current.add(1, "month");
    }

    return [...data, ...missingData].sort((a, b) => {
        if (a.year === b.year) {
            return a.month - b.month;
        } else {
            return a.year - b.year;
        }
    });
};

export const parseSubscriptionStartDate = (startDate) => {
    return dayjs.unix(startDate.seconds + startDate.nanoseconds / 1e9);
};

// Helper function to format Firestore timestamps
export const getTime = (date) => {
    if (!date) return "";

    const milliseconds = date.seconds * 1000 + Math.round(date.nanoseconds / 1000000);
    const output = dayjs(milliseconds);

    const month = output.month() + 1;
    const year = output.year();

    return `${month}/${year}`;
};