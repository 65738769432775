import React, { useState, useRef, useEffect } from "react";
import { Button } from "antd";
import styles from "../../styles/ModelViewer/ModelContainer.module.css";
import { useMediaQuery } from "react-responsive";

function MenuContainer(props) {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
    let topBottomItemWidth = 200 * props.childCount;

    if (props.menu === "circle") {
        topBottomItemWidth = 45 * (props.childCount - 1) + 50;
    }

    const containerRef = useRef(null);
    const [selectedId, setSelectedId] = useState(props.selectedId);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [containerWidth, setContainerWidth] = useState(0);

    function selectNext() {
        const index = props.children.findIndex(
            (child) => child.props.modelId === selectedId
        );

        setScrollPosition(1);

        if (index < props.children.length - 1) {
            props.setSelectedId(props.children[index + 1].props.modelId);
            setSelectedId(props.children[index + 1].props.modelId);
        }
    }

    function selectPrevious() {
        const index = props.children.findIndex(
            (child) => child.props.modelId === selectedId
        );
        if (index > 0) {
            props.setSelectedId(props.children[index - 1].props.modelId);
            setSelectedId(props.children[index - 1].props.modelId);
        }
    }

    useEffect(() => {
        const updateContainerWidth = () => {
            if (containerRef.current) {
                setContainerWidth(containerRef.current.offsetWidth);
            }
        };

        window.addEventListener("resize", updateContainerWidth);

        updateContainerWidth(); // Inizializza la larghezza del contenitore

        return () => {
            window.removeEventListener("resize", updateContainerWidth);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (containerRef.current) {
                setScrollPosition(containerRef.current.scrollLeft);
            }
        };

        if (containerRef.current) {
            containerRef.current.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (containerRef.current) {
                containerRef.current.removeEventListener("scroll", handleScroll);
            }
        };
    }, [containerRef.current]);

    useEffect(() => {
        setSelectedId(props.selectedId); // Aggiorna selectedId quando cambia props.selectedId
    }, [props.selectedId]);

    return (
        <div
            key={props.cardStyle}
            ref={containerRef}
            className={
                props.style === "TOP" || props.style === "BOTTOM"
                    ? styles["menu-container-horizontal"]
                    : styles["menu-container-vertical"]
            }
        >
            {/* Freccia sinistra visibile solo se c'è uno scroll maggiore di 0 e non siamo su mobile/tablet */}
            {scrollPosition > 0 && !isTabletOrMobile && (
                <Button
                    type="default"
                    icon={<span>&lt;</span>}
                    onClick={selectPrevious}
                    className="scroll-arrow left"
                />
            )}

            {props.style === "TOP" && (
                <div className={styles["wrapper-classic-parent"]}>
                    <div
                        className={styles["wrapper-classic"]}
                        style={{
                            width:
                                containerWidth >= topBottomItemWidth
                                    ? topBottomItemWidth
                                    : "100%",
                        }}
                    >
                        {props.children}
                    </div>
                </div>
            )}

            {props.style === "BOTTOM" && (
                <div className={styles["wrapper-classic-parent"]}>
                    <div
                        className={styles["wrapper-classic"]}
                        style={{
                            width:
                                containerWidth >= topBottomItemWidth
                                    ? topBottomItemWidth
                                    : "100%",
                        }}
                    >
                        {props.children}
                    </div>
                </div>
            )}

            {props.style === "LEFT" && (
                <div
                    className={
                        props?.menu === "plane"
                            ? styles["wrapper-classic-lateral"]
                            : styles["row-wrapp-lateral"]
                    }
                >
                    {props.children}
                </div>
            )}
            {props.style === "RIGHT" && (
                <div
                    className={
                        props?.menu === "plane"
                            ? styles["wrapper-classic-lateral"]
                            : styles["row-wrapp-lateral"]
                    }
                >
                    {props.children}
                </div>
            )}

            {/* Freccia destra visibile solo se c'è contenuto scrollabile e non siamo su mobile/tablet */}
            {scrollPosition < topBottomItemWidth - containerWidth && !isTabletOrMobile && (
                <Button
                    type="default"
                    icon={<span>&gt;</span>}
                    onClick={selectNext}
                    className="scroll-arrow right"
                />
            )}
        </div>
    );
}

export default MenuContainer;