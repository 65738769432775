import React, { useState, useEffect } from "react";
import logoAr from "../../assets/images/logo/ARSHADES_AR_LOGO.svg";
import ModalQR from "./ModalQR";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";
import styles from "../../styles/ModelViewer/ButtonAr.module.css"
import { Button } from "antd";
import { message } from "antd";

const ButtonAr = ({ project, urlView, modelViewer }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const mirror = queryParams?.get("mirror") ? true : false;

    const [isWebView, setIsWebView] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [isARActive, setIsARActive] = useState(false);

    const projectData = useSelector((state) => state.brandViewer);

    const currentUrl = urlView;
    const bgColor = projectData[0]?.qrBackGroundColor ?? "#ffffff";
    const fgColor = projectData[0]?.qrForeGroundColor ?? "#000000";

    const activateAR = async () => {
        if (!modelViewer) return;

        try {
            // Controlla se il dispositivo supporta l'AR
            if (modelViewer.canActivateAR) {
                // Per Android (Scene Viewer)
                if (/android/i.test(navigator.userAgent)) {
                    await modelViewer.activateAR({
                        mode: 'scene-viewer'
                    });
                }
                // Per iOS (Quick Look)
                else if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
                    await modelViewer.activateAR({
                        mode: 'quick-look'
                    });
                }
                // Per altri dispositivi WebXR
                else {
                    await modelViewer.activateAR();
                }
            } else {
                console.warn('AR non supportato su questo dispositivo');
                message.warning('AR non supportato su questo dispositivo');
            }
        } catch (error) {
            console.error('Errore nell\'attivazione AR:', error);
            message.error('Errore nell\'attivazione AR');
        }
    };

    function modalHandler() {
        setModalOpen(!modalOpen);
    }

    useEffect(() => {
        const userAgent = navigator.userAgent;
        const isWebView = /\bwv\b/.test(userAgent);
        if (isWebView) {
            setIsWebView(true);
        }
    }, []);

    useEffect(() => {
        if (!modelViewer) return;

        const handleARStart = () => {
            setIsARActive(true);
        };

        const handleAREnd = () => {
            setIsARActive(false);
        };

        // Aggiungi gli event listener
        modelViewer.addEventListener('ar-status', (event) => {
            if (event.detail.status === 'session-started') {
                handleARStart();
            } else if (event.detail.status === 'not-presenting') {
                handleAREnd();
            }
        });

        return () => {
            // Rimuovi gli event listener quando il componente viene smontato
            if (modelViewer) {
                modelViewer.removeEventListener('ar-status', handleARStart);
                modelViewer.removeEventListener('ar-status', handleAREnd);
            }
        };
    }, [modelViewer]);

    const handleClick = () => {
        if (isMobile && !mirror) {
            // Su mobile, attiva AR
            activateAR();
        } else {
            // Su desktop, apri il modale QR
            modalHandler();
        }
    };

    return (
        <>
            {!isWebView && !isARActive && (  // Usa isARActive invece di modelViewer?.isPresenting
                <>
                    {isMobile && !mirror && (
                        <Button
                            className={styles["buttonARBottom"]}
                            icon={
                                <img
                                    src={projectData[0]?.ar_icon || logoAr}
                                    style={{ width: "40px", height: "40px" }}
                                    alt="ar"
                                />
                            }
                            shape="circle"
                            onClick={handleClick}
                        />
                    )}
                    {(!isMobile || mirror) &&
                        !(
                            project?.menu_position === "left" ||
                            project?.menu_position === "right"
                        ) && (
                            <div className={styles["button-right-bottom"]}>
                                <Button
                                    className={styles["button"]}
                                    icon={
                                        <img
                                            src={projectData[0]?.ar_icon || logoAr}
                                            style={{ width: "40px", height: "40px" }}
                                            alt="ar"
                                        />
                                    }
                                    shape="circle"
                                    onClick={handleClick}
                                />
                                {modalOpen && (
                                    <ModalQR
                                        fgColor={fgColor}
                                        bgColor={bgColor}
                                        project={project}
                                        url={currentUrl}
                                        modalHandler={modalHandler}
                                        modalOpen={modalOpen}
                                    />
                                )}
                            </div>
                        )}
                </>
            )}

            {isWebView && !isARActive && (
                <Button
                    className={styles["buttonARBottomWebView"]}
                    shape="round"
                    onClick={activateAR}
                >
                    View in AR
                </Button>
            )}
        </>
    );
};

export default ButtonAr;