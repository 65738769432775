import React from "react";
import { Table, Spin, Tag } from "antd";
import dayjs from "dayjs";

const AssociatedBrands = ({ loading, subscriptions, percentageData }) => {
    // Funzione che determina se la subscription è scaduta
    const isExpired = (endDate) => {
        return endDate && endDate < new Date();
    };

    // Funzione che restituisce i giorni mancanti
    const getDaysLeft = (endDate) => {
        if (!endDate) return null;
        const diff = dayjs(endDate).diff(dayjs(), "day");
        return diff > 0 ? diff : null;
    };

    const columns = [
        {
            title: "Subcription Type",
            dataIndex: "subscriptionType",
            key: "subscriptionType",
        },
        {
            title: "Start Date",
            dataIndex: "startDate",
            key: "startDate",
            render: (date) => (date ? date.toLocaleDateString() : "N/A"),
        },
        {
            title: "End Date",
            dataIndex: "endDate",
            key: "endDate",
            render: (date) => (date ? date.toLocaleDateString() : "N/A"),
        },
        {
            title: "Days Left",
            key: "daysLeft",
            render: (text, record) => {
                const daysLeft = getDaysLeft(record.endDate);
                return daysLeft !== null ? `${daysLeft} days left` : "Expired";
            },
        },
        {
            title: "Name",
            dataIndex: "store",
            key: "store",
        },
        {
            title: "Services",
            dataIndex: "service_list",
            key: "service_list",
            render: (services) =>
                services && services.length > 0
                    ? services.map((service, index) => (
                        <Tag key={index} color="blue">
                            {service}
                        </Tag>
                    ))
                    : "N/A",
        },
        {
            title: "% Consumed",
            key: "percentageConsumed",
            render: (text, record, index) => {
                const percentageItem = percentageData?.[index];

                const bandwidthDetails = percentageItem?.bandwidthDetails;
                const totalBandwidth = bandwidthDetails?.totalBandWidth
                    ? parseFloat(bandwidthDetails.totalBandWidth)
                    : 0;

                const consumptionLimit = percentageItem?.consumptionLimit || 1;

                const usedPercentage = ((totalBandwidth / consumptionLimit) * 100).toFixed(2);

                const color = usedPercentage > 100 && "red";

                return (
                    <span style={{ color: color }}>
                        {`${usedPercentage}%`}
                    </span>
                );
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (status, record) => (
                <Tag color={isExpired(record.endDate) ? "red" : "green"}>
                    {isExpired(record.endDate) ? "Expired" : "Active"}
                </Tag>
            ),
        },
    ];

    return (
        <>
            {loading ? (
                <div style={{ marginBottom: "40px", display: "flex", justifyContent: "center", alignItems: "center", minHeight: "200px" }}>
                    <Spin size="large" />
                </div>
            ) : (
                <Table
                    size="small"
                    dataSource={subscriptions}
                    columns={columns}
                    pagination={false}
                />
            )}
        </>
    );
};

export default AssociatedBrands;
