import { Button, Card, Col, message, Row, Space, Spin, Table, theme } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAnalyticsFilterOptions } from "../../redux/analytics/actions";
import { getDurationFromSecond } from "../../services/utils/analyticsUtils";
import ColorIndicator from "./ColorIndicator";
import SummaryCard from "./SummaryCard";
import SummaryGrid from "./SummaryGrid";

function AnalyticsTable({ aggregateList = [], loading, setTableOverview, onResetFilters, loadingSelect }) {
    const { token } = theme.useToken();
    const [lineList, setLineList] = useState([]);
    const [glassList, setGlassList] = useState([]);
    const [modelList, setModelList] = useState([]);
    const [selectedTab, setSelectedTab] = useState("Lines");

    const lineListRef = useRef([]);
    const glassListRef = useRef([]);
    const modelListRef = useRef([]);

    const dispatch = useDispatch();

    const lineDocList = useSelector((state) => state.analytics.lineList);
    const glassDocList = useSelector((state) => state.analytics.glassList);
    const modelDocList = useSelector((state) => state.analytics.modelList);
    const filterOptions = useSelector((state) => state.analytics.filterOptions);
    const selectedCatalog = useSelector((state) => state.catalog.selectedCatalog);

    const { line, glass, variant } = filterOptions;
    const [inloading, setLoader] = useState(loading);

    const filterOptionMap = {
        Lines: "line",
        Glasses: "glass",
        Variants: "variant",
    };

    const tabMap = useMemo(() => {
        return {
            Lines: lineList,
            Glasses: glassList,
            Variants: modelList,
        };
    }, [lineList, glassList, modelList]);

    const tooltipTextMap = {
        Lines: "The sum of time that the users spent trying-on a line",
        Glasses: "The sum of time that the users spent trying-on a glass",
        Variants: "The sum of time that the users spent trying-on a variant",
    };

    const [list, totalRow] = useMemo(() => {
        const tempList = tabMap[selectedTab];
        const totalRow = tempList.find(
            (row) => row?.name?.toLowerCase?.() === "total"
        );
        const restList = tempList.filter(
            (row) => row?.name?.toLowerCase?.() !== "total"
        );
        return [restList, totalRow];
    }, [tabMap, selectedTab]);

    const handleDataIndexChange = (key) => {
        if (inloading) {
            message.error("Data is loading, Please wait...");
            return;
        }
        setSelectedTab(key);
    };

    const getSessionAndDuration = (data, key, time, sessionCount, visualization, glassId, lineRef) => {
        if (data['total']) {
            data['total'].sessions += sessionCount || 1;
            data['total'].duration += time;
            data['total'].visualization += visualization;
        } else {
            data['total'] = {
                sessions: sessionCount || 1,
                duration: time,
                visualization,
            };
        }

        if (data[key]) {
            data[key].sessions += sessionCount || 1;
            data[key].duration += time;
            data[key].visualization += visualization;
        } else {
            data[key] = {
                sessions: sessionCount || 1,
                duration: time,
                visualization,
            };
        }
        if (glassId) {
            data[key].glassId = glassId;
        }
        if (lineRef) {
            data[key].lineaRef = lineRef;
        }

        return data;
    };

    const createArrayFromObject = (doc, nameField, parentList = [], setterCallback, ref) => {
        const parentMap = new Map();
        const list = [];

        const total = {
            ...doc?.total,
            name: "Total",
            visualization: doc?.total?.visualization || 0,
        };

        list.push(total);
        delete doc.total;

        parentList.forEach((item) => {
            parentMap.set(item.id, item);
        });

        Object.entries(doc).forEach(([id, value]) => {
            const docDetails = parentMap.get(id);
            if (docDetails) {
                let name = docDetails[nameField];

                if (nameField === "nomeOcchiale") {
                    name = (
                        <Space size="small" align="center">
                            <span>{docDetails.nomeOcchiale}</span>
                            <ColorIndicator
                                primary={docDetails.esaColorFramePrimary}
                                secondary={docDetails.esaColorFrameSecondary}
                                type="square"
                                size="small"
                            />
                            <ColorIndicator
                                primary={docDetails.esaColorLensesPrimary}
                                secondary={docDetails.esaColorLensesSecondary}
                                size="small"
                            />
                        </Space>
                    );
                }

                const itemData = {
                    ...value,
                    ...docDetails,
                    id,
                    name,
                    visualization: value.visualization || 0,
                };

                list.push(itemData);
            }
        });

        ref.current = list;
        setterCallback(list);
    };

    const handleSelectionChange = (optionName, value) => () => {
        if (inloading) return message.error("Processing data!");

        const updatedFilterOptions = { ...filterOptions };

        if (optionName === 'line') {
            updatedFilterOptions.line = value || '';
            updatedFilterOptions.glass = '';
            updatedFilterOptions.variant = '';
        } else if (optionName === 'glass') {
            const glass = glassDocList.find((item) => item.id === value);
            const line = lineDocList.find((item) => item.id === glass?.lineaRef);

            updatedFilterOptions.glass = glass?.id || '';
            updatedFilterOptions.line = line?.id || updatedFilterOptions.line;
            updatedFilterOptions.variant = '';
        }
        else if (optionName === 'variant') {
            const model = modelList.find((item) => item.id === value);
            const glass = glassDocList.find((item) => item.id === model?.glassId);
            const line = lineDocList.find((item) => item.id === glass?.lineaRef);

            updatedFilterOptions.variant = model?.id || '';
            updatedFilterOptions.glass = glass?.id || updatedFilterOptions.glass;
            updatedFilterOptions.line = line?.id || updatedFilterOptions.line;
        }

        dispatch(setAnalyticsFilterOptions(updatedFilterOptions));
    };

    useEffect(() => {
        let lineData = {};
        let glassData = {};
        let modelData = {};
        let totalLineTime = 0;
        let totalLineSession = 0;

        setLoader(true);

        if (aggregateList.length > 0) {
            // Processa i dati aggregati e costruisce i dataset
            aggregateList.forEach(({ lineArray, glassesArray, variantsArray }) => {
                lineArray.forEach((item) => {
                    totalLineTime += item.time ?? 0;
                    totalLineSession += item.numberOfSessions || 0;

                    lineData = getSessionAndDuration(
                        lineData,
                        item.lineRef,
                        item.time,
                        item.numberOfSessions,
                        item.visualization
                    );
                });

                if (glassesArray) {
                    glassesArray.forEach((item) => {
                        glassData = getSessionAndDuration(
                            glassData,
                            item.glassesRef,
                            item.time,
                            item.numberOfSessions,
                            item.visualization
                        );
                    });
                }

                if (variantsArray) {
                    variantsArray.forEach((item) => {
                        modelData = getSessionAndDuration(
                            modelData,
                            item.modelRef,
                            item.time,
                            item.numberOfSessions,
                            item.visualization,
                            item.glassesRef,
                            item.lineRef
                        );
                    });
                }
            });

            // Converte gli oggetti aggregati in array e aggiorna lo stato
            createArrayFromObject(
                lineData,
                "nome_linea",
                lineDocList,
                setLineList,
                lineListRef
            );

            createArrayFromObject(
                glassData,
                "nome_modello",
                glassDocList,
                setGlassList,
                glassListRef
            );

            createArrayFromObject(
                modelData,
                "nomeOcchiale",
                modelDocList,
                setModelList,
                modelListRef
            );
        } else {
            setLineList([]);
            setGlassList([]);
            setModelList([]);
            lineListRef.current = [];
            glassListRef.current = [];
            modelListRef.current = [];
        }

        setLoader(false);
    }, [glassDocList, lineDocList, modelDocList, aggregateList]);

    useEffect(() => {
        if (!!line && !!!glass && !!!variant) {
            filterLine();
        }

        if (!!glass && !!line && !!!variant) {
            filterGlass();
        }

        if (!!variant && !!line && !!glass) {
            filterVariant();
        }
    }, [glass, line, variant, aggregateList]);

    const filterLine = () => {
        const total = { name: 'Total', sessions: 0, duration: 0 };

        const filteredLineList = lineListRef.current.filter((item) => {
            if (item.id === line) {
                total.sessions += !isNaN(Number(item.sessions)) ? Number(item.sessions) : 0;
                total.duration += !isNaN(Number(item.duration)) ? Number(item.duration) : 0;
            }
            return item.id === line;
        });

        setLineList([total, ...filteredLineList]);

        const gtotal = { name: 'Total', sessions: 0, duration: 0 };
        const lineDoc = filteredLineList[0];
        const lineGlassList = new Set(
            (lineDoc?.listaRefsOcchiale || []).map((item) => {
                return String(item).trim();
            })
        );

        const filteredGlassList = glassListRef.current.filter((item) => {
            const itemId = String(item.id);
            if (lineGlassList.has(itemId)) {
                gtotal.sessions += !isNaN(Number(item.sessions)) ? Number(item.sessions) : 0;
                gtotal.duration += !isNaN(Number(item.duration)) ? Number(item.duration) : 0;
            }
            return lineGlassList.has(itemId);
        });

        setGlassList([gtotal, ...filteredGlassList]);

        const glassSet = new Set(filteredGlassList.map((item) => item.id));
        const mtotal = { name: 'Total', sessions: 0, duration: 0 };

        const filteredModelList = modelListRef.current.filter((item) => {
            const isGlass = glassSet.has(item.glassId);
            if (isGlass) {
                mtotal.sessions += !isNaN(Number(item.sessions)) ? Number(item.sessions) : 0;
                mtotal.duration += !isNaN(Number(item.duration)) ? Number(item.duration) : 0;
            }
            return isGlass;
        });

        setModelList([mtotal, ...filteredModelList]);
    };

    const filterGlass = () => {
        const total = { name: 'Total', sessions: 0, duration: 0 };
        const filteredGlassList = glassListRef.current.filter((item) => {
            return String(item.id) === String(glass);
        });

        if (filteredGlassList.length === 0) {
            console.error("No glasses found matching the filter.");
            return;
        }

        filteredGlassList.forEach((item) => {
            total.sessions += Number(item.sessions) || 0;
            total.duration += Number(item.duration) || 0;
        });

        setGlassList([total, ...filteredGlassList]);

        const glassSet = new Set(filteredGlassList.map((item) => String(item.id)));

        const glassDoc = filteredGlassList[0];

        if (!glassDoc) {
            console.error("glassDoc is undefined.");
            return;
        }

        const mtotal = { name: 'Total', sessions: 0, duration: 0 };

        const filteredModelList = modelListRef.current.filter((item) => {
            const isGlass = glassSet.has(String(item.glassId));
            if (isGlass) {
                mtotal.sessions += !isNaN(Number(item.sessions)) ? Number(item.sessions) : 0;
                mtotal.duration += !isNaN(Number(item.duration)) ? Number(item.duration) : 0;
            }
            return isGlass;
        });

        setModelList([mtotal, ...filteredModelList]);

        const ltotal = { name: 'Total', sessions: 0, duration: 0 };
        const filteredLineList = lineListRef.current.filter((item) => {
            if (!filteredModelList[0]) return false;
            if (item.id === filteredModelList[0].lineaRef) {
                ltotal.sessions += !isNaN(Number(item.sessions)) ? Number(item.sessions) : 0;
                ltotal.duration += !isNaN(Number(item.duration)) ? Number(item.duration) : 0;
            }
            return item.id === filteredModelList[0].lineaRef;
        });

        setLineList([ltotal, ...filteredLineList]);
    };

    const filterVariant = () => {
        const total = { name: 'Total', sessions: 0, duration: 0 };
        // Filtra e aggrega i dati del modello
        const filteredModelList = modelListRef.current.filter((item) => {
            if (item.id === variant) {
                total.sessions += !isNaN(Number(item.sessions)) ? Number(item.sessions) : 0;
                total.duration += !isNaN(Number(item.duration)) ? Number(item.duration) : 0;
            }
            return item.id === variant;
        });

        setModelList([total, ...filteredModelList]);

        // Filtra e aggrega i dati degli occhiali
        const gtotal = { name: 'Total', sessions: 0, duration: 0 };
        const filteredGlassList = glassListRef.current.filter((item) => {
            if (!filteredModelList[0]) return false;
            if (item.id === filteredModelList[0].glassId) {
                gtotal.sessions += !isNaN(Number(item.sessions)) ? Number(item.sessions) : 0;
                gtotal.duration += !isNaN(Number(item.duration)) ? Number(item.duration) : 0;
            }
            return item.id === filteredModelList[0].glassId;
        });

        setGlassList([gtotal, ...filteredGlassList]);

        // Filtra e aggrega i dati delle linee
        const ltotal = { name: 'Total', sessions: 0, duration: 0 };
        const filteredLineList = lineListRef.current.filter((item) => {
            if (!filteredModelList[0]) return false;
            if (item.id === filteredModelList[0].lineaRef) {
                ltotal.sessions += !isNaN(Number(item.sessions)) ? Number(item.sessions) : 0;
                ltotal.duration += !isNaN(Number(item.duration)) ? Number(item.duration) : 0;
            }
            return item.id === filteredModelList[0].lineaRef;
        });

        setLineList([ltotal, ...filteredLineList]);
    };

    const columns = [
        {
            title: selectedTab,
            dataIndex: "name",
            key: "name",
            width: "50%",
            render: (text, row) => (
                <span
                    style={{ cursor: "pointer" }}
                    onClick={handleSelectionChange(filterOptionMap[selectedTab], row.id)}
                >
                    {text}
                </span>
            ),
        },
        {
            title: "Visualizations",
            dataIndex: "visualization",
            key: "visualization",
            width: "25%",
            sorter: (a, b) => a.visualization - b.visualization,
        },
        {
            title: "Time",
            dataIndex: "duration",
            key: "duration",
            width: "25%",
            render: (text) => getDurationFromSecond(text),
            sorter: (a, b) => a.duration - b.duration,
        },
    ];

    useEffect(() => {
        setTableOverview({
            lines: tabMap["Lines"]?.length ?? 0,
            glasses: tabMap["Glasses"]?.length ?? 0,
            variants: tabMap["Variants"]?.length ?? 0,
            linesList: tabMap['Lines'],
            glassesList: tabMap['Glasses'],
            variantsList: tabMap['Variants'],
        });
    }, [loading]);

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });

    const handleTableChange = (paginationInfo) => {
        setPagination({
            ...pagination,
            current: paginationInfo.current,
            pageSize: paginationInfo.pageSize,
        });
    };

    return (
        <>
            <SummaryGrid>
                {Object.entries(tabMap).map(([key, list]) => {
                    let value = 0;
                    list.forEach((item) => {
                        if (item.name?.toLowerCase?.() !== "total" && item.sessions) {
                            value += 1;
                        }
                    });
                    return (
                        <div key={key}>
                            <SummaryCard
                                label={key}
                                value={value}
                                loading={loading}
                                active={key === selectedTab}
                                onClick={() => handleDataIndexChange(key)} // Non serve la factory function
                                toolTipText={tooltipTextMap[key]}
                            />
                        </div>
                    );
                })}
            </SummaryGrid>

            <Card
                title={
                    <Row justify="space-between" align="middle" style={{ width: "100%" }}>
                        <Col>
                            <span>{selectedTab}</span>
                        </Col>
                        <Col>
                            {(filterOptions.line !== "" ||
                                filterOptions.variant !== "" ||
                                filterOptions.glass !== "" ||
                                selectedCatalog?.id !== "all") && (
                                    <Button type="default" onClick={onResetFilters}>
                                        Clear filters
                                    </Button>
                                )}
                        </Col>
                    </Row>
                }
            >
                {totalRow && (
                    <div
                        style={{
                            cursor: "pointer",
                            fontSize: "1rem",
                            marginBottom: "1rem",
                            color: token.colorPrimary,
                        }}
                        onClick={handleSelectionChange(filterOptionMap[selectedTab], totalRow.id)}
                    >
                        <span style={{ color: 'black', fontWeight: 'bold' }}>
                            {`${totalRow?.name} - ${totalRow?.sessions ?? totalRow?.visualization ?? ''} - ${getDurationFromSecond(totalRow.duration || totalRow.time)}`}
                        </span>
                    </div>
                )}

                {loading || loadingSelect ? (
                    <div style={{ textAlign: "center", padding: "2rem" }}>
                        <Spin size="large" />
                    </div>
                ) : (
                    <Table
                        columns={columns}
                        size="small"
                        dataSource={list.sort((a, b) => b.visualization - a.visualization)}
                        rowKey="id"
                        pagination={{
                            current: pagination.current,
                            pageSize: pagination.pageSize,
                            showSizeChanger: true,
                            pageSizeOptions: [10, 20, 50, 100, 200, 500],
                        }}
                        onChange={handleTableChange}
                        scroll={{
                            x: 1000,
                        }}
                        style={{
                            width: "100%",
                        }}
                    />
                )}
            </Card>
        </>
    );
};

export default AnalyticsTable;