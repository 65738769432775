import React, { useState } from 'react';
import { Button, Row, Col, DatePicker, message } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { db } from '../../data/base';
import dayjs from 'dayjs';
import { aggregateDataForAllBrands } from './Helper';

// Default date range (last month)
export const getDefaultDateRange = () => {
    return {
        startDate: dayjs().subtract(1, 'month').startOf('month'),
        endDate: dayjs().subtract(1, 'month').endOf('month')
    };
};

// Format date for display
export const formatDateForDisplay = (date) => {
    if (!date) return '';
    return dayjs(date).format('DD MMM, YYYY');
};

const { RangePicker } = DatePicker;

const SessionAggregator = () => {
    const [loading, setLoading] = useState(false);
    const [dateRange, setDateRange] = useState([dayjs(), dayjs()]);
    const [totalOperations, setTotalOperations] = useState(0);
    const [completedOperations, setCompletedOperations] = useState(0);
    const [loadingMessage, setLoadingMessage] = useState('');

    // Predefined ranges for the date picker
    const rangePresets = [
        {
            label: 'Today',
            value: [dayjs().startOf('day'), dayjs().endOf('day')],
        },
        {
            label: 'Yesterday',
            value: [
                dayjs().subtract(1, 'day').startOf('day'),
                dayjs().subtract(1, 'day').endOf('day'),
            ],
        },
        {
            label: 'This Week',
            value: [dayjs().startOf('week'), dayjs().endOf('day')],
        },
        {
            label: 'Last Week',
            value: [
                dayjs().subtract(1, 'week').startOf('week'),
                dayjs().subtract(1, 'week').endOf('week'),
            ],
        },
        {
            label: 'This Month',
            value: [dayjs().startOf('month'), dayjs().endOf('day')],
        },
        {
            label: 'Last Month',
            value: [
                dayjs().subtract(1, 'month').startOf('month'),
                dayjs().subtract(1, 'month').endOf('month'),
            ],
        },
    ];

    const incrementCompletedOperations = () => {
        setCompletedOperations(prevCompleted => prevCompleted + 1);
    };

    const handleDateRangeChange = (dates) => {
        setDateRange(dates);
    };

    const handleAggregation = async () => {
        try {
            setLoading(true);
            setCompletedOperations(0);

            await aggregateDataForAllBrands(
                dateRange[0],
                dateRange[1],
                setLoadingMessage,
                () => { }, // empty function for skippedDays
                incrementCompletedOperations,
                {},
                setTotalOperations,
                db
            );

            message.success('Data aggregation completed successfully');
        } catch (error) {
            console.error('Error in aggregation:', error);
            message.error('Error during data aggregation');
        } finally {
            setLoading(false);
            setCompletedOperations(0);
            setTotalOperations(0);
        }
    };

    const buttonProgress = totalOperations > 0
        ? Math.round((completedOperations / totalOperations) * 100)
        : 0;

    return (
        <Row gutter={16} style={{ padding: 10 }}>
            <Col span={8}><strong>Run Aggregate Data for Session</strong></Col>
            <Col span={8}>
                <RangePicker
                    style={{ width: '100%' }}
                    value={dateRange}
                    onChange={handleDateRangeChange}
                    format="DD MMM, YYYY"
                    presets={rangePresets}
                />
            </Col>
            <Col span={8}>
                <Button
                    onClick={handleAggregation}
                    disabled={loading}
                    type="primary"
                >
                    {loading ? (
                        <>
                            <SyncOutlined spin style={{ marginRight: 8 }} />
                            Executing... {buttonProgress}%
                        </>
                    ) : (
                        'Execute'
                    )}
                </Button>
            </Col>
        </Row>
    );
};

export default SessionAggregator;