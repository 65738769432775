import { collection, getDocs, doc, getDoc, query, where } from "firebase/firestore";
import { statusMapping, genderMapping } from "../utils/ar3dViewer";
import { db } from "../../data/base";

export const getBrands = async (setLoadingCatalogs, rxBrands, setCatalogsList, messageApi) => {
    try {
        setLoadingCatalogs(true); // Inizio caricamento

        // Rimuove eventuali duplicati negli ID
        const uniqueRxBrands = [...new Set(rxBrands)];

        // Recupera i dati dei brand in parallelo
        const result = await Promise.all(
            uniqueRxBrands.map(async (brandId) => {
                const brandDocRef = doc(collection(db, "Brand"), brandId);
                const brandData = await getDoc(brandDocRef);
                if (brandData.exists()) {
                    return { id: brandData.id, ...brandData.data() };
                }
                return null;
            })
        );

        // Filtra i brand validi e aggiunge l'opzione "Tutti i brand"
        const filteredBrands = result.filter((brand) => brand !== null);
        setCatalogsList([{ id: "all", nome_brand: "All Catalogues" }, ...filteredBrands]);
    } catch (error) {
        messageApi.error("Something went wrong while fetching brands");
        console.error("Error fetching brands:", error);
    } finally {
        setLoadingCatalogs(false); // Fine caricamento
    }
};
export const getStates = async (setLoadingStates, setStatesList, messageApi) => {
    try {
        setLoadingStates(true);
        const modelsRef = collection(db, "Modello");
        const modelsSnapshot = await getDocs(modelsRef);

        const statiSet = new Set();
        modelsSnapshot.forEach((doc) => {
            const data = doc.data();
            if (data.stato) {
                statiSet.add(data.stato);
            }
        });

        const statiArray = Array.from(statiSet).map((s) => ({
            id: s,
            name: statusMapping[s] || s
        }));

        setStatesList([{ id: "all", name: "All Statuses" }, ...statiArray]);
    } catch (error) {
        console.error("Error fetching states:", error);
        messageApi.error("Something went wrong while fetching statuses");
    } finally {
        setLoadingStates(false);
    }
};

export const getTagClasses = async (setLoadingTags, availableTagIDs, setGenders, setModelTypes, setFrameTypes, setLensTreatments, messageApi) => {
    try {
        setLoadingTags(true);
        const tagClassRef = collection(db, "TagClass");

        if (availableTagIDs.size === 0) {
            setLoadingTags(false);
            return;
        }

        // Dividiamo in batch per evitare il limite Firestore di 10 elementi in "in"
        const batchSize = 10;
        const tagIDArray = Array.from(availableTagIDs);
        const tagBatches = [];

        for (let i = 0; i < tagIDArray.length; i += batchSize) {
            tagBatches.push(tagIDArray.slice(i, i + batchSize));
        }

        let fetchedTags = [];

        await Promise.all(
            tagBatches.map(async (batch) => {
                const tagQuery = query(tagClassRef, where("__name__", "in", batch));
                const tagSnapshot = await getDocs(tagQuery);

                tagSnapshot.forEach((doc) => {
                    fetchedTags.push({ id: doc.id, ...doc.data() });
                });
            })
        );

        const genderArray = [];
        const modelTypeArray = [];
        const frameTypeArray = [];
        const lensTreatmentArray = [];

        fetchedTags.forEach(tag => {
            if (tag.type?.toLowerCase() === "categoria") {
                const translatedName = genderMapping[tag.name] || tag.name; // Traduzione del nome
                genderArray.push({ ...tag, name: translatedName });
            }
            if (tag.group?.toLowerCase() === "tipologia") {
                modelTypeArray.push(tag);
            }
            if (tag.group?.toLowerCase() === "forma") {
                frameTypeArray.push(tag);
            }
            if (tag.group?.toLowerCase() === "treatment" || tag.group?.toLowerCase() === "treatement") {
                lensTreatmentArray.push(tag);
            }
        });

        setGenders(genderArray);
        setModelTypes(modelTypeArray);
        setFrameTypes(frameTypeArray);
        setLensTreatments(lensTreatmentArray);
    } catch (error) {
        console.error("Error fetching TagClass data:", error);
        messageApi.error("Something went wrong while fetching tag classes");
    } finally {
        setLoadingTags(false);
    }
};

export const updateGlassCategories = async (model, setGlassIDToCategories, setGenders, setModelTypes, setFrameTypes, setLensTreatments) => {
    if (model.length === 0) {
        setGlassIDToCategories({});
        return;
    }

    const glassIDs = model.map(item => item.glassID);
    const uniqueGlassIDs = [...new Set(glassIDs)];
    const batchSize = 10;
    const batches = [];
    for (let i = 0; i < uniqueGlassIDs.length; i += batchSize) {
        batches.push(uniqueGlassIDs.slice(i, i + batchSize));
    }

    const mapping = {};
    const occhialeRef = collection(db, "Occhiale");

    let tagClassIDs = new Set();

    await Promise.all(
        batches.map(async (batch) => {
            const q = query(occhialeRef, where("__name__", "in", batch));
            const snapshot = await getDocs(q);
            snapshot.docs.forEach(doc => {
                const data = doc.data();
                if (!data.lista_categorie || !Array.isArray(data.lista_categorie)) {
                    return;
                }
                const tagIDs = data.lista_categorie.map(ref => ref.id);
                tagIDs.forEach(id => tagClassIDs.add(id));
                mapping[doc.id] = tagIDs;
            });
        })
    );

    // Recupera e setta eventuali dettagli dai TagClass, se necessario
    // (questo blocco può rimanere invariato oppure essere spostato in un useEffect separato)
    if (tagClassIDs.size > 0) {
        const tagClassRef = collection(db, "TagClass");
        const tagBatches = [];
        const tagArray = Array.from(tagClassIDs);
        for (let i = 0; i < tagArray.length; i += batchSize) {
            tagBatches.push(tagArray.slice(i, i + batchSize));
        }
        let fetchedTags = [];
        await Promise.all(
            tagBatches.map(async (tagBatch) => {
                const tagQuery = query(tagClassRef, where("__name__", "in", tagBatch));
                const tagSnapshot = await getDocs(tagQuery);
                tagSnapshot.forEach(tagDoc => {
                    fetchedTags.push({ id: tagDoc.id, ...tagDoc.data() });
                });
            })
        );

        const genderArray = [];
        const modelTypeArray = [];
        const frameTypeArray = [];
        const lensTreatmentArray = [];

        fetchedTags.forEach(tag => {
            if (tag.type === "categoria") {
                genderArray.push(tag);
            }
            if (tag.group === "Tipologia") {
                modelTypeArray.push(tag);
            }
            if (tag.group === "Forma") {
                frameTypeArray.push(tag);
            }
            if (tag.group === "Treatement") {
                lensTreatmentArray.push(tag);
            }
        });

        setGenders(genderArray);
        setModelTypes(modelTypeArray);
        setFrameTypes(frameTypeArray);
        setLensTreatments(lensTreatmentArray);
    }

    setGlassIDToCategories(mapping);
}

export const fetchModelVariants = async (selectedModel, setLoading, setModelsBrandSelected, messageApi) => {
    if (!selectedModel) return;

    setLoading(true);

    try {
        const models = [];
        const incompleteModels = [];
        const modelsRef = collection(db, "Modello");
        const modelsSnapshot = await getDocs(modelsRef);

        modelsSnapshot.forEach((doc) => {
            const data = doc.data();

            if (data?.loadingId) {
                const glassesIDCode = data.loadingId.substring(0, 5);
                const selGlassesCode = selectedModel.loadingId?.substring(0, 5);

                if (glassesIDCode === selGlassesCode) {
                    const variantData = {
                        ...data,
                        brandId: selectedModel.brandId,
                        glassID: selectedModel.glassID,
                        id: doc.id,
                        mainBrandId: selectedModel.mainBrandId,
                    };

                    // Separa i modelli incompleti da quelli validi
                    if (data.stato?.toLowerCase() === "incompleto") {
                        incompleteModels.push(variantData);
                    } else {
                        models.push(variantData);
                    }
                }
            }
        });

        setModelsBrandSelected(models);
    } catch (error) {
        console.error("Error fetching model variants:", error);
        messageApi.error("Error fetching model details. Please try again later.");
    } finally {
        setLoading(false);
    }
};

export const fetchGlassesModels = async (glasses, setLoading, setModel, messageApi) => {
    try {
        setLoading(true);

        const modelsRef = collection(db, "Modello");
        const modelsSnapshot = await getDocs(modelsRef);

        const models = [];
        modelsSnapshot.forEach((doc) => {
            const data = doc.data();
            const foundGlass = glasses.find((g) => g.initial_model === doc.id);

            if (foundGlass) {
                models.push({
                    ...data,
                    brandId: foundGlass.brandId,
                    glassID: foundGlass.glassID,
                    variantId: doc.id,
                    mainBrandId: foundGlass.mainBrandId,
                });
            }
        });

        setModel(models);
    } catch (error) {
        console.error("Errore durante il caricamento dei modelli:", error);
        messageApi.error("Error fetching models. Please try again later.");
    } finally {
        setLoading(false);
    }
};

export const fetchGlassesByBrand = async (selectedCatalogs, rxBrands, setInitialLoading, setGlasses, messageApi) => {
    const listGlasses = [];
    setInitialLoading(true);

    try {
        const visualizzatoreRef = collection(db, "Visualizzatori3D");
        const visualizzatoreSnapshot = await getDocs(visualizzatoreRef);

        // Se non è stato selezionato nessun filtro o viene scelto "all",
        // utilizziamo i brand associati (rxBrands) per filtrare i dati
        let brandsFilter = [];
        if (selectedCatalogs.length === 0 || selectedCatalogs.includes("all")) {
            brandsFilter = rxBrands;
        } else {
            brandsFilter = selectedCatalogs;
        }

        await Promise.all(
            visualizzatoreSnapshot.docs.map(async (doc) => {
                const data = doc.data();

                // Filtra i documenti in base al brand
                if (brandsFilter.includes(data.brand)) {
                    const glassesRef = collection(db, "Visualizzatori3D", doc.id, "Glasses");
                    const glassesSnapshot = await getDocs(glassesRef);

                    glassesSnapshot.forEach((g) => {
                        listGlasses.push({
                            ...g.data(),
                            brandId: doc.id,
                            glassID: g.id,
                            mainBrandId: data.brand,
                        });
                    });
                }
            })
        );

        setGlasses(listGlasses);
    } catch (error) {
        console.error("Errore durante il caricamento dei dati:", error);
        messageApi.error("Errore durante il caricamento dei dati.");
    } finally {
        setInitialLoading(false);
    }
};