import React from "react";
import { Card, theme } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import ArrowOutwardOutlinedIcon from "@mui/icons-material/ArrowOutwardOutlined";
import CustomTooltip from "../common/CustomTooltip";

const StatCard = ({ title, value, onClick, showIcon = true, tooltipTitle }) => {

    const { token } = theme.useToken();

    return (
        <Card
            style={{
                width: 300,
                textAlign: "center",
                padding: "16px",
                borderRadius: "8px",
                position: "relative",
                cursor: showIcon ? "pointer" : "default",
            }}
            onClick={showIcon ? onClick : undefined}
        >
            {/* Icona in alto a destra con Tooltip dinamico */}
            <CustomTooltip title={tooltipTitle}>
                <InfoCircleOutlined
                    style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        fontSize: "18px",
                        color: token.colorPrimary,
                    }}
                />
            </CustomTooltip>

            {/* Numero grande e centrale */}
            <div
                style={{
                    fontSize: "28px",
                    fontWeight: "bold",
                    color: token.colorTextBlack,
                }}
            >
                {value}
            </div>

            {/* Titolo piccolo sotto */}
            <div
                style={{
                    fontSize: "14px",
                    color: token.colorTextBlack,
                }}
            >
                {title}
            </div>

            {/* Icona della freccia in basso a destra */}
            {showIcon && (
                <ArrowOutwardOutlinedIcon
                    sx={{
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                        fontSize: "20px",
                        color: token.colorPrimary,
                        transform: "rotate(90deg)",
                    }}
                />
            )}
        </Card>
    );
};

export default StatCard;