import React from 'react';
import { Table, Spin, Card, Row, Col, Button } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import CustomTooltip from "../common/CustomTooltip";

const PagesAnalytics = ({ data, loading, clearFiltersVisible, onResetFilters }) => {

    const processAnalyticsData = (dataCollection) => {
        if (!dataCollection || dataCollection.length === 0) return [];

        const combinedAnalytics = dataCollection.flatMap((item) => item.page_analitycs_list || []);

        const totalVisualisations = combinedAnalytics.length;

        const pageMap = combinedAnalytics.reduce((acc, page) => {
            let { page_name, visualizazion_time = 0 } = page;

            if (page_name.startsWith('VTO -')) {
                page_name = 'VTO';
            } else if (page_name.startsWith('SingleGlassesPage -')) {
                page_name = 'SingleGlassesPage';
            }

            if (!acc[page_name]) {
                acc[page_name] = { pageName: page_name, visualizazion_time: 0, visualisations: 0 };
            }
            acc[page_name].visualizazion_time += visualizazion_time;
            acc[page_name].visualisations += 1;

            return acc;
        }, {});

        return Object.values(pageMap)
            .map((page, index) => {
                const { pageName, visualizazion_time, visualisations } = page;
                const avgTimeSpent = Math.floor(visualizazion_time / visualisations);

                return {
                    key: `${pageName}-${index}`,
                    pageName,
                    visualisations,
                    percentVisualisations: totalVisualisations > 0
                        ? ((visualisations / totalVisualisations) * 100).toFixed(2)
                        : 0,
                    totalTimeSpent: formatTime(visualizazion_time),
                    avgTimeSpent: formatTime(avgTimeSpent),
                };
            })
            .sort((a, b) => b.visualisations - a.visualisations); // Ordina per Num of Visualisations in modo decrescente
    };

    const formatTime = (seconds) => {
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${String(hrs).padStart(2, '0')}:${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };

    const tableData = processAnalyticsData(data);

    const columns = [
        {
            title: (
                <span>
                    Page{' '}
                    <CustomTooltip title="The name of the page being analyzed.">
                        <InfoCircleOutlined />
                    </CustomTooltip>
                </span>
            ),
            dataIndex: 'pageName',
            key: 'pageName',
            sorter: (a, b) => a.pageName.localeCompare(b.pageName),
            showSorterTooltip: false,
            width: '25%',
        },
        {
            title: (
                <span>
                    Views{' '}
                    <CustomTooltip title="The total number of visualisations for the page.">
                        <InfoCircleOutlined />
                    </CustomTooltip>
                </span>
            ),
            dataIndex: 'visualisations',
            key: 'visualisations',
            sorter: (a, b) => a.visualisations - b.visualisations,
            defaultSortOrder: 'descend', // Ordinamento predefinito decrescente
            showSorterTooltip: false,
            width: '15%',
        },
        {
            title: (
                <span>
                    %{' '}
                    <CustomTooltip title="The percentage of visualisations relative to the total.">
                        <InfoCircleOutlined />
                    </CustomTooltip>
                </span>
            ),
            dataIndex: 'percentVisualisations',
            key: 'percentVisualisations',
            sorter: (a, b) => a.percentVisualisations - b.percentVisualisations,
            render: (value) => `${value}%`,
            showSorterTooltip: false,
            width: '15%',
        },
        {
            title: (
                <span>
                    Tot Time{' '}
                    <CustomTooltip title="The total time spent on the page in hh:mm:ss format.">
                        <InfoCircleOutlined />
                    </CustomTooltip>
                </span>
            ),
            dataIndex: 'totalTimeSpent',
            key: 'totalTimeSpent',
            sorter: (a, b) => timeInSeconds(a.totalTimeSpent) - timeInSeconds(b.totalTimeSpent),
            showSorterTooltip: false,
            width: '15%',
        },
        {
            title: (
                <span>
                    Avg Time{' '}
                    <CustomTooltip title="The average time spent per visualisation in hh:mm:ss format.">
                        <InfoCircleOutlined />
                    </CustomTooltip>
                </span>
            ),
            dataIndex: 'avgTimeSpent',
            key: 'avgTimeSpent',
            sorter: (a, b) => timeInSeconds(a.avgTimeSpent) - timeInSeconds(b.avgTimeSpent),
            showSorterTooltip: false,
            width: '15%',
        },
    ];

    const timeInSeconds = (timeString) => {
        const [hrs, mins, secs] = timeString.split(':').map(Number);
        return hrs * 3600 + mins * 60 + secs;
    };

    return (
        <div style={{ width: "100%", marginTop: 16 }}>
            <Card
                title={
                    <Row justify="space-between" align="middle" style={{ width: "100%" }}>
                        <Col>
                            <span>Pages Analytics</span>
                        </Col>
                        <Col>
                            {clearFiltersVisible === true && (
                                <Button
                                    type="default"
                                    onClick={onResetFilters}
                                >
                                    Clear filters
                                </Button>
                            )}
                        </Col>
                    </Row>
                }
            >
                <Spin spinning={loading}>
                    <Table
                        dataSource={tableData}
                        rowKey="key"
                        columns={columns}
                        pagination={{
                            defaultPageSize: 10,
                            showSizeChanger: true,
                            pageSizeOptions: ['10', '20', '50', '100', '200', '500', '1000'],
                        }}
                        size="small"
                        style={{ marginTop: '16px' }}
                        scroll={{ x: "max-content" }}
                    />
                </Spin>
            </Card>
        </div>
    );
};

export default PagesAnalytics;