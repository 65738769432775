import { db } from "../../data/base";
import {
    collection,
    getDocs,
    query,
    where,
    doc,
    getDoc,
} from "firebase/firestore";
import dayjs from "dayjs";
import { processBandwidthData } from "../data/dataConsumptionData";
import { calculateTotalBandwidth, getDateRange } from "../utils/dataConsumptionUtils";
import { secureGetItem } from "../../data/utils";

// Funzione di utilità per convertire una data in modo sicuro in un oggetto dayjs
const safeParseDate = (date) => {
    if (!date) return dayjs(null);
    if (date instanceof Date) return dayjs(date);
    if (date.toDate) return dayjs(date.toDate());
    return dayjs(date);
};

export const filterValidConsumption = (snapshot, subscriptions, checkDate, currentDate) => {
    // Se checkDate non è definita o se la data corrente non è prima di checkDate, restituisce un array vuoto
    if (!checkDate || !currentDate.isBefore(checkDate)) return [];
    return snapshot.docs
        .filter((doc) => subscriptions?.consumption_list?.consumptionRef === doc.id)
        .map((doc) => doc.data());
};

export const getSubscriptionData = async ({
    startDate,
    endDate,
    bandwidthType,
    brandList,
    subStartDate,
    subscriptions,
}) => {
    const snapshot = await getDocs(collection(db, "Contatori_Brand"));
    const subDate = safeParseDate(subStartDate);
    const checkDate = safeParseDate(subscriptions?.endDate);
    const currentDate = dayjs();

    // Filtra i dati di consumo validi
    const validConsumption = filterValidConsumption(
        snapshot,
        subscriptions,
        checkDate,
        currentDate
    );

    // Elabora i dati di banda, passando anche checkDate come subEndDate
    const bandwidthData = processBandwidthData(
        validConsumption,
        bandwidthType,
        startDate,
        endDate,
        subDate,
        checkDate
    );

    // Calcola il totale della banda
    const [_, totalBandwidth] = calculateTotalBandwidth(bandwidthData);

    const bandwidthDetails = {
        totalBandWidth: (totalBandwidth / 1024).toFixed(2),
        dateRange: getDateRange(bandwidthData),
    };

    return bandwidthDetails;
};

export const getBandwidthDetailsOnly = async ({
    bandwidthType,
    brandList,
    subStartDate,
    subscriptions,
}) => {
    // Definisce le date fisse: dal primo giorno del mese in corso a "oggi"
    const startDate = dayjs().startOf("month").toDate();
    const endDate = dayjs().endOf("day").toDate();

    const snapshot = await getDocs(collection(db, "Contatori_Brand"));

    // Converte in modo sicuro subStartDate e subscriptions.endDate
    const subDate = safeParseDate(subStartDate);
    const checkDate = safeParseDate(subscriptions?.endDate);
    const currentDate = dayjs();

    const validConsumption = filterValidConsumption(
        snapshot,
        subscriptions,
        checkDate,
        currentDate
    );

    // Passa anche checkDate (la data di fine della subscription) a processBandwidthData
    const bandwidthData = processBandwidthData(
        validConsumption,
        bandwidthType,
        startDate,
        endDate,
        subDate,
        checkDate
    );

    const [, totalBandwidth] = calculateTotalBandwidth(bandwidthData);

    const bandwidthDetails = {
        totalBandWidth: (totalBandwidth / 1024).toFixed(2),
        dateRange: getDateRange(bandwidthData),
    };

    return bandwidthDetails;
};

export const fetchSubscriptions = async (
    setLoading,
    setSubscriptions,
    setProfileData
) => {
    setLoading(true);

    const refClient = secureGetItem("ref_cliente");
    const refEmail = secureGetItem("email");
    const profileCatalogsRef = secureGetItem("brands");

    try {
        const profileDoc = await getDoc(
            doc(db, "Profile", refEmail.toLowerCase())
        );
        const profileData = profileDoc.data();
        setProfileData(profileData);

        const serviceSnapshot = await getDocs(
            collection(db, "Service_ARShades")
        );
        const serviceMap = {};
        serviceSnapshot.forEach((doc) => {
            const data = doc.data();
            serviceMap[doc.id] = data.service;
        });

        let subsSnapshot = null;
        if (profileData?.role === "Admin") {
            subsSnapshot = await getDocs(
                query(
                    collection(db, "ARShades_Subscription"),
                    where("isRefactored", "==", true)
                )
            );
        } else {
            subsSnapshot = await getDocs(
                query(
                    collection(db, "ARShades_Subscription"),
                    where("isRefactored", "==", true),
                    where("refClient", "==", refClient)
                )
            );
        }

        const clientDoc = await getDoc(doc(db, "Client", refClient));
        const clientData = clientDoc.data();

        const subs = [];
        const currentDate = new Date();

        subsSnapshot.forEach((doc) => {
            const data = doc.data();
            const found = clientData?.mainProfileList?.includes(
                refEmail.toLowerCase()
            );

            // Converte in modo sicuro startDate ed endDate (se esistono)
            const startDate = data.startDate?.toDate
                ? data.startDate.toDate()
                : data.startDate || null;
            const endDate = data.endDate?.toDate
                ? data.endDate.toDate()
                : data.endDate || null;

            if (
                data.status === "Active" &&
                (!endDate || endDate >= currentDate) &&
                (found ||
                    (data?.catalogList?.catalogRefList || []).some((catalog) =>
                        profileCatalogsRef.includes(catalog)
                    ))
            ) {
                subs.push({
                    key: doc.id,
                    id: doc.id,
                    subscriptionType: data.subscriptionType || "N/A",
                    startDate: startDate,
                    endDate: endDate,
                    store: data.name || "N/A",
                    status: data.status || "Active",
                    service_list: (data.service_list || []).map(
                        (serviceId) => serviceMap[serviceId] || "Unknown Service"
                    ),
                    bandwidthLimit: data.bandwidthLimit || 10000,
                });
            }
        });

        const orderedSubs = subs.sort((a, b) => {
            const order = [
                "ARShades Branded License",
                "ARShades Additional Branded License",
                "ARShades Smart License",
            ];
            return order.indexOf(a.subscriptionType) - order.indexOf(b.subscriptionType);
        });

        // Per ogni subscription, calcola i dettagli di banda
        for (const sub of orderedSubs) {
            // Passa sub.startDate e sub.endDate (convertiti in Date) tramite getBandwidthDetailsOnly
            const bandwidthDetails = await getBandwidthDetailsOnly({
                subStartDate: sub.startDate,
                subscriptions: sub,
                bandwidthType: "all",
                brandList: profileData?.catalogsList,
            });

            const usedMB = parseFloat(bandwidthDetails.totalBandWidth) || 0;
            let percentage = 0;
            if (sub.bandwidthLimit && sub.bandwidthLimit > 0) {
                percentage = (usedMB / sub.bandwidthLimit) * 100;
            }

            sub.bandwidthDetails = bandwidthDetails;
            sub.percentageConsumed = percentage.toFixed(2);
        }

        setSubscriptions(orderedSubs);
    } catch (error) {
        console.error("Error fetching subscriptions:", error);
    } finally {
        setLoading(false);
    }
};

export const getBrandsByRef = async (rxBrands) => {
    const brands = [];
    let storedBrands = [];
    const storedBrandsStr = secureGetItem("brands");

    if (storedBrandsStr) {
        try {
            // Se secureGetItem ha già deserializzato l'oggetto JSON
            storedBrands = Array.isArray(storedBrandsStr) ? storedBrandsStr : [];
        } catch (error) {
            console.error("Errore nel parsing di 'brands':", error);
            storedBrands = [];
        }
    }

    if (!Array.isArray(storedBrands)) {
        console.error("Errore: storedBrands non è un array valido", storedBrands);
        return brands;
    }

    for (const brand of rxBrands) {
        if (storedBrands.includes(brand)) {
            try {
                const brandDocRef = doc(db, "Brand", brand);
                const brandSnapshot = await getDoc(brandDocRef);
                if (brandSnapshot.exists()) {
                    brands.push({ id: brand, ...brandSnapshot.data() });
                }
            } catch (error) {
                console.error(`Errore durante il recupero del brand con ID ${brand}:`, error);
            }
        }
    }

    return brands;
};