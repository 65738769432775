import hdr1 from '../../assets/hdr/brown_photostudio_04_1k.hdr';
import hdr2 from '../../assets/hdr/hamburg_canal_1k.hdr';
import hdr3 from '../../assets/hdr/studio_small_08_1k.hdr';
import hdr4 from '../../assets/hdr/st_peters_square_night_2k.hdr'
import hdr5 from '../../assets/hdr/christmas_photo_studio_07_2k.hdr'
import hdr6 from '../../assets/hdr/studio_small_08_1k_BROWN_blur.hdr'
import hdr7 from '../../assets/hdr/clarens_midday_1k.hdr'
// Importa gli altri file HDR qui...

const hdrMap = {
  1: { name: "Brown Photostudio", file: hdr1 },
  2: { name: "Hamburg Canal", file: hdr2 },
  3: { name: "Studio Small", file: hdr3 },
  4: { name: "Peter Square", file: hdr4 },
  5: { name: "Spiaggia di Mondello", file: hdr5 },
  6: { name: "Brown Photostudio Blur", file: hdr6 },
  7: { name: "Clarens_midday", file: hdr7 }
  // Aggiungi altre immagini HDR qui...
};

export function getHdr(num) {
  if (typeof num === 'undefined') {
    return 0;
  }
  if (hdrMap.hasOwnProperty(num)) {
    return hdrMap[num].file;
  } else {
    // Controlla se l'URL del file HDR è fornito
    if (typeof num === 'string' && num.indexOf('http') === 0) {
      return num; // Restituisci l'URL direttamente
    } else {
      return 0;
    }
  }
}

export function getHdrName(num) {
  if (hdrMap.hasOwnProperty(num)) {
    return hdrMap[num].name;
  } else {
    return null;
  }
}
