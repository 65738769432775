import React from "react";
import { QRCodeSVG } from "qrcode.react";
import { Modal, Typography } from "antd";
import { LinkOutlined, CloseOutlined } from "@ant-design/icons";

const { Text, Paragraph } = Typography;

function ModalQR({ modalHandler, modalOpen, isLarge, children, url, bgColor, fgColor }) {
    return (
        <Modal
            open={modalOpen}
            onCancel={modalHandler}
            footer={null}
            centered
            width={isLarge ? "80%" : "400px"}
            style={{
                maxHeight: "350px",
                overflowY: "auto",
                padding: "15px",
                position: "relative",
            }}
            closeIcon={
                <CloseOutlined
                    style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        fontSize: "14px",
                        cursor: "pointer",
                        backgroundColor: "transparent",
                        padding: "8px",
                        borderRadius: "50%",
                        transition: "opacity 0.3s"
                    }}
                    onMouseOver={(e) => e.currentTarget.style.opacity = "0.7"}
                    onMouseOut={(e) => e.currentTarget.style.opacity = "1"}
                />
            }
        >
            {isLarge ? (
                children
            ) : (
                <>
                    <div style={{ marginBottom: "10px", textAlign: "center" }}>
                        <Text strong style={{ fontSize: "14px" }}>
                            Try this experience in
                        </Text>
                        <br />
                        <Text strong style={{ fontSize: "16px", color: "#1890ff" }}>
                            Argumented Reality
                        </Text>
                    </div>
                    <div style={{ marginBottom: "10px", textAlign: "center" }}>
                        <QRCodeSVG
                            value={url}
                            bgColor={bgColor}
                            size={150}
                            fgColor={fgColor}
                            style={{ borderRadius: "4px" }}
                        />
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <Paragraph style={{ marginBottom: "0px" }}>
                            by{" "}
                            <a
                                href="https://www.arshades.it/it/"
                                target="_blank"
                                rel="noreferrer"
                                style={{ color: "#1890ff" }}
                            >
                                Arshades <LinkOutlined />
                            </a>
                        </Paragraph>
                    </div>
                </>
            )}
        </Modal>
    );
}

export default ModalQR;