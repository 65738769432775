import React from "react";
import { theme } from "antd";
import CustomTooltip from "../common/CustomTooltip";

function ColorIndicator({
    primary,
    secondary,
    type = "circle", // "circle" o "square"
    size = "default", // default, small, large
}) {
    const { token } = theme.useToken();

    const indicatorSize = {
        small: 16,
        default: 24,
        large: 32,
    };

    const dimension = indicatorSize[size] || indicatorSize.default;

    const commonStyle = {
        width: dimension,
        height: dimension,
        display: "flex",
        borderRadius: type === "circle" ? "50%" : "4px",
        overflow: "hidden",
        border: `1px solid ${token.colorBorder}`,
    };

    const halfStyle = {
        flex: 1,
        height: "100%",
    };

    return (
        <CustomTooltip title={`Primary: ${primary || "N/A"}, Secondary: ${secondary || "N/A"}`}>
            <div style={commonStyle}>
                <div style={{ ...halfStyle, backgroundColor: primary || token.colorLightGray }} />
                <div style={{ ...halfStyle, backgroundColor: secondary || token.colorLightGray }} />
            </div>
        </CustomTooltip>
    );
};

export default ColorIndicator;