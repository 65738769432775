import {
  GET_EMAILS,
  GET_ALL_EMAILS,
  GET_EMAIL_TYPES,
  UPDATE_EMAIL_TYPES,
} from "../actionType";
import { data } from "../../data/email/email";

const initialState = {
  allEmails: data,
  singleData: [],
  types: [],
  loading: false,
};

const emailReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EMAILS:
      return state;

    case GET_ALL_EMAILS:
      return {
        ...state,
        allEmails: data,
      };

    case GET_EMAIL_TYPES:
      return {
        ...state,
        loading: true,
        types: action.payload,
      };

    case UPDATE_EMAIL_TYPES: {
      const updatedEmails = state.allEmails.map((email) =>
        email.id === action.payload.id
          ? { ...email, favourite: !email.favourite }
          : email
      );

      return {
        ...state,
        loading: true,
        allEmails: updatedEmails,
        singleData: updatedEmails,
      };
    }

    default:
      return state;
  }
};

export default emailReducer;
