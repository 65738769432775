import React, { createContext, useState, useContext, useEffect } from 'react';
import { ConfigProvider } from 'antd';
import { getTheme } from './theme';

// Chiave per salvare la preferenza nel localStorage
const THEME_STORAGE_KEY = 'app_theme_mode';

// Creazione del contesto
const ThemeContext = createContext();

/**
 * Provider per il contesto del tema
 * Gestisce lo stato del tema e fornisce metodi per modificarlo
 */
export const ThemeProvider = ({ children }) => {
    // Inizializza lo stato del tema dal localStorage se disponibile
    const [isDarkMode, setIsDarkMode] = useState(() => {
        const savedTheme = localStorage.getItem(THEME_STORAGE_KEY);
        return savedTheme === 'dark';
    });

    // Aggiorna il localStorage quando cambia il tema
    useEffect(() => {
        localStorage.setItem(THEME_STORAGE_KEY, isDarkMode ? 'dark' : 'light');
    }, [isDarkMode]);

    // Funzione per alternare il tema chiaro/scuro
    const toggleTheme = () => {
        setIsDarkMode(prevMode => !prevMode);
    };

    // Ottiene la configurazione del tema in base alla modalità corrente
    const currentTheme = getTheme(isDarkMode);

    return (
        <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
            <ConfigProvider theme={currentTheme}>
                {children}
            </ConfigProvider>
        </ThemeContext.Provider>
    );
};

/**
 * Hook personalizzato per utilizzare il contesto del tema
 * @returns {{isDarkMode: boolean, toggleTheme: function}} Stato e funzioni del tema
 */
export const useTheme = () => {
    const context = useContext(ThemeContext);
    if (context === undefined) {
        throw new Error('useTheme deve essere utilizzato all\'interno di un ThemeProvider');
    }
    return context;
};