import React, { useEffect, useState } from 'react';
import { Table, Spin, Row, Col, Typography } from 'antd';
import {
    collection,
    doc,
    getDocs,
    getDoc
} from 'firebase/firestore';
import { db } from '../../data/base';
import DbTestComponent from './DbTest';
import SessionAggregator from './SessionAggregator';
import DeleteUserMetrics from './DeleteUserMetrics';

const { Title } = Typography;

const AdminView = () => {
    // Stati principali
    const [loading, setLoading] = useState(false);
    const [clients, setClients] = useState([]);

    // --- FETCH CLIENTS ---
    useEffect(() => {
        const fetchClients = async () => {
            setLoading(true);
            try {
                const clientSnap = await getDocs(collection(db, 'Client'));
                const clientsList = clientSnap.docs.map(docSnap => ({
                    id: docSnap.id,
                    ...docSnap.data()
                }));
                const clientsWithLicenses = await Promise.all(
                    clientsList.map(async client => {
                        if (client.licenseList && Array.isArray(client.licenseList)) {
                            const licensePromises = client.licenseList.map(async licId => {
                                const licRef = doc(db, 'ARShades_Subscription', licId);
                                const licSnap = await getDoc(licRef);
                                return { id: licId, ...licSnap.data() };
                            });
                            client.licenseDataList = await Promise.all(licensePromises);
                        } else {
                            client.licenseDataList = [];
                        }
                        return client;
                    })
                );
                setClients(clientsWithLicenses);
            } catch (err) {
                console.error("Error fetching clients:", err);
            } finally {
                setLoading(false);
            }
        };
        fetchClients();
    }, []);

    // --- RENDER ---
    return (
        <>
            <div>
                <Title level={5} style={{ marginBottom: "20px" }}>ADMIN CONFIGURATION</Title>
            </div>
            <div style={{ padding: 10 }}>
                <DeleteUserMetrics />
                <SessionAggregator />
            </div>
            <div>
                <div>
                    <Title level={5} style={{ marginBottom: "20px", marginTop: "40px" }}>CLIENT LIST</Title>
                </div>
                <div>
                    {loading ? (
                        <Spin />
                    ) : (
                        <Table
                            dataSource={clients}
                            rowKey="id"
                            pagination={false}
                            variant="bordered"
                            size="small"
                            columns={[
                                { title: 'Client ID', dataIndex: 'id', key: 'id' },
                                { title: 'Company Name', dataIndex: 'companyName', key: 'companyName' },
                            ]}
                            expandable={{
                                expandedRowRender: (client) => (
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <h5 style={{ fontStyle: 'italic' }}>Client Details</h5>
                                            <p>Client ID: <strong>{client.id}</strong></p>
                                            <p>Company Name: <strong>{client.companyName}</strong></p>
                                        </Col>
                                        <Col span={12}>
                                            <h5 style={{ fontStyle: 'italic' }}>License List</h5>
                                            {client.licenseDataList && client.licenseDataList.length > 0 ? (
                                                <ul>
                                                    {client.licenseDataList.map(license => (
                                                        <li key={license.id}>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <p>License: {license.subscriptionType || 'N/A'} - <strong>({license.id})</strong></p>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <p>No licenses found for this client.</p>
                                            )}
                                        </Col>
                                    </Row>
                                )
                            }}
                        />
                    )}
                </div>
            </div>
            <div>
                <Title level={5} style={{ marginBottom: "20px", marginTop: "40px" }}>DATABASE TEST</Title>
            </div>
            <div>
                <DbTestComponent />
            </div>
        </>
    );
};

export default AdminView;