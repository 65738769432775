import dayjs from "dayjs";
import { getDataConsumptionUnitValue } from "../utils/dataConsumptionUtils";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export const processBandwidthData = (data, bandwidthType, startDate, endDate, subDate, subEndDate) => {
    const subDateParsed = dayjs(subDate);
    const subEndDateParsed = dayjs(subEndDate);

    if (!subDateParsed.isValid() || !subEndDateParsed.isValid()) {
        console.error("❌ Invalid subscription dates:", subDate, subEndDate);
        throw new Error("Invalid subscription dates");
    }

    const flatData = data.flatMap((item) => [
        ...(bandwidthType === "bandwidth_3dviewer" || bandwidthType === "all" ? item?.bandwidth_3dviewer || [] : []),
        ...(bandwidthType === "bandwidth_array" || bandwidthType === "all" ? item?.bandwidth_array || [] : []),
        ...(bandwidthType === "bandwidth_webVto" || bandwidthType === "all" ? item?.bandwidth_webVto || [] : []),
        ...(bandwidthType === "storage_data" || bandwidthType === "all" ? item?.storage_data || [] : []),
    ]);

    const filteredData = flatData.filter((item) => {
        const year = Number(item?.year);
        const month = Number(item?.month);
        const date = dayjs(`${year}-${month}-01`);

        const isValid = (
            date.isSameOrAfter(subDateParsed, 'month') &&
            date.isSameOrBefore(subEndDateParsed, 'month') &&
            date.isSameOrAfter(startDate, 'month') &&
            date.isSameOrBefore(endDate, 'month')
        );

        return isValid;
    });

    const reducedData = filteredData.reduce((acc, item) => {
        const existingItem = acc.find(
            (accItem) => accItem.year === item?.year && accItem.month === item?.month
        );

        const bandwidthValue = item?.bandwidth_month ??
            getDataConsumptionUnitValue(item?.consumption, item?.month, item?.year);

        if (isNaN(bandwidthValue)) {
            console.warn("⚠️ Found invalid bandwidth value:", bandwidthValue, "for item:", item);
        }

        if (existingItem) {
            existingItem.bandwidth += bandwidthValue;
        } else {
            acc.push({
                year: Number(item?.year),
                month: Number(item?.month),
                bandwidth: bandwidthValue,
            });
        }
        return acc;
    }, []);

    return reducedData;
};

export const processCallsData = (data, bandwidthType, startDate, endDate, subDate) => {
    const subDateParsed = dayjs(subDate);

    if (!dayjs.isDayjs(subDateParsed)) {
        throw new Error("Invalid subDate: Ensure subDate is a valid Day.js object.");
    }

    const flatData = data.flatMap((item) => {
        return [
            ...(bandwidthType === "bandwidth_3dviewer" || bandwidthType === "all"
                ? item?.bandwidth_3dviewer || []
                : []),
            ...(bandwidthType === "bandwidth_array" || bandwidthType === "all"
                ? item?.bandwidth_array || []
                : []),
            ...(bandwidthType === "bandwidth_webVto" || bandwidthType === "all"
                ? item?.bandwidth_webVto || []
                : []),
            ...(bandwidthType === "storage_data" || bandwidthType === "all"
                ? item?.storage_data || []
                : []),
        ];
    });

    const filteredData = flatData.filter((item) => {
        const year = Number(item && item.year);
        const month = Number(item && item.month);
        const date = new Date(year, month - 1, 1);

        return (
            (subDateParsed.isSameOrBefore(date, "day")) &&
            (startDate === null || dayjs(startDate).isSameOrBefore(date, "day")) &&
            (endDate === null || dayjs(endDate).isSameOrAfter(date, "day"))
        );
    });

    const reducedData = filteredData.reduce((acc, item) => {
        const existingItem = acc.find(
            (accItem) => accItem.year === item?.year && accItem.month === item?.month
        );

        const callsValue = item?.calls_number ?? item?.models_number ?? 0;

        if (existingItem) {
            existingItem.calls += callsValue;
        } else {
            acc.push({
                year: Number(item?.year),
                month: Number(item?.month),
                calls: callsValue,
            });
        }
        return acc;
    }, []);
    return reducedData;
};

export const processSDKCallsData = (data, bandwidthType, subDate) => {
    return [
        ...(bandwidthType === "ARPD_meter_app_sdk" || bandwidthType === "all"
            ? data.ARPD_meter_app_sdk || []
            : []),
        ...(bandwidthType === "App_sdk" || bandwidthType === "all"
            ? data.App_sdk || []
            : []),
        ...(bandwidthType === "ARPD_meter_web_sdk" || bandwidthType === "all"
            ? data.ARPD_meter_web_sdk || []
            : []),
    ]
        .flat()
        .filter((item) => {
            const date = dayjs(`${item?.year}-${item?.month}-01`);
            return subDate.isSameOrBefore(date, "day");
        })
        .reduce((acc, item) => {
            const existingItem = acc.find(
                (accItem) =>
                    accItem.year === item?.year && accItem.month === item?.month
            );

            if (existingItem) {
                existingItem.calls += item?.calls || 0;
            } else {
                acc.push({
                    year: Number(item?.year),
                    month: Number(item?.month),
                    calls: item?.calls || 0,
                });
            }

            return acc;
        }, []);
};

export const filterValidConsumption = (snapshot, subscriptions, checkDate, currentDate) => {
    if (!checkDate || !currentDate.isBefore(checkDate)) return [];
    return snapshot.docs
        .filter((doc) => subscriptions?.consumption_list?.consumptionRef === doc.id)
        .map((doc) => doc.data());
};