import React, { useRef, useState, useEffect } from "react";
import styles from "../styles/ARShades3dViewerCustom/ARShades3dsViewerCustom.module.css";

function ARShades3dViewerCustomTwo() {
    const modelRef = useRef(null);
    const [isExploded, setIsExploded] = useState(true); // Initial state is "composed"
    const [isAnimating, setIsAnimating] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        function handleModelLoad() {
            const modelViewer = modelRef.current;
            modelViewer.animationName = "Teardown Inverse";  // Starts in the composed (normal) position
            setIsLoaded(true);
        }

        const modelViewer = modelRef.current;
        if (modelViewer) {
            modelViewer.addEventListener("load", handleModelLoad);
        }

        return () => {
            if (modelViewer) {
                modelViewer.removeEventListener("load", handleModelLoad);
            }
        };
    }, []);

    async function handleImplodeAnimation() {
        if (isAnimating) return;  // Prevent propagation during animation
        const modelViewer = modelRef.current;
        if (modelViewer) {
            setIsAnimating(true);  // Disable the button

            modelViewer.animationName = "Teardown Inverse";  // Trigger "compose" animation
            await modelViewer.updateComplete;

            modelViewer.play({ repetitions: 1 });
            setIsExploded(false);

            setTimeout(() => {
                modelViewer.cameraOrbit = "42.64deg 87.75deg 0.4182m";
                modelViewer.cameraTarget = "-0.09m 0.08m 0.0m";
            }, 1500);

            setTimeout(() => {
                modelViewer.pause();
                setIsAnimating(false);  // Re-enable the button
            }, 4000);
        }
    }

    async function handleExplodedAnimation() {
        if (isAnimating) return;  // Prevent propagation during animation
        const modelViewer = modelRef.current;
        if (modelViewer) {
            setIsAnimating(true);  // Disable the button

            modelViewer.animationName = "Teardown";  // Trigger "teardown" animation
            await modelViewer.updateComplete;

            modelViewer.play({ repetitions: 1 });
            setIsExploded(true);

            setTimeout(() => {
                modelViewer.cameraOrbit = "-0.3707deg 22.5deg 0.9146m";
                modelViewer.cameraTarget = "-0.09m 0.08m 0.0m";
            }, 1500);

            setTimeout(() => {
                modelViewer.pause();
                setIsAnimating(false);  // Re-enable the button
            }, 4000);
        }
    }

    return (
        <div className={styles['md-animation-custom']}>
            <model-viewer
                disable-pan
                camera-controls="true"
                interaction-prompt="none"
                interpolation-decay={300}
                ref={modelRef}
                id="customModel"
                src="https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2FTest%2FCARRERA315S_teardown_armature_9_double%20animation.glb?alt=media&token=acfe814b-db8e-40b3-a4d9-5c182f8144b1"
                alt="Un modello 3D con animazioni e informazioni"
                class={styles['md-container']}
                camera-target="-0.09m 0.08m 0.0m"
                camera-orbit="-0.3707deg 22.5deg 0.9146m"
                field-of-view="26deg"
                tone-mapping="aces"
            >
                {isLoaded && (
                    <button
                        className={styles['md-animation-button']}
                        onClick={isExploded ? handleImplodeAnimation : handleExplodedAnimation}
                        disabled={isAnimating}  // Disable button when animating
                    >
                        {isExploded ? 'Compose' : 'Teardown'}
                        <span className={styles['play-icon']}></span>
                    </button>
                )}
            </model-viewer>
        </div>
    );
}

export default ARShades3dViewerCustomTwo;
