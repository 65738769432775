import React, { useState, useEffect } from 'react';
import { Card, message, theme } from 'antd';
import logo from '../assets/images/logo.png';
import LoginForm from '../components/Login/LoginForm';
import ForgotPasswordForm from '../components/Login/ForgotPasswordForm';
import { useDispatch } from "react-redux";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import {
    doc,
    getDoc,
    collection,
    query,
    where,
    getDocs,
    runTransaction,
} from "firebase/firestore";
import { configActions } from '../redux/config/config';
import { db } from '../data/base';
import { useNavigate } from 'react-router-dom';
import { loginSuccess } from '../redux/authentication/authSlice';
import { secureSetItem } from '../data/utils';

const Login = (props) => {
    const { token } = theme.useToken();
    const [messageApi, contextHolder] = message.useMessage();
    const [isForgotPassword, setIsForgotPassword] = useState(false);
    const [value, setValue] = useState(localStorage.getItem("profileURL") || "man");
    const [name, setName] = useState(localStorage.getItem("Name") || "Elana Saint");
    const [isUser, setIsUser] = useState(localStorage.getItem("isUser") === "true");
    const [role, setRole] = useState(localStorage.getItem("role") || "delegato");
    const [email, setEmail] = useState(null);
    const [refCliente, setRefCliente] = useState("");

    function forgotTrue() {
        setIsForgotPassword(true);
    }

    function forgotFalse() {
        setIsForgotPassword(false);
    }

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const auth = getAuth();

    const onSubmit = async (data) => {
        try {
            const re = await signInWithEmailAndPassword(auth, data.email, data.password);
            const idToken = await re.user.getIdToken();

            await createCustomToken(idToken);
            await postLogin(data.email);
        } catch (error) {
            if (error.code === "auth/multi-factor-auth-required") {
                messageApi.error("MFA required. Please complete the verification.");
            } else {
                messageApi.error(error?.message);
            }
        }
    };

    const postLogin = async (email) => {
        try {
            const userDocRef = doc(db, "Profile", email.toLowerCase());
            const userDoc = await getDoc(userDocRef);

            if (!userDoc.exists()) {
                throw new Error("User document does not exist");
            }

            const userData = userDoc.data();
            const idToken = await auth.currentUser.getIdToken();

            dispatch(loginSuccess({
                user: { uid: auth.currentUser.uid, email },
                token: idToken,
                version: "1.0",
            }));

            setValue("man");
            setName(userData.nome || "");
            setIsUser(true);
            setRole(userData.role || "");
            setEmail(email);
            setRefCliente(userData.clientRef || "");

            // Utilizzo secureSetItem per salvare i dati sensibili in modo crittografato
            secureSetItem("brands", userData.catalogsList || []);
            secureSetItem("role", userData.role || "");
            secureSetItem("email", email);
            secureSetItem("ref_cliente", userData.clientRef || "");
            secureSetItem("profileURL", value);
            secureSetItem("Name", userData.nome || "");
            secureSetItem("isUser", true);

            let listaBrands = [];

            const brandsSnapshot = await getDocs(collection(db, "Brand"));

            if (userData.role === "Admin") {
                // Admin: prende tutti i brand
                listaBrands = brandsSnapshot.docs.map((doc) => doc.id);
            } else {
                // User: filtra solo i brand presenti in catalogsList e non include quelli con "REV"
                const tempListBrand = userData.catalogsList || [];
                listaBrands = brandsSnapshot.docs
                    .filter((doc) => {
                        const nomeBrand = doc.data()?.nome_brand || "";
                        return !nomeBrand.includes("REV") && tempListBrand.includes(doc.id);
                    })
                    .map((doc) => doc.id);
            }

            dispatch(configActions.setListaBrand(listaBrands));

            await writeLoginAccess();
            messageApi.success("Login successful");

            navigate(`${process.env.PUBLIC_URL}/home`);
        } catch (error) {
            console.error("Error during post-login:", error);
            messageApi.error("An error occurred during login. Please contact support.");
        }
    };

    useEffect(() => {
        // Utilizzo secureSetItem al posto di localStorage.setItem per sicurezza
        secureSetItem("profileURL", value);
        secureSetItem("Name", name);
        secureSetItem("isUser", isUser);
        secureSetItem("role", role);
        secureSetItem("email", email);
        secureSetItem("ref_cliente", refCliente);
    }, [value, name, isUser, role, email, refCliente]);

    const writeLoginAccess = async () => {
        if (!email) return;

        try {
            const logAccessCollection = collection(db, "Coll_LogAccess");
            const logAccessQuery = query(logAccessCollection, where("account", "==", email));
            const logDocs = await getDocs(logAccessQuery);

            let docRef = null;
            logDocs.forEach((doc) => {
                docRef = doc.ref;
            });

            if (docRef) {
                await runTransaction(db, async (transaction) => {
                    const tDoc = await transaction.get(docRef);
                    if (!tDoc.exists()) return;

                    const logAccess = tDoc.data().listaLog || [];
                    const date = new Date();
                    const newLog = {
                        data: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`,
                        dispositivo: navigator.userAgent,
                    };

                    logAccess.push(newLog);
                    transaction.update(docRef, { listaLog: logAccess });
                });
            }
        } catch (error) {
            console.error("Error writing login access:", error);
        }
    };

    const createCustomToken = async (idToken) => {
        try {
            const url = `${process.env.REACT_APP_ACTIVE_BASE_TOKEN}?token=${idToken}`;
            const response = await fetch(url);
            const result = await response.json();

            // Salvataggio sicuro del token
            secureSetItem("csToken", result.csToken);
        } catch (error) {
            console.error("Error creating custom token:", error);
            messageApi.error("Dashboard failed to authenticate");
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                background: `radial-gradient(circle at bottom, ${token.colorPrimary} 10%, #241f45 90%)`,
            }}
        >
            {contextHolder}
            <Card
                style={{
                    width: 360,
                    borderRadius: '12px',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                }}
            >
                <div style={{ textAlign: 'center' }}>
                    <img src={logo} alt="logo" />
                </div>
                {isForgotPassword ? <ForgotPasswordForm setIsForgotPassword={forgotFalse} /> : <LoginForm setIsForgotPassword={forgotTrue} onSubmit={onSubmit} />}
            </Card>
        </div>
    );
};

export default Login;