import React, { useEffect, useMemo, useState } from 'react';
import { Card } from 'antd';
import { getLinearGraphData } from '../../data/analytics/chartjsData'
import SummaryGraphs from './SummaryGraphs'
import SummaryCard from './SummaryCard';
import { getTimeInSecs } from '../../services/utils/analyticsUtils';
import dayjs from 'dayjs';
import SummaryGrid from './SummaryGrid';

const overviewLabels = [
    'totalUser',
    'totalSession',
    'totalNewUser',
    'totalAvgTime',
    'totalAvgEndTime',
    'totalAvgSpU',
];

function AnalyticsOverviewCard({ getData, loading, aggregateList = [] }) {
    const [userData, setUserData] = useState({});
    const [newUserData, setNewUserData] = useState({});
    const [sessionData, setSessionData] = useState({});
    const [avgTimeData, setAvgTimeData] = useState({});
    const [avgEngTimeData, setAvgEngTimeData] = useState({});
    const [avgSessionPerUserData, setAvgSessionPerUserData] = useState({});
    const [selectedDataIndex, setSelectedDataIndex] = useState(0);

    const handleDataIndexChange = (index) => {
        setSelectedDataIndex(index);
    };

    const sortArrByDate = ({ data, labels }) => {
        const dataArray = labels.map((label, index) => ({
            label,
            value: data[index],
        }));

        dataArray.sort((a, b) => dayjs(a.label).valueOf() - dayjs(b.label).valueOf());

        return {
            data: dataArray.map((item) => item.value),
            labels: dataArray.map((item) => item.label),
        };
    };

    const sumDuplicates = ({ data, labels }) => {
        const labelSumMap = {};

        for (let i = 0; i < labels.length; i++) {
            const label = labels[i];
            const value = data[i];
            if (labelSumMap[label]) {
                labelSumMap[label] += value;
            } else {
                labelSumMap[label] = value;
            }
        }

        const uniqueLabels = Object.keys(labelSumMap);
        return {
            data: uniqueLabels.map((label) => labelSumMap[label]),
            labels: uniqueLabels,
        };
    };

    const cleanData = ({ data, labels }) => {
        const uniqueData = sumDuplicates({ data, labels });
        const sortedData = sortArrByDate({
            data: uniqueData.data,
            labels: uniqueData.labels,
        });
        return sortedData;
    };

    const convertiSecondiAMinuti = (secondi) => {
        const minuti = Math.floor(secondi / 60);
        const secondiRimanenti = secondi % 60;
        const risultato = minuti + secondiRimanenti / 100;
        return parseFloat(risultato.toFixed(2));
    };

    // LOGICA INVARIATA: useEffect PER ELABORARE I DATI
    useEffect(() => {
        const userRecord = {};
        const newUserRecord = {};
        const sessionRecord = {};
        const avgTimeRecord = {};
        const avgEngTimeRecord = {};
        const avgSessionPerUserRecord = {};

        let date = dayjs();
        let key = '';
        let data = null;
        let sortedData = {};

        let totalUsers = 0;
        let totalNewUsers = 0;
        let totalSession = 0;
        let totalAvgEngTime = 0;
        let totalAvgSessionTime = 0;
        let totalSessionPeruser = 0;

        aggregateList.forEach((item) => {
            // Converti la data in formato dayjs
            const dateF = dayjs(item.date);
            date = `${dateF.format('DD/MMM/YYYY')}`;

            // Somma i totali
            totalUsers += item.totalUniqueUsers;
            totalSession += item.totalSessions;
            totalNewUsers += item.newUsersCount;
            totalAvgSessionTime += getTimeInSecs(item.avgSessionTime) * item.totalSessions;
            totalSessionPeruser += item.averageSessionsPerUser;
            totalAvgEngTime += getTimeInSecs(item.avgEngagementTime);

            key = `${date}`;
            userRecord[date] = userRecord[date] ? userRecord[date] + item.totalUniqueUsers : item.totalUniqueUsers;
            newUserRecord[date] = newUserRecord[date] ? newUserRecord[date] + item.newUsersCount : item.newUsersCount;
            sessionRecord[date] = sessionRecord[date] ? sessionRecord[date] + item.totalSessions : item.totalSessions;

            if (!avgTimeRecord[date]) {
                avgTimeRecord[date] = { totalSeconds: 0, totalSessions: 0 };
            }
            avgTimeRecord[date].totalSeconds += getTimeInSecs(item.avgSessionTime) * item.totalSessions;
            avgTimeRecord[date].totalSessions += item.totalSessions;

            if (!avgEngTimeRecord[date]) {
                avgEngTimeRecord[date] = 0;
            }
            avgEngTimeRecord[date] += getTimeInSecs(item.avgEngagementTime) / item.totalUniqueUsers;

            avgSessionPerUserRecord[date] =
                (avgSessionPerUserRecord[date] || 0) +
                (item.totalUniqueUsers === 0 ? 0 : item.totalSessions / item.totalUniqueUsers);
        });

        const avgTimeRecordCopy = JSON.parse(JSON.stringify(avgTimeRecord));

        // Calcola i tempi medi finali dopo il loop
        Object.keys(avgTimeRecord).forEach((date) => {
            avgTimeRecord[date] = convertiSecondiAMinuti(
                avgTimeRecord[date].totalSeconds / avgTimeRecord[date].totalSessions
            );
        });

        // Converte avgEngTimeRecord in minuti e limita a 2 decimali
        Object.keys(avgEngTimeRecord).forEach((date) => {
            avgEngTimeRecord[date] = convertiSecondiAMinuti(avgTimeRecordCopy[date].totalSeconds / userRecord[date]);
        });

        // Imposta i dati utente
        data = getLinearGraphData('User', userRecord);

        // Somma duplicati e ordina
        sortedData = cleanData({
            data: data.datasets[0].data,
            labels: data.labels,
        });
        data.labels = sortedData.labels;
        data.datasets[0].data = sortedData.data;

        setUserData({ data, total: totalUsers });

        // Imposta i dati dei nuovi utenti
        data = getLinearGraphData('New User', newUserRecord);

        // Somma duplicati e ordina
        sortedData = cleanData({
            data: data.datasets[0].data,
            labels: data.labels,
        });
        data.labels = sortedData.labels;
        data.datasets[0].data = sortedData.data;

        setNewUserData({ data, total: totalNewUsers });

        // Imposta i dati delle sessioni
        data = getLinearGraphData('Session', sessionRecord);
        sortedData = sortArrByDate({
            data: data.datasets[0].data,
            labels: data.labels,
        });
        data.labels = sortedData.labels;
        data.datasets[0].data = sortedData.data;
        setSessionData({ data, total: totalSession });

        // Imposta i dati del tempo medio
        data = getLinearGraphData('Avg Time', avgTimeRecord);
        sortedData = cleanData({
            data: data.datasets[0].data,
            labels: data.labels,
        });
        data.labels = sortedData.labels;
        data.datasets[0].data = sortedData.data;

        setAvgTimeData({
            data,
            total: convertiSecondiAMinuti(totalAvgSessionTime / totalSession),
        });

        // Imposta i dati del tempo di coinvolgimento medio
        data = getLinearGraphData('Avg Eng Time', avgEngTimeRecord);
        sortedData = cleanData({
            data: data.datasets[0].data,
            labels: data.labels,
        });
        data.labels = sortedData.labels;
        data.datasets[0].data = sortedData.data;

        setAvgEngTimeData({
            data,
            total: convertiSecondiAMinuti(totalAvgSessionTime / totalUsers),
        });

        // Imposta i dati delle sessioni per utente
        data = getLinearGraphData('Avg S/U', avgSessionPerUserRecord);
        sortedData = cleanData({
            data: data.datasets[0].data,
            labels: data.labels,
        });
        data.labels = sortedData.labels;
        data.datasets[0].data = sortedData.data;

        setAvgSessionPerUserData({
            data,
            total: totalSession !== 0 && totalUsers !== 0 ? (totalSession / totalUsers).toFixed(2) : 0,
        });
    }, [aggregateList]);

    // LISTA DEI DATI PER I GRAFICI
    const graphList = useMemo(() => [
        userData,
        sessionData,
        newUserData,
        avgTimeData,
        avgEngTimeData,
        avgSessionPerUserData,
    ], [userData, sessionData, newUserData, avgTimeData, avgEngTimeData, avgSessionPerUserData]);

    const tooltipTextMap = {
        userData: "The number of distinct who visited the app",
        sessionData: "Sum of user sessions",
        newUserData: "The number of distinct who visited the app for the first time",
        avgTimeData: "The average length of time that the app was in the foreground during each session",
        avgEngTimeData: "The average length of time that the app was in the foreground for each unique user",
        avgSessionPerUserData: "The average number of sessions per unique user",
    };

    const graphData = graphList[selectedDataIndex]?.data;

    useEffect(() => {
        const data = {
            user: userData?.data?.datasets[0].data,
            session: sessionData?.data?.datasets[0].data,
            newUser: newUserData?.data?.datasets[0].data,
            avgTime: avgTimeData?.data?.datasets[0].data,
            avgEngTime: avgEngTimeData?.data?.datasets[0].data,
            avgSpU: avgSessionPerUserData?.data?.datasets[0].data,
            dates: userData?.data?.labels,
        };

        for (let i = 0; i < overviewLabels.length; i++) {
            data[overviewLabels[i]] = graphList[i]?.total || 0;
        }
        getData(data);
    }, [graphData, graphList]);

    return (
        <>
            <SummaryGrid>
                {graphList.map((item, idx) => {
                    const tooltipKey = Object.keys(tooltipTextMap).find(
                        (key) => item === eval(key)
                    );
                    const tooltipText = tooltipTextMap[tooltipKey];

                    return (
                        <div key={`${item?.total || 0}_${idx}`}>
                            <SummaryCard
                                loading={loading}
                                value={item?.total || 0}
                                active={idx === selectedDataIndex}
                                label={item.data?.datasets[0].label}
                                onClick={() => handleDataIndexChange(idx)}
                                toolTipText={tooltipText}
                            />
                        </div>
                    );
                })}
            </SummaryGrid>

            <Card title="Overview">
                <SummaryGraphs
                    data={graphData || { datasets: [], labels: [] }}
                    isLoading={loading}
                />
            </Card>
        </>
    );
};

export default AnalyticsOverviewCard;