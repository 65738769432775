import React, { useEffect, useState, useRef, forwardRef } from "react";
import { useParams } from "react-router-dom";
import { db } from "../data/base";
import gsap from "gsap";
import play_icon from "../assets/icon/play_icon.svg";
import styles from "../styles/ARShades3dsTemplate/ARShades3dsTamplate.module.css";
import { doc, getDoc } from "firebase/firestore";

const ARShades3dsTemplate = forwardRef((props, ref) => {
    const { templateId, variantId } = useParams();
    const modelViewerRef = useRef(null);
    const hotspotContentRef = useRef(null);
    const [steps, setSteps] = useState([]);
    const [modelUrl, setModelUrl] = useState("");
    const [animationAvailable, setAnimationAvailable] = useState(false);
    const [animationStarted, setAnimationStarted] = useState(false);
    const [isTablet, setIsTablet] = useState(false);
    const [currentHotspotIndex, setCurrentHotspotIndex] = useState(null); // Inizializzato a null
    const [visibleContentIndex, setVisibleContentIndex] = useState(null);
    const [visibleHotspots, setVisibleHotspots] = useState([]);
    const [animationButtonText, setAnimationButtonText] = useState("EXPAND");
    const [animationButtonTextBack, setAnimationButtonTextBack] = useState("COME BACK");
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [animationReversed, setAnimationReversed] = useState(false);
    const [animationCompleted, setAnimationCompleted] = useState(false);
    const [isAnimationInProgress, setIsAnimationInProgress] = useState(false); // Nuova variabile di stato

    useEffect(() => {
        const fetchTemplateData = async () => {
            try {
                const templateRef = doc(db, "3DViewerAnimation", templateId);
                const templateSnap = await getDoc(templateRef);

                if (templateSnap.exists()) {
                    const variantRef = doc(db, "3DViewerAnimation", templateId, "Variant", variantId);
                    const variantSnap = await getDoc(variantRef);

                    if (variantSnap.exists()) {
                        const variantData = variantSnap.data();
                        setSteps(variantData.steps || []);
                        setModelUrl(variantData.modelUrl || "");
                    } else {
                        console.error("Variant not found");
                    }
                } else {
                    console.error("Template not found");
                }
            } catch (error) {
                console.error("Error fetching template data:", error);
            }
        };

        fetchTemplateData();
    }, [templateId, variantId]);

    useEffect(() => {
        if (modelViewerRef.current) {
            const modelViewer = modelViewerRef.current;
            const handleInteraction = () => {
                modelViewer.removeEventListener("pointerdown", handleInteraction);
            };

            modelViewer.addEventListener("pointerdown", handleInteraction);

            return () => {
                modelViewer.removeEventListener("pointerdown", handleInteraction);
            };
        }
    }, [modelViewerRef]);

    useEffect(() => {
        const detectTablet = () => {
            const width = window.innerWidth;
            return width < 1024; // Regola i valori secondo le tue esigenze
        };
        setIsTablet(detectTablet());
    }, []);

    const startAnimation = async () => {
        const modelViewer = modelViewerRef.current;
        modelViewer.addEventListener("finished", handleAnimationFinished);

        if (!animationStarted && animationAvailable && !animationCompleted) {
            modelViewer.animationName = "explosion_glasses_an_1";
            modelViewer.timeScale = 1; // Assicura che timeScale sia 1 per l'animazione in avanti
            modelViewer.interpolationDecay = 200;

            modelViewer.cameraOrbit = "0deg 147.6deg 0.6727m";
            modelViewer.fieldOfView = "37deg";
            modelViewer.cameraTarget = "0.0m 0.03m 0.030m";

            setTimeout(() => {
                setAnimationStarted(true);
                setAnimationReversed(false);
                setVisibleHotspots(steps.map((_, index) => index));
                setIsButtonDisabled(true);
                setAnimationButtonText(animationButtonTextBack);

                setIsAnimationInProgress(true); // L'animazione è iniziata

                modelViewer.play({ repetitions: 1 });

                setTimeout(() => {
                    modelViewer.pause();
                    setIsButtonDisabled(false);
                    modelViewer.currentTime = 3.000000000000003;
                    setIsAnimationInProgress(false); // L'animazione è terminata
                }, 3000);
            }, 400);
        } else if (animationCompleted) {
            resetAnimation();
        }
    };

    const resetAnimation = () => {
        const modelViewer = modelViewerRef.current;
        setIsButtonDisabled(true);

        modelViewer.timeScale = -1; // Assicura che timeScale sia -1 per l'animazione inversa
        modelViewer.play({ repetitions: 1 });

        setIsAnimationInProgress(true); // L'animazione inversa è iniziata

        const handleResetAnimationFinished = () => {
            modelViewer.removeEventListener("finished", handleResetAnimationFinished);
            // Non reimpostare timeScale qui

            // Reimposta le proprietà della fotocamera
            modelViewer.cameraOrbit = "20.26deg 87.4deg 0.4259m";
            modelViewer.fieldOfView = "45deg";
            modelViewer.cameraTarget = "0.0m 0.03m 0.01m";

            // Reimposta lo stato
            setVisibleHotspots([]); // Nascondi gli hotspot durante il reset
            setVisibleContentIndex(null);
            setAnimationCompleted(false);
            setAnimationButtonText("EXPAND");
            setAnimationStarted(false);
            setIsButtonDisabled(false);
            setAnimationReversed(false);

            // Reimposta timeScale a 1 dopo aver reimpostato lo stato
            modelViewer.timeScale = 1;

            setIsAnimationInProgress(false); // L'animazione inversa è terminata

            setCurrentHotspotIndex(null); // Reimposta l'hotspot selezionato
        };

        modelViewer.addEventListener("finished", handleResetAnimationFinished);
    };

    const reverseAnimation = () => {
        const modelViewer = modelViewerRef.current;
        modelViewer.timeScale = -1; // Imposta timeScale a -1 per l'animazione inversa
        modelViewer.play({ repetitions: 1 });
        setAnimationReversed(true);
        setIsButtonDisabled(true);
        setIsAnimationInProgress(true); // L'animazione inversa è iniziata

        const handleReverseAnimationFinished = () => {
            modelViewer.removeEventListener("finished", handleReverseAnimationFinished);
            // Non reimpostare timeScale qui
            setIsButtonDisabled(false);
            setIsAnimationInProgress(false); // L'animazione inversa è terminata
        };

        modelViewer.addEventListener("finished", handleReverseAnimationFinished);
    };

    // Definizione della funzione 'handleAnimationFinished'
    const handleAnimationFinished = () => {
        setIsButtonDisabled(false);
        setAnimationStarted(false);
        setAnimationReversed(false);
        setAnimationCompleted(true);
    };

    useEffect(() => {
        if (visibleContentIndex !== null && hotspotContentRef.current) {
            // Animazione di ingresso quando il contenuto viene montato
            animateContentIn(hotspotContentRef.current);
        }
    }, [visibleContentIndex]);

    const handleHotspotClick = (index) => {
        if (isAnimationInProgress) return; // Non fare nulla se un'animazione è in corso

        setCurrentHotspotIndex(index); // Imposta l'hotspot attualmente selezionato

        if (visibleContentIndex !== null && hotspotContentRef.current) {
            // Animazione di uscita
            animateContentOut(hotspotContentRef.current, () => {
                setVisibleContentIndex(index);
                // Animazione di ingresso
                animateContentIn(hotspotContentRef.current);
            });
        } else {
            // Nessun contenuto visibile, mostra direttamente il nuovo contenuto con animazione
            setVisibleContentIndex(index);
            animateContentIn(hotspotContentRef.current);
        }

        // Imposta le proprietà della fotocamera in base allo step selezionato
        if (modelViewerRef.current && steps[index]) {
            const modelViewer = modelViewerRef.current;
            const step = steps[index];
            modelViewer.cameraOrbit = step.cameraOrbit;
            modelViewer.cameraTarget = step.cameraTarget;
            modelViewer.fieldOfView = step.fieldOfView;
        }

        // Avvia l'animazione inversa ma mantieni lo stato espanso
        if (animationStarted && !animationReversed) {
            reverseAnimation();
        }
    };

    // Definizione delle funzioni 'animateContentIn' e 'animateContentOut'
    const animateContentIn = (element) => {
        const startX = isTablet ? "100vw" : "100vw";
        const endX = isTablet ? "-50%" : "-50%";

        gsap.fromTo(
            element,
            { x: startX, opacity: 0 }, // Partenza fuori schermo a destra
            { x: endX, opacity: 1, duration: 1, ease: "power3.out" } // Entrata e centratura tramite GSAP
        );
    };

    const animateContentOut = (element, onComplete) => {
        const endX = isTablet ? "-100vw" : "-100vw";

        gsap.to(element, {
            x: endX, // Uscita verso sinistra, fuori schermo
            opacity: 0,
            duration: 0.5,
            ease: "power3.in",
            onComplete: onComplete,
        });
    };

    useEffect(() => {
        if (modelViewerRef.current !== null) {
            const modelViewer = modelViewerRef.current;

            modelViewer.addEventListener("load", () => {
                if (modelViewer.cameraOrbit) {
                    modelViewer.cameraOrbit = "20.26deg 87.4deg 0.4259m";
                    modelViewer.fieldOfView = "45deg";
                }

                modelViewer.maxCameraOrbit = "auto auto 0.5m";
                modelViewer.minCameraOrbit = "auto auto 0.3m";

                if (modelViewer.availableAnimations[0]) {
                    if (modelViewer.availableAnimations[0] === "explosion_glasses_an_1") {
                        modelViewer.animationName = "explosion_glasses_an_1";
                        setAnimationAvailable(true);
                    } else {
                        modelViewer.animationName = "Clip on full";
                    }
                }
            });
        }
    }, []);

    return (
        <div className={`${styles.containerModelviewer} ${animationStarted ? styles.expandedContainer : ""}`}>
            <model-viewer
                ref={modelViewerRef}
                className={animationStarted ? styles.expandedModelViewer : ""}
                style={{
                    width: "100%",
                    position: "absolute",
                    height: "100%",
                    transition: "height 0.5s ease",
                    display: "absolute",
                }}
                interaction-prompt="none"
                disable-pan
                exposure="1.1"
                src={modelUrl}
                tone-mapping="aces"
                shadow-intensity="0"
                interpolation-decay="1000"
                camera-controls
                camera-orbit="0deg 117.6deg 0.6727m"
                field-of-view="45deg"
                camera-target="0.0m 0.03m 0.01m"
            >
                <div slot="progress-bar">
                    <div className={styles.updateBar}></div>
                </div>

                {steps.length > 0 &&
                    steps.map((step, index) => (
                        <button
                            key={step.id}
                            className={`${styles.pulsingCircle} ${visibleHotspots.includes(index) ? "" : styles.hotspotHidden
                                } ${isAnimationInProgress ? styles.hidden : ""} ${currentHotspotIndex === index ? styles.hidden : ""
                                }`} // Nasconde l'hotspot se è quello selezionato
                            slot={`hotspot-${step.id}`}
                            data-visibility-attribute="visible"
                            data-surface={step?.dataPosition}
                            onClick={() => handleHotspotClick(index)}
                        >
                            {step.id}
                        </button>
                    ))}
            </model-viewer>

            {animationAvailable && (
                <button
                    className={`${styles.clippedButton} ${isAnimationInProgress ? styles.hidden : ""
                        }`} // Nasconde il pulsante durante l'animazione
                    onClick={animationStarted || animationCompleted ? resetAnimation : startAnimation}
                    disabled={isButtonDisabled || isAnimationInProgress}
                >
                    <img src={play_icon} alt="Play Icon" className={styles.clippedIconButton} />
                    {animationButtonText}
                </button>
            )}
            {visibleContentIndex !== null && steps[visibleContentIndex]?.content && (
                <div ref={hotspotContentRef} className={styles.hotspotContent}>
                    {steps[visibleContentIndex]?.content}
                </div>
            )}
        </div>
    );
});

export default ARShades3dsTemplate;
