import { createSlice } from "@reduxjs/toolkit";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const authSlice = createSlice({
    name: "auth",
    initialState: {
        isAuthenticated: false,
        user: null,
        token: null,
        version: null,
        loading: true,
        permissions: {
            canPlaceOrders: false,
            profileURL: "",
            name: "",
            role: "",
            email: "",
            clientRef: "",
        },
    },
    reducers: {
        loginSuccess: (state, action) => {
            const { user, token } = action.payload;
            state.isAuthenticated = true;
            state.user = { uid: user.uid, email: user.email };
            state.token = token;
            state.version = action.payload.version;
            state.loading = false;
        },
        logoutSuccess: (state) => {
            state.isAuthenticated = false;
            state.user = null;
            state.token = null;
            state.version = null;
            state.loading = false;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        updateUserPermissions: (state, action) => {
            state.permissions = {
                ...state.permissions,
                ...action.payload,
            };
        },
    },
});

// Thunk per inizializzare l'autenticazione
export const initializeAuth = () => async (dispatch) => {
    const auth = getAuth();

    // Imposta lo stato di caricamento
    dispatch(setLoading(true));

    // Ascolta i cambiamenti dello stato di autenticazione
    onAuthStateChanged(auth, async (user) => {
        if (user) {
            try {
                const token = await user.getIdToken();

                // Aggiorna Redux con i dati dell'utente
                dispatch(
                    loginSuccess({
                        user: { uid: user.uid, email: user.email },
                        token: token,
                        version: "1.0", // Puoi personalizzare questa versione
                    })
                );
            } catch (error) {
                console.error("Error retrieving user token:", error);
                dispatch(logoutSuccess());
            }
        } else {
            // Se l'utente non è autenticato
            dispatch(logoutSuccess());
        }

        // Fine del caricamento
        dispatch(setLoading(false));
    });
};

export const { loginSuccess, logoutSuccess, setLoading, updateUserPermissions } = authSlice.actions;
export default authSlice.reducer;