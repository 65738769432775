import React, { useState } from "react";
import { Input, Button, Typography, Space, Alert, theme } from "antd";

const { Text } = Typography;

function Password({ password, unlock }) {
    const [pwdInput, setPwdInput] = useState("");
    const [error, setError] = useState(false);
    const { token } = theme.useToken();

    const handleSubmit = () => {
        setError(false);
        if (pwdInput === password) {
            unlock();
        } else {
            setError(true);
        }
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                background: "#f0f2f5",
            }}
        >
            <Space
                direction="vertical"
                align="center"
                style={{
                    padding: "24px",
                    background: token.white,
                    borderRadius: "8px",
                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
                    textAlign: "center",
                }}
            >
                <Text strong style={{ fontSize: "16px" }}>
                    Enter Password
                </Text>
                <Input.Password
                    placeholder="Password"
                    value={pwdInput}
                    onChange={(e) => setPwdInput(e.target.value)}
                    style={{ width: "300px" }}
                />
                <Button type="primary" onClick={handleSubmit}>
                    Submit
                </Button>

                {error && (
                    <Alert
                        type="error"
                        message="Wrong Password"
                        showIcon
                        style={{ marginTop: "16px", width: "300px" }}
                    />
                )}
            </Space>
        </div>
    );
}

export default Password;
