import React, { useState } from 'react';
import { Button, Input, Form, message } from 'antd';
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from '../../data/base';

const ForgotPasswordForm = ({ setIsForgotPassword }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const handleConfirm = async (values) => {
        setLoading(true);
        const { email } = values;

        try {
            await sendPasswordResetEmail(auth, email);
            messageApi.success("Password reset email sent. Please check your inbox.");
            form.resetFields(['email']);
            setLoading(false);
        } catch (error) {
            messageApi.error("Failed to send password reset email. Check your email address.");
            console.error(error);
            form.resetFields(['email']);
            setLoading(false);
        }
    };

    return (
        <div>
            {contextHolder}
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <h2 style={{ fontSize: '24px', fontWeight: 'bold' }}>Forgot Password?</h2>
                <p style={{ fontSize: '16px', color: '#666' }}>
                    We will send you a link to reset your password.
                </p>
            </div>
            <Form
                form={form}
                layout="vertical"
                onFinish={handleConfirm}
                autoComplete="on"
                requiredMark={false}
            >
                <Form.Item
                    label={<span style={{ fontWeight: 'bold' }}>Email address</span>}
                    name="email"
                    style={{ marginBottom: '25px' }}
                    rules={[
                        {
                            required: true,
                            message: 'Email is required',
                        },
                        {
                            validator: (_, value) =>
                                !value || /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
                                    ? Promise.resolve()
                                    : Promise.reject(new Error('Please enter a valid email address')),
                        },
                    ]}
                >
                    <Input placeholder="Email address" />
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        block
                        loading={loading}
                        style={{
                            height: '40px',
                            fontWeight: 'bold'
                        }}
                        htmlType="submit"
                    >
                        Confirm
                    </Button>
                </Form.Item>

                <Button
                    block
                    style={{
                        height: '40px',
                        fontWeight: 'bold',
                        marginBottom: '30px',
                    }}
                    onClick={() => setIsForgotPassword(false)}
                >
                    Back to Login
                </Button>
            </Form>
        </div>
    );
};

export default ForgotPasswordForm;
