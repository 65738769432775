import authReducer from "./authentication/authSlice";
import analyticsReducer from "./analytics/reducer";
import brandReducer from "./brand/reducer";
import catalogReducer from "./catalog/reducer";
import cartReducer from "./cart/reducer";
import chatReducer from "./chat-app/reducer";
import customizerReducer from "./customizer/reducer";
import emailReducer from "./email-app/reducer";
import filtersReducer from "./filter/reducer";
import productReducer from "./product/reducer";
import todoReducer from "./todo/todo.reducer";
import wishlistReducer from "./wishlist/reducer";
import config from "./config/config";
import generalConfig from "./config/generalConfig";
import occhiale from "./occhiale/occhiale";
import taglia from "./occhiale/taglia";
import modello from "./occhiale/modello";
import brandViewer from "./brandViewer/brandViewer";
import modelGlasses from "./brandViewer/modelGlasses";
import profileReducer from "./profile/reducer";
import sidebarReducer from "./sidebar";

const reducers = {
  auth: authReducer,
  analytics: analyticsReducer,
  brand: brandReducer,
  catalog: catalogReducer,
  cart: cartReducer,
  chat: chatReducer,
  customizer: customizerReducer,
  email: emailReducer,
  filters: filtersReducer,
  product: productReducer,
  todo: todoReducer,
  wishlist: wishlistReducer,
  config,
  generalConfig,
  occhiale,
  taglia,
  modello,
  brandViewer,
  modelGlasses,
  profile: profileReducer,
  sidebar: sidebarReducer,
};

export default reducers;